import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { EventEmitter } from "@billjs/event-emitter";
import { PublicProfile, RoomUser } from "../models/Users";
import { AuthService } from "../services/auth-service/auth.service";
import { LogService } from "../services/log-service/log.service";
import { UserObjectDataEvent, UserObjectDataEventTypes, UserObjectEvent, UserObjectState } from "./userObjectEnum";
import * as moment from 'moment';
import Peer, { DataConnection, MediaConnection } from "peerjs";
import { Room, RoomRoster } from "../models/Room";
import { DeviceService } from "../services/device-service/device.service";
import { NgZone } from "@angular/core";
import { StunServiceService } from "../services/stun-service/stun-service.service";
import { AgoraService } from "../services/agora-service/agora-service";


export class UserObject extends EventEmitter {
    rid: string;
    uid: string;
    userRosterRef: AngularFirestoreDocument;
    user: RoomRoster;
    state: UserObjectState;
    connection?: DataConnection;
    peer?: Peer;
    calls: MediaConnection[] = [];
    streams: MediaStream[] = [];
    extraStreams: MediaStream[] = [];
    profile: PublicProfile;
    creation = moment.now();
    userForcedMute = false;
    // Video 
    videoEnabled = false;
   
    // Audio 
    muted = true;
    localMute = false;
    audioContext: AudioContext;
    previousVolume: number = 0; // Holds the audio level 0 - 100
    audioSrcNode: any;
    analyserNode: any;

    // Agora
    agoraAutoTrack: any;
    agoraVideoTrack: any;
    constructor(rid: string, uid: string, 
        public afs: AngularFirestore,
        public logSvc: LogService,
        public dvcSvc: DeviceService,
        public agoraSvc: AgoraService,
        public authSvc: AuthService,
        public stunSvc: StunServiceService,
        private ngZone: NgZone, ) {
        super();
        this.rid = rid;
        this.uid = uid;
        this.initiate();
    }

    async initiate() {
        this.logSvc.info('User Init');
        
        this.userRosterRef = this.afs.doc(
            `room/${this.rid}/roster/${this.uid}`
          );
        
        await this.setupSubscriptions();
    }

    async setupSubscriptions() {

        this.authSvc.user.subscribe( (user) => {
          if(user) {
            if(this.uid === this.authSvc.localUser.uid && this.state != UserObjectState.ready) {
              this.setState(UserObjectState.init); 
            }
          }
          
            
            if(user && !this.state) {
              this.setState(UserObjectState.init);
              
            }
        });

        if(this.agoraSvc) {
          this.agoraSvc.agoraEvents$.subscribe( async (evt) => {
            if(!evt.user) {
              evt.user = {uid: evt.uid};
            }
            // Any events that come from the agora service will be handled here
            if(evt.user.uid === this.uid) {
              if(evt.type === 'user-joined') {
                
                this.setState(UserObjectState.ready);
              }

              if(evt.type === 'user-published') {
                
                if(evt.mediaType == "audio") {
                  
                  this.agoraAutoTrack = evt.user.audioTrack;
                  if(this.agoraAutoTrack.muted) {
                    this.muted = true;
                  }
                  this.agoraAutoTrack.play();
                  this.muteToggle();
                }

                if(evt.mediaType == "video") {
                  this.agoraVideoTrack = evt.user.videoTrack;
                  this.toggleVideo();
                }
                
              }
              if(evt.type === 'user-unpublished') {
               
              
                if(evt.mediaType == "video") {
                  this.agoraVideoTrack = evt.user.videoTrack;
                 
                  
                  this.toggleVideo();
                }

                if(evt.mediaType == 'audio') {
                  this.agoraAutoTrack = null;
                  this.muteToggle();
                }
                
              }

              if(evt.type === 'volume-indicator') {
                if(evt.uid === this.uid) {
                  this.remoteUpdateVolume(evt.level);
                }
              }

              if(evt.type === 'user-left') {
                
                await this.setState(UserObjectState.disconnected);
              }

              if(evt.type === 'user-info-update') {
              

                
              }

            }
          });

          if(this.agoraSvc.userEvents[this.uid]) {
            this.agoraSvc.userEvents[this.uid].subscribe( async (evt) => {
              // Any events that come from the agora service will be handled here
              if(evt.uid === this.uid) {
                
              }
            });
          }
        }

      // Window Subscriptions
      // Setup Window events for if the window is closed, or goes offline 
      window.addEventListener('beforeunload', async (event) => {
        await this.setState(UserObjectState.disconnected);
      });
      // window offline event
      window.addEventListener('offline', async (event) => {
        await this.setState(UserObjectState.disconnected);
      });
      // window online event
      window.addEventListener('online', async (event) => {
        await this.setState(UserObjectState.init);
      });
    }

    async agoraUserEvents(evt) {
     
      
    }

    async setState(state: UserObjectState) {
        return new Promise( async (resolve, reject) => {
            this.logSvc.info('UserObject State Change - ' + state);
            this.state = state;
            this.fire(UserObjectEvent.userStateChanged, {state, uid: this.uid, rid: this.rid});
            switch(state) {
                case UserObjectState.init:
                    await this.getRosterItem();
                    await this.updateProfile();
                    if(this.uid == this.authSvc.localUser.uid ) {
                        this.muteToggle();
                        this.streams.push(this.dvcSvc.mainStream);
                        this.setState(UserObjectState.ready);
                        resolve(this);
                        break;
                    }
                    this.setState(UserObjectState.loading);
                    resolve(this);
                    break;
                case UserObjectState.loading:
                    // old internal service call
                    //await this.startConnection();
                    resolve(this);
                    break;
                case UserObjectState.ready:
                    await this.checkAudioLevelsStart();
                    
                    resolve(this);
                    break;
                case UserObjectState.disconnected:
                    this.disconnect();
                    resolve(this);
                    break;
                case UserObjectState.reconnecting:
                    await this.reconnect();
                    resolve(this);
                    break;
            }
        })
    }

    async getRosterItem(): Promise<RoomRoster> {
        return new Promise( (resolve, reject) => {
            this.userRosterRef.get().subscribe( userRef => {
                const tmpRoster = userRef.data() as RoomRoster;
                if(tmpRoster) {
                    this.user = tmpRoster;
                    resolve(this.user);
                } else {
                  resolve(null);
                }
                
                
            })
        })
       
    }

    async reconnect() {
        return new Promise( async (resolve, reject) => {
          try {
            this.logSvc.info('userObject - reconnect');
            await this.disconnect();
            await this.setState(UserObjectState.init);
            resolve(null);
          } catch(e) {
    
          }
        })
    }

    async updateProfile(): Promise<PublicProfile> {
        return new Promise( (resolve, reject) => {
            this.logSvc.info('UserObject - updateProfile ' + this.uid );
            this.afs.doc(`publicProfiles/${this.uid}`).get().subscribe( (response) => {
                if(response.data()) {
                    let tmpProfile = this.mapProfile(response.data());
                    this.profile = tmpProfile;
                    this.fire(UserObjectEvent.userProfileChanged, this.profile);
                    resolve(this.profile);
                } else {
                    this.fire(UserObjectEvent.userProfileChanged, this.profile);
                    resolve(this.profile);
                }
            })
        })
    }

    async updateUserProfileRecord(props: Partial<PublicProfile>) {
      return new Promise( async (resolve, reject) => {
        this.logSvc.info('UserObject - Profile Update' + this.uid);
        try {
          props.lastUpdated = moment.now();
          props.uid = this.uid;
          const result = await this.afs.doc(`publicProfiles/${this.uid}`).update(props);
          this.profile = {...this.profile, ...props};
          this.fire(UserObjectEvent.userProfileChanged, this.profile);
          resolve(result);
        } catch(e) {
          this.logSvc.error('updateUserProfileRecord Error - ' + e);
          const result = await this.afs.doc(`publicProfiles/${this.uid}`).set(props);
          // @ts-ignore
          this.profile = {...this.profile, ...props};
          this.fire(UserObjectEvent.userProfileChanged, this.profile);
          resolve(result);
          
        }
        
      })

    }

    async checkAudioLevelsStart() {
        setTimeout( () => {
            
            this.audioContext = new AudioContext();
            if(this.streams.length) {
                this.audioSrcNode =  this.audioContext.createMediaStreamSource(this.streams[0]);
                this.analyserNode = this.audioContext.createAnalyser();
                this.audioSrcNode.connect(this.analyserNode);
                this.updateAudioLevels();
            }
            
        }, 500);
        
    }

    async updateAudioLevels() {
      
        const pcmData = new Float32Array(this.analyserNode.fftSize);
        this.analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        // @ts-ignore
        for (const amplitude of pcmData) { sumSquares += (amplitude*amplitude); }
        let finalVolume = Math.sqrt(sumSquares / pcmData.length) * 1000;
        let previousDiff = Math.abs(this.previousVolume - finalVolume);
        if ( previousDiff > 0.05) {
            this.previousVolume = Math.round(finalVolume);
            await this.audioLevelBroadcast(finalVolume*100);
            this.fire(UserObjectEvent.audioLevelUpdate, this.previousVolume);
        }
        if(!this.calls.length) {
            setTimeout( this.updateAudioLevels.bind(this), 100);
        }
        return;
    }

    async audioLevelBroadcast(finalVolume: number) {
      await this.sendEvent(UserObjectDataEventTypes.audioLevel, this.uid, finalVolume, this.rid );
      return;
    }

    async sendDataEvent(evt: UserObjectDataEvent) {
      if(this.connection) {
        this.connection.send(evt);
      }
      return;
      
    }

    async remoteUpdateVolume(volume: number) {
      this.previousVolume = volume;
      this.fire(UserObjectEvent.audioLevelUpdate, volume);
      return;
    }

    async sendEvent(evtType: UserObjectDataEventTypes, uid: string, data: any, roomId?: string, msg?: string ) {
      const newEvent: UserObjectDataEvent = {
        type: evtType,
        uid: uid,
        roomId: roomId,
        data: data,
        msg: msg
      };
      await this.sendDataEvent(newEvent);
      return;
    }

    async muteToggle(setting?: boolean) {
      this.logSvc.info('userObject - muteToggle' + this.uid);
      if(this.uid === this.authSvc.localUser.uid) {
        //this.muted = await this.authSvc.agoraSvc.muteAudio();
        if(setting != undefined) {
          this.muted = setting;
        }
      } else {
        // remote user 
        if(this.agoraAutoTrack) {
          this.muted = false;
        } else {
          this.muted = true;
        }
      }
      
    
      this.fire(UserObjectEvent.actionMuted, this.muted);
      
    }

    muteUserLocal() {
      
      
      if(this.agoraAutoTrack && !this.muted) {
        if(this.agoraAutoTrack.isPlaying) {
          this.agoraAutoTrack.stop();
          this.localMute = true;
          this.fire(UserObjectEvent.actionMuted, this.localMute);
        }else {
          this.agoraAutoTrack.play();
          this.localMute = false;
          this.fire(UserObjectEvent.actionMuted, this.localMute);
        }
      }
      
    }

    toggleVideo() {
      this.videoEnabled = !this.videoEnabled;
      this.fire(UserObjectEvent.actionVideoToggle, this.videoEnabled);
    }

    disconnect() {
        this.peer = null;
        this.connection = null;
        this.calls = [];
        this.streams = [];
        this.extraStreams = [];
        this.fire(UserObjectEvent.DisconnectionEvent, this);
        this.fire(UserObjectEvent.ScreensUpdated, this.streams);
        return;
      }

    async checkConnection() {
        try {
          if(!this.connection?.peerConnection) {
            return;
          }
          if(this.audioContext) {
            await this.updateAudioLevels();
          }
          // @ts-ignore
          let stats = await this.connection.peerConnection.getStats();
          stats.forEach( (value, key, report: any) => {
            let sentbytes;
            let readbytes;
            let headerBytes;
            let packets;
            let state;
            // @ts-ignore
            if (value.type === 'transport') {
               // @ts-ignore
              if (value.isRemote) {
                return;
              }
               // @ts-ignore
              const now = value.timestamp;
              sentbytes = value.bytesSent;
              headerBytes = value.headerBytesSent;
              readbytes = value.bytesReceived;
              packets = value.packetsSent;
              state = value.iceState;
              if(state == 'disconnected' || state == 'failing') {
                this.setState(UserObjectState.disconnected);
                return;
              }
              
            }
          });
          
          setTimeout(this.checkConnection.bind(this), 5000);
        } catch(e) {
          throw e;
        }
    }

    disconnectComputer() {
        this.peer = null;
        this.connection = null;
        this.calls = [];
        this.streams = [];
        this.extraStreams = [];
        this.fire(UserObjectEvent.DisconnectionEvent, this);
        this.fire(UserObjectEvent.ScreensUpdated, this.streams);
    }

    async startConnection() {
        return new Promise( async (resolve, reject) => {
          try {
            this.logSvc.info('UserObject - startConnection');
            if(this.user && this.user.peer) {
                
                const urls = await this.stunSvc.getURLS(); 
                this.peer = new Peer({
                    config: {
                        'iceServers': [urls.v.iceServers],
                      pingInterval: 500,
                      
                    },
                    // @ts-ignore
                    host: peerjsserver,
                    secure: true,
                  });
          
                 
                  //this.ngZone.run( async() => {
                    this.peer.on('open', this.handlePeerOpen.bind(this));
                    this.peer.on('call', this.handlePeerCall.bind(this));
                    this.peer.on('connection', this.handlePeerConnection.bind(this));
                    this.peer.on('error', this.handlePeerError.bind(this));
                    this.peer.on('disconnected', this.handlePeerDisconnected.bind(this));
                    this.peer.on('close', this.handlePeerClose.bind(this));
                  //})
                  
                  
                  // update the data of the component
            } else {
                this.setState(UserObjectState.offline);
            }
            
          } catch(e) {
            reject(e);
          }
        })
    }

    handleConnectionError(err: any) {
        this.logSvc.info('UserObject handleConnectionError');
        this.streams = [];
        this.connection = undefined;
      }
    
    handleConnectionClose() {
        this.logSvc.info('UserObject handleConnectionClose');
        this.streams = [];
        this.connection = undefined;
        this.fire(UserObjectEvent.DisconnectionEvent);
        this.setState(UserObjectState.disconnected);
    }

    async handleConnectionOpen(conn) {
        this.logSvc.info('UserObject handleConnectionOpen');
        if(this.connection) {
            
            const message = {
                type: UserObjectDataEventTypes.Initiator,
                uid: this.uid,
                peerId: this.peer.id
            }
            this.connection.send(JSON.stringify(message));
        }  else {
          this.connection = conn;
        }
      }
    
    async handlePeerCall(call: MediaConnection) {
        this.logSvc.info('UserObject handlePeerCall');
        call.answer();
        this.calls.push(call);
        call.on('stream', this.handleCallStream.bind(this));
        call.on('close', this.handleCallStreamClose.bind(this));
        call.on('error', this.handleCallStreamError.bind(this));
        
    }

    async handleCallStreamClose() {
        this.logSvc.info('UserObject handleCallStreamClose');
        
      }
    
      async handleCallStreamError(err) {
        this.logSvc.info('UserObject handleCallStreamError' + JSON.stringify(err));
      }
    
    async handleCallStream(stream: MediaStream) {
        this.logSvc.info('UserObject handleCallStream');
        this.streams.push(stream);
        this.setState(UserObjectState.ready);
        const allstreams = [];
        const videoTracks = stream.getVideoTracks();
        
        setTimeout(this.checkConnection.bind(this), 5000);
        
        if( videoTracks.length > 1 ) {
          for await (const screen of videoTracks) {
            const mediaStream1 = new MediaStream([screen]);
            allstreams.push(mediaStream1);
          }
          this.extraStreams = allstreams;
        } 
        this.setState(UserObjectState.ready);
        this.fire(UserObjectEvent.StreamSharedEvent, stream);
        this.fire(UserObjectEvent.ScreensUpdated, stream);
    }

    handlePeerError(err: any) {
        this.logSvc.info('UserObject handlePeerError - ' + JSON.stringify(err));
        this.setState(UserObjectState.disconnected);
    }
    
    handlePeerDisconnected(evt) {
        this.logSvc.info('UserObject handlePeerDisconnected' + evt);
        this.fire(UserObjectEvent.DisconnectionEvent);
        this.setState(UserObjectState.disconnected);
    }
    
    handlePeerClose() {
        this.logSvc.info('UserObject handlePeerClose');
        this.fire(UserObjectEvent.DisconnectionEvent);
        this.setState(UserObjectState.disconnected);
    }

    async handleCommandData(data: any) {
      this.logSvc.info('UserObject - handleCommandData');

      const {type, uid, rid} = data;
      const msgData = data.data;

      switch(type) {
        case UserObjectDataEventTypes.audioLevel:
          await this.remoteUpdateVolume(msgData);
          break;
        case UserObjectDataEventTypes.audioMuted:
          this.userMuteToggle()
          break;
        case UserObjectDataEventTypes.screenShared:
          this.screenShared();
          break;
      }
      return;
    
    }

    userMuteToggle() {
      this.userForcedMute = !this.userForcedMute;
    }

    screenShared() {
      this.toggleVideo();
    }
    
    async handlePeerOpen(id: string) {
        this.logSvc.info('UserObject - handlePeerOpen' + id);
        this.connectUser();
    }

    async connectUser() {
        return new Promise( async (resolve, reject) => {
          try {
              this.logSvc.info('UserObject - connectUser');
              if(!this.peer) {
                resolve(null);
              }
              this.logSvc.info('UserObject - connectUser if peers' + this.user.peer);
              this.connection = this.peer!.connect(this.user.peer);
              
              this.connection.on('open', this.handleConnectionOpen.bind(this));
              this.connection.on('data', this.handleConnectionData.bind(this));
              this.connection.on('error', this.handleConnectionError.bind(this));
              this.connection.on('close', this.handleConnectionClose.bind(this));

          } catch(e) {
            this.logSvc.error(`connectUser Error ${e}`)
            reject(e);
          }
        })
        
    }

    async forceConnect() {
        this.logSvc.info('UserObject - forceConnect if peers' + this.user.peer);
        this.connection = this.peer!.connect(this.user.peer);
        this.connection.on('open', this.handleConnectionOpen.bind(this));
        this.connection.on('data', this.handleConnectionData.bind(this));
        this.connection.on('error', this.handleConnectionError.bind(this));
        this.connection.on('close', this.handleConnectionClose.bind(this));
    }

    async handlePeerConnection(conn: DataConnection) {
        this.logSvc.info('UserObject - handlePeerConnection');
        this.connection = conn;
        this.connection.on('data', this.handleCommandData.bind(this));
        this.setState(UserObjectState.ready);
        this.fire(UserObjectEvent.ConnectionEvent);
      }
    
    async handleConnectionData(data: any) {
        this.logSvc.info('UserObject - handleConnectionData');
        if(data === 'PING') {
            this.connection!.send('PONG');
            return;
        }
        if(data === 'PONG') {
            const tmpTimestamp = performance.now();
            
            return;
        }
        this.handleCommandData(data);
    }

    mapProfile(doc: any): PublicProfile {
        return doc as PublicProfile;
    }






}