import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoomObject } from 'src/app/objects/roomObject';
import { LogService } from 'src/app/services/log-service/log.service';
import { ShareRoomModalComponent } from '../popups/share.room.modal/share.room.modal.component';
import { RoomAddComputerDialogComponent } from '../popups/room-add-computer-dialog/room-add-computer-dialog.component';

@Component({
  selector: 'invite-resizeable-card',
  templateUrl: './invite-resizeable-card.component.html',
  styleUrls: ['./invite-resizeable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteResizeableCardComponent implements OnInit {
  @Input()
  room: RoomObject;
  @Input()
  width = '50%';
  height = '50%';
  constructor(
    public dialog: MatDialog,
    public logSvc: LogService,
  ) { }

  ngOnInit(): void {
  }

  clickShare() {
    try {
      // Open the share room modal 
      this.openShareRoomDialog('200ms', '200ms');
    } catch(e) {
      this.logSvc.error(`room-control-bar - clickShare - ${e}`);
    }
  }

  openShareRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ShareRoomModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  openAddComputerShareDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(RoomAddComputerDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  clickAddComputer() {
    this.openAddComputerShareDialog('200ms', '200ms');
  }



}
