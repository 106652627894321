import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as auth from 'firebase/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogService } from '../log-service/log.service';
import { UserObject } from '../../objects/userObject';
import { DeviceService } from '../device-service/device.service';
import { StunServiceService } from '../stun-service/stun-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileModalComponent } from '../../components/popups/profile.modal/profile.modal.component';
import { UpdateServiceService } from '../update-service/update-service.service';
import { GetStartedModalComponent } from 'src/app/components/popups/get-started-modal/get-started-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: BehaviorSubject<any> = new BehaviorSubject(null);
  registering = false;
  working: BehaviorSubject<boolean> = new BehaviorSubject(true);
  localUser: UserObject;
  constructor(
    public afs: AngularFirestore, // Inject Firestore service, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public dialog: MatDialog,
    public logSvc: LogService, 
    public devSvc: DeviceService,
    public stunSvc: StunServiceService, 
    public updaterSvc: UpdateServiceService,
    public zone: NgZone
  ) {
    
    // Non-main windows will have additional arguments attached 
    if(!window.child) {
      this.setupSubscriptions();
    } 
  }

    async setupSubscriptions() {
      
      this.afAuth.authState.subscribe(async (user) => {
        console.log('authState', user)
        if (user) {

          setTimeout( async () => {
           
            localStorage.setItem('user', JSON.stringify(user));
            this.logSvc.info('User Authenticated ' + JSON.stringify(user.uid));
            this.localUser = new UserObject(null, user.uid, this.afs, this.logSvc, this.devSvc, null, this, this.stunSvc, this.zone );
            const tmpProfile = await this.localUser.updateProfile();
            // @ts-ignore
            user.profile = tmpProfile;
           
            
            if(!tmpProfile) {
              this.openProfile('200ms', '200ms');
            } else {
              this.devSvc.mainstream$.subscribe( (stream) => {
                
                  this.user.next(user);
                  this.working.next(false);
                
                
              })
             
            }
            
            
          }, 200);
          
        } else {
          
          localStorage.setItem('user', 'null');
          this.user.next(null);
          this.working.next(false);
        }
        
      });
    }

    activateUser() {
      this.afAuth.authState.subscribe(async (user) => {
        console.log('authState', user)
        if (user) {

          setTimeout( async () => {
           
            localStorage.setItem('user', JSON.stringify(user));
            this.logSvc.info('User Authenticated ' + JSON.stringify(user.uid));
            this.localUser = new UserObject(null, user.uid, this.afs, this.logSvc, this.devSvc, null, this, this.stunSvc, this.zone );
            const tmpProfile = await this.localUser.updateProfile();
            // @ts-ignore
            user.profile = tmpProfile;
           
            
            if(!tmpProfile) {
              this.openProfile('200ms', '200ms');
            } else {
              this.devSvc.mainstream$.subscribe( (stream) => {
                
                  this.user.next(user);
                  this.working.next(false);
                
                
              })
             
            }
            
            
          }, 200);
          
        } else {
          
          localStorage.setItem('user', 'null');
          this.user.next(null);
          this.working.next(false);
        }
        
      });
    }

    openProfile(enterAnimationDuration: string, exitAnimationDuration: string): void {
      this.dialog.open(GetStartedModalComponent, {
        width: '620px',
        enterAnimationDuration,
        exitAnimationDuration,
        
      });
    }

    signIn(email: string, password: string) {
      this.working.next(true);
      return this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          this.working.next(false);
          if(result.user) {
            this.user.next(result.user);
          }
        })
        .catch((error) => {
          this.working.next(false);
          window.alert(error.message);
        });
    }

    // Sign up with email/password
  signUp(email: string, password: string) {
    this.working.next(true);
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        console.log('registration result', result)
        this.registering = true;
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.sendVerificationMail();
        this.localUser = new UserObject(null, result.user.uid, this.afs, this.logSvc, this.devSvc, null, this, this.stunSvc, this.zone );
        this.user.next(result.user);
        this.working.next(false);
      })
      .catch((error) => {
        this.working.next(false);
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        //this.router.navigate(['verify-email-address']);
      });
  }

  async reload() {
    this.working.next(true);
    setTimeout( () => {
      this.user.next(this.user.value);
      this.working.next(false);
      return;
    },200)
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  get isLoggedIn(): boolean {
    return !!this.user.value;
  }

  // Sign in with Google
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      console.log('googleAuth', res)
      if (res) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  // Auth logic to run auth providers
  authLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('result', result)
        this.user.next(result.user);
        this.router.navigate(['dashboard']);
       
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.user.next(null);
    });
  }

}
