import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MenuItems } from '../../../models/Menu';
import { ComputerObject } from '../../../objects/computerObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { MenuService } from 'src/app/services/menu-service/menu.service';
import { InviteService } from 'src/app/services/invite-service/invite.service';
import { InviteItems } from 'src/app/models/Invites';
import { AddRoomDialogComponent } from '../../popups/add-room/add-room/add-room.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuComponent implements OnInit {
  menuItems: BehaviorSubject<MenuItems[]> | undefined;
  invitesItems: BehaviorSubject<InviteItems[]> | undefined;
  computers$: BehaviorSubject<ComputerObject[]>;
  showFiller = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private computerSvc: ComputerService,
    private inviteSvc: InviteService,
    public dialog: MatDialog,
    private menuSvc: MenuService) { }

  ngOnInit(): void {
    this.setupSubscriptions();
   
  }

  async setupSubscriptions(): Promise<void> {
    this.menuItems = this.menuSvc.menuItems$;
    console.log('menu items', this.menuItems)
    this.invitesItems = this.inviteSvc.inviteItems$;
    this.invitesItems.subscribe( (invites) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    })
    this.menuItems.subscribe( (menuItems) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    })
    await this.menuSvc.getMenuItems();
    this.computers$ = this.computerSvc.computers$;
    this.computers$.subscribe( async (computers) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    })
  }

  addSpace() {
    this.openRoomAddDialog('200ms', '200ms');
  }

  openRoomAddDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(AddRoomDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

}


