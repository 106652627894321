import {FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { MatDialog } from '@angular/material/dialog';
import { LocaldbService } from 'src/app/services/localdb-service/localdb.service';

@Component({
  selector: 'allow-screen-record-modal',
  templateUrl: './allow-screen-record-modal.component.html',
  styleUrls: ['./allow-screen-record-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class AllowScreenRecordModalComponent implements OnInit {
  @ViewChild('testBtn', { read: ElementRef }) testBtn: any;
  finalTestBtnText: string = 'Test Access';
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  constructor(private _formBuilder: FormBuilder,
    public dvcSvc: DeviceService,
    public dialog: MatDialog,
    public localDBSvc: LocaldbService,
    public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  async testAccess() {
    const access = await this.dvcSvc.testScreenAccess();
    if(access) {
      console.log(this.testBtn)
      await this.localDBSvc.saveSetting('global', 'allowScreenRecord', 'true');
       this.testBtn.nativeElement.setAttribute('style', 'background-color: green;');
       this.finalTestBtnText = 'Access Granted';
       this.cdr.detectChanges();
       this.cdr.markForCheck();
       setTimeout( () => {
        
        this.finalTestBtnText = 'Closing...';
        this.cdr.detectChanges();
        this.cdr.markForCheck();
        setTimeout( () => {
          this.dialog.closeAll();
          }, 1000)
       }, 1000)
       
    } else {
        this.testBtn.nativeElement.setAttribute('style', 'background-color: red;');
        this.finalTestBtnText= 'Access Denied. Try Again.';
        this.cdr.detectChanges();
        this.cdr.markForCheck();
    }
  }


  async finalStep() {
    await this.localDBSvc.saveSetting('global', 'allowScreenRecord', 'true');
    this.dialog.closeAll();

  }

}
