import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RoomObject } from '../../objects/roomObject';
import { UserObject } from '../../objects/userObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { DeviceService } from '../../services/device-service/device.service';
import { DeviceEvents } from '../../services/device-service/LocalDeviceEnum';

@Component({
  selector: 'user-profile-header',
  templateUrl: './user-profile-header.component.html',
  styleUrls: ['./user-profile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileHeaderComponent implements OnInit {
  @Input()
  uid: string;
  @Input()
  room?: RoomObject;
  @Input()
  user: UserObject;
  @Input()
  src: string;
  @Input()
  editor = false;
  @Input()
  editorCallback: any;
  @Input()
  disableVideo = false;
  @Input()
  editable = false;
  constructor(
    public devSvc: DeviceService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.user.on(UserObjectEvent.actionMuted, this.updateView.bind(this));
    this.devSvc.on(DeviceEvents.videoTrackAdded, this.updateView.bind(this));
    this.user.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this));
  }

  clickUserMute() {
    this.user.muteToggle();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  updateView() {
    console.log('updateView')
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
