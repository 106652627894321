import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './models/Users';
import * as auth from 'firebase/auth';
import { AuthService } from './services/auth-service/auth.service';
import { LogService } from './services/log-service/log.service';
import { MenuService } from './services/menu-service/menu.service';
import { ComputerService } from './services/computer-service/computer.service';
import { VrService } from './services/vr-service/vr.service';
import { NotificationService } from './services/notification-service/notification-service.service';
import { FriendsService } from './services/FriendsService/friends.service';
import { AgoraService } from './services/agora-service/agora-service';
import { SpatialSettingsService } from './services/spatialSettings/spatial-settings-service.service';
import { AudioCueService } from './services/AudioCueService/audio-cue.service';
import { AudioCue } from './services/AudioCueService/AudioCues';
import { MatDialog } from '@angular/material/dialog';
import { AllowScreenRecordModalComponent } from './components/popups/allow-screen-record-modal/allow-screen-record-modal.component';
import { DeviceService } from './services/device-service/device.service';
import { ElectronService } from './services/electron-service/electron.service';
import { LocaldbService } from './services/localdb-service/localdb.service';
import { ComputerServerService } from './services/computer-server-service/computer.server.service';
import { AppDownloadModalComponent } from './components/popups/app-download-modal/app-download-modal/app-download-modal.component';
import { ComputerAddModalComponent } from './components/popups/computer-add.modal/computer-add.modal.component';
import { ComputerServerEvents, ComputerServerState } from './objects/computerEnum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ARO';
  currentUser$?: BehaviorSubject<User>;
  @Input()
  url?: any;
  window = window;
  viewerurl = null;
  @Input()
  child = false;
  viewUser = null;
  navigator = navigator;
  computer$?: BehaviorSubject<any>;
  showServer = false;
  hold = true;
  _width = '100%';
  _height = '100%';
  @HostBinding('style.width')
    set width(value: string) {
      this._width = value;
    }
    @HostBinding('style.height')
    set height(value: string) {
      this._height = value;
    }
  constructor(public authSvc: AuthService,
    public logSvc: LogService,
    public routerSvc: Router,
    public menuSvc: MenuService,
    public actRouter: ActivatedRoute, 
    public computSvc: ComputerService,
    public compServerSvc: ComputerServerService,
    public vrSvc: VrService,
    public notSvc: NotificationService,
    public friendSvc: FriendsService,
    public agoraSvc: AgoraService,
    public spatialSettingsSvc: SpatialSettingsService,
    public audioSvc: AudioCueService,
    public dvcSvc: DeviceService,
    public electronSvc: ElectronService,
    public localDBSvc: LocaldbService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef) {}

  async ngOnInit() {
    // Service Worker for PWA 
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
          // Register the Service Worker.
          .register('../assets/js/pwa.js')
          // Using promises tell us if successful or there was an error
          .then(reg => {console.info('Service Worker registration successful: ', reg)})
          .catch(err => {console.warn('Service Worker setup failed: ', err)});
    }



    ///
    
    this.routerSvc.routeReuseStrategy.shouldReuseRoute = () => false;
    this.child = window.child;
    setTimeout(() => {
      if (window.child) {
       const approot = document.querySelector('app-root');
       document.body.style.width = 'auto';
       document.body.style.height = 'auto';
       const apphtml = document.querySelector('html');
        apphtml.style.height = "auto";
        apphtml.style.width = "auto";
       // @ts-ignore
       approot.style.width = 'auto';
       // @ts-ignore
       approot.style.height = 'auto';
        this.width = 'auto';
      } else {
        const apphtml = document.querySelector('html');
        apphtml.style.height = "100%";
      }
    }, 500); // 500 milliseconds = 0.5 seconds
    
    if(window.child) {
      document.body.style.width = '';
      document.body.style.height = '';
      
      if(window.childCfg) {
        this.viewerurl = window.childCfg.initUrl;
      }
      
      //this.viewerurl = window.process.argv[window.process.argv.length-1];
      document.title = 'ARO.Work';
      if(window.uid) {
        
        this._width = 'auto';
        document.body.style.height = '';
        this.viewUser = window.uid;
        document.body.style.backgroundColor = 'rgba(255, 255, 255, 0)';
      }

      
      
    }

    
    this.authSvc.user.subscribe(async (user) => {
      if(user) {
        this.computer$ = this.compServerSvc.server$;
          console.log('app-component-check if server is registered');
          this.computer$.subscribe( (computer) => {
            console.log('app-component-check if server is registered 2', computer );
            computer.on(ComputerServerEvents.computerStateChanged, () => {
              if(computer && (computer.state == ComputerServerState.ready)) {
                console.log('app-component-server ready')
                this.showServer = true;
                this.cdr.markForCheck();
                this.cdr.detectChanges();
              } else {
                if( computer.state == ComputerServerState.unregistered ) {
                  console.log('app-component-server unregistered')
                  this.openComputerAddDialog('200ms', '200ms');
                }
                
              }
              this.cdr.markForCheck();
              this.cdr.detectChanges();
            })
            
          });
      }
    });
    

    setTimeout( async () => {
      this.hold = false;
      this.setupSubscriptions();
      const firstInitialized = this.localDBSvc.getSetting('global', 'allowScreenRecord');
      if(this.electronSvc.isDesktop() && !firstInitialized) {
        if(!(await this.dvcSvc.testScreenAccess())) {
          // Remove after testing allow-screen-record-modal
          this.dialog.open(AllowScreenRecordModalComponent, {
            width: '620px',
            data: { },
            enterAnimationDuration: '200ms',
            exitAnimationDuration: '200ms',
          });
        }
      }
      
    }, 200);
    

    window.addEventListener('online', this.checkOnline.bind(this));
    window.addEventListener('offline', this.checkOnline.bind(this));
    
  }

  openComputerAddDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ComputerAddModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  setupSpatialWindowSvc() {
    // @ts-ignore
    spatialwindowservice.setupService(this.authSvc, this.computSvc, this.vrSvc, this.agoraSvc, this.friendSvc, this.notSvc, this.logSvc, this.spatialSettingsSvc);
  }

  checkOnline() {
    this.logSvc.info('App - checkOnline');
    this.updateView();
    
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  routerCanReuse() {
    return false;
  }

  async setupSubscriptions() {
    this.currentUser$ = this.authSvc.user;
    this.currentUser$.subscribe((user) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      this.setupSpatialWindowSvc();
      if( !user ) {
        // this.authSvc.authLogin(new auth.GoogleAuthProvider()).then((res: any) => {
        //   // Force a Login if no user
        //   this.logSvc.info('No user available for login');
        // });
      }
    });
  }
}


