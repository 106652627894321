<div class="profile-header" [matTooltip]="user.profile.name" [matTooltipHideDelay]="1000" *ngIf="user.profile && user.profile.name">
    <div class="backspash">
        <user-content [disableVideo]="disableVideo" [editor]="editor" [user]="user" [room]="room" [size]="100" [src]="src"></user-content>
    </div>
    <div class="user-actions-btns">
        <!-- <button class="action-btn" mat-mini-fab aria-label="Favourite">
            <mat-icon class='mat-accent'>favorite</mat-icon>
          </button> -->
        <button class="action-btn" mat-mini-fab aria-label="Microphone" (click)="clickUserMute()">
        <mat-icon [ngClass]="{'mat-accent' : user.muted || user.userForcedMute}">mic</mat-icon>
        </button>
        <!-- <button class="action-btn" mat-mini-fab aria-label="Screenshare">
            <mat-icon> screen_share</mat-icon>
        </button> -->
        
    </div>
    <div class="description"></div>
</div>
