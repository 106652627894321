<div class="mat-border-container fullrow">
    <div style="width:100%;height: 100%; display:flex; justify-content: center; align-items: center;">
        <div class="left-side">
            <img style="width:45px; height:45px; border-radius: 5px;" [src]="screenDef.thumbnail">
        </div>
        <div class="right-side">
            {{screenDef.name}}
        </div>
        <div class="check-right-side">
            <input type="checkbox"  [formControl]="included"> 
        </div>
    </div>
   
</div>
