import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoomObject } from '../../../objects/roomObject';
import { APP_CONFIG } from '../../../../environments/environment';
import { FriendsService } from 'src/app/services/FriendsService/friends.service';

@Component({
  selector: 'app-share.room.modal',
  templateUrl: './share.room.modal.component.html',
  styleUrls: ['./share.room.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareRoomModalComponent implements OnInit {

  shareLink: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {room: RoomObject},
    public dialogRef: MatDialogRef<ShareRoomModalComponent>,
    public friendSvc: FriendsService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.calcShareLink();
  }

  async shareRoom() {
    await navigator.clipboard.writeText(this.shareLink);
    await alert('Link Copied to Clipboard');
    this.dialogRef.close();

  }

  calcShareLink() {
    this.shareLink = APP_CONFIG.url + 'space/' + this.data.room.roomId;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
