import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Computer } from '../../models/Computers';
import { ComputerObjectEvents, ComputerServerEvents } from '../../objects/computerEnum';
import { ComputerObject } from '../../objects/computerObject';
import { ComputerServerService } from '../../services/computer-server-service/computer.server.service';
import { ServerObject } from '../../services/computer-server-service/ServerObject';
import { ComputerService } from '../../services/computer-service/computer.service';

@Component({
  selector: 'computer-list-item',
  templateUrl: './computer-list-item.component.html',
  styleUrls: ['./computer-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerListItemComponent implements OnInit {
  @Input()
  computer: ComputerObject;
  serverObject: ServerObject;
  thumbnail: string;
  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    public server: ComputerServerService,
    public computerService: ComputerService
  ) { }

  async ngOnInit(): Promise<void> {
    if(this.computer) {
      this.computer.on(ComputerObjectEvents.computerStateChanged, this.updateView.bind(this));
    }
    
    this.server.server$.subscribe( (serverInstance: ServerObject) => {
      if(serverInstance) {
        this.serverObject = serverInstance;
        this.serverObject.on(ComputerServerEvents.computerStateChanged, this.handleServerStateChange.bind(this));
        this.updateView();
      } 
        
    });

    this.computer.connectionDetails$.subscribe(this.updateView.bind(this));

  }

  handleServerStateChange() {
    this.updateView();
  }

  updateView() {
    if(this.serverObject && this.serverObject.registrationId === this.computer.cid) {
      this.thumbnail = this.serverObject.thumbnail;
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  clickComputer() {
    try {
      //this.router.navigateByUrl('/computer/' + this.computer.cid, { });
      
    } catch(e) {
      alert('URL does not exist');
    }
  }

  remove() {
    // remove computer here
    this.computerService.delete(this.computer.cid!);
  }
}
