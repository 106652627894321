<button type="button" mat-raised-button style="width: 100%;">
    <mat-icon class="mat-accent" style="margin-right: 5px;" (click)="addSpace()">add_circle</mat-icon>  Spaces 
  </button>
<general-list-item *ngFor="let menuItem of menuItems.value"  [menuItem]="menuItem"> 
    {{ menuItem.title }}
</general-list-item>
        
        




  
    
  
  
