<div class="hoverArea mainArea" style="position:relative; display: flex; justify-content: center;">
  <!-- <mat-card-header [class.revealOnHover]="hideTop">
    <div class="profile-header-row">
      <div class="left-side-listing">
        <mat-card-title>{{computer.name}}</mat-card-title>
        <mat-card-subtitle class="right-side-listing subtitle">
          <div *ngIf="computer.computerState === 'Ready'">
            Online: {{ currentTime | timeSince}}
          </div>
          <div class="" *ngIf="computer.computerState != 'Ready'">
            Offline: {{ currentTime | timeSince}}
          </div>
        </mat-card-subtitle>
      </div>
      <div class="">
        <user-avatar *ngIf="computerOwner && computerOwner.profile" [user]="computerOwner"></user-avatar>
        <user-avatar *ngIf="!computerOwner" [name]="'Loading'"></user-avatar>
      </div>
    </div>
    
    
      
  </mat-card-header> -->
  <div>
    <img class="computer-card-img" [matTooltip]="imgTooltip" mat-card-image [src]="computer.computer.thumbnail" alt="Computer Screen Grab" (click)="mainscreenClick()">
    <div [matTooltip]="imgTooltip" [class.online-indicator]="computer.computerState == 'Ready' || computer.computerState == 'DataOnline'" [class.offline-indicator]="computer.computerState != 'Ready' && computer.computerState != 'DataOnline'">
      <span [class.blink]="computer.computerState == 'Ready' || computer.computerState == 'DataOnline'" [class.offlineblink]="computer.computerState != 'Ready' && computer.computerState != 'DataOnline'"></span>
    </div>
  </div>
  
  <div class="compTitle">{{computer.computer.name}}</div>
  <!-- <div id="computer-actions-menu" class="horizontal-side-menu revealOnHover">
    <mat-icon matTooltip="Edit">edit</mat-icon>
    <mat-icon matTooltip="Open in new window">open_in_new</mat-icon>
    <mat-icon matTooltip="Add to Space">add</mat-icon>
    <mat-icon matTooltip="Remove from Space">clear</mat-icon>
  </div> -->
</div>
<div *ngIf="computer.computerState == 'Ready' && showScreens" style="display:flex;" [class.revealOnHover]="hideBottom">
  <div *ngFor="let screen of computer.computer.availableScreens; let i = index">
    <img class="screen-thumbnail" [src]="screen.thumbnail" [matTooltip]="screen.name" (click)="focus(screen.id)">
  </div>
</div>
