import { Injectable } from '@angular/core';
import { LogService } from '../log-service/log.service';
import { AuthService } from '../auth-service/auth.service';
import AgoraRTC, {IAgoraRTCClient} from "agora-rtc-sdk-ng";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from '../api-service/api-service';
import { SpatialSettingsService } from '../spatialSettings/spatial-settings-service.service';
import { NotificationService } from '../notification-service/notification-service.service';
// Constants
const APP_ID = '56e4990982b94726994d372a6abcea1f';


export type AgoraEvent = {
    type: string,
    user: any,
    mediaType?: string
}

@Injectable({
    providedIn: 'root'
  })
export class AgoraService {
    agoraClient: IAgoraRTCClient;
    currentChannel: string;
    agoraEvents$: Subject<any> = new Subject();
    userEvents: {[key: string]: BehaviorSubject<any>} = {};
    localVideo$: BehaviorSubject<any> = new BehaviorSubject(null);
    channelParameters =
        {
        // A variable to hold a local audio track.
        localAudioTrack: null,
        localVideoTrack: null,
        // A variable to hold a remote audio track.
        remoteAudioTrack: null,
            // A variable to hold the remote user id.
        remoteUid: null,
        };
    videoStart: boolean = false;
    muted: boolean = true;
    constructor(
        public logSvc: LogService,
        public authSvc: AuthService,
        public notifySvc: NotificationService,
        public spatialSettingsSvc: SpatialSettingsService,
        public apiSvc: ApiService,
    ) {
        this.authSvc.user.subscribe( async (user) => {
            if(user && !window.electron) {
                this.authSvc.localUser.agoraSvc = this;
                this.authSvc.localUser.setupSubscriptions();
                await this.initiate();
            }
        });
    }

    async initiate() {
        this.logSvc.info('AgoraService Initiated');
        // Subscribe to the auth service then use the apiservice to 
        // make an api call to get the agora rtc token as they join the channel 
        const savedMutedSetting = this.spatialSettingsSvc.getSetting('local', null, this.authSvc.localUser.uid, 'mute');
        if(savedMutedSetting ) {
            this.muted = (savedMutedSetting == 'true');
            this.authSvc.localUser.muted = this.muted;
        } 

    }

    async joinChannel(channelName: string) {
        try {
            console.log('joinChannel');
            this.apiSvc.get('https://us-central1-zero-f9dc1.cloudfunctions.net/api/rtc/' + channelName + '/publisher/' + this.authSvc.user.value.uid).then( (response) => {
                const token = response.data.rtcToken;
                this.authSvc.user.subscribe( async (user) => {
                    if(user) {
                        this.logSvc.info('joinChannel');
                        
                        let config = {
                            mode: 'rtc',
                            codec: 'vp8',
                            appId: APP_ID,
                            channel: channelName,
                            token: token,
                            uid: this.authSvc.user.value.uid
                        }
                        await this.setupAgora(config);
                        return;
                    }
        
                })

            })
        } catch(e) {
            this.logSvc.error(`joinChannel ${e}`);
            return;
        }
       
        
        
    }

    async getAgoraToken() {
        try {
            let response = await this.apiSvc.get('/agora/token');
            return response.data.token;
        } catch(e) {
            this.logSvc.error(`getAgoraToken - ${e}`)
        }
        
    }

    async setupAgora(config: any) {
        try {
            AgoraRTC.setLogLevel(4);
            this.agoraClient = AgoraRTC.createClient(config);
            this.agoraClient.on('user-published', this.handleUserPublished.bind(this));
            this.agoraClient.on('user-unpublished', this.handleUserUnpublished.bind(this));
            this.agoraClient.on('user-joined', this.handleUserJoined.bind(this));
            this.agoraClient.on('user-left', this.handleUserLeft.bind(this));
            this.agoraClient.on('user-info-update', this.handleUserInfoUpdated.bind(this));
            this.agoraClient.on('volume-indicator', this.handleVolumeIndicator.bind(this));
            
            await this.agoraClient.join(config.appId, config.channel, config.token, config.uid);
            await this.setupLocalAudio();
            if(!this.channelParameters.localAudioTrack) {
                throw new Error('No agora local audiotrack');
            }
            this.agoraClient.publish(this.channelParameters.localAudioTrack);
            return;
        } catch(e) {
            this.logSvc.error(`setupAgora ${e}`);
        }
        
    }

    async setupLocalAudio() {
       try {
        this.logSvc.info('setupLocalAudio');
        // Create an audio track from the audio sampled by a microphone.
        this.channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            AEC: true,
            AGC: true,
            ANS: true,
        });
        const savedMutedSetting = this.spatialSettingsSvc.getSetting('local', null, this.authSvc.localUser.uid, 'mute');
        if(savedMutedSetting ) {
            this.muted = (savedMutedSetting == 'true');
        }
        this.channelParameters.localAudioTrack.setMuted(this.muted);
        this.agoraClient.enableAudioVolumeIndicator();
        return
       
       } catch(e) {
        this.logSvc.error(`setupLocalAudio - ${e}`)
       }
        
      
    }
    
    async setupLocalVideo() {
        this.channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack({

            optimizationMode: "motion",
        })
        this.localVideo$.next(this.channelParameters.localVideoTrack);
        
        return;
    }

    async toggleVideo() {
        if(!this.channelParameters.localVideoTrack && !this.videoStart) {
            this.videoStart = true;
            await this.setupLocalVideo();
            this.authSvc.localUser.agoraVideoTrack = this.channelParameters.localVideoTrack;
            console.log(this.channelParameters.localVideoTrack)
            this.authSvc.localUser.toggleVideo();
            this.localVideo$.next(this.channelParameters.localVideoTrack);
            this.sendUserEvent({type: 'user-publish', mediaType: 'video', user: {uid: this.authSvc.localUser.uid, videoTrack: this.channelParameters.localVideoTrack}});
            await this.agoraClient.publish(this.channelParameters.localVideoTrack);
            this.videoStart = false;
        } else {
            this.channelParameters.localVideoTrack.close()
            await this.agoraClient.unpublish(this.channelParameters.localVideoTrack);
            this.authSvc.localUser.agoraVideoTrack = null;
            this.channelParameters.localVideoTrack = null;
            this.authSvc.localUser.toggleVideo();
            this.localVideo$.next(this.channelParameters.localVideoTrack);
            this.sendUserEvent({type: 'user-unpublish',mediaType: 'video', user: {uid: this.authSvc.localUser.uid, videoTrack: this.channelParameters.localVideoTrack}});
        }
        return false;

    }

    async disableVideo() {
        if(this.channelParameters.localVideoTrack) {
            this.channelParameters.localVideoTrack.close()
            await this.agoraClient.unpublish(this.channelParameters.localVideoTrack);
            this.authSvc.localUser.agoraVideoTrack = null;
            this.channelParameters.localVideoTrack = null;
            this.authSvc.localUser.toggleVideo();
            this.localVideo$.next(this.channelParameters.localVideoTrack);
            this.sendUserEvent({type: 'user-unpublish',mediaType: 'video', user: {uid: this.authSvc.localUser.uid, videoTrack: this.channelParameters.localVideoTrack}});
        }
        return false;
    }

    async handleVolumeIndicator(volumes) {
        volumes.forEach((volume) => {
            if(volume.level > 5) {
                this.logSvc.info('agoraService handleVolumeIndicator: ' + volume.uid + ' ' + volume.level);
                this.sendUserEvent({type: 'volume-indicator', uid: volume.uid, level: volume.level});
            }
        });

    }

    async handleUserInfoUpdated(evt) {
        this.logSvc.info('agoraService handleUserInfoUpdated: ' + evt.user.uid + ' ' + evt.msg);
        this.sendUserEvent({type: 'user-info-update', user: evt.user, msg: evt.msg});
    }

    async handleUserLeft(user, mediaType) {
        this.logSvc.info('agoraService handleUserLeft: ' + user.uid + ' ' + mediaType);
        this.agoraEvents$.next({type: 'user-left', user: user});
    }

    async handleUserJoined(user, mediaType) {
        this.logSvc.info('agoraService handleUserJoined: ' + user.uid + ' ' + mediaType);
        this.sendUserEvent({type: 'user-joined', user: user})
    }

    async handleUserPublished(user, mediaType) {
        
        this.logSvc.info('agoraService handleUserPublished: ' + user.uid + ' ' + mediaType);
        await this.agoraClient.subscribe(user, mediaType);
        this.sendUserEvent({type: 'user-published', user: user, mediaType: mediaType});
        // if (mediaType == "audio")
        // {
        //   this.channelParameters.remoteUid=user.uid;
        //   // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
        //   this.channelParameters.remoteAudioTrack = user.audioTrack;
        //   // Play the remote audio track. 
        //   this.channelParameters.remoteAudioTrack.play();
        // }

    }

    async muteAudio(): Promise<boolean> {
       try {
        this.muted = !this.muted;
        this.spatialSettingsSvc.saveSetting('local', null, this.authSvc.localUser.uid, 'mute', this.muted.toString());
        if(this.agoraClient.localTracks.length) {
          
            const audioTrack = this.agoraClient.localTracks.findIndex((item, index) => { return item.trackMediaType == 'audio'})
            await this.agoraClient.localTracks[audioTrack].setMuted(this.muted);
        } else {
           
            this.channelParameters.localAudioTrack.setMuted(this.muted);
            
            if(this.agoraClient.connectionState == "CONNECTED") {
              
                await this.agoraClient.publish(this.channelParameters.localAudioTrack);
            } else {
               // If it is not connected after a while 
            }
            
            // No audio tracks published, publish the audio 
           
            
           
        }

        return this.muted;
       } catch(e) {
        this.logSvc.error(`muteAudio - ${e}`)
        return this.muted;
       }
       
        
    }

    async handleUserUnpublished(user, mediaType, msg) {
        
        this.logSvc.info('agoraService handleUserUnpublished: ' + user.uid);
        this.sendUserEvent({type: 'user-unpublished', user: user, mediaType: mediaType, msg: msg });
    }

    async leaveChannel() {
        await this.agoraClient.leave();
        this.channelParameters.remoteAudioTrack = null;
        this.channelParameters.remoteUid = null;
    }

    async sendUserEvent(event: any) {
        if(!this.userEvents[event.uid]) {
            this.userEvents[event.uid] = new BehaviorSubject(event);
        } else {
            this.userEvents[event.uid].next(event);
        }
        
        this.agoraEvents$.next(event);
    }

    async getAudioDevices() {
        return await AgoraRTC.getDevices(true)
    }

    async getScreen() {
        return await navigator.mediaDevices.getDisplayMedia()
    }

    async changeVideoToScreenShare(media?: MediaStream, enableAudio: boolean = false) {
        try {
            console.log('start presentation')
            if(this.channelParameters.localVideoTrack) {
                await this.toggleVideo();
            }
            this.channelParameters.localVideoTrack = await AgoraRTC.createScreenVideoTrack({
                
                optimizationMode: "detail",
            })
           
            await this.agoraClient.publish(this.channelParameters.localVideoTrack);
            console.log(this.authSvc.localUser.uid)
            this.sendUserEvent({type: 'user-published', mediaType: 'video', user: {uid: this.authSvc.localUser.uid, videoTrack: this.channelParameters.localVideoTrack}});
            //await this.authSvc.localUser.toggleVideo();
            this.localVideo$.next(this.channelParameters.localVideoTrack);
            return;
        } catch(e) {
            this.logSvc.error(`changeVideoToScreenShare - ${e}`)
            this.notifySvc.sendLocal('Error sharing screen', null, this.authSvc.localUser.uid);
            throw new Error('Error sharing screen');
            return;
        }
       
    }
}