import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
import { AgoraService } from '../agora-service/agora-service';
import { LogService } from '../log-service/log.service';
import { NotificationService } from '../notification-service/notification-service.service';
import { AROSignal, RealtimeService } from '../realtime-service/realtime.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { RoomObject } from 'src/app/objects/roomObject';
import { PresentModalComponent } from 'src/app/components/popups/present-modal/present-modal.component';
export type PresentationConfig = {
  type: 'user' | 'computer'
  userid: string;
  computerId?: string;
  screenId?: string;

}

@Injectable({
  providedIn: 'root'
})
export class PresentationServiceService {
  mepresenting: boolean = false;
  presentation: BehaviorSubject<PresentationConfig | null> = new BehaviorSubject(null);

  constructor(
    public authSvc: AuthService,
    public agoraSvc: AgoraService,
    public logSvc: LogService,
    public notifySvc: NotificationService,
    public realtimeSvc: RealtimeService,
    public dialog: MatDialog,

  ) { }

  async startPresentationModal(room: RoomObject, enterAnimationDuration: string = '250ms', exitAnimationDuration: string = '250ms' ) {
    console.log('startPresentationModal')
    const onlineComps = room.roomComputers$.value.filter((item) => {return item.computerState === 'Ready'});
    try {
      if(!this.mepresenting) {
      
        await this.agoraSvc.changeVideoToScreenShare();
        this.mepresenting = true;
      }
    } catch(e) {
      this.logSvc.error(`presentationSvc - startPresentationModal - ${e}`);
      throw new Error(e);
      return;
    }
    
    return;
    if(onlineComps.length) {
      this.dialog.open(PresentModalComponent, {
        width: '320px',
        enterAnimationDuration,
        exitAnimationDuration,
        data: {
          room: room
        }
      });
    } else {
      await this.agoraSvc.changeVideoToScreenShare()
    }
    return;
  }

  presentNow(userId: string, roomId: string,  type: 'user' | 'computer', computerId?: string, screenId?: string): void {
    //New Presentation Starting 
    // Determine if local or remote user 
      // local 
        // set me presenting 
        // send out broadcast of presentation 
        // set presentation var and broadcast it locally 
        // broadcast the snack bar 
        // High light that streem only - UI side from behavior subject notification 
    if(userId === this.authSvc.localUser.uid) {
      // Local User Started Sharing 
      this.mepresenting = true;
      this.notifySvc.sendLocal('You started presenting.', null, this.authSvc.localUser.uid);
      const presentationConfig: PresentationConfig = {
        type: 'user',
        userid: userId,
      }
      this.presentation.next(presentationConfig);
      const signal: AROSignal = {
        type: 'present',
        timestamp: moment().unix(),
        roomId,
        userId,
        meta: presentationConfig
      }
      this.realtimeSvc.create(signal);
    } else {
      // Remote User Started Sharing 
      this.mepresenting = false;

    }

  }

  stopPresentation(): void {
    this.mepresenting = false;
    this.agoraSvc.disableVideo();
    
    this.presentation.next(null);
    
  }
}
