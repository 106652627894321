<div #userCont *ngIf="user" class="user-content-area">
    
    <div style="display: flex;
    position: relative; width: 150px;
    justify-content: center; height: 120px;">
        
        <div *ngFor="let videoTrack of videoTracks">
            <user-video-track *ngIf="user.videoEnabled" [id]="videoTrack.id" [room]="room" [user]="user" [videoTrack]="videoTrack"></user-video-track>
        </div>
        <user-avatar *ngIf="user.profile && user.profile.name" (mouseenter)="isHidden = !isHidden" (mouseleave)="isHidden = !isHidden" [name]="user.profile.name" [img]="user.profile.avatar || src" style="position:absolute;" [size]="size" [showNameAlways]="true" [user]="user"></user-avatar>
        <user-avatar *ngIf="user.profile && !user.profile.name" (mouseenter)="isHidden = !isHidden" (mouseleave)="isHidden = !isHidden" [name]="'Username'" style="position:absolute;" [size]="size" [showNameAlways]="true" [user]="user"></user-avatar>
        <user-avatar *ngIf="user && !user.profile" (mouseenter)="isHidden = !isHidden" (mouseleave)="isHidden = !isHidden" [name]="'Username'" style="position:absolute;" [size]="size" [showNameAlways]="true" [user]="user"></user-avatar>
        <!-- <mat-icon (click)="editorCallback()" *ngIf="editor" style="position:absolute; bottom: -12px;">edit</mat-icon> -->
        <!-- <mat-icon *ngIf="editor" style="position:absolute; bottom: -40px;">remove_red_eye</mat-icon> -->
        <div class="profileName" *ngIf="(isHidden || showNameAlways) && user.profile">
            {{ user.profile.name  }}
        </div>
        <div style="display: flex;
        position: absolute; bottom:0px; width: 150px;
        justify-content: center;">
            <div #sendhelp class="sendHelp" *ngIf="message">
                {{message}}
                <mat-icon style="transform: scale(0.75);" (click)="copyClipboard()">copyright</mat-icon>
            </div>
        </div>
    </div>
    
    
    
    <!-- <div>
        {{user.state}}
    </div>
    <div *ngIf="user.peer">
        {{user.peer.id}}
    </div>
    <div>
        {{ audioLevel }}
        {{ user.streams.length }}
    </div> -->
    <!-- <div *ngIf="user.streams.length && user && user.peer">
        <audio [muted]="muted" autoplay [srcObject]="user.streams[0]"></audio> 
    </div> -->

    <div *ngIf="linkSender" class="links-send-ctn">

        <input #linktosend class="link-sender" placeholder="Send Link - click here"><div><mat-icon (click)="sendLink()">check_circle_outline</mat-icon></div>
    </div>
    
</div>

