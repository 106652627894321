 import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComputerObjectEvents } from '../../objects/computerEnum';
import { ComputerObject } from '../../objects/computerObject';
import { RoomObject } from '../../objects/roomObject';

@Component({
  selector: 'computer-screen-list-horizontal',
  templateUrl: './computer-screen-list-horizontal.component.html',
  styleUrls: ['./computer-screen-list-horizontal.component.scss'],
})
export class ComputerScreenListHorizontalComponent implements OnInit, OnDestroy {
  @Input()
  computer: ComputerObject;
  @Input()
  extraStreams: MediaStream[] = [];
  @Input()
  room: RoomObject;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }
  stream: MediaStream;
  ngOnInit(): void {

    this.computer.on(ComputerObjectEvents.computerStateChanged, this.checkStreamSetup.bind(this));
    this.computer.on(ComputerObjectEvents.computerStreamSharedEvent, this.checkStreamSetup.bind(this));
    this.computer.on(ComputerObjectEvents.computerScreensUpdated, this.checkStreamSetup.bind(this));
    this.computer.on(ComputerObjectEvents.computerNewScreen, this.checkStreamSetup.bind(this));
    
  }

  async updateView() {
    
    //setTimeout( () => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    //}, 500);
    
  }

  async checkStreamSetup() {
    // const streamlens = await this.computer.getAllStreams(this.computer.streams[0]);
    // if(streamlens.length != this.extraStreams.length) {
    //   this.extraStreams = streamlens
    // }
   

      
      
      this.updateView();
  }


  async ngOnChanges(changes: SimpleChanges) {
    await this.checkStreamSetup();
     
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    console.log('Items destroyed');
  }

}
