import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AngularFireList } from '@angular/fire/compat/database';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { AudioCue } from '../../services/AudioCueService/AudioCues';
import { AgoraService } from '../../services/agora-service/agora-service';
import { AuthService } from '../../services/auth-service/auth.service';
import { LogService } from '../../services/log-service/log.service';
import { MenuService } from '../../services/menu-service/menu.service';
import { NotificationService } from '../../services/notification-service/notification-service.service';
import { PresentationServiceService } from '../../services/presentation-service/presentation-service.service';
import { AROSignal, RealtimeService } from '../../services/realtime-service/realtime.service';
import { VrService } from '../../services/vr-service/vr.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareRoomModalComponent } from '../popups/share.room.modal/share.room.modal.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'room-control-bar',
  templateUrl: './room-control-bar.component.html',
  styleUrls: ['./room-control-bar.component.scss'],
  
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomControlBarComponent implements OnInit {
  @Input()
  room: RoomObject;
  signal: AngularFireList<AROSignal>;
  videoStart = false;
  @Input()
  videoEnabled = false;
  constructor(
    public agoraSvc: AgoraService,
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef,
    public vrSvc: VrService,
    public logSvc: LogService,
    public menuSvc: MenuService,
    public notifySvc: NotificationService,
    public presentSvc: PresentationServiceService,
    public realtimeSvc: RealtimeService,
    public computerSvc: ComputerService,
    public router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(
    
  ): void {
    this.agoraSvc.localVideo$.subscribe(this.updateView.bind(this));
    this.room.on(RoomEvents.roomProfileChanged, this.updateView.bind(this));
    this.authSvc.localUser.on(UserObjectEvent.actionVideoToggle, this.updateView.bind(this));
    this.authSvc.localUser.on(UserObjectEvent.actionMuted, this.updateView.bind(this));
    this.signal = this.realtimeSvc.getRoomSignal(this.room.roomId);
    this.computerSvc.focusComputer.subscribe(this.computerActivated.bind(this))
  }

  computerActivated(computer) {
    if(computer) {
      this.notifySvc.sendLocal(computer.computer.name + ' Focused', this.room, this.authSvc.localUser.uid);
      this.menuSvc.toggleMainMenu(false);
    }
  }

  async micToggle() {
    const muted = await this.agoraSvc.muteAudio();
    await this.authSvc.localUser.muteToggle(muted);
    let muteTxt = (muted ? 'muted' : 'unmuted');
    this.notifySvc.sendLocal('Mic ' + muteTxt, this.room, this.authSvc.localUser.uid)
    if(muted) {
      this.notifySvc.sendAudioNotification(AudioCue.DEVICE_MUTE);
    }
    else {
      this.notifySvc.sendAudioNotification(AudioCue.DEVICE_UNMUTE);
    }
    this.room.roomUserServer.muteMeRemoteToggle();
    this.updateView();
  }

  async updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async toggleVR() {
    let vrTxt = (!this.vrSvc.vrEngaged.value ? 'Entering' : 'Exiting')
    this.notifySvc.sendLocal(vrTxt + ' VR', this.room, this.authSvc.localUser.uid)
   
    if(!this.vrSvc.vrEngaged.value) {
      this.vrSvc.toggleVREngaged();
      this.vrSvc.overrideresult = true;
      this.vrSvc.override = true;
    } else {
      this.vrSvc.toggleVREngaged();
    }
    //await this.vrSvc.activateVR();
  }

  toggleLeftSide() {
    this.menuSvc.toggleMainMenu();
  }

  clickShare() {
    try {
      // Open the share room modal 
      this.openShareRoomDialog('200ms', '200ms');
    } catch(e) {
      this.logSvc.error(`room-control-bar - clickShare - ${e}`);
    }
  }

  openShareRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ShareRoomModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }


  async presentClick() {
    try {
      let presTxt = (this.presentSvc.presentation.value ? 'Stopped' : 'Started')
      if(this.presentSvc.mepresenting) {
        await this.presentSvc.stopPresentation()
        
      } else {
        await this.presentSvc.startPresentationModal(this.room);
      }
      
      this.notifySvc.sendLocal('Presentation ' + presTxt, this.room, this.authSvc.localUser.uid)
      return;
    } catch(e) {
      this.logSvc.error(`room-control-bar - presentClick - ${e}`);
    }
    
  }

  localVideoToggle() {
    this.videoStart = true;
    this.videoEnabled = !this.videoEnabled;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    this.videoEnable();

  }

  async videoEnable() {
    let videoTxt = (this.authSvc.localUser.videoEnabled ? 'Disabled' : 'Enabled' );
    if(this.authSvc.localUser.videoEnabled) {
      this.notifySvc.sendAudioNotification(AudioCue.DEVICE_MUTE);
    } else {
      this.notifySvc.sendAudioNotification(AudioCue.DEVICE_UNMUTE);
    }
    this.notifySvc.sendLocal('Video ' + videoTxt, this.room, this.authSvc.localUser.uid)
    this.updateView();
    this.agoraSvc.toggleVideo()
    this.videoStart = false;
  }

  audioMenu() {
    // Show the audio-src-menu component
    // TODO: Finish Menu Showing Implementation
  }

  videoMenu() {
    // Show the video-src-menu component
    // TODO: Finish Menu Showing Implementation

  }

  exit() {
    this.router.navigate(['/']);
  }


}
