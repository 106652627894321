import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputerObject } from '../../../objects/computerObject';
import { LogService } from 'src/app/services/log-service/log.service';

@Component({
  selector: 'computer-vector-space-handler',
  templateUrl: './computer-vector-space-handler.component.html',
  styleUrls: ['./computer-vector-space-handler.component.scss'],
  standalone: true,
  imports: [
		CommonModule,
	],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerVectorSpaceHandlerComponent implements OnInit {
  @Input()
  computer: ComputerObject;
  @Input()
  elements?: HTMLElement[] = [];
  spaceConfig: any;
  constructor(
    public logSvc: LogService,
    public cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.logSvc.info('Computer Vector System;');
    //await this.drawVectorSpace();
    //this.computer.on(ComputerObjectEvents.computerStreamSharedEvent, this.updateView.bind(this) );
    //this.computer.on(ComputerObjectEvents.computerStateChanged, this.updateView.bind(this));
  }


  async drawVectorSpace() {
    console.log('drawVectorSpace')
    if(this.computer.streams.length &&  this.computer.extraStreams.length) {
      console.log('drawVectorSpace 2')
      this.spaceConfig = this.computer.screens;
      this.computer.extraStreams.forEach( (newstream, index) => {
        const screenElem = document.createElement('a-curvedimage');
        screenElem.setAttribute('class', 'screensClass');
        screenElem.setAttribute('mixin', 'screensMixin');
        screenElem.setAttribute('position', this.spaceConfig[index].position);
        screenElem.setAttribute('rotation', this.spaceConfig[index].rotation);
        screenElem.setAttribute('width', this.spaceConfig[index].width);
        screenElem.setAttribute('height', this.spaceConfig[index].height);
        screenElem.setAttribute('muted', 'true');
        screenElem.setAttribute('margin', "0 0.25 0 0")
        screenElem.setAttribute('src', '#' + newstream.id.toString());
        
        
        screenElem.setAttribute('theta-length', '90');
        screenElem.setAttribute('theta-start', '130');
        document.querySelector('#mainScene').appendChild(screenElem);
        this.elements.push(screenElem);
      
      })

    } else {
      console.log('drawVectorSpace 3')
      if(this.computer.streams.length == 1) {
        console.log('drawVectorSpace 4')
        console.log(this.computer.streams[0].id.toString());
        this.spaceConfig = this.computer.screens;
        const screenElem = document.createElement('a-curvedimage');
        screenElem.setAttribute('class', 'screensClass');
        screenElem.setAttribute('mixin', 'screensMixin');
        screenElem.setAttribute('position', this.spaceConfig[0].position);
        screenElem.setAttribute('rotation', this.spaceConfig[0].rotation);
        screenElem.setAttribute('width', this.spaceConfig[0].width);
        screenElem.setAttribute('height', this.spaceConfig[0].height);
        screenElem.setAttribute('muted', 'true');
        screenElem.setAttribute('margin', "0 0.25 0 0")
        screenElem.setAttribute('src', '#' + this.computer.streams[0].id.toString());
        
        
        screenElem.setAttribute('theta-length', '90');
        screenElem.setAttribute('theta-start', '130');
        document.querySelector('#mainScene').appendChild(screenElem);
        this.elements.push(screenElem);
      }
    }
    
  }

  async updateView() {
    if(!this.spaceConfig && this.computer.streams.length) {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      await this.drawVectorSpace();
    }
    
   
  }



}
