
<div #headContainer class="headContainer" >
    <mat-icon (click)="clickClose($event)" class="closeIcon">close</mat-icon>
    <user-avatar *ngIf="window.profile && window.profile.name" [name]="window.profile.name" [img]="window.profile.avatar" style="position:absolute;"></user-avatar>
    
    <div class="profileName" *ngIf="(isHiddenName) && window.profile">
        {{ window.profile.name  }}
    </div>
    <div class="linksarea" *ngIf="message">
        <shared-card [sharedtxt]="message"></shared-card>
    </div>
</div>
