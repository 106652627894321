<a-scene id="ascene" render-order="menuBackground, menuItem, button, ontop" renderer="antialias: true; highRefreshRate: true; foveationLevel: 0; anisotropy:32; maxCanvasWidth: 4096; maxCanvasHeight: 4096;"
cursor__mouse="rayOrigin: mouse"
xr-mode-ui="XRMode: xr;" > 
    <a-assets id="aframeassets">
            <!-- Tempaltes -->

            <!-- User Click Template -->
            <script id="userdialog" type="text/html">
              <a-entity position="0 1 -1" class="dialog">
  
                  <a-entity id="dialogMain" >
                  <a-entity position="0 0.7" id="userimg" geometry="primitive: plane; width: 0.2; height: 0.2" material="src: ${topicon}; transparent: true; shader: flat" render-order="menuitem"></a-entity> 
                  <a-entity position="0.39 0.55 0.1" id="username" text="color: #FFF; value: ${header};"></a-entity>
                  <a-entity position="0 0.5" id="mainmenuBackground" mixin="slice" slice9="height: 0.5; width: 1; color: #171717" render-order="menuBackground">
                  <a-entity raycastable exitbtnhandler bind-toggle__raycastable="menu === 'main'" color="#fc0303" position="0.42 .23 0.1" material="side: double; color: #fc0303; shader: flat;" geometry="primitive:circle;radius:0.03" radius="0.03"></a-entity>
  
                  </a-entity>
                  <a-entity position="-0.1 0.4" layout="type: box; columns: 2; marginRow: -0.4; marginColumn: 0.2;">
                      <a-entity position="-3 0.9" id="usercallbtn" geometry="primitive: plane; width: 0.15; height: 0.15" material="src: #callbtn; transparent: true;" render-order="menuitem"></a-entity>  
                      <a-entity position="0 0.9" color="#FFFFFF" id="userinvitebtn" geometry="primitive: plane; width: 0.15; height: 0.15" material="src: #invitebtn; transparent: true; color: #FFF;" render-order="menuitem"></a-entity>    
                  </a-entity>
              </a-entity>
              </a-entity>
          </script>
          <a-mixin id="wireframematerial" material="wireframe: true; transparent: true; color:  #030303; opacity: 0.2;"></a-mixin>
            <!-- /User Click Template -->

            <!-- /Templates -->
            <!-- /Templates -->
            <!-- /Templates -->
            

            <!-- Video Assets -->
            <video id="thanksvideo" src="../assets/video/video.mp4" muted="false" crossorigin></video>
            <!-- /Video Assets -->
    
            <!-- Img Assets -->
            <img id="pageIconImg" src="https://cdn.glitch.com/0ddef241-2c1a-4bc2-8d47-58192c718908%2FdownIcon.png?1557829539105">
            <img id="logoImg" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/AROLogonobk.png?v=1685898819218" crossorigin>
            <img id="user-avatar-icon" src="../assets/img/user-avatar-icon.png" crossorigin>
            <img id="sadcomputer" src="../assets/img/sadcomputer.png" crossorigin>
            <img id="arrow" src="../assets/img/uparrow.png" crossorigin>
            <img id="downarrow" src="../assets/img/downarrow.png" crossorigin>
            <img id="getstarted" src="../assets/img/getstarted.jpeg" crossorigin>
            <img id="cursorpng" src="../assets/img/cursor.png" crossorigin>
            <img id="callbtn" src="../assets/img/call-icon.png" crossorigin>
            <img id="adduserbtn" src="../assets/img/add-friend.png" crossorigin>
            <img id="mutebtn" src="../assets/img/mute.png" crossorigin>
            <img id="unmutebtn" src="../assets/img/unmuted.png" crossorigin>
            <img id="joinicon" src="../assets/img/join.png" crossorigin>
            <img id="computers" src="../assets/img/computers.png" crossorigin>
            <img id="spaces" src="../assets/img/spaces.png" crossorigin>
            <img id="ar" src="../assets/img/ar.png" crossorigin>
            <img id="exit" src="../assets/img/exit.png" crossorigin>
            <img id="loader" src="../assets/img/loading-spinner.gif" crossorigin>
              
            <a-entity id="assets-user-avatars">
               <img id="sliceImg" src="https://cdn.glitch.com/0ddef241-2c1a-4bc2-8d47-58192c718908%2Fslice.png?1557308835598" crossorigin>
                
            </a-entity>
            <!-- /Img Assets -->
        

            <!-- Mixins Assets -->
            <a-mixin id="slicesmall" slice9="color: #030303; transparent: true; opacity: 0.7; src: #sliceImg; left: 40; right: 40;  top: 50; bottom: 50;"></a-mixin>
            <a-mixin id="slice" slice9="color: #030303; transparent: true; opacity: 0.9; src: #sliceImg; left: 55; right: 55; top: 50; bottom: 52; padding: 0.05"></a-mixin>
            <a-mixin id="slicebtn" slice9="color: #484a49; transparent: true; opacity: 0.9; src: #sliceImg; left: 5; right: 20; top: 5; bottom: 5; padding: 0.15"></a-mixin>
            <a-mixin id="slicesmlbtn" slice9="color: #484a49; transparent: true; opacity: 0.9; src: #sliceImg; left: 1; right: 5; top: 1; bottom: 1; padding: 0.05"></a-mixin>
            <a-mixin id="buttonBackground" mixin="slicesmall" slice9="width: 0.2; height: 0.125; color: #030303" render-order="button"></a-mixin> 
            <a-mixin id="buttonText" text="align: center;width: 0.4; zOffset: 0.01; color: #333"></a-mixin>
            <a-mixin id="smallbtn" mixin="slicesmlbtn" slice9="width: 0.2; height: 0.1; color: #030303" render-order="button"></a-mixin>
            <a-mixin
            id="buttonHoverEffect" raycastable
            animation__mouseenter="property: components.slice9.material.color; type: color; from: #030303; to: #333; easing: easeOutCubic; dur: 150; startEvents: mouseenter"
            animation__mouseleave="property: components.slice9.material.color; type: color; from: #333; to: #030303; easing: easeOutCubic; dur: 150; startEvents: mouseleave"></a-mixin>
            <a-mixin id="button" mixin="buttonBackground buttonHoverEffect buttonText" render-order="button" proxy-event="event: click; to: a-scene; captureBubbles: true"></a-mixin>
            <a-mixin id="lilbutton" mixin="buttonBackground buttonHoverEffect buttonText" render-order="button" proxy-event="event: click; to: a-scene; captureBubbles: true"></a-mixin>
            <a-mixin id="pageButton" geometry="primitive: plane; width: 0.05; height: 0.15" material="src: #pageIconImg; shader: flat; transparent: true"
            animation__mouseenter="property: components.material.material.color; type: color; from: #FFF; to: #AAF; dur: 100; startEvents: mouseenter"
            animation__mouseleave="property: components.material.material.color; type: color; from: #AAF; to: #FFF; dur: 100; startEvents: mouseleave"></a-mixin>

            <!-- /Mixins Assets -->
    </a-assets>

    <!-- Background -->
    <a-entity id="environmentBackground" environment="preset: moon;"></a-entity>
    <!-- /Background -->

    <!-- Screen Placeholders Snapgrid-->
   
    <!-- /Screen Placeholders Snapgrid-->

    <!-- System Menus -->
    <a-entity id="systemMenus" position="0 1 -1" visible="false">
        
        <!-- User Dock -->
        <a-curvedimage position="0 -0.2 0.5" height="0.2" id="userdock" start="90" transparent="false" opacity="0.6" color="#FFD700" radius="1.2" theta-length="180"
        rotation="0 0 0" render-order="ontop">
            <!-- Show Dock -->
            <a-curvedimage raycastable clickshowmenu start="84" visible="false" position="0 0.55 0.15" id="showmenu" rotation="0 90 0" src="#arrow" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
            <!-- /Show Dock -->
            <!-- Hide Dock -->
            <a-curvedimage raycastable clickhidemenu start="84" position="0 -0.20 0.15" id="hidemenu" rotation="0 90 0" src="#downarrow" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
            <!-- /Hide Dock -->
            <a-curvedimage id="user-dock-holder" userdock animation="property: rotation;from:0 90 0 to: 0 0 0; dur: 2000; easing: linear;" position="0 0 0.1" start="120" height="0.9" transparent="true" opacity="0" radius="1.2" theta-length="120"
                    rotation="0 0 0" >
                    <a-curvedimage id="mainMenubtn" raycastable clickhandlerlogo start="84" position="0 0.05 0.15" rotation="0 90 0" src="#logoImg" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <!-- <a-curvedimage username="dave" friend="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <a-curvedimage username="brad" friend="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <a-curvedimage username="matt" friend="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <a-curvedimage username="lily" friend="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <a-curvedimage username="natalie" roster="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage>
                    <a-curvedimage username="tammy" roster="true" raycastable start="95" position="0 0.05 0.15" rotation="0 90 0" src="https://cdn.glitch.global/da2d581d-e314-4aae-86da-7fcd55afd496/Hotpot%20(1).png?v=1685901716018" height="0.25" radius="1.2" theta-length="12"></a-curvedimage> -->
          
            </a-curvedimage>
        </a-curvedimage>
        <!-- /User Dock -->

        <!-- App Dock -->
        <a-curvedimage position="0 0.1 0.5" height="0.31" id="appdock" start="90" transparent="false" opacity="0.6" color="#84d3fa" radius="1.2" theta-length="180"
            rotation="0 0 0" render-order="ontop">
            <a-curvedimage id="app-dock-screen-holder" app-dock animation="property: rotation; from:0 90 0 to: 0 0 0; dur: 2000; easing: linear;" position="0 0 0.1" start="120" height="0.3" transparent="true" opacity="0" radius="1.4" theta-length="120"
                 rotation="0 0 0">
                 <a-curvedimage hover-text="txt: Call User" raycastable videoplayer="thanksvideo" start="84" position="0 0.03 0.1" id="screen3" rotation="0 90 0" src="#getstarted" height="0.25" radius="1.2" theta-length="20"></a-curvedimage>
                 
            </a-curvedimage>
        </a-curvedimage>
        <!-- /App Dock -->

        

    </a-entity>
    <!-- /System Menus -->
      
      
      <!-- <a-entity position="0 0.7 -0.9" >
        <a-entity position="0 0.9 0" id="mainmenuBackground" mixin="slice" slice9="height: 1.4; width: 1; color: #171717;" render-order="menuBackground">
               <a-entity id="mainMenuExit" raycastable exitbtnhandler bind__raycastable="menu === 'main'" color="#fc0303" position="0.43 .61 0.1" material="side: double; color: #fc0303; shader: flat;" geometry="primitive:circle;radius:0.03" radius="0.03"></a-entity>
                <a-entity id="title" text="value: ARO.Work; color: #FAFAFA; width: 2; align: center" position="0 0.47 0.01" render-order="menuItem"></a-entity>
                <a-entity id="logo" geometry="primitive: plane; width: 0.3; height: 0.3" material="src: #logoImg; transparent: true; shader: flat" position="0 0.65 0.02" render-order="menuitem"></a-entity>
          <a-entity id="menuBack" mixin="pageButton" scale="1.5 1.5 1.5" position="-0.3 0.5 0.02" rotation="0 0 -90" proxy-event="event: click; to: a-scene; as: menuBack" bind__raycastable="menu !== 'main'" bind__visible="menu !== 'main'" render-order="menuItem"></a-entity>
               
        </a-entity>
        <a-entity bind__visible="menu === 'main'" class="buttons" layout="align:center; type: box; columns: 1; marginRow: -0.3;" position="0. 0.76 0.02">
                  <a-entity mixin="button" text="value: Environments; color: #F85333;" proxy-event="as: menuEnvironment"></a-entity>
                  <a-entity mixin="button" text="value: Passthrough; color: #48BAEA" proxy-event="as: tooglePassthrough; to: a-scene;"></a-entity>
                  <a-entity mixin="button" text="value: Exit; color: #48BAEA" ></a-entity>
              </a-entity>
        
        
       <a-entity id="environmentMenu" bind__visible="menu === 'environment'" position="0 1.1 0.02">
          <a-entity class="buttons" layout="type: box; columns: 1; marginRow: -0.3" position="0 0 0.02" bind-for="for: environment; in: environmentOptions" environment-changer button-colors>
            <template>
              <a-entity mixin="button" text='color: #FFF; value: {{ environment }}' bind-toggle__raycastable="menu === 'environment'"></a-entity>
            </template>
          </a-entity>
          
          <a-entity id="environmentPrev" mixin="pageButton" position="-0.8 -1.45 0.02" rotation="0 0 -90" proxy-event="event: click; to: a-scene; as: environmentPagePrev" bind__raycastable="menu === 'environment'" bind__visible="environmentPage !== 0"></a-entity>
          <a-entity id="environmentNext" mixin="pageButton" position="0.8 -1.45 0.02" rotation="0 0 90" proxy-event="event: click; to: a-scene; as: environmentPageNext" bind__raycastable="menu === 'environment'" bind__visible="environmentPage <= (environmentNumPages - 2)"></a-entity>
        </a-entity> 
      </a-entity> -->
          
      
    <a-entity main-menu-click material="src: #logoImg; transparent: true; shader: flat"  geometry="primitive: plane; width: 0.25; height: 0.25" class="intersect" hover-text="txt: Main Menu; adjustdistance: 0.2;" id="mainMenubtn" raycastable start="84" position="0 0.4 -2" src="#logoImg" height="0.25" wdith="0.25"></a-entity>
      
      

    <!-- Dialog No-Computers or Users -->
    <!-- <a-entity id="nothingOnlineDialog" position="0 0.85 0" visible="true">
        <a-entity position="0 0.5" id="nocomputerDialogBackground" mixin="slice" slice9="height: 1; width: 1; color: #171717" render-order="menuBackground"></a-entity>
        <a-entity position="0.45 0.2 0" scale="1.5 1.5 0" text="value: Computers Offline!"></a-entity>
        <a-entity position="0.1 0.7 0" id="offlinecomputer" geometry="primitive: plane; width: 1; height: 1" material="src: https://cdn.glitch.global/08e65564-f1b8-4ca7-aec5-f751b8993f59/sadcomputer-fotor-bg-remover-20230510225752.png?v=1683773887447; transparent: true; shader: flat" render-order="menuitem"></a-entity>
    </a-entity> -->
    <!-- /Dialog No-Computers or Users -->

    <!-- Friends Dialog -->
    <!-- <a-entity id="userdialog"  template="src: #dialog"
            data-topicon="#user1" data-header="Matt" ></a-entity> -->
    <!-- /Friends Dialog -->
    


    <!-- Interaction Components -->
    <a-entity id="camera" camera position="0 2 0"

   ></a-entity>
    <a-image render-order="ontop" id="cursor" width="0.05" height="0.05"  src="#cursorpng" visible="false"></a-image>
    <!-- <a-entity id="mouseCursor" trigger-listener cursor="rayOrigin: mouse" raycaster="objects: raycastable .intersect"></a-entity> -->
    <a-entity id="leftHand" hand-controls="hand: left;"></a-entity>
    <a-entity id="rightHand" trigger-listener sphere-collider="objects: .intersect" oculus-touch-controls="hand: right;" hand-controls="hand: right;" laser-controls="hand: right;" raycaster="lineColor: red; lineOpacity: 1; interval: 20; objects: .intersect; showLine:true;"></a-entity>
    <!-- /Interaction Components -->
    

</a-scene>