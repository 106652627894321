<div class="profile-header-row mat-border-container hoverArea" style="position:relative;" *ngIf="profile">
    <div class="left-side-listing" >
        <user-avatar *ngIf="profile" [name]="profile.name" [img]="profile.avatar"></user-avatar>
     </div>
     <div class="right-side-listing">
        <div class="right-side-ontop">
            <div class="right-side-full" >
                {{ profile.name}}
            </div>
        </div>
        <div class="right-side-full">
            <div class="right-side-actions" >
                <mat-icon [id]="'msg-fri-' + friend.otherseruid" [matTooltip]="'Message'" (click)="messageUser()">message</mat-icon>
            </div>
            <div class="right-side-actions">
                <mat-icon [id]="'call-fri-' + friend.otherseruid" [matTooltip]="'Call'" (click)="phoneUser()">phone</mat-icon>
            </div>
            <!-- <div class="right-side-actions" *ngIf="rosterItem.userObject.state === 'Ready'">
                <mat-icon [matTooltip]="'Video'" [matTooltipHideDelay]="1000" (click)="clickVideoToggle()">video_call</mat-icon>
            </div> -->
            <div class="right-side-actions" >
                <mat-icon [id]="'invite-fri-' + friend.otherseruid" [matTooltip]="'Invite'" (click)="invite()"> add_circle</mat-icon>
            </div>
            <div class="right-side-actions top-right revealOnHover" style=" transform: scale(0.8);">
                <mat-icon [id]="'remove-fri-' + friend.otherseruid" class="mat-warn" [matTooltip]="'Remove'" (click)="remove()"> remove_circle</mat-icon>
            </div>
            
        </div>
    </div>
</div>
