<mat-drawer-container class="main-container" style="display: flex;" id="roomNav" hasBackdrop="false">
    <mat-drawer id="roomMenu" #rightdrawer class="main-sidenav" mode="side" position="end" [opened]="menuSvc.secondaryMenuOpen$.value">
        <room-right-menu [room]="room"></room-right-menu>
        <app-panel-toggle [drawer]="rightdrawer"></app-panel-toggle>
        <button *ngIf="inMenu" class="mat-raised-button mat-accent">Add Room</button>
    </mat-drawer>
    <mat-drawer-content style="display: flex; width: 100%; height: 100%;">
        <div class="roomContent" style="width: 100%; height: 100%;">
            <div class="mainRoomContainer" style="width: 100%; height: 100%;">
                <!-- <room-header [room]="room"></room-header>
                <room-content-area [room]="room" [computers]="computers"></room-content-area> -->
                <room-main-view-incall *ngIf="this.authSvc.localUser.profile.name" [room]="room" [computer]="computers"></room-main-view-incall>
            </div>
        </div>
        <app-panel-toggle [drawer]="rightdrawer"></app-panel-toggle>
    </mat-drawer-content>
</mat-drawer-container>

