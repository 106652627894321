import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { RoomRoster } from '../../models/Room';
import { UserObject } from '../../objects/userObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { AgoraService } from '../../services/agora-service/agora-service';
import { AuthService } from '../../services/auth-service/auth.service';
import { VrService } from '../../services/vr-service/vr.service';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { LargeStreamModal } from '../popups/large-stream-modal/large-stream.modal.component';

@Component({
  selector: 'user-video-card',
  templateUrl: './user-video-card.component.html',
  styleUrls: ['./user-video-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVideoCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  user: UserObject;
  @Input()
  roster: RoomRoster;
  uuid: string;
  showTop = false;
  videoTrack: any;
  videoPlaying = false;
  agoraSub = null;
  vrsub = null;
  constructor(
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public agoraSvc: AgoraService,
    public vrSvc: VrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.uuid = uuidv4();
    if(!this.user && this.roster) {
      this.user = this.roster.userObject;
    }
    this.user.on(UserObjectEvent.actionVideoToggle, this.updateVideo.bind(this));
    this.user.on(UserObjectEvent.actionMuted, this.updateView.bind(this));
    if(this.user.uid == this.authSvc.localUser.uid) {
      this.agoraSub = this.agoraSvc.localVideo$.subscribe(this.updateVideo.bind(this));
    }
   this.vrsub = this.vrSvc.vrEngaged.subscribe(this.updateVideo.bind(this));
    
  }

  async ngAfterViewInit() {
    if(this.user.agoraVideoTrack) {
      await this.initialVideoLoad();
    }
  }

  hoverHide() {
    this.showTop = false;
  }

  hoverShow() {
    this.showTop = true;
  }

  async initialVideoLoad() {
    console.log('initial video load')
    this.videoTrack = this.user.agoraVideoTrack;
    this.videoPlaying = true;
    this.updateView();
    this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-small');
    
    return;
  }

  async updateVideo() {
    if(this.videoTrack && this.videoTrack._isClosed && this.videoPlaying && !this.vrSvc.vrEngaged.value) {
      this.videoTrack = null;
      this.videoPlaying = false;
      this.updateView();
      return;
    }

    if(this.videoPlaying && !this.vrSvc.vrEngaged.value && this.videoTrack && this.user.agoraVideoTrack) {
      setTimeout(() => {
        const domElement = document.getElementById('user-video-' + this.user.uid + '-resizeable');
        if(!domElement) {
          return;
        }
        this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
        this.videoPlaying = true;

        
      }, 200);
      this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
      this.videoPlaying = true;
      this.videoTrack = this.user.agoraVideoTrack;
      this.updateView();

    }

    const domElement = document.getElementById('user-video-' + this.user.uid + '-small');
    if(!domElement) {
      this.user.off(UserObjectEvent.actionVideoToggle, this.updateVideo.bind(this));
      this.videoTrack = null;
      this.videoPlaying = false;
      return;
    }
    
    if(this.user.agoraVideoTrack && !this.vrSvc.vrEngaged.value) {
      setTimeout(() => {
        this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-small');
        this.updateView();
      }, 500)
        
      
      
      this.videoTrack = this.user.agoraVideoTrack;
      this.videoPlaying = true;
      return;
    }

    if(this.agoraSvc.channelParameters.localVideoTrack && this.authSvc.localUser.uid == this.user.uid && !this.videoPlaying && !this.vrSvc.vrEngaged.value) {
      setTimeout(() => {
        // find a dom element 
        const domElement = document.getElementById('user-video-' + this.user.uid + '-small');
        if(!domElement) {
          return;
        }
        this.agoraSvc.channelParameters.localVideoTrack.play('user-video-' + this.user.uid + '-small');
        this.updateView();
      }, 500)
      
      this.videoTrack = this.agoraSvc.channelParameters.localVideoTrack;
      this.videoPlaying = true;
    }

    

    console.log('update video', this.videoTrack)
    
    
  }

  updateView() {
    console.log('updateview')
    console.log(this.videoTrack)
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async clickMuteToggle() {
    if(this.roster.userObject.uid === this.authSvc.user.value.uid) {
      const muted = await this.agoraSvc.muteAudio();
      await this.authSvc.localUser.muteToggle(muted)
    } else {
      this.roster.userObject.muteUserLocal();
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  wideScreenVideo() {
    if(this.videoTrack) {
      // get the sceren width in px 
      const screenWidth = window.screen.width;
      // open dialog LargeStreamModal 
      const dialogRef = this.dialog.open(LargeStreamModal, {
        width: screenWidth + 'px',
        enterAnimationDuration: '200ms',
        exitAnimationDuration: '200ms',
        data: {videoTrack: this.videoTrack, title: this.user.profile.name}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.videoTrack.play('user-video-' + this.user.uid + '-small');
      });
    }
  }



  //ngondestroy to remove on event listeners
  ngOnDestroy() {
    this.videoPlaying = false;
    this.videoTrack = null;
    this.vrsub.unsubscribe();
    if(this.user) {
      this.user.off(UserObjectEvent.actionVideoToggle, this.updateVideo.bind(this));
      this.user.off(UserObjectEvent.actionMuted, this.updateView.bind(this));
      if(this.user.uid == this.authSvc.localUser.uid) {
        this.agoraSub.unsubscribe();
      }
    }
    
  }
}
