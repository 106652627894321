import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { BehaviorSubject } from 'rxjs';
import { Computer } from '../../../models/Computers';
import { ComputerServerState } from 'src/app/objects/computerEnum';

@Component({
  selector: 'computer-add.modal',
  templateUrl: './computer-add.modal.component.html',
  styleUrls: ['./computer-add.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerAddModalComponent implements OnInit {
  computer$?: BehaviorSubject<Computer>
  registration: string;
  hostnamefield: string;
 
  @ViewChild('hostname') hostname: ElementRef;
  constructor( 
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public logSvc: LogService,
    public compServerSvc: ComputerServerService,
    public dialogRef: MatDialogRef<ComputerAddModalComponent>) {}

  async ngOnInit(): Promise<void> {
    this.hostnamefield = await this.getHostname();
    this.registration = await window.electron.getRegistration();
    this.setupSubscriptions();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    
  }

  async setupSubscriptions() {
      this.computer$ = this.compServerSvc.server$.value.server$;
      this.computer$.subscribe( async (computer) => {
          this.registration = await window.electron.getRegistration()
          this.cdr.markForCheck();
          this.cdr.detectChanges();
      });
      await this.getHostname();
      this.compServerSvc.server$.subscribe( async (server) => {
        if(server) {
          server.on('computerStateChanged', async () => {
            if(server.state === ComputerServerState.ready) {
              this.dialogRef.close();
            }
          });
        }
      });
  }

  async getRegistration() {
    return await window.electron.getRegistration();
  }

  async getHostname() {
    this.hostnamefield = await window.electron.getComputerName();
    return this.hostnamefield
  }

  async clickSave() {
    try {
      await this.compServerSvc.registerServer(this.hostname.nativeElement.value);
      setTimeout( () => {
        this.dialogRef.close();
      }, 500)
    } catch(e) {
      this.logSvc.error('ComputerAddModalComponent - clickSave');
    }
    
  }

  clickNext() {
    this.dialogRef.close();
  }

}
