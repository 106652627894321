import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ComputerObject } from '../../objects/computerObject';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { defaultSpaceConfig } from 'src/app/utils/defaultSpaceconfig';
import { VrService } from 'src/app/services/vr-service/vr.service';
import { Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LargeStreamModal } from '../popups/large-stream-modal/large-stream.modal.component';
import { NotificationService } from 'src/app/services/notification-service/notification-service.service';
import { off } from 'process';

@Component({
  selector: 'computer-screen-large-input',
  templateUrl: './computer-screen-large-input.component.html',
  styleUrls: ['./computer-screen-large-input.component.scss'],
})
export class ComputerScreenLargeInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  screenId: string;
  @Input()
  streamIndex: number;
  @Input()
  stream: MediaStream;
  @Input()
  computer: ComputerObject;
  @Input()
  room: RoomObject;
  @Input()
  opacity = 1;
  @Input()
  type: 'camera' | 'screen' 
  @Input()
  messageToSend: string = '';
  linkCords = {x: 0, y:0, ewidth: 0, eheight: 0};
  tracking = false;
  inputTaken = false;
  @ViewChild('lgviewer') viewer: ElementRef;
  @ViewChild('sendhelp') helper: ElementRef;
  @ViewChild('helptext') helptext: ElementRef;
  @Input() 
  totalScreens: number = 1;
  @Input() 
  focusIndex: number;
  element: HTMLElement;
  videoElement: HTMLElement;
  @Input()
  spaceConfig: any;
  @Output() screenEventEmitter = new EventEmitter<string>();
  screenConfig: any;
  constructor(
    public authSvc: AuthService,
    public logSvc: LogService,
    public vrSvc: VrService,
    public dialog: MatDialog,
    public notifySvc: NotificationService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if(!this.stream) {
      console.log(this.computer.screens);
      console.log(this.screenId);
      const screen = this.computer.screens.find((item) => { return item.id == this.screenId})
      this.screenConfig = screen;
      if(screen.stream) {
        
        this.stream = screen.stream;
      } else {
        this.stream = this.computer.screens[0].stream;
      }
      if(this.computer.computer.ownerUid == this.authSvc.user.value.uid) {

        this.activateUserTracking();
      }
    }
    
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    this.setViewerSize();
    //this.viewer.nativeElement.addEventListener('mousemove', this.watchMouse.bind(this));
    //this.viewer.nativeElement.addEventListener('click', this.watchMouse.bind(this));
    //this.viewer.nativeElement.addEventListener('dblclick', this.watchMouse.bind(this));
    //this.viewer.nativeElement.addEventListener('contextmenu', this.watchMouse.bind(this));
    // add event listener on video load to get the videowidth
    this.viewer.nativeElement.addEventListener('loadedmetadata', (e) => {
      //console log the videoWidth
      console.log('videoWidth:', this.viewer.nativeElement.videoWidth);
      console.log('videoHeight:', this.viewer.nativeElement.videoHeight);
    });
    if(!this.spaceConfig) {
      this.spaceConfig = this.computer.screens[this.focusIndex];
    }
    
    this.vrSvc.vrEngaged.subscribe(this.updateVREngaged.bind(this));
    this.helper.nativeElement.style.opacity = 0;

    
    
  }

  async updateVREngaged() {
    if(this.vrSvc.vrEngaged.value) {
      if(!this.element) {
        setTimeout( async () => {
          await this.drawVectorSpace();
        }, 500);
        
      }
      
    } else {
      if(this.element) {
        this.element.remove();
      }
      return;
    }
  }


  async activateUserTracking() {
    if(this.tracking) {
      await this.computer.sendUserViewEnd(this.authSvc.user.value.uid, this.computer.screens[this.streamIndex || 0].id, this.room.roomId);
    } else {
      await this.computer.sendUserViewStart(this.authSvc.user.value.uid, this.computer.screens[this.streamIndex || 0].id, this.room.roomId);
    }
    this.setWatch();
  }

  setWatch() {
    this.tracking = !this.tracking;
    this.notifySvc.sendLocal('Computer Tracking: ' + this.tracking);
    // if tracking is turned on then setup an event listener for all keyboard events, otherwise remove the listener
    if(this.tracking) {
      window.addEventListener('keydown', this.handleKeyboardEvent.bind(this));
      window.addEventListener('keyup', this.handleKeyboardEvent.bind(this));
      window.addEventListener('keypress', this.handleKeyboardEvent.bind(this));
    } else {
      window.removeEventListener('keydown', this.handleKeyboardEvent.bind(this));
      window.removeEventListener('keyup', this.handleKeyboardEvent.bind(this));
      window.removeEventListener('keypress', this.handleKeyboardEvent.bind(this));
    }
  }
  
  handleKeyboardEvent(evt: KeyboardEvent) {
    evt.preventDefault();
    console.log('keyboard event');
    console.log(evt)
    console.log(JSON.stringify(evt))
    const keyboardEvent = {
      altKey: evt.altKey,
      charCode: evt.charCode,
      code: evt.code,
      composed: evt.composed,
      ctrlKey: evt.ctrlKey,
      key: evt.key,
      keyCode: evt.keyCode,
      shiftKey: evt.shiftKey,
      type: evt.type


    }
    this.computer.sendKeyboardEvent(this.authSvc.user.value.uid, keyboardEvent)
  }

  async watchMouse(e) {
    return;
    if(this.tracking) e.preventDefault();
    console.log(e)
    this.logSvc.info('ComputerScreenLargeInputComponent - Mouse Watch')
      this.inputTaken = true;
      let offsetX = 0;
      let offsetY = 0;
      //const offsetX = (e.target.offsetWidth - e.target.videoWidth)/2;
      //const offsetY = (e.target.offsetHeight - e.target.videoHeight)/2;
      console.log(offsetX, offsetY)
      const width = e.target.videoWidth;
      const height = e.target.videoHeight;
      // Below the offset
      // if(e.x < offsetX || e.y < offsetY) {
      //   return;
      // }
      // // above the offset
      // if(e.x > (e.target.width  - offsetX) || e.y > (e.target.height - offsetY)) {
      //   return;
      // }
      //let realx = e.x+offsetX;
      // const realy = e.y+offsetY;

      const { newx, newy } = this.translateMouseEvent(e);
      let type = e.type;
      if(e.type == 'contextmenu') {
        type = 'click';
      }
      const mouseEvent: any = {
        type: type,
        x: newx,
        y: newy,
        ewidth: width,
        eheight: height,
        screenId: this.screenId

      }

      switch(type) {
        case 'click':
          setTimeout( () => {
            const lgviewerElement = this.viewer.nativeElement;

            // Calculate the offset of the lgviewer element
            const offsetTop = lgviewerElement.offsetTop - lgviewerElement.scrollTop + lgviewerElement.clientTop;
            const offsetLeft = lgviewerElement.offsetLeft - lgviewerElement.scrollLeft + lgviewerElement.clientLeft;
            
            this.linkCords = {x: e.x +  + offsetLeft, y: e.y + offsetTop, ewidth: width, eheight: height};
            //this.moveLinkSender((e.x - 100), (e.y - 100), width, height);
          }, 200);
          mouseEvent.shiftKey = e.shiftKey;
          mouseEvent.ctrlKey = e.ctrlKey;
          mouseEvent.altKey = e.altKey;
          mouseEvent.metaKey = e.metaKey;
          mouseEvent.button = e.button;
          this.computer.sendMouseEvent(this.authSvc.user.value.uid, this.screenId, mouseEvent, this.room.roomId);
          break;
        case 'dblclick':
         
          mouseEvent.shiftKey = e.shiftKey;
          mouseEvent.ctrlKey = e.ctrlKey;
          mouseEvent.altKey = e.altKey;
          mouseEvent.metaKey = e.metaKey;
          mouseEvent.button = e.button;
          this.computer.sendMouseEvent(this.authSvc.user.value.uid, this.screenId, mouseEvent, this.room.roomId);
          break;
        case 'mousemove':
          console.log('mousemove')
          if (this.tracking) {
            this.computer.sendMouseEvent(this.authSvc.user.value.uid, this.screenId, mouseEvent, this.room.roomId);
          }   
          break;
      }
      return;    
  }

  translateMouseEvent(e: MouseEvent) {
    const videoElement = e.target as HTMLVideoElement;
  
    // Calculate the scale of the video content to the video element
    const scaleX = videoElement.videoWidth / videoElement.offsetWidth;
    const scaleY = videoElement.videoHeight / videoElement.offsetHeight;
  
    // Calculate the position of the video element relative to the page
    const rect = videoElement.getBoundingClientRect();
  
    // Translate the page coordinates to video content coordinates
    // @ts-ignore
    const newx = ((e.pageX - rect.left) - e.target.offsetLeft) * scaleX;
    // @ts-ignore
    const newy = ((e.pageY - rect.top) - e.target.offsetTop) * scaleY;
  
    return { newx, newy };
  }

  moveLinkSender(x, y, ewidth?, eheight?) {
    this.opacity = 1;
    
    this.helper.nativeElement.setAttribute('style', 'position:absolute;top:'+ (y)+ 'px;left:'+ (x) + 'px;');
    this.inputTaken = true;
    
  }
  
  sendMouseLink() {
    const mouseEvent = {
      type: 'linkshared',
      x: this.linkCords.x,
      y: this.linkCords.y,
      ewidth: this.linkCords.ewidth,
      eheight: this.linkCords.eheight,
      msg: this.helptext.nativeElement.value
    }
    
    this.computer.sendMouseEvent(this.authSvc.user.value.uid, this.screenId, mouseEvent, this.room.roomId);
    this.fadeAwayLinkSender();
  }

  fadeAwayLinkSender() {
    if(this.opacity < 0) {
      this.helptext.nativeElement.value = '';
      return;
    }
    setTimeout(this.fadeAwayLinkSender.bind(this), 100);
    this.opacity = this.opacity - 0.8;
    this.helper.nativeElement.style.opacity = this.opacity;
  }

  checkforInput() {
    if(!this.inputTaken) {
      this.fadeAwayLinkSender();
    }
    this.inputTaken = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.viewer) {
      this.setViewerSize();
    }
    
    // changes.prop contains the old and the new value...
  }

  setViewerSize() {
    const screenRatio = this.screenConfig.size.width / this.screenConfig.size.height;
    const screenHeight = window.innerHeight - 100; // Subtract 100px for top and bottom offset
    const screenWidth = screenHeight * screenRatio;

    this.viewer.nativeElement.style.width = screenWidth + 'px';
    this.viewer.nativeElement.style.height = screenHeight + 'px';
  }

  clickRemove(index: number) {
    console.log(index);
    if(this.room) {
      this.room.removeFocus(index);
      this.screenEventEmitter.emit(this.screenId);
    } 
   
  }

  async drawVectorSpace() {
    
    if(this.spaceConfig) {

      const videoElement = document.createElement('video');
      videoElement['srcObject'] = this.stream;
      videoElement.setAttribute('id', this.stream.id)
      videoElement.setAttribute('autoplay', 'true');
      document.querySelector('a-assets').appendChild(videoElement);
      
      this.videoElement = videoElement;
  

      setTimeout( () => {
        const screenElem = document.createElement('a-curvedimage');
        screenElem.setAttribute('class', 'screensClass');
        screenElem.setAttribute('raycastable', 'true');
        screenElem.setAttribute('position', '0 0.05 0.15');
        screenElem.setAttribute('rotation', '0 90 0');
        screenElem.setAttribute('radius', '1.2');
        screenElem.setAttribute('height', this.spaceConfig.height);
        screenElem.setAttribute('muted', 'true');
        screenElem.setAttribute('margin', "0 0.25 0 0")
        screenElem.setAttribute('src', '#' + this.stream.id.toString());
        screenElem.setAttribute('theta-length', '25');
        screenElem.setAttribute('start', '76');
        screenElem.setAttribute('cownername', this.computer.computer.owner);
        screenElem.setAttribute('cownerid', this.computer.computer.ownerUid);
        screenElem.setAttribute('clastonline', this.computer.computer.lastOnline);
        screenElem.setAttribute('computername', this.computer.computer.name);
        screenElem.setAttribute('computer', this.computer);
        screenElem.setAttribute('screenId', this.screenId);
        screenElem.setAttribute('streamIndex', this.streamIndex);
        document.querySelector('#app-dock-screen-holder').appendChild(screenElem);
        window.computer = this.computer;
        this.element = screenElem;
      }, 500);
      

    }
    
  }

  wideScreenVideo() {
    // open dialog LargeStreamModal 
     this.dialog.open(LargeStreamModal, {
      width: '320px',
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {stream: this.stream, screenId: this.screenId, computer: this.computer, room: this.room}
    });
   

  }

  ngOnDestroy() {
    if(this.element) {
      this.element.remove();
      this.videoElement.remove();
      delete this.element;
      delete this.videoElement;
    }
  }

}
