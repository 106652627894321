import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MenuItems } from '../../models/Menu';
import { RoomRoster } from '../../models/Room';
import { UserObject } from '../../objects/userObject';
import { UserObjectEvent, UserObjectState } from '../../objects/userObjectEnum';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { StunServiceService } from 'src/app/services/stun-service/stun-service.service';
import { AddFriendModalComponent } from '../popups/add-friend-modal/add-friend.component';
import { MatDialog } from '@angular/material/dialog';
import { VrService } from 'src/app/services/vr-service/vr.service';
import { AgoraService } from 'src/app/services/agora-service/agora-service';

@Component({
  selector: 'user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListItemComponent implements OnInit {

  @Input()
  // @ts-ignore
  menuItem?: MenuItems;
  @Input()
  rosterItem: RoomRoster
  name = 'Loading...';
  @Input()
  userObject: UserObject;
  @Input()
  userId: string;
  @Input()
  itemNum?: number;
  element: HTMLElement;
  constructor(
    public afs: AngularFirestore,
    public authSvc: AuthService,
    public logSvc: LogService,
    public dvcSvc: DeviceService,
    public agoraSvc: AgoraService,
    public dialog: MatDialog,
    public vrSvc: VrService,
    public stunSvc: StunServiceService,
    public agora: AgoraService,
    public zoneSvc: NgZone,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.logSvc.info('User-List-Item');
    if(this.userId) {
      this.userObject = new UserObject(undefined, this.userId, this.afs, this.logSvc, this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.zoneSvc  )
      setTimeout( () => {
        this.setupSubscription();
      }, 500)
      
    }
    setTimeout( () => {
      if(this.rosterItem && this.rosterItem.userObject) {
        this.userObject = this.rosterItem.userObject;
        this.setupSubscription();
        
      } 
      if(this.userObject && this.userObject.profile) {
        this.name = this.userObject.profile.name;
        this.updateView();
      }
    }, 500)
   
   
  }

  setupSubscription() {
    this.userObject.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this))
    this.userObject.on(UserObjectEvent.userStateChanged, this.updateView.bind(this));
    this.userObject.on(UserObjectEvent.actionMuted, this.updateView.bind(this));
    
    this.updateView();
  }

  updateView() {
    if(this.userObject && this.userObject.profile) {
      this.name = this.userObject.profile.name;
    }
   
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  routerCanReuse() {
    return false;
  }

  async clickMuteToggle() {
    if(this.userObject.uid === this.authSvc.user.value.uid) {
      const muted = await this.agoraSvc.muteAudio();
      await this.authSvc.localUser.muteToggle(muted)
    } else {
      this.userObject.muteUserLocal();
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }


  clickVideoToggle() {
    this.userObject.toggleVideo();
  }

  addFriend(user: UserObject) {
    this.openAddFriendDialog(user, '250ms', '250ms');
  }

  openAddFriendDialog(user: UserObject, enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(AddFriendModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        user: user
      }
    });
  }

  async updateVREngaged() {
    if(this.vrSvc.vrEngaged.value) {
      console.log('vrengaged true')
      if(!this.element) {
        setTimeout( async () => {
          await this.drawVectorSpace();
        }, 1000);
        
      }
      
    } else {
      console.log('vrengaged false')
      if(this.element) {
        this.element.remove();
      }
      return;
    }
  }

  async drawVectorSpace() {
    console.log(this.userObject.profile);
    if(this.userObject.profile.avatar) {
      const screenElem = document.createElement('a-entity');
      screenElem.setAttribute('user-avatar', 'src: test' + '; size:0.15;');
      screenElem.setAttribute('position', (this.itemNum*0.3).toString() + ' 0 0')
      document.querySelector('#user-heads').appendChild(screenElem);
      this.element = screenElem;
    }
    

  
    
  }



}
