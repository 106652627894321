<server-header-bar *ngIf="computer" [server]="server" [computer]="computer"></server-header-bar>
<!-- <div *ngIf="server">
    <div *ngFor="let item of server.usersConnected| keyvalue">
        {{ item.key }}   {{ JSON.stringify(item.value)}}    
        </div>
</div> -->
<mat-accordion [ngClass]="{'deactive': computer.computerState != 'Ready'}" *ngIf="computer.computer.ownerUid == authSvc.localUser.uid">
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div style="display: flex; justify-content:center; width: 100%; height: 100%;" *ngIf="config">
        <div class="basicsection">
            <h2>Basic Settings:</h2>
            <div *ngFor="let option of config.baseSettings| keyvalue">
                <setting-list-item (configUpdate)="computerConfigUpdated($event)"  [setting]="option.value" [name]="option.key"></setting-list-item>
            </div>
          </div>
          <div class="sections">
            <h2>vScreens:</h2>
            <div *ngFor="let option of config.availableScreens">
                <available-screen-list-item (screenConfigUpdate)="computerConfigUpdated($event)" [screenDef]="option"></available-screen-list-item>
            </div>
          </div >
          <!-- <div class="sections">
            <h2>Rooms:</h2>
            <div *ngFor="let room of rooms">
                <space-list-item [space]="room">  </space-list-item>
            </div> -->
          <!-- </div>
          <div  class="sections">
            <h2>Online Users:</h2>
            <user-list-item *ngIf="authSvc.localUser" [userObject]="authSvc.localUser"></user-list-item>
        </div> -->
      </div>
      
    </mat-expansion-panel>
  </mat-accordion>
<div style="display: flex; justify-content:center; align-items:center; width: 100%; height: 80%;">
    <computer-screen-list-horizontal [computer]="computer" *ngIf="computer.computer.ownerUid == authSvc.localUser.uid && !focused.length && timeDiff<3000"></computer-screen-list-horizontal>
    <div *ngFor="let focusWindow of focused;  let i = index">
        <computer-screen-large-input *ngIf="focusWindow.computer" [screenId]="focusWindow.screenId" [type]="focusWindow.type" [stream]="focusWindow.stream" [computer]="focusWindow.computer" [totalScreens]="focused.length" [focusIndex]="i" (screenEventEmitter)="screenRemoveEvent($event)"></computer-screen-large-input>
    </div>
    <div class="msg" *ngIf="computer.computer.ownerUid != authSvc.localUser.uid">
        <h1 style="font-size: 45px; padding-top: 15px;" >No Access</h1>
    </div >
    <div class="msg" *ngIf="computer.computerState != 'Ready'">
      <h1 style="font-size: 45px; padding-top: 15px;" >Offline</h1>
  </div >
    

    
</div>