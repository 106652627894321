import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MenuItems } from '../../models/Menu';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareRoomModalComponent } from '../popups/share.room.modal/share.room.modal.component';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { RoomService } from 'src/app/services/room.service/room.service';
import { MenuService } from 'src/app/services/menu-service/menu.service';

@Component({
  selector: 'space-list-item',
  templateUrl: './space-list-item.component.html',
  styleUrls: ['./space-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpaceListItemComponent implements OnInit {

  @Input()
  menuItem: MenuItems;
  constructor(
    public authSvc: AuthService,
    public logSvc: LogService,
    public dialog: MatDialog,
    public rmSvc: RoomService,
    private menuSvc: MenuService,
    public dvcSvc: DeviceService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(
  ): void {
  }
  
  reload() {
    window.location.reload();
  }

  openShareRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ShareRoomModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }


  shareRoom() {
    this.openShareRoomDialog('200ms', '400ms');
  }

  goToRoom() {
    try {
      this.router.navigate(['/space/' + this.menuItem.mid +'?name='+ encodeURIComponent(this.menuItem.title)], { });
      //this.router.navigateByUrl('/space/' + this.menuItem.mid +'?name='+ encodeURIComponent(this.menuItem.title));
    } catch(e) {
      alert('URL does not exist');
    }
    
  }

  clickRoom() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/space/' + this.menuItem.mid +'?name='+ encodeURIComponent(this.menuItem.title), {skipLocationChange: true}).then(() => {
      this.reload();
    });
  }

  async addScreen() {
    this.logSvc.info('SpaceListItem - click addScreen');
    await this.dvcSvc.addScreen();
  }

  encode(tmp: string) {
    return encodeURIComponent(tmp);
  }

  async removeSpace() {
    this.logSvc.info('SpaceListItem - click removeSpace');
    try{
      this.rmSvc.removeRoom(this.menuItem.mid);
    } catch(e) {
      this.logSvc.error(e);
    }
   try {
    await this.menuSvc.removeMenuItem(this.menuItem);
   }
    catch(e) {
      this.logSvc.error(e);
    }
    //this.authSvc.removeSpace(this.menuItem.mid);
  } 

}
