import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { ComputerObjectEvents, ComputerObjectState, ComputerServerState } from '../../objects/computerEnum';
import { ComputerObject } from '../../objects/computerObject';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import * as moment from 'moment';
import { UserObject } from '../../objects/userObject';
import { User } from '../../models/Users';
import { LogService } from '../../services/log-service/log.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DeviceService } from '../../services/device-service/device.service';
import { AgoraService } from '../../services/agora-service/agora-service';
import { AuthService } from '../../services/auth-service/auth.service';
import { StunServiceService } from '../../services/stun-service/stun-service.service';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { ComputerService } from '../../services/computer-service/computer.service';
import { NotificationService } from '../../services/notification-service/notification-service.service';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';

@Component({
  selector: 'computer-card',
  templateUrl: './computer-card.component.html',
  styleUrls: ['./computer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerCardComponent implements OnInit {
  @Input()
  computer: ComputerObject;
  @Input()
  room: RoomObject;
  currentTime: number = moment().unix()
  computerOwner: UserObject;
  @Input()
  hideTop = false;
  @Input()
  hideBottom = false;
  @Input()
  showScreens = true;
  imgTooltip: string;
  constructor(
    public cdr: ChangeDetectorRef,
    public afs: AngularFirestore,
    public logSvc: LogService, 
    public dvcSvc: DeviceService, 
    public agoraSvc: AgoraService, 
    public authSvc: AuthService,
    public stunSvc: StunServiceService,
    public notifSvc: NotificationService,
    public computerSvc: ComputerService,
    public serverSvc: ComputerServerService,
    public ngSvc:  NgZone,


  ) { }

  ngOnInit(): void {
    this.computer.on(ComputerObjectEvents.computerStateChanged, this.updateview.bind(this));
    this.computer.on(ComputerObjectEvents.computerProfileChanged, this.updateview.bind(this));
  }

  updateview() {
    if(this.computer.computerState == ComputerObjectState.dataOnline || this.computer.computerState == ComputerObjectState.ready) {
      this.imgTooltip = this.computer.computer.name;

    } else {
      this.imgTooltip = this.computer.computer.name + '(Offline)'
    }
    this.currentTime = this.computer.computer.lastOnline;
    if(!this.computerOwner && this.computer.computer.ownerUid) {
      this.computerOwner = new UserObject(null, this.computer.computer.ownerUid, this.afs, this.logSvc, this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngSvc);
      this.computerOwner.on(UserObjectEvent.userProfileChanged, this.updateview.bind(this));
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  clickCard() {
    
    if ( this.computer.computerState == ComputerObjectState.dataOnline || this.computer.computerState == ComputerObjectState.ready) {
      alert('Computer Offline');
    }
    if(this.room) {
      this.room.addFocus({streamIndex: 0, stream: this.computer.streams[0], computer: this.computer});
    }
  }

  clickComputerMain() {
    // What to do when main screen is clicked 
  }

  focus(screenId: string) {
    this.computerSvc.setFocusComputer(this.computer, screenId);
  }

  mainscreenClick() {
    // if the computer is offline, send message to that effect 
    if(this.computer.computerState == ComputerObjectState.dataOnline || this.computer.computerState == ComputerObjectState.ready) {
      this.notifSvc.sendLocal('Computer Offline', this.room, this.authSvc.user.value.uid, this.computer.cid);
    } else {
     
      this.computerSvc.setFocusComputer(this.computer, this.computer.screens[0].id);
    }
  }


}
