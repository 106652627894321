import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu-service/menu.service';
import { MenuItems, MenuTypes } from '../../../../models/Menu';
import { Router } from '@angular/router';
import { Room } from 'src/app/models/Room';
import { RoomService } from 'src/app/services/room.service/room.service';

@Component({
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRoomDialogComponent implements OnInit {
  @ViewChild('roomName') roomName: ElementRef<HTMLInputElement> | undefined;
  constructor(public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public logSvc: LogService,
    private menuSvc: MenuService,
    public roomSvc: RoomService,
    private routerSvc: Router, 
    public dialogRef: MatDialogRef<AddRoomDialogComponent>) { }

  ngOnInit(): void {
  }

  async createRoom() {
    const roomName = this.roomName!.nativeElement.value;
    if(!roomName) {
      alert('Please enter a room name');
      return;
    }

    const room = await this.roomSvc.createRoom(roomName);
    let tmpRoomMenu: MenuItems = {
      mid: room.roomId,
      uid: this.authSvc.user.value.uid,
      title: this.roomName!.nativeElement.value,
      type: MenuTypes.room

    }
    let tmpMenuItem = await this.menuSvc.addMenuItem(tmpRoomMenu);
    alert('Room Created');
    // Navigate to Room
    await this.routerSvc.navigateByUrl('/space/'+ tmpMenuItem.mid + '?name=' + encodeURIComponent(tmpMenuItem.title));
    this.dialogRef.close();
  }

}
