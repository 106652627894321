<div id="browserControls" class="browserControls">
    <mat-icon (click)="goBack()">backspace</mat-icon><mat-icon (click)="goReload()">replay</mat-icon>
    <input type="text"
    [placeholder]="url"
    aria-label="Number"
    class="addressbarInput"
    matInput
    [formControl]="myControl"
    [matAutocomplete]="auto"> <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
</div>
<div *ngFor="let rosterItem of roster " class="user-head">
  <user-content [user]="rosterItem.userObject"></user-content>
</div>
<div id="browserArea" class="browser">


</div>
