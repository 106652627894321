import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FriendItems } from 'src/app/models/Friend';
import { PublicProfile } from 'src/app/models/Users';
import { FriendsService } from 'src/app/services/FriendsService/friends.service';
import { UserInviteManyRoomsDialogComponent } from '../popups/user-invite-many-rooms-dialog/user-invite-many-rooms-dialog.component';
import { VrService } from 'src/app/services/vr-service/vr.service';

@Component({
  selector: 'friend-list-item',
  templateUrl: './friend-list-item.component.html',
  styleUrls: ['./friend-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FriendListItemComponent implements OnInit {
  @Input()
  friend: FriendItems
  @Input()
  profile: PublicProfile
  element: HTMLElement;
  assetElm: HTMLElement;
  constructor(
    public friendSvc: FriendsService,
    public vrSvc: VrService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.profile = await this.friendSvc.getFriendProfile(this.friend.otherseruid);
    this.vrSvc.vrEngaged.subscribe(this.updateVREngaged.bind(this));
    this.cdr.markForCheck();
    this.cdr.detectChanges();

  }

  updateVREngaged() {
    if(this.vrSvc.vrEngaged.value) {
      
        setTimeout( async () => {
          await this.drawVectorSpace();
        }, 1000);
      
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

  async drawVectorSpace() {
    // Find element by id 
    const userAsset = document.getElementById(`user-avatar-${this.friend.uid}`);
   
      
        if(this.profile.avatar) {
          setTimeout( () => {
            const asset = document.createElement('a-entity');
            asset.setAttribute('id', 'user-avatar-' + this.friend.otherseruid);
            asset.setAttribute('src', this.profile.avatar);
            asset.setAttribute('position', '0 0 0')
            document.querySelector('a-assets').appendChild(asset);
            this.assetElm = asset;

            // Now add the actual entity for the docked screen
            const screenElem = document.createElement('a-curvedimage');
            screenElem.setAttribute('src', '#user-avatar-' + this.friend.otherseruid);
            screenElem.setAttribute('position', '0 0.05 0.15');
            screenElem.setAttribute('rotation', '0 90 0');
            screenElem.setAttribute('height', '0.25');
            screenElem.setAttribute('radius', '1.2');
            screenElem.setAttribute('theta-length', '12');
            screenElem.setAttribute('username', this.profile.name);
            screenElem.setAttribute('friend', true);
            screenElem.setAttribute('uid', this.friend.otherseruid);
            screenElem.setAttribute('raycastable', true);
            document.querySelector('#user-dock-holder').appendChild(screenElem);
            this.element = screenElem;
          }, 200);
        } 
    
  }

  getImageDataFromSrc(src) {
    const prefix = 'data:image/png;base64,';
    if (!src.startsWith(prefix)) {
      throw new Error('Invalid image src: ' + src);
    }
    return src.substring(prefix.length);
  }

  remove() {
    this.friendSvc.deleteFriend(this.friend);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  phoneUser() {
    // TODO: Use the p2p to call users - should just be able to share p2p negotiations directly 
      

  }

  invite() {
    // TODO: Open Up the invite dialog 
    this.openUserInvite('250ms', '250ms');

  }

  openUserInvite(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(UserInviteManyRoomsDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        uid: this.friend.otherseruid,
        profile: this.profile
      }
    });
  }

  messageUser() {
    // Should just join the socketio room of the combo of both users? 

  }

}
