<div style="display:block; justify-content: center;" *ngIf="serverComputer">
    <br>
    <br>
    <div style="display:flex; justify-content: center;">
        <h1>Server</h1>
    </div>
    
    <div style="display:flex; justify-content: center; ">
        <br>
        <div style="display:block; justify-content: center;">
            <h4>Server: {{serverComputer.name}}</h4>
            <h2>Status: {{serverComputer.computerState}}</h2>
            <computer-card  [computer]="serverComputer" [hideBottom]="true" [hideTop]="false" [addToolTip]=""></computer-card>
        </div>
    </div>
    <div style="display:flex; justify-content: center; padding-top: 30px;">
        <button class="mat-raised-button mat-accent" (click)="openWebBrowser()">Open Website</button>
        <button class="mat-raised-button mat-warn" (click)="logout()">Logout</button>
    </div>
    
</div>