import { ComputerShares } from '../objects/computerEnum';
import { RoomRoster } from './Room';
import { User } from './Users';

export type Computer = {
    cid?: string;
    ownerUid: string;
    owner?: User;
    name: string;
    lastOnline?: number;
    status?: string;
    peerId?: string;
    currentSession?: ComputerSession;
    thumbnail?: string;
    availableScreens?:Array<{name: string, id: string, type: 'screen' | 'camera' | 'arowindow', thumbnail: string, vectorConfig?: any, included?: boolean }>;
    baseSettings?: ServerBaseSettings,
    rooms?: ComputerShares[];
    onlineUsers?: RoomRoster[];
    created?: number;
};

export type ServerBaseSettings = {
    
    startAtStartup?: {
        readableLabel: 'Start Application at Startup.', 
        type: 'boolean',
        default: true,
        setTo: boolean
    },
    preventPowersave?: {
        readableLabel: 'Prevent PowerSaving.', 
        type: 'boolean',
        default: true,
        setTo: boolean
    },
    allowUserHUDOverlayBoxes?: {
        readableLabel: 'Allow Users to send computer overlays.', 
        type: 'boolean',
        default: true,
        setTo: boolean
    },
    allowUsersToTrackMouseOverlay?: {
        readableLabel: 'Allow Users mouse tracking overlays.', 
        type: 'boolean',
        default: true,
        setTo: boolean
    },
    allowServerAutoOnline?: {
        readableLabel: 'Allow the server to come online in all rooms with application',
        type: 'boolean',
        default: true,
        setTo: boolean
    }
}

export type ComputerSession = {
    id: string;
    created: number;
    peerSession?: RTCPeerConnection;
    lastUpdated: number;
    peerId?: string;
    computerId: string;
    user: User;
    approvedOn: number;
    approvedBy: User;
    security: 'Owner' | 'Guest';
    validUtil: number;
    thumbnail?: string;
};


export type ComputerDataEvent = {
    type: ComputerDataEventTypes,
    roomId?: string,
    uid: string,
    msg?: string,
    data?: any;
}

export enum ComputerDataEventTypes {
    msg = 'ComputerDataEventMsgType',
    screensUpdated = 'ComputerDataEventScreenUpdateType',
    addBrowser = 'ComputerDataEventAddBrowserType',
    removeBrowser = 'ComputerDataEventRemoveBrowserType',
    screenViewStart = 'ComputerDataEventScreenViewStartType',
    screenViewEnd = 'ComputerDataEventScreenViewEndType',
    screenHoverUpdate = 'ComputerDataEventScreenHoverUpdate',
    mouseEvent = 'ComputerDataEventMouseEventType',
    keyboardEvent = 'ComputerDataEventKeyboardEventType',
    mouseLink = 'ComputerDataEventMouseLinkEventType',
    configRequest = "ComputerDataEventConfigRequested",
    configShared = "ComputerDataEventConfigShared",
    configUpdate = "ComputerDataEventConfigUpdate",
    requestScreenStreams = "ComputerDataEventRequestScreenStreams",
}

