import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

export type AROSignal = {
  roomId?: string,
  userId?: string, 
  type: 'present',
  meta: any
  timestamp: number
}

export type AROState =  {
  roomId?: string,
  userId?: string, 
  type: REAL_TIME_ROOM_STATES
  meta: any,
  timestamp: number
}

export enum REAL_TIME_ROOM_SIGNALS {
  NewPresenter = 'new_presenter',
  User_Join = 'user_join',
  User_Leave = 'user_leave'
}

export enum REAL_TIME_ROOM_STATES {
  Presenter = 'Presenter',
  Host = 'Host'
}


@Injectable({
  providedIn: 'root'
})
export class RealtimeService {
  private basePath = '/room/';
  signalsRef: AngularFireList<AROSignal>;
  currentRoom: string;
  currentRoomTimeline: AngularFireList<AROSignal>;
  currentRoomState: AngularFireList<AROState>;
  constructor(
    private db: AngularFireDatabase
  ) { }

  
  
  getRoomSignal(roomId: string) {
    return this.signalsRef = this.db.list(this.basePath + roomId);
  }

  joinRoom(roomId: string) {
    this.currentRoom = roomId;
    this.currentRoomState = this.db.list(this.basePath + roomId + '/state');
    this.currentRoomTimeline = this.db.list(this.basePath + roomId + '/timeline');
    //Setup Subscriptions
    this.setupRoomSuscriptions();
  }

  setupRoomSuscriptions() {
    this.currentRoomState.stateChanges(['child_added', 'child_changed']).subscribe((changes) => {
      // Subscriptions to new of updated values
      const key = changes.payload.key;
      // @ts-ignore
      const msgData: AROState = changes.payload.toJSON();
      if(msgData.type == REAL_TIME_ROOM_STATES.Presenter) {
        console.log('got here')
      }
    })

    this.currentRoomTimeline.stateChanges(['child_added', 'child_changed', 'child_removed']).subscribe((changes) => {

    })
  }

  roomStateSet(type: REAL_TIME_ROOM_STATES, value: any) {
    
  }

  roomAddTimelineEvent(type: REAL_TIME_ROOM_SIGNALS, value: any) {
    
  }

  create(signal: AROSignal) {
    if(!this.signalsRef) {
      this.getRoomSignal(signal.roomId);
    }
    return this.signalsRef.push(signal);
  }

  
}
