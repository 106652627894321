import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoomRoster } from '../../models/Room';
import { RoomObject } from '../../objects/roomObject';
import { UserObject } from '../../objects/userObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { AgoraService } from '../../services/agora-service/agora-service';
import { AuthService } from '../../services/auth-service/auth.service';
import { VrService } from '../../services/vr-service/vr.service';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { LargeStreamModal } from '../popups/large-stream-modal/large-stream.modal.component';

@Component({
  selector: 'user-video-resizable-card',
  templateUrl: './user-video-resizable-card.component.html',
  styleUrls: ['./user-video-resizable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVideoResizableCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  room: RoomObject;
  @Input()
  roster: RoomRoster
  @Input()
  user: UserObject;
  uuid: string;
  showMenu = false;
  videoTrack: any;
  videoPlaying = false;
  agoraSub = null;
  vrsub = null;
  vrEngaged = false;
  constructor(
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public agoraSvc: AgoraService,
    public vrSvc: VrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.uuid = uuidv4();
    this.user.on(UserObjectEvent.actionVideoToggle, this.updateVideo.bind(this));
    this.user.on(UserObjectEvent.actionMuted, this.updateView.bind(this));
    this.vrsub = this.vrSvc.vrEngaged.subscribe(this.updateVideo.bind(this));
    if(this.user.uid == this.authSvc.localUser.uid) {
      this.agoraSub = this.agoraSvc.localVideo$.subscribe(this.updateVideo.bind(this));
    }
   
    
  }

  async ngAfterViewInit() {
    if(this.user.agoraVideoTrack) {
      await this.initialVideoLoad();
    }
  }

  async initialVideoLoad() {
    this.videoTrack = this.user.agoraVideoTrack;
    this.videoPlaying = true;
    this.updateView();
    setTimeout(() => {

      this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
    }, 500);
    return;
  }

  async updateVideo() {
    console.log('updatevideo')
    if(this.videoTrack && this.videoTrack._isClosed && this.videoPlaying && !this.vrSvc.vrEngaged.value) {
    
      this.videoTrack = null;
      this.videoPlaying = false;
      this.vrEngaged = false;
      this.updateView();
      return;
    }

    if(this.videoPlaying && !this.vrSvc.vrEngaged.value && this.videoTrack && this.user.agoraVideoTrack) {
      setTimeout(() => {
        const domElement = document.getElementById('user-video-' + this.user.uid + '-resizeable');
        if(!domElement) {
          return;
        }
        this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
        this.videoPlaying = true;
        this.vrEngaged = false;
        
      }, 200);
      this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
      this.videoPlaying = true;
      this.videoTrack = this.user.agoraVideoTrack;
      this.vrEngaged = false;
      this.updateView();

    }

    const domElement = document.getElementById('user-video-' + this.user.uid + '-resizeable');
    if(!domElement) {

      this.videoTrack = null;
      this.videoPlaying = false;
      this.vrEngaged = false;
      return;
    }
    
    if(this.user.agoraVideoTrack && !this.vrSvc.vrEngaged.value && !this.videoPlaying) {

      setTimeout(() => {
        const domElement = document.getElementById('user-video-' + this.user.uid + '-resizeable');
        if(!domElement) {
          return;
        }
     
        this.user.agoraVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
        this.videoPlaying = true;
        this.vrEngaged = false;
        this.updateView();
      }, 200)
        
      
      
      this.videoTrack = this.user.agoraVideoTrack;
      this.videoPlaying = true;
      return;
    }

    if(this.agoraSvc.channelParameters.localVideoTrack && this.authSvc.localUser.uid == this.user.uid && !this.videoPlaying && !this.vrSvc.vrEngaged.value) {
      console.log('update video4')
      this.videoTrack = this.agoraSvc.channelParameters.localVideoTrack;
      this.videoPlaying = true;
      this.vrEngaged = true;
      this.updateView();
      setTimeout(() => {
        // find a dom element 
        const domElement = document.getElementById('user-video-' + this.user.uid + '-resizeable');
        if(!domElement) {
          return;
        }
        this.agoraSvc.channelParameters.localVideoTrack.play('user-video-' + this.user.uid + '-resizeable');
      }, 500)
      
      
    
    }

    return;
    
   
    
    
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async clickMuteToggle() {
    if(this.roster.userObject.uid === this.authSvc.user.value.uid) {
      const muted = await this.agoraSvc.muteAudio();
      await this.authSvc.localUser.muteToggle(muted)
    } else {
      this.roster.userObject.muteUserLocal();
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  wideScreenVideo() {
    if(this.videoTrack) {
      // get the sceren width in px 
      const screenWidth = window.screen.width;
      // open dialog LargeStreamModal 
      const dialogRef = this.dialog.open(LargeStreamModal, {
        width: screenWidth + 'px',
        enterAnimationDuration: '200ms',
        exitAnimationDuration: '200ms',
        data: {videoTrack: this.videoTrack, title: this.user.profile.name}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.videoTrack.play('user-video-' + this.user.uid + '-resizeable');
      });
    }
  }



  //ngondestroy to remove on event listeners
  ngOnDestroy() {
    this.videoPlaying = false;
    this.videoTrack = null;
    this.vrsub.unsubscribe();
    this.user.off(UserObjectEvent.actionVideoToggle, this.updateVideo.bind(this));
    this.user.off(UserObjectEvent.actionMuted, this.updateView.bind(this));
    if(this.user.uid == this.authSvc.localUser.uid) {
      this.agoraSub.unsubscribe();
    }
  }

}
