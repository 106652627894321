import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { deleteField } from "@angular/fire/firestore";
import { EventEmitter } from "@billjs/event-emitter";
import * as url from 'url';
import * as moment from "moment";
import Peer, { DataConnection, MediaConnection } from "peerjs";
import { BehaviorSubject, Observable } from "rxjs";
import { Computer, ComputerDataEvent, ComputerDataEventTypes } from "../../models/Computers";
import { ComputerServerEvents, ComputerServerState } from "../../objects/computerEnum";
import { AuthService } from "../auth-service/auth.service";
import { ElectronService } from "../electron-service/electron.service";
import { LogService } from "../log-service/log.service";
import { StunServiceService } from "../stun-service/stun-service.service";
import { defaultSpaceConfig } from "../../utils/defaultSpaceconfig";
import { RoomService } from "../room.service/room.service";
import { UserObject } from "../../objects/userObject";
import { PublicProfile } from "../../models/Users";
import { DeviceService } from "../device-service/device.service";
import { checkConnection, modifySdp } from "src/app/utils/webrtcutil";
import { NotificationService } from "../notification-service/notification-service.service";
const _ = require('lodash/core');


export class ServerObject extends EventEmitter {
    server$: BehaviorSubject<Computer | null> = new BehaviorSubject(null);
    user$: BehaviorSubject<any>;
    serverRef: AngularFirestoreDocument
    registrationId: string;
    hostname: string;
    streams: MediaStream[] = [];
    streamIds: Array<any> = [];
    peer: Peer;
    peerId: string;
    creation: number;
    // Remote Access Items
    browsers: Window[] = [];
    userTracking: any = {};
    calls: MediaConnection[] = [];
    connections: DataConnection[] = [];
    checkingConnection = false;
    // Tracking Users 
    usersConnected = {} // {uid: string, lastAction: number};
    sourceConfig: any;
    mainStream: MediaStream;
    state: ComputerServerState;
    thumbnail: string;

    checkingUpdateBackoff = false; // As two events usually get sent for display-metric-update 
    constructor(
        private authSvc: AuthService,
        private logSvc: LogService,
        public afs: AngularFirestore, 
        public electronSvc: ElectronService,
        public stunSvc: StunServiceService,
        public roomSvc: RoomService,
        public dvcSvc: DeviceService,
        public notifySvc: NotificationService,
        serverName?: string
        
    ) {
        super();
        this.logSvc.info('ServerObject Init');
        if(serverName) {
            this.hostname = serverName;
        }
        this.setupSubscriptions();
        this.creation = moment.now();
        
    }

    async setupSubscriptions() {
      this.user$ = this.authSvc.user;

      this.user$.subscribe( async (user) => {
        if(!user) {
          if(this.state == ComputerServerState.ready) {
            this.setState(ComputerServerState.closing);
          }
        }
        

      });


      window.addEventListener('message', async (event) => {
        console.log('Backend Message');
        console.log(event);
        const msg = event.data;

        // Messages from backend
        switch(msg) {
          case 'resume':
            this.handleComputerResume()
            break;
          case 'display-added':
            await this.handleDisplayUpdateEvent();
            break;
          case 'display-removed':
            await this.handleDisplayUpdateEvent();
            break;
          case 'display-metrics-changed':
            await this.handleDisplayMetricChangedEvent();
            break;
          case 'unlock-screen':
            await this.handleDisplayUpdateEvent();
            break;
        }
        return;
      })
    
      this.user$.subscribe( (user) => {
        if(user) {
            this.setState(ComputerServerState.init);
        } else {
            this.setState(ComputerServerState.unregistered);
        }
      });
      window.addEventListener('online', this.resetConnection.bind(this));
      window.addEventListener('offline', this.close.bind(this));
      window.addEventListener('beforeunload', this.close.bind(this));
     
    }

    shutdownAll() {
      this.logSvc.info('ComputerObject - shutdownAll');

      // Stop on the main Stream
      for(let i = 0; i < this.mainStream.getTracks().length; i++) {
        this.mainStream.getTracks()[i].stop();
      }
      // First Order Streams
      for(let x = 0; x < this.streams.length; x++) {
        for(let y = 0; y < this.streams[x].getTracks().length; y++) {
          this.streams[x].getTracks()[y].stop();
        }
      }
      
  
      // Calls 
      for(let x = 0; x < this.calls.length; x++) {
        this.calls[x].close();
      }

      // Connections 
      for(let x = 0; x < this.connections.length; x++) {
        this.connections[x].close();
      }
  
      this.peer?.disconnect();
    }

    async handleComputerResume() {
      console.log('handleComputerResume');
      this.resetConnection();
      await this.setupScreens(true);
      return;
    }


    async handleOnBackOnline() {
      console.log('handleOnBackOnline');
      this.resetConnection();
      await this.setupScreens(true);
      await this.sendConfigUpdatedBroadcast();
      return;
    }

    async handleDisplayUpdateEvent() {
      console.log('handleDisplayUpdateEvent');
      if(this.checkingUpdateBackoff) {
        return;
      }
      this.checkingUpdateBackoff = true;
      // Run to make sure config has the new available screen
      
      setTimeout( async () => {

       
        const shouldUpdate = await this.validateScreenChange();
        console.log(shouldUpdate)
        if(!shouldUpdate) {
          return;
        }
        this.calls.forEach(call => {
          call.close();
        });
        // for loop through the calls 
        for(let x = 0; x < this.calls.length; x++) {
          this.calls[x].close();

        }
        this.calls = [];
        await this.setupScreens(true);
        this.fire(ComputerServerEvents.computerDisplayMetricChanged);
        this.checkingUpdateBackoff = false;
        await this.sendConfigUpdatedBroadcast();  
      }, 2000);
      return;
    }

    async handleDisplayMetricChangedEvent() {
      console.log('handleDisplayMetricChangedEvent');
      if(this.checkingUpdateBackoff) {
        return;
      }
      this.checkingUpdateBackoff = true;
      setTimeout( async () => {

       
        const shouldUpdate = await this.validateScreenChange();
        console.log(shouldUpdate)
        if(!shouldUpdate) {
          return;
        }
        // Close the existing calls 
        this.calls.forEach(call => {
          call.close();
        });
        // for loop through the calls 
        for(let x = 0; x < this.calls.length; x++) {
          this.calls[x].close();

        }
        this.calls = [];
        await this.setupScreens(true);
        this.fire(ComputerServerEvents.computerDisplayMetricChanged);
        await this.sendConfigUpdatedBroadcast();
        this.checkingUpdateBackoff = false;
      }, 2000);
     
    }

    async validateScreenChange(): Promise<boolean> {
      console.log('validateScreenChange');
      const monitors = await window.electron.getSources();
      console.log(monitors);
      if(monitors.length != this.sourceConfig.length) {
        return true;
      }
      // check if the monitors size match 
      for(let i = 0; i < monitors.length; i++) {
        if(monitors[i].id != this.sourceConfig[i].id) {
          return true;
        }
        if(monitors[i].size.width != this.sourceConfig[i].size.width) {
          return true;
        }
        if(monitors[i].size.height != this.sourceConfig[i].size.height) {
          return true;
        }
      }
      
      // check if the monitors 
      return false;
      
    }

    async sendConfigUpdatedBroadcast(computer?) {
      console.log('sendConfigUpdatedBroadcast');
      if(computer) {
        this.server$.next(computer)
      }
      await this.fire(ComputerServerEvents.computerConfigUpdated);
      await this.broadcast({type: ComputerDataEventTypes.configUpdate});
      //this.resetConnection();
      return;
    }

    closeConnection() {
      this.streams = [];
      this.streamIds = [];
      // Cycle through this.connects and close 
      // Cycle through this.calls and close 
      this.peer.destroy();
      this.peer = null;
      this.peerId = null;
      this.streams = [];
      this.mainStream = null;
      // Remote Access Items
      this.browsers = [];
      delete this.userTracking;
      
      this.userTracking = {};
      this.calls = [];
      this.connections = [];
    }

    resetConnection() {
      this.shutdownAll();
      this.closeConnection();
      
      this.setState(ComputerServerState.init);

    }

    async getInitInfo() {
        this.registrationId = await window.electron.getRegistration();
        if(!this.hostname) {
            this.hostname = await window.electron.getComputerName();
        }
        
        this.serverRef = this.afs.doc(`computer/${this.registrationId}`);
    }

    setState(state: ComputerServerState): Promise<void> {
        return new Promise( async (resolve, reject) => {
            try {
                this.logSvc.info('ServerObject - stateChange:' + state);
                this.state = state;
                this.fire(ComputerServerEvents.computerStateChanged, state);
                switch(state) {
                    case ComputerServerState.init:
                        await this.getInitInfo();
                        await this.validateRegister();
                        resolve();
                        break;
                    case ComputerServerState.loading:
                        //await this.setupScreens();
                        await this.setupPeer(); // Keep Context of the callbacks
                        resolve();
                        break;
                    case ComputerServerState.ready:
                        this.startRegistration();
                        resolve();
                        break;
                    case ComputerServerState.closing:
                        await this.close();
                        resolve();
                        break;
                    case ComputerServerState.unregistered:
                        break;
                }
            } catch(e){
                reject(e);
            }
        })
    }

    async close() {
        return new Promise(async (resolve, reject) => {
            try {
              if(this.state == ComputerServerState.closed || this.state == ComputerServerState.closing) {
                resolve(null);
              }
                this.shutdownAll();
                this.closeConnection();
                this.peer = null;
                this.peer = null;
                this.calls = [];
                this.connections = [];
                this.streams = [];
                await this.serverRef.update({peerId: deleteField()});
                this.setState(ComputerServerState.closed);
            } catch(e) {
                reject(e);
            }
        })
    }

    async startRegistration() {
        return new Promise( async (resolve, reject) => {
            try {
                this.updateRegistration();
                resolve(null);
            } catch(e) {
                reject(e);
            }
        })
    }

    async updateRegistration(): Promise<void> {
        return new Promise( async (resolve, reject) => {
            try {
                let tmpServer = this.server$.value;
                tmpServer.lastOnline = moment.now();
                tmpServer.peerId = this.peerId;
                if(this.thumbnail) {
                    tmpServer.thumbnail = this.thumbnail;
                }
                if(!this.thumbnail && !tmpServer.thumbnail) {
                  const tmpscreens = await window.electron.getSources();
                  tmpServer.thumbnail = tmpscreens[0].imgthumbnail;
                }
                tmpServer = await this.getConfig(tmpServer);
                delete tmpServer.rooms;
                delete tmpServer.onlineUsers;
                await this.serverRef.set(tmpServer);
                let nextUpdate = 5000;
                const updateTime = moment.now() - this.creation;
                if(updateTime > 60000) {
                    nextUpdate = 30000;
                }
                if(updateTime > 360000) {
                    nextUpdate = 180000;
                }
                setTimeout(this.updateRegistration.bind(this), nextUpdate);
                resolve(null);
            } catch(e) {
                console.log('updateRegistration error' + e);
                reject(e);
            }
        })
    }

    async setupPeer() {
        return new Promise ( async (resolve, reject) => {
            if(!this.peer) {
                const urls = await this.stunSvc.getURLS();
                this.peer = new Peer(this.registrationId, {
                  debug: 3,
                    config: {
                      'iceServers': [urls.v.iceServers],
                        },
                  // @ts-ignore
                  host: peerjsserver,
                  secure: true,
                });
                this.peer.on('open', this.handleNewPeerId.bind(this));
                this.peer.on('call', this.handlePeerCall.bind(this));
                this.peer.on('connection', this.handleConnectionOpen.bind(this));
                this.peer.on('error', this.handlePeerError.bind(this));
                this.peer.on('disconnected', this.handlePeerDisconnected.bind(this));
                this.peer.on('close', this.handlePeerClose.bind(this));
            }
                
        })
    }

    handlePeerClose() {
        this.logSvc.info('handlePeerClose');
      }

    handlePeerDisconnected() {
        this.logSvc.info('handlePeerDisconnected');
      }

    handlePeerError(err: any) {
        this.logSvc.info('ComputerServerSvc - handlePeerError' + JSON.stringify(err));
        this.fire(ComputerServerEvents.computerServerPeerError, err);
      }
    
    checkStreamActive() {
        if(!this.mainStream) {
            // Reset 
            this.setState(ComputerServerState.closing);
            return;
        }
        console.log("testing stream active");
        
        setTimeout(this.checkStreamActive.bind(this), 5000);
    }

    checkForAV1(videoTrack: MediaStreamTrack) {
      const pc = new RTCPeerConnection();
      
      const sender = pc.addTrack(videoTrack);
      const capabilities = RTCRtpSender.getCapabilities('video');
      
      if (capabilities) {
        const av1Codec = capabilities.codecs.find(codec => codec.mimeType === 'video/AV1');
        if (av1Codec) {
          console.log('AV1 codec is supported');
        } else {
          console.log('AV1 codec is not supported');
        }
      } else {
        console.log('Could not get video capabilities');
      }
    }

    checkConnections() {
      try {
        console.log(this.connections.length)
        console.log(this.calls.length)
        console.log(this.streams.length)
        console.log(this.mainStream)
        let active = false;
        this.checkingConnection = true;
        // for loop through calls
        for(let i = 0; i < this.calls.length; i++) {
          if(!this.calls[i]) {
            this.calls.splice(i, 1);
            // if calls.length = 0 then close the streams 
            if(this.calls.length == 0) {
              for(let x = 0; x < this.streams.length; x++) {
                for(let y = 0; y < this.streams[x].getTracks().length; y++) {
                  const track = this.streams[x].getTracks()[y];
                  track.stop();
                  this.streams[x].removeTrack(track);
                }
                this.streams[x] = null;
              }
              for(let x = 0; x < this.mainStream.getTracks().length; x++) {
                const track = this.mainStream.getTracks()[x];
                track.stop();
                this.mainStream.removeTrack(track);
              }
              this.mainStream = null;
              this.streams = [];

            }
            continue;
          }
          
          if(this.calls[i] && this.calls[i].peerConnection && this.calls[i].peerConnection.connectionState == 'connected' || this.calls[i].peerConnection.connectionState == 'new') {
            active = true;
            console.log('call open');
            const connDetails = checkConnection(this.calls[i].peerConnection);
          } else {
            console.log('call possibly closed', this.calls[i].peerConnection);
            // TODO: check the call and then clean up the streams and data if not used 
            // Remove this call from the list
            
            this.calls.splice(i, 1);
            if(this.calls.length == 0) {
              this.mainStream.getTracks().forEach(track => {
                track.stop();
              });
              this.mainStream = null;
              this.streams.forEach(stream => {
                stream.getTracks().forEach(track => {
                  track.stop();
                });
              })
              this.streams = [];
              
            }
            
          }
        }
        // for loop through connections
        for(let i = 0; i < this.connections.length; i++) {
          if(!this.connections[i]) {
            this.connections.splice(i, 1);
            continue;
          }
          if(this.connections[i] && this.connections[i].peerConnection && (this.connections[i].peerConnection.connectionState == 'connected' || this.connections[i].peerConnection.connectionState == 'new')) {
            active = true;
          } else {
            // TODO: check the call and then clean up the streams and data if not used 
            // Remove this connection from the list
            this.connections.splice(i, 1);
            console.log('connection closed');
          }
        }
        if(this.calls.length == 0) {
          for(let x = 0; x < this.streams.length; x++) {
            for(let y = 0; y < this.streams[x].getTracks().length; y++) {
              const track = this.streams[x].getTracks()[y];
              track.stop();
              this.streams[x].removeTrack(track);
            }
            this.streams[x] = null;
          }
          if(this.mainStream) {
            for(let x = 0; x < this.mainStream.getTracks().length; x++) {
              const track = this.mainStream.getTracks()[x];
              track.stop();
              this.mainStream.removeTrack(track);
            }
            this.mainStream = null;
          }
          this.streams = [];
        }
        if(this.calls.length > 0 || this.connections.length > 0) {
          active = true;
        }
        if(active) {
          
          setTimeout(this.checkConnections.bind(this), 5000);
        } else {
          console.log('not active');
          this.checkingConnection = false;
          this.shutdownAll();
          this.closeConnection();
        }
      } catch(e) {
        console.error(e);
        console.log(this.calls)
        console.log(this.connections)
        setTimeout(this.checkConnections.bind(this), 5000);
      }
     
      
      

    }

    async handleConnectionOpen(connection: DataConnection) {
        console.log('handleConnectionOpen');
        return new Promise( async (resolve, reject) => {
          try {
            //connection.on('data', this.handleConnectionData.bind(this));
            this.logSvc.info('handleConnectionOpenComputer');
                
                
            connection.on('data', async (dataEvent: ComputerDataEvent) => {
              console.log('connection data event');
              console.log(dataEvent);
              const { type, roomId, msg, data, uid} = dataEvent;
              switch(type) {
                case ComputerDataEventTypes.configRequest:
                  await this.shareConfig();
                  break;
                case ComputerDataEventTypes.requestScreenStreams:
                  console.log('requestScreenStreams')
                  if(!this.mainStream) {
                    await this.setupScreens();
                  }
                  const config = await this.getSpaceConfig();
                  console.log(config);
                  let options = {"mandatory": {googCpuOveruseDetection: false, googImprovedWifiBwe: true, googDscp: true, googSuspendBelowMinBitrate: true, googScreencastMinBitrate:400, googCpuOveruseEncodeUsage: false}, metadata: {"screens":JSON.stringify(config)},
                    sdpTransform: modifySdp,
                  };
                   
                  console.log(connection)
                  console.log(connection.peer)
                  console.log(this.peer)
                  // @ts-ignore
                  if(connection.call && connection.call.open) {
                    console.log('call exists and is active')
                    // @ts-ignore
                    console.log(connection.call);
                    return;
                  }
                 
                  const newCall = await this.peer!.call(connection.peer, this.mainStream!, options);
                  
                    
                  newCall.peerConnection.onnegotiationneeded = async () => {
                    console.log('negotiation needed');
                    const senders = newCall.peerConnection.getSenders().filter(s => s.track?.kind == 'video');
                    console.log(senders)
                    for(let x = 0; x < senders.length; x++) {
                      const oldparams = senders[x].getParameters();
                      console.log(oldparams)
                      for(let i = 0; i < oldparams.encodings.length; i++) {
                        oldparams.encodings[i].maxBitrate = 25000000; // 25 Mbps
                        oldparams.encodings[i].priority = 'high';
                        // @ts-ignore
                        oldparams.encodings[i].networkPriority = 'high';
                        // @ts-ignore
                        oldparams.networkPriorities = 'high';
                        oldparams.encodings[i].scaleResolutionDownBy = 1;
                        
                      }
                      
                      oldparams.degradationPreference = 'maintain-resolution';
                      senders[x].setParameters(oldparams);
                    }
                    
                    const receivers = newCall.peerConnection.getReceivers()[0];
                    console.log(receivers.getParameters());
                  }
                  // @ts-ignore
                  connection.call = newCall;
                  this.calls.push(newCall);
                  console.log(newCall);
                  break;
                
                
              }
              this.handleConnectionData(dataEvent, connection);
            })

            connection.on('close', async () => {
              await this.handleConnectionClose(connection)
            });

            connection.on('error', this.handleConnectionError.bind(this));
            connection.on('close', this.handleConnectionClose.bind(this));
            console.log('new connection added')
            console.log(connection);
            this.connections.push(connection);
            await this.onNewConnection(connection);
            if(!this.checkingConnection) {
              this.checkingConnection = true;
              this.checkConnections();
            }
            
          
          } catch(e) {
            this.logSvc.error('handleConnectionOpenComputer' + JSON.stringify(e));
            reject(e);
          }
        })
       
      };

      async onNewConnection(connection: DataConnection) {
        console.log('onNewConnection')
        setTimeout( () => {
          connection.send({type: ComputerDataEventTypes.configShared, data: this.server$.value})
        }, 1000);
      }

      async handlePeerOpen(conn) {
        console.log('handlePeerOpen');
        console.log(conn);
      }
    
      async handleConnectionData(dataEvent: ComputerDataEvent, connection: DataConnection) {
        this.logSvc.info('ServerObject - handleConnectionData');
        console.log(dataEvent, connection)
        const { type, roomId, msg, data, uid} = dataEvent;
        if(!this.usersConnected[uid] && uid) {
          //if(uid && !this.usersConnected[uid]) {
            this.usersConnected[uid] =  { lastAction: moment.now() }
            setTimeout( async () => {
              const userProfile = await this.updateProfile(uid);
              console.log(userProfile);
              console.log('focusMainWindow')
              window.electron.focusMainWindow()
              if(userProfile) {
                this.notifySvc.sendLocal(userProfile?.name + " Joined Computer")
              }
              
              // TODO: add in notification to notify the user has joined
              this.fire(ComputerServerEvents.computerServerUserConnect, this.usersConnected);
            }, 2000);
          //} 
        } else {
          this.usersConnected[uid].lastAction = moment.now();
        }
        // For now we broadcast server messages by default;
        switch(type) {
          case ComputerDataEventTypes.addBrowser:
            await this.addScreenAsChild(data, roomId);
            await this.broadcast({type: ComputerDataEventTypes.screensUpdated, roomId: roomId})
            break;
          case ComputerDataEventTypes.screenViewStart:
            await this.addUserScreen(uid);
            break;
          case ComputerDataEventTypes.screenViewEnd:
            await this.removeUserScreen(uid);
            break;
          case ComputerDataEventTypes.mouseEvent:
            await this.processMouseEvent(dataEvent);
            break;
          case ComputerDataEventTypes.keyboardEvent:
            await this.processKeyboardEvent(dataEvent)
            break;
          case ComputerDataEventTypes.configRequest:
            await this.shareConfig();
            break;
          case ComputerDataEventTypes.configUpdate:
            await this.remoteConfigUpdate(dataEvent);
            break;
        }
          

      }

      async remoteConfigUpdate(dataEvent: any) {
        const { data, uid, roomId} = dataEvent;
        
        const data2  = dataEvent['data']['evtData'];
        await this.checkUpdateScreens(data);
        await this.processRemoteconfigUpdate(data);
        
        return;
       
      }

      async processRemoteconfigUpdate(configUpdate: Partial<Computer>) {
        this.logSvc.info('ServerObject - processRemoteconfigUpdate');
        
        const currentConfig = this.server$.value;

        // Strip the update of non relevant updtes 
        delete configUpdate.lastOnline;
        delete configUpdate.peerId;
        delete configUpdate.status;
        delete configUpdate.cid;
        delete configUpdate.onlineUsers;
        delete configUpdate.ownerUid;

        const newConfig: Computer = currentConfig;
        
        newConfig.availableScreens = configUpdate.availableScreens;
        newConfig.baseSettings = configUpdate.baseSettings
        
        
        this.server$.next(newConfig);
        await this.updateRegistration();
        await this.setupScreens(true);
        await this.broadcast({type: ComputerDataEventTypes.screensUpdated, data: newConfig})

        // console.log('processRemoteConfigUpdate');
        // console.log(configUpdate);
        // console.log(this.server$.value);
        // console.log(_.isEqual(configUpdate.availableScreens, this.server$.value.availableScreens))
        // if(!configUpdate.name || !configUpdate.cid || !configUpdate.availableScreens) {
        //   // Partial Update, now name or other fields 
        //   if(!_.isEqual(configUpdate.availableScreens, this.server$.value.availableScreens)) {
        //     // Screen Config Change 
        //     console.log('Screen Settings Change')
        //   }
        //   if(!_.isEqual(configUpdate.baseSettings, this.server$.value.baseSettings)) {
        //     // Basic Settings Change
        //     console.log('Basic Settings Change')
        //   }

        return;
        
      }

      async shareConfig() {
        this.broadcast({type: ComputerDataEventTypes.configShared, data: this.server$.value})
      }

      async addUserScreen(uid: string) {
        this.logSvc.info('ServerUserOvject - addUserScreen');
        // if the user is the owner, don't do an overlay 
        if(uid == this.authSvc.user.value.uid) {
          return;
        }
        const profile = await this.updateProfile(uid);
       
        const config = {
          uid: uid,
          profile: profile
        };
        const result = await window.electron.createUserWindow(config);
        
        return; 
      }

      async removeUserScreen(uid: string) {
        this.logSvc.info('ServerUserOvject - addUserScreen');
        if(uid = this.authSvc.user.value.uid) {
          return;
        }
        const result = await window.electron.removeUserWindow(uid);
        
        return;
      }

      async addOverlay() {
        this.logSvc.info('ServerUserOvject - addOverlay');
        const profile = this.authSvc.user.value.profile;
        const fakeevent = {
          sharedMessage: 'Test',
          uid: this.authSvc.user.value.uid,
          profile: profile
        }

        await window.electron.createExpiringOverlayWindow(fakeevent);
      }
      
      async processKeyboardEvent(evtdata) {
        const { uid, roomId} = evtdata;
        const data  = evtdata['data']['evtData'];
        data.uid = uid;
        console.log(data)

        await window.electron.sendKeyboardEvent(data);

      }

      async processMouseEvent(evtdata) {
        const { uid, roomId} = evtdata;
        const data  = evtdata['data']['evtData'];
        data.uid = uid;
        console.log(data)
        const cloneabledata = JSON.parse(JSON.stringify(data));
        const { type, x, y, eheight, ewidth, screenId } = data;

        //const room = await this.roomSvc.getRoom(roomId);

         
        
        if(type == 'mousemove') {
          //if(this.userTracking[uid]) {
            const newy = (1 + (eheight/screen.height)) * y;
            const newx = ( 1 + (1- ( ewidth/screen.width))) * x;
            // Calculate more relative position
            const clonabletype = JSON.parse(JSON.stringify(type));
            if(uid == this.authSvc.user.value.uid) {
              await window.electron.sendMouseEvent(cloneabledata);
              return;
            }
            if(uid == this.authSvc.user.value.uid) {
              await window.electron.sendMouseEvent(data);
              return;
            } else {
              await window.electron.postWindowMessage(cloneabledata);
            }
            

          //}
          
        }

        if(type == 'click') {
          if(uid == this.authSvc.user.value.uid) {
            await window.electron.sendMouseEvent(data);
            return;
          }
          
        }
        if(type == 'linkshared') {
          this.logSvc.info('HUD Window Shared');
          
            const profile = await this.updateProfile(uid);
            const config = {
              expiry: 12000,
              uid: uid,
              profile: profile,
              sharedMessage: data.msg,
              x: data.x,
              y: data.y,

            }
            await window.electron.createExpiringOverlayWindow(config);
        }


        // Broadcast event to the browsers
        this.browsers.forEach( (browser) => {
          browser.postMessage(cloneabledata);
        })
        
      }

      async broadcast(evt) {
        this.connections.forEach(connect => {
          if(connect.open) {
            connect.send(evt);
          }
          
        })
        this.fire(evt.type, evt);
      }

      async handleConnectionError(e) {
        this.logSvc.error('ServerObject - handleConnection Error ' + e)
        console.log('handleError');
        console.log(e);
        //this.resetConnection();

      }

      async handleConnectionClose(data) {
        this.logSvc.error('ServerObject - handleConnection handleConnectionClose ')
        console.log(data);

      }

    handlePeerCall(call: MediaConnection) {
        return new Promise ( async (resolve, reject) => {
            try {
              this.logSvc.info('ComputerServerSvc - handlePeerCall ');
              call.answer();
              call.peerConnection.onconnectionstatechange = (evt) => {
                console.log('call state change')
                console.log(evt)
              }
              
              setTimeout(async () => {
                const senders = call.peerConnection.getSenders().filter(s => s.track?.kind == 'video');
              for await (const onesender of senders) {
                const oldparams = onesender.getParameters();
                //oldparams.encodings[0].scaleResolutionDownBy = 2;
                oldparams.encodings[0].priority = 'high';
                oldparams.degradationPreference = 'maintain-resolution';
                onesender.setParameters(oldparams);
                
              }
                const receivers = call.peerConnection.getReceivers()[0];
                console.log(receivers.getParameters());
              }, 3000);
              this.calls.push(call);
              call.on('stream', this.handleCallStream.bind(this));
              resolve(null);
            } catch(e) {
              this.logSvc.error('Error: ServerObject - handlePeerCall');
              reject(e);
            }
          })
    }

    handleCallStream(stream: MediaStream) {
        this.logSvc.info('handleConnectionClose - handleCallStream ');
        // Other Users Initiate Stream to the Server - do nothing 
        return;
      }

    handleNewPeerId(id: string) {
        this.logSvc.info('ServerObject - PeerId ' + id);
        this.peerId = id;
        this.setState(ComputerServerState.ready);
    }
  
  async createConfigStream(passedConfig?: Computer): Promise<MediaStream> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('createConfigStream')
        let config = await window.electron.getSources();

        this.sourceConfig = config;
        if(passedConfig) {
          config = passedConfig.availableScreens;
        }
        const stream = new MediaStream();
        for(let i = 0; i < config.length; i++) {
          const index = i;
          const screen = config[index];
          let scaleFactor = 1;
            // try {
            //     if(screen.scaleFactor != 2) {
            //         scaleFactor = screen.scaleFactor;
            //     }
            // } catch(e) {
            //     console.error(e);
            // }
          
          // TODO: Needs to take the scalefactor into account
          // TODO: Redo availableScreen config load 
          if(true) {
            const constraints = {
              video: {
                mandatory: {
                chromeMediaSourceId: screen.id,
                chromeMediaSource: 'desktop',
                minFrameRate: 1,
                maxFrameRate: 45,
                codec: 'H264',
                colorDepth: 10,
                width: screen.size.width,
                height: screen.size.height,
                },
                optional: [
                  {minWidth: screen.size.width * scaleFactor},
                  {minHeight: screen.size.height * scaleFactor},
                ]
              },
            
            };
            
            // @ts-ignore 
            const tmpStream = await navigator.mediaDevices.getUserMedia(constraints);
            const videoTrack = tmpStream.getVideoTracks()
            console.log(videoTrack[0]);
            videoTrack[0].contentHint = 'text';
            const trackid = videoTrack[0].id;
            stream.addTrack(videoTrack[0]);
            screen.trackId = trackid;
            console.log(screen)
          }
        }
       
        
        resolve(stream);
      } catch(e) {
        alert("Monitor Config Not working. Contact Support.")
        this.logSvc.error('ServerObject - createConfigStream ' + e )
        reject(e);
      }
      
    })
  }

    /*
  * setupScreens - Will pass back the mainStream combined of all streams;
  */
  async setupScreens(forceUpdate?: boolean): Promise<MediaStream> {
    return new Promise(async (resolve, reject) => {
      try {
        this.logSvc.info('serverObject - setupScreens');
        console.log('setupScreens')
        
        if(this.mainStream && !forceUpdate) {
          resolve(this.mainStream);
        }
        console.log('setupScreens')
        if(this.mainStream && forceUpdate) {
          const tracks = this.mainStream.getTracks();
          for(let x = 0; x < tracks.length; x++) {
            tracks[x].stop();
          }
          this.mainStream = null;
        }
        
        const stream = await this.createConfigStream();
        // get video track from stream 
        const videoTrack = stream.getVideoTracks()[0];
        this.checkForAV1(videoTrack);
        this.mainStream = stream;
        resolve(this.mainStream);
      } catch(e) {
        this.logSvc.error('ServerObject - setupScreens ' + JSON.stringify(e))
        reject(e);
      }
      
    })
  }

  async addScreenAsChild(data: any, roomId?: string) {
    const urlSender = url.format({
      pathname: await window.electron.getMainPath(),
      protocol: "file:",
      slashes: true,
    }) + '?viewer=' + data.initUrl;

    const childPreload = await window.electron.getChildPreload();
    const settings = "top=500,left=200,title='ARO.Work',frameName='ARO.Work',backgrounThrottling=false,nodeIntegration=false,contextIsolation=false,preload='" + childPreload + "',webviewTag=true";
    const room = this.roomSvc.getRoom(roomId);
    const newWindow = window.open(urlSender, '_blank', settings);
    newWindow.child = true;
    newWindow.childCfg = data;
    newWindow.room = room;
    
    this.browsers.push(newWindow); 
    newWindow.addEventListener('dom-ready', () => {

      newWindow.document.title = 'ARO.Work';
    })      // @ts-ignore
    if(this.calls.length) {
      setTimeout(this.updateExistingStreams.bind(this), 1000);
      
    }
  }

  async addUserScreenTracking(uid: string, user?: UserObject, roomId?: string) {
    if(!this.userTracking[uid]) {
      this.userTracking[uid] = {};
      const urlSender = url.format({
        pathname: await window.electron.getMainPath(),
        protocol: "file:",
        slashes: true,
      }) + '?user=' + uid;
      const childPreload = await window.electron.getChildPreload();
      const settings = "visibleOnAllWorkspaces=true,skipTaskbar=true,alwaysOnTop='normal',visibleOnFullScreen=true,type='panel',focusable=false,height=150,width=150,transparent=true,resizable=false,frame=false,title='ARO.Work',frameName='ARO.Work',backgrounThrottling=false,nodeIntegration=false,contextIsolation=false,preload='" + childPreload + "',webviewTag=true";
      const room = this.roomSvc.getRoom(roomId);

      const roster = await room.getRoster();
      const profile = await this.updateProfile(uid);
      const newWindow = window.open(urlSender, '_blank', settings);
      
      newWindow.child = true;
      newWindow.user = user;
      newWindow.uid = uid;
      newWindow.room = room;
      newWindow.profile = profile;
      newWindow.server = this;
      
      // newWindow.setAlwaysOnTop(true, "screen-saver")     // - 2 -
      // newWindow.setVisibleOnAllWorkspaces(true) 
      this.userTracking[uid] = newWindow;
      setTimeout(this.updateExistingStreams.bind(this), 3000)
    }
  }

  async updateProfile(uid: string): Promise<PublicProfile | null> {
    return new Promise( (resolve, reject) => {
        this.logSvc.info('ServerObject - updateProfile ' + uid );
        this.afs.doc(`publicProfiles/${uid}`).get().subscribe( async (response) => {
            if(response.data()) {
                let tmpProfile = this.mapProfile(response.data());
                console.log('profile update');
                await window.electron.postWindowMessage({type: 'profileupdate', profile: response.data(), uid: uid});
                console.log('profile update 2');
                
                resolve(tmpProfile);
            } else {
                
                resolve(null);
            }
        })
    })
}

mapProfile(doc: any): PublicProfile {
  return doc as PublicProfile;
}

  async addScreen(data: any) {
    //const tmpScreen = await this.electronSvc.addBrowser(data);
    if(this.calls.length) {
      setTimeout( () => {
        this.updateExistingStreams();
      }, 1000)
      
    }
  }

  async updateExistingStreams() {
    const tmpscreens = await window.electron.getSources();
    console.log(tmpscreens);
    const streams = [];
    const newstream = new MediaStream();
    for await (const screen of tmpscreens) {
      const constraints = {
        video: {
          mandatory: {
          chromeMediaSourceId: screen.id,
          chromeMediaSource: 'desktop',
          minFrameRate: 1,
          maxFrameRate: 35,
          codec: 'AV1',
          colorDepth: 12,
          },
            optional: [
              //{maxWidth: 1280},
            ]
        }
      };
     
      
      if(!this.streamIds.includes(screen.id)) {
        this.streamIds.push(screen.id);
        
        // @ts-ignore
        const tmpStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.mainStream.addTrack(tmpStream.getVideoTracks()[0])
       
      }
      
    }
  }

    async validateRegister() {
        this.serverRef.get().subscribe( async (server) => {
            if(server.exists) {
                let tmpComputer = this.mapComputer(server);
                await this.checkUpdateScreens(tmpComputer);
                this.server$.next(tmpComputer);
                this.setState(ComputerServerState.loading);
                return;
            } else {
                this.setState(ComputerServerState.unregistered); 
            }
        })
    }

    mapComputer(doc: any): Computer {
        return doc.data() as Computer;
    }

    async checkUpdateScreens(computer: Computer, regenStream?: boolean) {
      this.logSvc.info('ServerObject - checkUpdteScreens');
      const configMon = computer.availableScreens;
      if(!computer) {
        console.log('checkUpdateScreens - no computer')
        return;
      }
      let newScreenConfig = configMon;
      if(configMon) {
        console.log('checkUpdateScreens - computer')
        let monitors = await this.dvcSvc.getServerAvailableMonitors();
        const unmatchedMonitors = monitors.filter(mon => !configMon.find(cm => cm.name === mon.name && cm.type === mon.type));
        const unUsedMonitors = configMon.filter(monCfg => !monitors.find(cm => cm.name === monCfg.name && cm.type === monCfg.type));
        unmatchedMonitors.forEach(obj => obj.included = true);
        if(unUsedMonitors.length) {
          // Monitors Removed 
          let tmpConfig = configMon.filter(mon => !unUsedMonitors.find(um => um.name === mon.name && um.type === mon.type));
          newScreenConfig = tmpConfig;
        } 
        // If Monitors Added 
        newScreenConfig = newScreenConfig.concat(unmatchedMonitors);
        
        
      } else {
        console.log('checkUpdateScreens - no availableScreens')
        // no availableScreen config 
        let monitors = await this.dvcSvc.getServerAvailableMonitors();
        newScreenConfig = monitors;
      }
      computer.availableScreens = newScreenConfig;
      if(regenStream) {
        const newStream = await this.createConfigStream(computer);
      }
      return;
    }

    async getSpaceConfig() {
      try {
        let sources = this.sourceConfig;
        console.log(typeof sources);
        console.log(sources);
        const numScreen = sources.length;
        console.log(numScreen);
        let config = []; 
        for (let x = 0; x < sources.length; x++) {
          config.push({});
          config[x].id = sources[x].id;
          config[x].bounds = sources[x].bounds;
          config[x].displayFrequency = sources[x].displayFrequency;
          config[x].label = sources[x].label;
          config[x].imgthumbnail = sources[x].imgthumbnail;
          config[x].rotation = sources[x].rotation;
          config[x].scaleFactor = sources[x].scaleFactor;
          config[x].workArea = sources[x].workArea;
          config[x].workAreaSize = sources[x].workAreaSize;
          config[x].size = sources[x].size;
          config[x].trackId = sources[x].trackId;
          
        }
        

        console.log(config);
        return config;
      } catch(e) {
        console.log('getSpaceConfig error' + e)
        const config = defaultSpaceConfig[defaultSpaceConfig.length - 1 ].screens;
        config.id = this.streamIds[0];
        return config;
      }
      
    }

    async getRooms() {
      return [];
    }

    async getConfig(originalConfig?) {
      if(!this.server$.value) {
        return null;
      }
      if(!originalConfig) {
        originalConfig = this.server$.value;
      }
      let monitors = await this.dvcSvc.getServerAvailableMonitors();
      const rooms = await this.getRooms();
      if(!this.server$.value.availableScreens) {
        monitors.forEach((monitor) => {
          monitor.included = true;
        })
      } else {
        await this.checkUpdateScreens(this.server$.value);
        monitors = this.server$.value.availableScreens;
      }
      const config: Computer = {
        ...originalConfig,
        rooms: rooms,
        availableScreens: monitors
      }
      const updateBasicConfig = this.checkUpdateBasicSettings(config);
      return updateBasicConfig;
    }

    checkUpdateBasicSettings(computer: Computer) {
      let currentBasicSettings = this.server$.value.baseSettings;
      if(!currentBasicSettings) {
        currentBasicSettings = {};
      }
      let allSettings = {
        allowUserHUDOverlayBoxes: {
            readableLabel: 'Allow Users to send computer overlays.', 
            type: 'boolean',
            default: true,
            setTo: true
        },
        allowUsersToTrackMouseOverlay: {
            readableLabel: 'Allow Users mouse tracking overlays.', 
            type: 'boolean',
            default: true,
            setTo: true
        },
        startAtStartup: {
          readableLabel: 'Start Application at Startup.', 
          type: 'boolean',
          default: true,
          setTo: true
        },
        preventPowersave: {
          readableLabel: 'Prevent PowerSaving.', 
          type: 'boolean',
          default: true,
          setTo: true
        },
        serverAutoOnline: {
          readableLabel: 'Server Automatically goes online.',
          type: 'boolean',
          default: true,
          setTo: true
        }
      };

      Object.keys(allSettings).forEach(setting => {
        if (!currentBasicSettings.hasOwnProperty(setting)) {
          currentBasicSettings[setting] = allSettings[setting];
          currentBasicSettings[setting].setTo = currentBasicSettings[setting].default
        }
      });
      computer.baseSettings = currentBasicSettings

      return computer;
      
    }


}