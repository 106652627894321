import { ScreenConfig, ScreenType } from "../models/Screens";

export enum RoomNetworkType {
  mesh = 'Mesh'
}

export enum RoomState {
  init = 'Initializing',
  loading = 'Loading', // loading roster and data
  rosterLoad = 'RosterLoading',
  p2pSetup = 'P2P Setup', // waiting for p2p connections Setup
  ready = 'Ready', // ready to start
}

export enum RoomEvents {
  roomStateChange = 'roomStateChange',
  roomRosterChange = 'roomRosterChange',
  roomJoined = 'roomJoined',
  roomCreated = 'roomCreated',
  UserJoin = 'UserJoin',
  UserStateChange = 'UserStateChange',
  roomProfileChanged = 'roomProfileChanged',
  roomComputerShareUpdated = 'roomComputerShareUpdated',
  roomUpdatedAvailableScreens = 'roomUpdatedAvailableScreens',
  newStream = 'newStream',
  trackUpdated = 'trackUpdated',
  screenFocused = 'screenFocused',
  removeFocused = 'removeFocused'
}

export const HelpRoomConfig: ScreenConfig = {
  screenType: ScreenType.video,
  name: 'Getting Started',
  src: 'assets/video/video.mp4',
  videoStatus: 'Playing',
  mute: true
}
