import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ComputerObjectEvents, ComputerObjectState } from '../../objects/computerEnum';
import { ComputerObject } from '../../objects/computerObject';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { ComputerService } from '../../services/computer-service/computer.service';
import { VrService } from '../../services/vr-service/vr.service';

@Component({
  selector: 'room-main-view-incall',
  templateUrl: './room-main-view-incall.component.html',
  styleUrls: ['./room-main-view-incall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomMainViewIncallComponent implements OnInit {
  @Input()
  room: RoomObject;
  @Input()
  computers: ComputerObject[];
  @Input()
  uiState: 'no-present' | 'presenting' = 'no-present';
  focusComputer: ComputerObject | null;
  focusScreenId: string | null;
  computerSubsSetup = false;
  myComputerSubsSetup = false;
  constructor(
    public cdr: ChangeDetectorRef,
    public comptSvc: ComputerService,
    public vrSvc: VrService,
  ) { }

  ngOnInit(): void {
    console.log('mainviewincall init')
    this.comptSvc.focusComputer.subscribe(this.focus.bind(this));
    this.vrSvc.vrEngaged.subscribe(this.updateView.bind(this));
    this.room.onlineRoster$.subscribe(this.updateView.bind(this));
    // Setup subscription to computers state change 
    this.room.roomComputers$.subscribe(this.setupComputerStateSubscriptions.bind(this));
    this.comptSvc.computers$.subscribe(this.setupMyComputerStateSubscriptions.bind(this));
    
  }

  setupComputerStateSubscriptions() {
    if(this.computerSubsSetup) {
      return;
    }
    this.room.roomComputers$.value.forEach(async c => {
      this.computerSubsSetup = true;
      if(c.computerState == ComputerObjectState.dataOnline) {
        await c.requestScreenStream();
      }
      if(c.computerState == ComputerObjectState.ready) {
        this.comptSvc.setFocusComputer(c, c.screens[0].id);
      }
      c.on(ComputerObjectEvents.computerStateChanged, this.computerStateCheckToPresent.bind(this))
    });
    
  }

  setupMyComputerStateSubscriptions() {
    if(this.myComputerSubsSetup) {
      return;
    }
    
    this.comptSvc.computers$.value.forEach(async c => {
      this.computerSubsSetup = true;
      if(c.computerState == ComputerObjectState.dataOnline) {
        await c.requestScreenStream();
      }
      if(c.computerState == ComputerObjectState.ready) {
        this.comptSvc.setFocusComputer(c, c.screens[0].id);
      }
      c.on(ComputerObjectState.ready, this.computerStateCheckToPresent.bind(this));
      c.on(ComputerObjectEvents.computerStateChanged, this.computerStateCheckToPresent.bind(this))
    });
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.room && this.room.roomComputers$) {
      this.room.roomComputers$.subscribe(this.updateView.bind(this))
      this.room.roster$.subscribe(this.updateView.bind(this))
    }
  }

  updateView() {
    this.computers = this.room.roomComputers$.value;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  computerStateCheckToPresent() {
    console.log(this.uiState)
    console.log('computer state check to present')
   
    
    // Check what computer is in ready state 
    // if there is one, present it
    let readyComputer = this.computers.find(c => c.computerState == ComputerObjectState.dataOnline);
    
    if(readyComputer) {
      function getScreensReturn() {
        console.log('get screens return')
        console.log(readyComputer.computerState)
        console.log(readyComputer.screens)
        if(readyComputer.computerState == ComputerObjectState.ready && readyComputer.screens.length) {
          this.comptSvc.setFocusComputer(readyComputer, readyComputer.screens[0].id);
        }
      }
      readyComputer.on(ComputerObjectEvents.computerStateChanged, getScreensReturn.bind(this));
      readyComputer.requestScreenStream();
      
      //return;
    }
    // check if my computers came online 
    let myComputer = this.comptSvc.computers$.value.find(c => c.computerState == ComputerObjectState.dataOnline);
    // check if my computer is in roomcomputers 
    
    console.log(myComputer)
    if(myComputer ) {
      function getScreensReturn() {
        console.log('get screens return')
        console.log(myComputer.computerState)
        console.log(myComputer.screens)
        if(myComputer.computerState == ComputerObjectState.ready && myComputer.screens.length) {
          this.comptSvc.setFocusComputer(myComputer, myComputer.screens[0].id);
         }
      }
      // If my computer already is a room computer, don't connect 
      let myComputerInRoom = this.room.roomComputers$.value.find(c => c.cid == myComputer.cid);
      if(myComputerInRoom) {
        return;
      }
      myComputer.requestScreenStream();
      
      if(myComputer.computerState == ComputerObjectState.ready && myComputer.screens.length) {
        this.comptSvc.setFocusComputer(myComputer, myComputer.screens[0].id);
      }
      myComputer.on(ComputerObjectEvents.computerStateChanged, getScreensReturn.bind(this));
    }
  }

  focus(val: {computer:ComputerObject, screenId: string} | null) {
    if(val) {
      this.uiState = 'presenting';
      this.focusComputer = val.computer;
      this.focusScreenId = val.screenId;
    } else {
      this.focusComputer = null;
      this.focusScreenId = null;
      this.uiState = 'no-present';
    }
    this.updateView()
  }

}
