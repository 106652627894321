import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RoomRoster } from '../../models/Room';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { AgoraService } from '../../services/agora-service/agora-service';
import { AuthService } from '../../services/auth-service/auth.service';
import { LogService } from '../../services/log-service/log.service';

@Component({
  selector: 'room-incall-users',
  templateUrl: './room-incall-users.component.html',
  styleUrls: ['./room-incall-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomIncallUsersComponent implements OnInit {
  @Input() 
  room: RoomObject;
  @Input()
  onlineUsers: RoomRoster[];
  displayState: 'justme' | 'collaborating';
  constructor(
    public authSvc: AuthService,
    public agoraSvc: AgoraService,
    public logSvc: LogService,
    public cdr: ChangeDetectorRef
    
  ) { }

  ngOnInit(): void {
    this.room.roster$.subscribe(this.updateView.bind(this))
    this.room.on(RoomEvents.UserJoin, this.updateView.bind(this));
    this.room.on(RoomEvents.UserStateChange, this.updateView.bind(this));
  }


  updateView() {

    this.onlineUsers = this.room.roster$.value.filter((item) => {return item.userObject.state === 'Ready'});
    const numUsers = this.onlineUsers.length;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
