
<div id="mainApp" class="mainContainer" *ngIf="!viewerurl && !window.child && !window.profile">
  <div id="toplevelalerts" class="topalertpanel"></div>
  <div id="mainpanel" class="mainContainer" *ngIf="currentUser$ && currentUser$.value && !viewerurl && authSvc.localUser && authSvc.localUser.profile && authSvc.localUser.profile.name">
    <computer-check></computer-check>
    <mat-drawer-container *ngIf="!window.electron" class="main-container" hasBackdrop="false">
      
      <mat-drawer #drawer class="main-sidenav" mode="side" [opened]="menuSvc.mainMenuOpen$.value" id="leftmenu">
        <left-menu></left-menu>
        <button type="button" mat-raised-button (click)="drawer.toggle()">
          Toggle Menu
        </button>
        <user-menu></user-menu>
        
        
      </mat-drawer>
      <app-panel-toggle [drawer]="drawer"></app-panel-toggle>
      <div class="main-sidenav-content">
          <router-outlet></router-outlet> 
      </div>
     
    </mat-drawer-container>
    <basic-server-screen *ngIf="window.electron && showServer"></basic-server-screen>
  </div>
  <div id="nouserpanel" style="width:100%;height:100%;" *ngIf="currentUser$ && !currentUser$.value && !window.uid && !window.child && !hold">
    <loader> </loader>
  </div>
 
</div>
<browser *ngIf="viewerurl && currentUser$.value" [url]="viewerurl"></browser>
<overlay-user-head [profile]="window.profile" *ngIf="window.child && !window.sharedMessage"></overlay-user-head>
<base-overlay *ngIf="window.child && window.sharedMessage"></base-overlay>

