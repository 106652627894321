

<div class="full-size" *ngIf="onlineUsers.length == 1">
    <div class="half-size" *ngFor="let rosterItem of onlineUsers; let i = index;">
        <user-video-resizable-card [room]="room" [roster]="rosterItem" [user]="rosterItem.userObject"></user-video-resizable-card>
       
    </div>
   <div class="half-size">
        <invite-resizeable-card [room]="room"></invite-resizeable-card>
   </div>
    
</div>


<div class="full-size" *ngIf="onlineUsers.length == 2">
    <div class="half-size" *ngFor="let rosterItem of onlineUsers; let i = index;">
        <user-video-resizable-card [room]="room" [roster]="rosterItem" [user]="rosterItem.userObject"></user-video-resizable-card>
        
    </div>
    
</div>


<div class="full-size" *ngIf="onlineUsers.length == 3">
    <div class="half-size" *ngFor="let rosterItem of onlineUsers; let i = index;">
        <user-video-resizable-card [room]="room" [roster]="rosterItem" [user]="rosterItem.userObject"></user-video-resizable-card>
    </div>
</div>

<div class="full-size" *ngIf="onlineUsers.length == 4">
    
    <div class="half-size" *ngFor="let rosterItem of onlineUsers; let i = index;">
        <user-video-resizable-card [room]="room" [roster]="rosterItem" [user]="rosterItem.userObject"></user-video-resizable-card>
        
        
    </div>
    
    
</div>

<div class="full-size" *ngIf="onlineUsers.length >= 5">
    <div class="half-size" *ngFor="let rosterItem of onlineUsers; let i = index;">
        <user-video-resizable-card [room]="room" [roster]="rosterItem" [user]="rosterItem.userObject"></user-video-resizable-card>
        
        
    </div>
</div>