import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { LogService } from '../log-service/log.service';
import { AngularFirestore, AngularFirestoreDocument, QuerySnapshot } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { MenuItems } from '../../models/Menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddRoomDialogComponent } from 'src/app/components/popups/add-room/add-room/add-room.component';
import { Menu } from 'electron';
import { NotificationService } from '../notification-service/notification-service.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menuItems$: BehaviorSubject<MenuItems[]> = new BehaviorSubject([]);
  mainMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  secondaryMenuOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private authSvc: AuthService,
    private logSvc: LogService,
    public dialog: MatDialog,
    public notSvc: NotificationService,
    public afs: AngularFirestore) { 
      this.logSvc.info('MenuService Init');
    }

    getMenuItems():Promise<MenuItems[]> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('MenuService - getMenuItems');
          if(this.authSvc.user.value) {
            let tmpRef = this.afs.collection('menuitems', ref => ref.where('uid', '==', this.authSvc.user.value.uid));
            tmpRef.get().subscribe((results) => {
              let tmpResults = this.mapDocsToMenuItems(results.docs);
              this.menuItems$.next(tmpResults);
              if( !tmpResults.length) {
                this.noRoomCheck()
              }
              
              resolve(tmpResults);
            })
          
          } else {
            console.log('No User')
            reject('No User');
          }
         
        } catch(e) {
          reject(e);
        }
      });
    }

    async noRoomCheck() {
      if(!this.menuItems$.value.length) {
        this.openAddRoomDialog('200ms', '200ms');
      }
    }

    openAddRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
      this.dialog.open(AddRoomDialogComponent, {
        width: '250px',
        enterAnimationDuration,
        exitAnimationDuration,
      });
    }

    checkMenuItem(item: MenuItems):Promise<any> {
      return new Promise( async (resolve, reject) => {

        try {
          this.logSvc.info('MenuService - checkMenuItem');
          // Search this.menuItems$.value for item
          let tmpItem = this.menuItems$.value.find((tmpItem) => {
            return tmpItem.mid === item.mid;
          })
          resolve(item);
        } catch(e) {
          this.logSvc.error(`checkmenuItem - ${e}`);
        }
      });
    }

    checkRoomMenuItem(rid: string):Promise<MenuItems> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('MenuService - checkRoomMenuItem');
          // Search this.menuItems$.value for item
          let tmpItem: MenuItems = this.menuItems$.value.find((tmpItem) => {
            return tmpItem.mid === rid;
          })
          resolve(tmpItem);
        } catch(e) {
          this.logSvc.error(`checkRoomMenuItem - ${e}`);
        }
      });
    }



    async addMenuItem(item: MenuItems):Promise<any> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('MenuService - getMenuItems');
          // Close the dialog
          this.dialog.closeAll();
          let tmpResult = await this.afs.collection('menuitems').add(item);
          item.id = tmpResult.id;
          let tmpMenuItems = this.menuItems$.value;
          tmpMenuItems.push(item);
          this.menuItems$.next(tmpMenuItems);
          this.notSvc.sendLocal(`Joined ${item.title} space`)
          resolve(item);
        } catch(e) {
          reject(e);
        }
      
      });
    }

    // remove menu item 
    async removeMenuItem(item: MenuItems):Promise<any> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('MenuService - removeMenuItem');
          await this.afs.collection('menuitems').doc(item.id).delete();
          let tmpMenuItems = this.menuItems$.value;
          let tmpIndex = tmpMenuItems.findIndex( (tmpItem) => {
            return tmpItem.id === item.id;
          })
          if(tmpIndex > -1) {
            tmpMenuItems.splice(tmpIndex, 1);
            this.menuItems$.next(tmpMenuItems);
          }
          // if no ohter users in the room, delete the room 
          
          resolve(item);
        } catch(e) {
          reject(e);
        }
      });
    }


    mapDocsToMenuItems(docs: Array<any>): MenuItems[] {
      const menuItems: MenuItems[] = [];
        docs.forEach(doc => {
          const menuItem = doc.data() as MenuItems;
          menuItem.id = doc.id;
          menuItems.push(menuItem);
        });
        return menuItems;
    }

    toggleMainMenu(toggle?: boolean) {
      const toggleValue = toggle ? toggle : !this.mainMenuOpen$.value;
      this.mainMenuOpen$.next(toggleValue);
      return this.mainMenuOpen$.value;
    }

    toggleSubMenu() {
      this.secondaryMenuOpen$.next(!this.secondaryMenuOpen$.value);
      return this.secondaryMenuOpen$.value;
    }



}
