import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { RoomAddComputerDialogComponent } from '../popups/room-add-computer-dialog/room-add-computer-dialog.component';
import { ShareRoomModalComponent } from '../popups/share.room.modal/share.room.modal.component';

@Component({
  selector: 'room-right-menu',
  templateUrl: './room-right-menu.component.html',
  styleUrls: ['./room-right-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomRightMenuComponent implements OnInit {
  @Input()
  room: RoomObject;

  constructor(
    public cdr: ChangeDetectorRef,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    console.log('room right menu init')
    this.setupSubscriptions();
  }

  setupSubscriptions(){
    this.room.on(RoomEvents.roomProfileChanged, this.updateProfile.bind(this));
    this.room.on(RoomEvents.roomRosterChange, this.updateProfile.bind(this));
    this.room.on(RoomEvents.roomComputerShareUpdated, this.updateProfile.bind(this));
    this.room.on(RoomEvents.roomStateChange, this.updateProfile.bind(this));
    this.room.roomComputers$.subscribe(this.updateProfile.bind(this));
  }

  openAddComputerShareDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(RoomAddComputerDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  clickShareAdd() {
    this.openAddComputerShareDialog('200ms', '200ms');
  }

  openShareRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ShareRoomModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  shareRoom() {
    this.openShareRoomDialog('200ms', '200ms');
  }

  updateProfile() {
    setTimeout( ()=> {
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    }, 500);
    
  }

}
