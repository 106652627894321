import { Injectable } from '@angular/core';
import { EventEmitter } from '@billjs/event-emitter';
import { ComputerCommands, ComputerObjectEvents, ComputerServerEvents, ComputerServerState } from '../../objects/computerEnum';
import { AuthService } from '../auth-service/auth.service';
import { LogService } from '../log-service/log.service';
import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../models/Users';
import { Computer } from '../../models/Computers';
import * as moment from 'moment';
import { ServerObject } from './ServerObject';
import { StunServiceService } from '../stun-service/stun-service.service';
import { ElectronService } from '../electron-service/electron.service';
import { RoomService } from '../room.service/room.service';
import { DeviceService } from '../device-service/device.service';
import { ComputerService } from '../computer-service/computer.service';
import { NotificationService } from '../notification-service/notification-service.service';


@Injectable({
  providedIn: 'root'
})
export class ComputerServerService extends EventEmitter {
  server$: BehaviorSubject<ServerObject> = new BehaviorSubject(null);
  user$: BehaviorSubject<any>;
  
  constructor(
    private authSvc: AuthService,
    private logSvc: LogService,
    public electronSvc: ElectronService,
    public afs: AngularFirestore,
    public stunSvc: StunServiceService,
    public roomSvc: RoomService,
    public devSvc: DeviceService,
    public computerSvc: ComputerService,
    public notifySvc: NotificationService
    //public cdr: ChangeDetectorRef
  ) { 
    super();
    this.logSvc.info('ComputerServerService Init');
    this.setupSubscriptions();
  }

  async setupSubscriptions() {
    this.user$ = this.authSvc.user;
    this.user$.subscribe( async (user) => {
      this.logSvc.info('ComputerServerService - User ' + user);
      if(user && !this.server$.value) {
        await this.setupServer();
      } else {
        
      }
    });
  }

  async setupServer() {
    return new Promise(async (resolve, reject) => {
      try {
        if(!this.server$.value && window.electron) {
          this.server$.next(new ServerObject(this.authSvc, this.logSvc, this.afs, this.electronSvc, this.stunSvc, this.roomSvc, this.devSvc, this.notifySvc));
        }
        resolve(this.server$.value);
      } catch(e) {
        reject(e);
      }
    })
  }

  start() {
    this.setupSubscriptions();
  }

  async registerServer(hostname: string) {
    return new Promise( async (resolve, reject) => {
      try {
        let tmpRegistration = await window.electron.getRegistration();
        let tmpServer: Computer = {
          name: hostname,
          cid: tmpRegistration,
          ownerUid: this.user$.value.uid,
          created: moment.now()
        }
        await this.afs.doc(`computer/${tmpRegistration}`).set(tmpServer);
        console.log('registerServer')
        console.log(this.server$.value);
        if(this.server$.value.state == ComputerServerState.unregistered && window.electron) {
          this.server$.next(new ServerObject(this.authSvc, this.logSvc, this.afs, this.electronSvc, this.stunSvc, this.roomSvc, this.devSvc, this.notifySvc));
          console.log(this.server$.value);
          setTimeout(() => {
            this.computerSvc.getRegisteredComputers(true);
            resolve(tmpServer);
          }, 1000);
        } else {
          resolve(tmpServer);
        }
        
      } catch(e) {
        this.logSvc.error(e);
        reject(e);
      }
    })
  }

}
