import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputerObject } from 'src/app/objects/computerObject';
import { RoomObject } from 'src/app/objects/roomObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { MatDialog } from '@angular/material/dialog';

import { RoomAddComputerDialogComponent } from '../popups/room-add-computer-dialog/room-add-computer-dialog.component';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { NotificationService } from 'src/app/services/notification-service/notification-service.service';

@Component({
  selector: 'computer-list-bar',
  templateUrl: './computer-list-bar.component.html',
  styleUrls: ['./computer-list-bar.component.scss'],
})
export class ComputerListBarComponent implements OnInit {
  @Input()
  computers: ComputerObject[];
  @Input()
  focusComputer: ComputerObject;
  @Input()
  focusScreenId: string;
  @Input()
  uistate: 'no-present' | 'presenting' = 'no-present';
  @Input()
  room: RoomObject;
  @Input()
  myComputers: ComputerObject[] = [];
  open: boolean = true;
  arrow = 'keyboard_arrow_up';
  @Input() 
  tracking = false;
  constructor(
    public computerSvc: ComputerService,
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public notSvc: NotificationService,
  ) { }

  ngOnInit(): void {
    console.log('computer list bar init')

    this.computerSvc.focusComputer.subscribe(this.setFocus.bind(this));
    this.computerSvc.computers$.subscribe(this.updateMyComputers.bind(this));
    this.myComputers = this.computerSvc.computers$.value.filter(c => c.computer.ownerUid == this.authSvc.user.value.uid);
  }

  updateMyComputers() {
    this.myComputers = this.computerSvc.computers$.value.filter(c => c.computer.ownerUid == this.authSvc.user.value.uid);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  collapse() {
    this.open = !this.open;
    this.arrow = this.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setFocus(val: {computer: ComputerObject, screenId: string}) {
    if(val == null && this.focusComputer != null) {
      this.notSvc.sendLocal('Unfocused', this.room, this.authSvc.localUser.uid);
      this.focusComputer = null;
      this.focusScreenId = null;
      return;
    }
    this.focusComputer = val.computer;
    this.focusScreenId = val.screenId;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  removeFocus() {
    this.computerSvc.unFocusComputer();
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  addComputer() {
    this.dialog.open(RoomAddComputerDialogComponent, {
      width: '320px',
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '500ms',
      data: {room: this.room}
    });
  }

  
}
