import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ComputerObject } from 'src/app/objects/computerObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';

@Component({
  selector: 'computer-header-card',
  templateUrl: './computer-header-card.component.html',
  styleUrls: ['./computer-header-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerHeaderCardComponent implements OnInit {
  @Input()
  computer: ComputerObject;
  @Input()
  screenId: string;
  constructor(
    public computerSvc: ComputerService
  ) { }

  ngOnInit(): void {

  }

  chooseScreen(index) {
    this.computerSvc.unFocusComputer();
    this.computerSvc.setFocusComputer(this.computer, this.computer.screens[index].id);
  }

}
