import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ComputerService } from '../../services/computer-service/computer.service';
import { ComputerObject } from 'src/app/objects/computerObject';
import { ComputerObjectEvents, ComputerObjectState } from 'src/app/objects/computerEnum';
import { Router } from '@angular/router';
import { RoomService } from 'src/app/services/room.service/room.service';
import { MenuService } from 'src/app/services/menu-service/menu.service';

@Component({
    selector: 'computer-list-available',
    templateUrl: `./computer-list-available.component.html`,
    styleUrls: ['./computer-list-available.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComputerListAvailableComponent implements OnInit { 
    onlineComputers: ComputerObject[] = [];
    defaultRoom: string | null;
    constructor(
        public compSvc: ComputerService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public roomSvc: RoomService,
        public menuSvc: MenuService
    ) { }

    async ngOnInit(): Promise<void> {
        this.compSvc.computers$.subscribe( (computers) => {
            this.onlineComputers = computers.filter( (computer) => {
                computer.on(ComputerObjectEvents.computerStateChanged, this.checkComputers.bind(this));
                return computer.computerState === ComputerObjectState.dataOnline || computer.computerState === ComputerObjectState.ready;
            });
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });
        this.menuSvc.menuItems$.subscribe( async (menu) => {
            this.defaultRoom = await this.roomSvc.getMyDefaultRoomId();
        });
        
        
    }



    checkComputers(): void {
        this.compSvc.computers$.subscribe( (computers) => {
            this.onlineComputers = computers.filter( (computer) => {
                return computer.computerState === ComputerObjectState.dataOnline || computer.computerState === ComputerObjectState.ready;
            });
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });

    }

    //click computer that will redirect the user to a default room and put a url param of the computerid to open up 
    //the computer in the room
    clickComputer(computer: ComputerObject): void {
        console.log('clickComputer', computer);
        if(this.defaultRoom) {
            this.router.navigate(['/space/' + this.defaultRoom ], { queryParams: { computerid: computer.cid } });
        }
    }
        
}
