import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth-service/auth.service';
import { LogService } from '../../../services/log-service/log.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../models/Users';
import { FriendItems } from '../../../models/Friend';
import { FriendsService } from '../../../services/FriendsService/friends.service';
import { UserObject } from '../../../objects/userObject';
import { InviteService } from '../../../services/invite-service/invite.service';
import { InviteItems, InviteType } from '../../../models/Invites';

@Component({
  templateUrl: './add-friend-modal.component.html',
  styleUrls: ['./add-friend-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFriendModalComponent implements OnInit {

  @Input()
  user: UserObject;
  friend?: FriendItems;
  invite?: InviteItems
  constructor(public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public logSvc: LogService,
    @Inject(MAT_DIALOG_DATA) public data: {user: UserObject},
    private friendsSvc: FriendsService,
    private inviteSvc: InviteService,
    public dialogRef: MatDialogRef<AddFriendModalComponent>) {
        this.user = this.data.user
     }

  ngOnInit(): void {
    this.invite = {
        uid: this.user.uid,
        otherseruid: this.authSvc.user.value.uid,
        type: InviteType.friend
    }
  }

  async addFriend() {
    try {
      await this.inviteSvc.addInviteItem(this.invite);
      this.dialogRef.close();
    } catch (e) {
      this.logSvc.error('Error adding friend' + e.message);
      alert('Error adding friend: ' + e.message);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
