<h1 mat-dialog-title> Add Friend </h1>
<mat-dialog-content>
    <div class="authBlock">
        <div class="formGroup">
          <user-list-item [userObject]="user"></user-list-item>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="formGroup">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Add" (click)="addFriend()">
        <input type="button" class="mat-raised-button mat-warn" mat-button value="Cancel" (click)="dialogRef.close()">
      </div>
</mat-dialog-actions>
