import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoomObject } from '../../../objects/roomObject';
import { AuthService } from '../../../services/auth-service/auth.service';
import { ComputerService } from '../../../services/computer-service/computer.service';
import { LogService } from '../../../services/log-service/log.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'room-add-computer-dialog',
  templateUrl: './room-add-computer-dialog.component.html',
  styleUrls: ['./room-add-computer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomAddComputerDialogComponent implements OnInit {
  selected: any;
  @Input()
  room: RoomObject;
  constructor(
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public computerSvc: ComputerService,
    public logSvc: LogService,
    @Inject(MAT_DIALOG_DATA) public data: {room: RoomObject},
    private routerSvc: Router, 
    public dialogRef: MatDialogRef<RoomAddComputerDialogComponent>
  ) {
    this.room = this.data.room;
   }

  ngOnInit(): void {
    this.selected = this.computerSvc.computers$.value[0].cid;
  }

  async shareComputer() {
    
    let result = await this.room.addComputerShare(this.selected, this.authSvc.user.value.uid);
    console.log(result);
    this.dialogRef.close();
  }

}
