import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UserObject } from '../../objects/userObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { AuthService } from 'src/app/services/auth-service/auth.service';



@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarItemComponent implements OnInit {
  @Input()
  // @ts-ignore
  name: string;
  @Input()
  // @ts-ignore
  img: string;
  @Input()
  size?: number = 40;
  @Input()
  round?: boolean = true
  @Input()
  src: string;
  @Input()
  user: UserObject;
  @Input()
  audioLevel = 0;
  @ViewChild('audioborder') audioborder: ElementRef;
  constructor(
    public authSvc: AuthService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    
    if(this.user) {
      this.user.on(UserObjectEvent.audioLevelUpdate, this.getAudioLevel.bind(this));
      this.user.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this));
      if(this.user.profile) {
        this.src = this.user.profile.avatar;
        this.name = this.user.profile.name;
      }
    }

    setTimeout( () => {
      const color = this.heatMapColorforValue(this.audioLevel/100);
      this.audioborder.nativeElement.setAttribute('style','border-color:' + color);
    }, 100)
  }

  getAudioLevel(level) {
    this.audioLevel = level.data;
    const color = this.heatMapColorforValue(this.audioLevel/100);
    this.audioborder.nativeElement.setAttribute('style','border-color:' + color);

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.user && !this.audioLevel) {
      
      this.user.on(UserObjectEvent.audioLevelUpdate, this.getAudioLevel.bind(this));
    }
  
  }

  heatMapColorforValue(value){
    var h = (1.0 - value) * 240;
    return "hsl(" + h + ", 100%, 50%)";
  }

  updateView() {
    if(this.user.profile.avatar) {
      this.src = this.user.profile.avatar;
      this.name = this.user.profile.name;
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  

}
