<div class="resizeable-card">
    <div (click)="wideScreenVideo()" [id]="'user-video-' + user.uid + '-resizeable'" class="video-area" [matTooltip]="user.profile ? user.profile.name : 'loading'">
        <div >
            <user-avatar *ngIf="!videoTrack" [user]="user"></user-avatar>  
            <br>
             <div *ngIf="!videoTrack && user.profile" class="username-title">
                {{user.profile.name}}
             </div>  
        </div>
    </div>
</div>
