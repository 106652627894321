import { Injectable } from '@angular/core';
import { EventEmitter } from '@billjs/event-emitter';
import { BehaviorSubject } from 'rxjs';
import { Computer } from 'src/app/models/Computers';
import { ElectronService } from '../electron-service/electron.service';
import { LogService } from '../log-service/log.service';
import { DeviceEvents } from './LocalDeviceEnum';
import { AgoraService } from '../agora-service/agora-service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends EventEmitter {
  displayStream = false;
  mainStream: MediaStream;
  mainstream$: BehaviorSubject<MediaStream> = new BehaviorSubject(null);
  availableDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
  mainAudioDevice: MediaDeviceInfo;
  mainVideoDevice: MediaDeviceInfo;
  defaultMediaConstraints: any = {
    audio: {
      optional: [
        {"googEchoCancellation": true}, 
        {"googAutoGainControl": true},
        {"googNoiseReduction": true},
        {"echoCancellation": true},
        {"noiseSuppression": true},
        {"sampleRate": true}, 
        {"sampleRate": 44100},
        {"suppressLocalAudioPlayback": true}
      ]
    },
    video: false
  };

  defaultScreenCaptureConstraints: any = {
    audio: true,
    video: {
      manditory: {
        minFrameRate: 60
      }
    }
  };
  
  constructor(
    public logSvc: LogService,
    public electron: ElectronService
  ) {
    super();
    this.initiate()
   }
  
  async initiate() {
    // Forces Permission Check and Access to Microphone for selection
    if( !this.electron.isDesktop()) {
      await this.createFirstStream();
      
    } else {
      //await this.createFirstStream();
      await this.getServerAvailableMonitors();
    }
    

  }

  async createFirstStream() {
    try {
      this.mainStream = await navigator.mediaDevices.getUserMedia(this.defaultMediaConstraints);
    
      this.mainStream.addTrack(this.blackVideo());
      this.mainstream$.next(this.mainStream);
      return this.mainStream;
    } catch(e) {
      this.logSvc.error(e);
      return null;
    }
    
  }

  async getSourcesBackend() {
    try {
      const screens = await window.electron.getSources();
      return screens;
    } catch(e) {
      this.logSvc.error(e);
      return null;
    }
  }

  async createConfigStream(passedConfig?: Computer): Promise<MediaStream> {
    return new Promise(async (resolve, reject) => {
      try {
        let config = await window.electron.getSources();
        if(passedConfig) {
          config = passedConfig.availableScreens;
        }
        const stream = new MediaStream();
        for(var i = 0; i < config.length; i ++) {
          const screen = config[i];
          // TODO: Redo availableScreen config load 
          if(true) {
            const constraints = {
              video: {
                mandatory: {
                chromeMediaSourceId: screen.id,
                chromeMediaSource: 'desktop',
                minFrameRate: 3,
                maxFrameRate: 26,
    
                },
                optional: [
                  //{maxWidth: 1280},
                ]
              }
            };
            
            // @ts-ignore 
            const tmpStream = await navigator.mediaDevices.getUserMedia(constraints);
            tmpStream.getVideoTracks().forEach((track) => {
              track.contentHint = 'text';
              stream.addTrack(track);
            });
          }
        }
       
        
        resolve(stream);
      } catch(e) {
        this.logSvc.error('deviceService - createConfigStream ' + e )
        reject(e);
      }
      
    })
  }



  async createFullStream() {
    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia(this.defaultScreenCaptureConstraints);
    
      this.mainStream = captureStream;
    } catch(e) {
      this.logSvc.error(e);
      return null;
    }
    
  }

  blackVideo(width = 640, height = 480) {
    const canvas = Object.assign(document.createElement('canvas'), {width, height});
    //canvas.getContext('2d').fillStyle = "rgba(255, 255, 255, 0.1)";
    // @ts-ignore
    canvas.getContext('2d').fillRect(0, 0, width, height);
    // @ts-ignore
    const stream = canvas.captureStream(1);
    return Object.assign(stream.getVideoTracks()[0], {enabled: true});
  }

  async getAvailableMediaDevices(){ 
    try {
      const allMediaStream = await navigator.mediaDevices.getUserMedia(this.defaultMediaConstraints);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.logSvc.error('enumerateDevices() not supported.');
        return [];
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.availableDevices$.next(devices);
      return this.availableDevices$.value;
    } catch(err) {
      throw (err)
    }
  }

  async getBasicStream() {
    const allMediaStream = await navigator.mediaDevices.getUserMedia(this.defaultMediaConstraints);
    this.mainStream = allMediaStream;
  }


  async addScreen() {
   try {
    
    
    const currentTracks = this.mainStream.getVideoTracks();
    if( this.displayStream ) {
      const blackstreamtrack = this.blackVideo();
      this.mainStream.removeTrack(currentTracks[0]);
      this.mainStream.addTrack(blackstreamtrack);
      this.fire(DeviceEvents.screenShare, true);
      this.fire(DeviceEvents.newStream, blackstreamtrack);
      this.fire(DeviceEvents.videoTrackAdded, blackstreamtrack);
      this.mainstream$.next(this.mainStream);
      this.displayStream = !this.displayStream;
      return this.mainStream;
    }
    
    let captureStream = await navigator.mediaDevices.getDisplayMedia(this.defaultScreenCaptureConstraints);
    let videoTracks = captureStream.getTracks();
    
    this.fire(DeviceEvents.newStream, captureStream);
    this.mainStream.removeTrack(currentTracks[0]);
    this.mainStream.addTrack(videoTracks[0]);
    this.fire(DeviceEvents.videoTrackAdded, videoTracks[0]);
    this.fire(DeviceEvents.screenShare, true);
    this.mainstream$.next(this.mainStream);
    this.displayStream = !this.displayStream;
    return this.mainStream;
   } catch(e) {
    this.logSvc.error('DeviceService - addScreen ' + e);
    const currentTracks = this.mainStream.getVideoTracks();
    this.logSvc.error('DeviceService - addScreen numtracks' + currentTracks.length); 
    this.logSvc.error('DeviceService - addScreen 0 track type' + typeof(currentTracks[0]));
    this.logSvc.error('DeviceService - addScreen ' + this.mainStream);
    return this.mainStream;
   }
    
  }

  // This is suppose to show the VR/AR view, may need some changes to adapt to a different viewport 
  async addMyView() {
   
    // @ts-ignore
    const captureStream = AFRAME.scenes[0].canvas.captureStream(25)
    this.fire(DeviceEvents.screenShare, true);
    this.fire(DeviceEvents.newStream, captureStream);
    const videoTracks = captureStream.getTracks();
    await this.mainStream.addTrack(videoTracks[0]);
    this.fire(DeviceEvents.videoTrackAdded, videoTracks[0]);
    return captureStream;
  }

  async getServerAvailableMonitors(): Promise<Array<{name: string,id: string, type: 'screen' | 'camera' | 'arowindow', thumbnail: string, included?: boolean}>> {
    const screens = await window.electron.getSources();
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = [];
    const availableDevices: {name: string,id: string, type: 'screen' | 'camera' | 'arowindow', thumbnail: string}[]  = [];
    
    
    for(var i = 0; i < screens.length; i ++) {
      const screen = screens[i];

      availableDevices.push({name: 'Screen ' + screen.display_id,id: screen.id, type: 'screen', thumbnail: screen.imgthumbnail});
    }

    return availableDevices;
  }

  async testScreenAccess() {
    try {
      const captureStream = await this.createConfigStream();
      const videoTracks = captureStream.getTracks();
      if(videoTracks.length === 0) {
        return false;
      }
      for(var i = 0; i < videoTracks.length; i ++) {
        videoTracks[i].stop();
      }
      return true;
    } catch(e) {
      this.logSvc.error(e);
      return false;
    }
  }

  async testMicAccess() {
    try {
      const captureStream = await navigator.mediaDevices.getUserMedia({ audio: true });
     
      const audioTracks = captureStream.getTracks();
      audioTracks[0].stop();
      return true;
    } catch(e) {
      this.logSvc.error(e);
      return false;
    }
  }

  // getAudioSources - Function to get all available audio sources
  async getAudioSources() {
    try {
      const audioSources = await navigator.mediaDevices.enumerateDevices();
      const audioInputSources = audioSources.filter((source) => {
        return source.kind === 'audioinput';
      });
      return audioInputSources;
    } catch(e) {
      this.logSvc.error(e);
      return [];
    }
  }

  // getVideoSources - Function to get all available video sources
  async getVideoSources() {
    try {
      const videoSources = await navigator.mediaDevices.enumerateDevices();
      const videoInputSources = videoSources.filter((source) => {
        return source.kind === 'videoinput';
      });
      return videoInputSources;
    } catch(e) {
      this.logSvc.error(e);
      return [];
    }
  }

  // changeAudioSource - Function to change the audio source
  async changeAudioSource(source) {
    try {
      console.log('changeAudioSource', source)
      this.mainAudioDevice = source;
      return this.mainAudioDevice;
    } catch(e) {
      this.logSvc.error(e);
      return null;
    }
  }

  // changeVideoSource - Function to change the video source
  async changeVideoSource(source) {
    try {
      console.log('changeVideoSource', source)
      this.mainVideoDevice = source;
      return this.mainVideoDevice;
    } catch(e) {
      this.logSvc.error(e);
      return null;
    }
  }

}
