export type InviteItems = {
    uid: string,
    otherseruid?: string,
    iid?: string,
    roomid?: string,
    title?: string, 
    subtitle?: string,
    creation?: number,
    type: InviteType
}

export enum InviteType {
    'room' = 'room',
    'friend' = 'friend'
}