<h2 mat-dialog-title>Computer Registration</h2>
<mat-dialog-content>
    <!-- <div class="formGroup " *ngIf="computer$ && !computer$.value">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Download Server" >
    </div> -->
    <div class="formControl container" >
      <label>Comp ID:</label>
        <div class="formGroup">
            <input
              type="text"
              class=""
              placeholder="Registration ID"
              [value]="registration"
              #computerId
              readonly
              required
            />
          </div>
          <label>Comp Name:</label>
          <div class="formGroup">
            <input
              type="name"
              class=""
              placeholder="Computer Name:"
              [value]="hostnamefield"
              #hostname
              required
            />
          </div>
    </div>
    <div style="display: flex;
    justify-content: center;">
      <input type="button" class="mat-raised-button mat-accent" mat-button value="Save" (click)="clickSave()">
      <!-- <input type="button" class="mat-raised-button mat-warn" mat-button value="Close" (click)="clickNext()"> -->
    </div>
    
    <mat-dialog-actions >

        
    </mat-dialog-actions>
   
                
</mat-dialog-content>
