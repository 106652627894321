import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit, ViewChild } from '@angular/core';
import { RoomObject } from '../../objects/roomObject';
import { VectorUserListComponent } from '../vector-user-list/vector-user-list.component';

@Component({
  selector: 'vector-room-list',
  templateUrl: './vector-user-list.component.html',
  styleUrls: ['./vector-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  standalone: true,
  imports: [
    VectorUserListComponent
	],
})
export class VectorRoomListComponent implements OnInit {
  @ViewChild('vectorRoomName') element;
  @Input()
  room: RoomObject;
  newElement: HTMLElement;
  constructor() { }

  ngOnInit(): void {
    // setTimeout( () => {
    //   this.element = document.createElement('a-entity');
    //   this.element.setAttribute('class', 'available');
    //   this.element.setAttribute('overlay', 'true');
    //   this.element.setAttribute('promoClick', '');
    //   this.element.setAttribute('screensMixin', 'true');
    //   this.element.setAttribute('position', '1.5 0.1 -0.1');
      
    //   this.element.setAttribute('width', '40');
    //   this.element.setAttribute('height', '30');
    //   this.element.setAttribute('scale', '3 3 3');
    //   this.element.setAttribute('text', "font: mozillavr; value: " + this.room.roomProfile.value.name);
    //   this.element.setAttribute('color', '#2F0E06');
    //   document.querySelector('#hud').appendChild(this.element);
    // }, 5000)
    
  }

}
