<h1 mat-dialog-title> Add Computer </h1>
<mat-dialog-content>
    <div class="authBlock">
        <div class="formGroup" *ngIf="computerSvc.computers$.value.length">
          <mat-select [(value)]="selected">
            <mat-option selected *ngFor="let computer of computerSvc.computers$.value" [value]="computer.cid">{{computer.name}}</mat-option>
          </mat-select>
        </div>
        <div class="formGroup" *ngIf="!computerSvc.computers$.value.length">
          <a (href)="updateSvc.getUpdateLink()" target="_blank"><input type="button" class="mat-raised-button mat-accent" mat-button value="Download Desktop Client"></a>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="computerSvc.computers$.value.length">
    <div class="formGroup" *ngIf="computerSvc.computers$.value.length">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Add" (click)="shareComputer()">
        <input type="button" class="mat-raised-button mat-warn" mat-button value="Cancel" (click)="dialogRef.close()">
      </div>
</mat-dialog-actions>
