export type MenuItems = {
    id?: string, // ID of the menu id record 
    uid: string,
    mid?: string, // id of the other artificat in the menu item 
    orderNum?: number,
    title: string, 
    subtitle?: string
    type: MenuTypes
}

export enum MenuTypes {
    room = 'roomMenuItem',
    user = 'userMenuItem',
    computer = 'computerMenuItem'
}
