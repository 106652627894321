import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ComputerObjectEvents } from 'src/app/objects/computerEnum';
import { ComputerObject } from 'src/app/objects/computerObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ServerObject } from 'src/app/services/computer-server-service/ServerObject';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';
import { StunServiceService } from 'src/app/services/stun-service/stun-service.service';
import { UpdateServiceService } from 'src/app/services/update-service/update-service.service';

@Component({
    selector: 'basic-server-screen',
    templateUrl: './basic-server-screen.component.html',
    styleUrls: ['./basic-server-screen.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicServerScreenComponent implements OnInit{ 
    serverId: string;
    serverComputer: ComputerObject | null | ServerObject = null;
    server: ServerObject | null = null;
    computerName: string = '';
    computerOwnerUid: string = '';
    constructor(
        public serverSvc: ComputerServerService,
        public afs: AngularFirestore,
        public authSvc: AuthService,
        public computerSvc: ComputerService,
        public serverService: ComputerServerService,
        public electronSvc: ElectronService,
        public cdr: ChangeDetectorRef,
        public updateSvc: UpdateServiceService,
        public ngZone: NgZone,
        public stunSv: StunServiceService
    ) { }

    async ngOnInit(): Promise<void> {
        console.log('basic-server-screen')
        this.serverId = await window.electron.getRegistration();
        this.serverSvc.server$.subscribe( async (server) => {
            console.log('server', server)
            if(server) {
                this.server = server;
                this.computerName = server.server$?.value?.name;
                this.computerOwnerUid = server.server$?.value?.ownerUid;
                // afs 
                this.serverComputer = await this.computerSvc.getExternalComputer(server.registrationId, server.server$?.value?.ownerUid);
                
                
                //this.serverComputer = server.server$?.value;
                this.cdr.markForCheck();
                this.cdr.detectChanges();
                this.serverComputer.on(ComputerObjectEvents.computerStateChanged, () => {
                    this.cdr.markForCheck();
                    this.cdr.detectChanges();
                });
            }
        });
    }

    async setupSubscriptions() {

    }

    openWebBrowser(): void {
        const linkAdd = this.updateSvc.getMainSiteLink();
        window.electron.openWebBrowser(linkAdd);
       
    }

    logout(): void {
        this.authSvc.signOut();
    }
}
