import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'shared-card',
  templateUrl: './shared-card.component.html',
  styleUrls: ['./shared-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedCardComponent implements OnInit {
  @Input()
  sharedtxt: string;
  @Input()
  copied = false;
  @ViewChild('sendhelp') sendhelp: ElementRef;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  copyLink() {
    this.copied = true;
    navigator.clipboard.writeText(this.sharedtxt);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    setTimeout(this.reset.bind(this), 2000);
    
  }

  async reset() {
    this.sharedtxt = null;
    this.copied = false;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
