<h1 mat-dialog-title> Share Space </h1>
<mat-dialog-content>
    <div class="authBlock">
        <div class="formGroup">
          <input type="text" #roomLink class="sharelinkfield" [value]="shareLink" readonly>
        </div>
    </div>
    <div class="formGroup">
       <input type="button" class="mat-raised-button mat-accent" mat-button value="Copy Link" (click)="shareRoom()">
    </div>
</mat-dialog-content>
