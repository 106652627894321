
<div style="width:100%;" id="overlayContainer" #overlayContainer >
  <mat-card class="user-whoacted-summary" *ngIf="show" [@openClose]="expanded ? 'open' : 'closed'">
      <mat-card-header style="margin-top: 3px;">
        <mat-card-title-group style="display:flex;">
          <div class="left-user-share-head-avatar">
              <user-avatar *ngIf="overlayData.profile && overlayData.profile.name" [name]="overlayData.profile.name" [img]="overlayData.profile.avatar"></user-avatar>
          </div>
          <div class="right-user-share-head">
              <mat-card-title style="display:block;">{{ overlayData.profile.name | slice:0:45 }}</mat-card-title>
              <mat-card-subtitle class="subTitle" style="display:block;" [innerHTML]="overlayData.sharedMessage | slice:0:30 | linky "></mat-card-subtitle>
          </div>   
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      
      <div class="overlay-action-icons-user-header">
          <button class="action-btn" mat-mini-fab aria-label="Close HUD Window">
              <mat-icon class="action-btn-icon" (click)="closeOut()">close</mat-icon>
          </button>
          <button class="action-btn" mat-mini-fab aria-label="Pin HUD Window and Stop expiry." >
            <mat-icon class="action-btn-icon" (click)="pinHUD()" [class.mat-accent]="!this.pinned" [class.mat-warn]="this.pinned" style="margin-top: 2px;">push_pin</mat-icon>
          </button>          
            <mat-progress-spinner class="expiry-progress-spinner mat-warn"
        [value]="progress"
        [strokeWidth]="2"
        mode="determinate"
        [diameter]="21"
      ></mat-progress-spinner>
      
      </div>
  </mat-card>

  <mat-card class="user-share-summary" *ngFor="let sum of msgSummaries">
    <div *ngIf="sum.type === 'Youtube'">
      <iframe *ngIf="sum.type =='Youtube'" width="320" [src]="sum.link | safe" allowfullscreen></iframe>  
    </div>
   
    <mat-card-header style="margin-top: 5px;" *ngIf="sum.type != 'Youtube'">
        <mat-card-title-group style="display:flex;">
          <div class="left-user-share-head-avatar">
              <img class="sumImage" [src]="sum.avatar">
              
              <!-- <user-avatar [name]="sum.header" [img]="sum.avatar"></user-avatar> -->
          </div>
          <div class="right-user-share-head">
              <mat-card-title style="display:block;"><a [href]="sum.link" target="_blank">{{ sum.header | slice:0:35 }}</a></mat-card-title>
              <mat-card-subtitle class="subTitle" style="display:block;">{{ sum.subHeader | slice:0:70 }}</mat-card-subtitle>
          </div>
        </mat-card-title-group>
      </mat-card-header>
    
      <div class="overlay-action-icons">
          
          <button class="action-btn" mat-mini-fab aria-label="Copy Text" (click)="clickCopy()" [class.mat-accent]="copied">
              <mat-icon class="action-btn-icon" *ngIf="!copied">copyright</mat-icon><mat-icon class="action-btn-icon" *ngIf="copied" class="mat-accent">check_circle</mat-icon>
          </button>

          <button class="action-btn" mat-mini-fab aria-label="Link out">
              <a [href]="sum.link" target="_blank" ><mat-icon class="mat-accent action-btn-icon" style="margin-top: 7px;">link</mat-icon></a>
          </button>
      
      </div>
  </mat-card>
</div>
  <!-- <div class="user-share-summary" *ngFor="let sum of msgSummaries">
       
    <mat-card class="container">
        <mat-card-header style="margin-top: 5px; margin-left: 5px">
          <mat-card-title-group style="display:flex;">
            <div class="left-user-share-head-avatar">
                <user-avatar [name]="sum.header" [img]="sum.avatar"></user-avatar>
            </div>
            <div class="right-user-share-head">
                <mat-card-title style="display:block;">{{ sum.header }}</mat-card-title>
                <mat-card-subtitle style="display:block;">{{ sum.subHeader }}</mat-card-subtitle>
            </div>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>Test</mat-card-content>
    </mat-card>
  </div> -->



