import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ScreenConfig } from '../../models/Screens';
import { RoomObject } from '../../objects/roomObject';
import { UserObject } from '../../objects/userObject';

@Component({
  selector: 'user-video-track',
  templateUrl: './user-video-track.component.html',
  styleUrls: ['./user-video-track.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVideoTrackComponent implements OnInit {
  @Input()
  videoTrack: MediaStreamTrack;
  @Input()
  user: UserObject;
  @Input()
  room?: RoomObject;
  userId?: string;
  vidIndex?: number;
  newStream?: MediaStream;
  element: HTMLElement;
  spaceConfig: ScreenConfig
  constructor() { }

  ngOnInit(): void {
    this.newStream = new MediaStream();
    this.newStream.addTrack(this.videoTrack);
    //this.spaceConfig = this.room.registerScreen(this.newStream);
    this.spaceConfig = this.room.registerHUDScreen(this.newStream);
    // setTimeout(this.drawVectorSpace.bind(this), 1000);
    // if(document.querySelector('a-scene')) {
    //   document.querySelector('a-scene').addEventListener('enter-vr', this.drawVectorSpace.bind(this));
    // }
    
  }

  clickUserVideoFocus() {
    this.room.addFocus({stream:this.newStream,streamIndex:0,user: this.user});
  }

  // drawVectorSpace() {
  //   if(!document.querySelector('#hud')) {
  //     return;
  //   }
  //   if(this.newStream) {
  //     const screenElem = document.createElement('a-plane');
  //     screenElem.setAttribute('class', 'available');
  //     screenElem.setAttribute('overlay', 'true');
  //     screenElem.setAttribute('promoClick', '');
  //     screenElem.setAttribute('position', this.spaceConfig.vectorProps.position);
  //     screenElem.setAttribute('rotation', this.spaceConfig.vectorProps.rotation);
  //     screenElem.setAttribute('width', this.spaceConfig.vectorProps.width);
  //     screenElem.setAttribute('height', this.spaceConfig.vectorProps.height);
  //     // screenElem.setAttribute('mixin', 'promoScreens');
  //     // screenElem.setAttribute('class', 'cube');
  //     screenElem.setAttribute('muted', 'true');
      
  //     screenElem.setAttribute('src', '#' + this.newStream.id);
      
      
  //     screenElem.setAttribute('theta-length', '90');
  //     screenElem.setAttribute('theta-start', '130');
  //     //screenElem.setAttribute('look-at', '#camera');
      
  //     document.querySelector('#hud').appendChild(screenElem);
  //     this.element = screenElem;
  //   }
    
  // }

}
