<div style="display:flex; margin-top:5px; width:100%;" *ngIf="server || computer">
    <div style="margin-left:30px; margin-right:15px">
        <img [src]="computer.computer.thumbnail" [ngClass]="{'deactive': computer.computerState != 'Ready'}">
    </div>
    <div style="display:flex; width:100%;" [ngClass]="{'deactive': computer.computerState != 'Ready'}">
        <div style="width:100%;">
            <div>
                <h2 style="margin-bottom:0px;">{{computer.computer.name}}</h2>
            </div>
            <div >
                Last Online: {{computer.computer.lastOnline | timeSince}}
            </div>
            <div>
                ID: {{computer.computer.cid}}
            </div>
            <div>
                <mat-slide-toggle
                    [checked]="computer.computerState == 'Ready'"
                    [disabled]="computer.computerState != 'Ready'"
                    >
                    {{ computer.computerState == 'Ready' ? 'Online' : 'Offline' }}
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div style="display:flex; justify-content: right; margin-right: 20px;">
        <div>
            <h3 style="margin-bottom:0px;">Owner:</h3>
            <user-list-item *ngIf="authSvc.localUser" [userId]="computer.computer.ownerUid"></user-list-item>
        </div>
        
    </div>
</div>
