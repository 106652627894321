import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { EventEmitter } from "@billjs/event-emitter";
import Peer, { DataConnection, MediaConnection } from "peerjs";
import { RoomRoster } from "../models/Room";
import { AuthService } from "../services/auth-service/auth.service";
import { LogService } from "../services/log-service/log.service";
import { UserRoomServerEvent, UserRoomServerState } from "./UserRoomServerEnum";
import * as moment from 'moment';
import { DeviceService } from "../services/device-service/device.service";
import { DeviceEvents } from "../services/device-service/LocalDeviceEnum";
import { StunServiceService } from "../services/stun-service/stun-service.service";
import { UserObjectDataEvent, UserObjectDataEventTypes } from "./userObjectEnum";
import { RoomObject } from "./roomObject";
import { RoomService } from "../services/room.service/room.service";
import { RoomState } from "./roomEnums";

export class UserRoomServer extends EventEmitter {
    roomRoster: RoomRoster;
    recordRef: AngularFirestoreDocument;
    uid: string;
    rid: string;
    peerId: string;
    peer: Peer;
    calls: MediaConnection[] = [];
    connections: DataConnection[] = [];
    mainStream: MediaStream;
    state: UserRoomServerState;
    thumbnail: string;
    creation = moment.now();
    constructor(
        uid: string, 
        rid: string,
        public room: RoomObject,
        private authSvc: AuthService,
        private logSvc: LogService,
        public dvcScv: DeviceService,
        public stunSvc: StunServiceService,
        public afs: AngularFirestore, 
    ) {
        super();
        this.logSvc.info('UserRoomServer Init');
        this.uid = uid;
        this.rid = rid; 
        this.setupSubscriptions();
    }

    setupSubscriptions() {
        this.recordRef = this.afs.doc(`room/${this.rid}/roster/${this.uid}`);
        this.mainStream = this.dvcScv.mainStream;
        this.dvcScv.on(DeviceEvents.videoTrackAdded, this.updateSenderTrackers.bind(this));
        this.dvcScv.on(DeviceEvents.muteAudio, this.sendMuted.bind(this));
        this.dvcScv.on(DeviceEvents.screenShare, this.sendScreenShareUpdate.bind(this));
      
        this.setState(UserRoomServerState.init);
    }

    async sendMuted() {
        let newEvent: UserObjectDataEvent = {
            type: UserObjectDataEventTypes.audioMuted,
            uid: this.authSvc.localUser.uid,
            roomId: this.room.roomId,
            data: this.authSvc.localUser.muted
        }
        // TODO: Updated Muted State holder and fire it
        await this.sendDataEvent(newEvent);
        return;
    }

    async sendScreenShareUpdate() {
        let newEvent: UserObjectDataEvent = {
            type: UserObjectDataEventTypes.screenShared,
            uid: this.authSvc.localUser.uid,
            roomId: this.room.roomId,
            data: this.authSvc.localUser.muted
        }
        // TODO: Updated Muted State holder and fire it
        await this.sendDataEvent(newEvent);
        return;
    }

    async updateSenderTrackers(track) {
        let replace = false;
        this.authSvc.localUser.videoEnabled = !this.authSvc.localUser.videoEnabled;
        
        this.calls.forEach((call) => {

            if(call.peerConnection) {
                let pc = call.peerConnection;
                call.peerConnection.onnegotiationneeded = async () => {
                    console.log('renegotiating');
                    const offer = await pc.createOffer();
                    if (pc.signalingState != "stable") return;
                    await pc.setLocalDescription(offer);
                    call.peerConnection.restartIce()//.socket.send({description: pc.localDescription});
                    
                  }
                  
                call.peerConnection.getSenders().forEach( (sender) => {
                    
                    if (sender.track.kind === "video") {
                        sender.replaceTrack(track.data);
                        replace = true;
                      }
                })
                
            }
            
        })
        if(!replace) {
            
        }
        this.sendEvent(UserObjectDataEventTypes.screenShared, 'newScreenShared', this.rid);
    }

    async setState(state: UserRoomServerState) {
        return new Promise( async (resolve, reject) => {
            this.logSvc.info('UserRoomServer State Change - ' + state);
            this.fire(UserRoomServerEvent.userServerStateChanged, state);
            this.state = state;
            switch(state) {
                case UserRoomServerState.init:
                    await this.validateRecords();
                    this.setState(UserRoomServerState.loading);
                    resolve(this);
                    break;
                case UserRoomServerState.loading:
                    await this.setupServer();
                    resolve(this);
                    break;
                case UserRoomServerState.ready:
                    this.startRecordUpdates();
                    resolve(this);
                    break;
                case UserRoomServerState.offline:
                    this.disconnect();
                    resolve(this);
                    break;
            }
        })
    }

    async validateRecords() {
        return new Promise( (resolve, reject) => {
            try {
                this.recordRef.get().subscribe( (record) => {
                    if(record.data()) {
                        this.roomRoster = record.data() as RoomRoster;
                        resolve(this);
                    } else {
                        resolve(this);
                    }
                })
            } catch(e) {
                reject('Error - ' + e);
            }
        }) 
    }

    async startRecordUpdates() {
        return new Promise( async (resolve, reject) => {
            try {
                this.logSvc.info('UserRoomServer recordUpdate')
                let tmpRoomRoster = JSON.parse(JSON.stringify(this.roomRoster)) as RoomRoster;
                tmpRoomRoster.lastUpdate = moment.now();
                tmpRoomRoster.peer = this.peerId;
                
                if(this.thumbnail) {
                    tmpRoomRoster.thumbnail = this.thumbnail;
                }
                await this.recordRef.set(tmpRoomRoster);
                let nextUpdate = 5000;
                const updateTime = moment.now() - this.creation;
                if(updateTime > 60000) {
                    nextUpdate = 30000;
                }
                if(updateTime > 360000) {
                    nextUpdate = 180000;
                }
                setTimeout(this.startRecordUpdates.bind(this), nextUpdate);
                resolve(null);
            } catch(e) {
                reject(e);
            }
        })
    }

    async setupServer() {
        return new Promise( async (resolve, reject) => {
            this.logSvc.info('UserRoomServerObject - startConnection');
            try {
                if(!this.roomRoster.peer) {
                    this.setState(UserRoomServerState.offline);
                    resolve(this);
                }
                const urls = await this.stunSvc.getURLS();
                this.peer = new Peer({
                    config: {
                        'iceServers': [urls.v.iceServers],
                      pingInterval: 500
                    },
                });

                this.peer.on('open', this.handlePeerSetup.bind(this));
                this.peer.on('call', this.handlePeerCall.bind(this));
                this.peer.on('connection', this.handleConnectionOpen.bind(this));
                this.peer.on('error', this.handlePeerError.bind(this));
                this.peer.on('disconnected', this.handlePeerDisconnected.bind(this));
                this.peer.on('close', this.handlePeerClose.bind(this));

            } catch(e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @description handler returned when PeerJs retreives 
     * it's peerid and sets up the connection.
     * @param {string} id
     * @memberof UserRoomServer
     */
    async handlePeerSetup(id: string) {
        this.logSvc.info('UserRoomServerObject - handlePeerSetup ' + id);
        this.peerId = id;
        await this.setState(UserRoomServerState.ready);
        return this.peerId;
    }

    /**
     *
     * Triggered when a users makes a MediaCall connection with streams.
     * @param {MediaConnection} call
     * @memberof UserRoomServer
     */
    async handlePeerCall(call: MediaConnection) {
        try {
            this.logSvc.info('UserRoomServerObject handlePeerCall');
            call.answer(null);
            
            this.calls.push(call);
            call.on('stream', this.handleCallStream.bind(this));
            call.on('close', this.handleCallStreamClose.bind(this));
            call.on('error', this.handleCallStreamError.bind(this));
            return;
        } catch(e) {
            throw e;
        }
    }

    async handleCallStreamClose() {
        this.logSvc.info('UserRoomServer handleCallStreamClose');
    }

    async handleCallStreamError() {
        this.logSvc.info('UserRoomServer handleCallStreamError');
    }
    /**
     * 
     *
     * @param {*} conn
     * @memberof UserRoomServer
     */
    async handleConnectionOpen(conn: DataConnection) {
        return new Promise( async (resolve, reject) => {
            try {
                this.logSvc.info('UserRoomServerObject handleConnectionOpen');
                if(!conn) {
                    throw 'No connection object passed';
                    
                }
                this.connections.push(conn);
                conn.on('open', this.handlePeerOpen.bind(this));
                conn.on('data', this.handleConnectionData.bind(this));
                conn.on('error', this.handleConnectionError.bind(this));
                conn.on('close', this.handleConnectionClose.bind(this));
                
                let newCall = await this.peer.call(conn.peer, this.dvcScv.mainStream);
                this.calls.push(newCall);
                this.setState(UserRoomServerState.ready);
                resolve(true)
            } catch(e) {
                this.logSvc.error('UserRoomServerObject handleConnectionOpen ' + e)
                reject(e);
            }
        })     
    }

    handlePeerClose() {
        this.logSvc.info('UserRoomServerObject handlePeerClose');
    }

    handlePeerDisconnected() {
        this.logSvc.info('UserRoomServerObject handlePeerDisconnected');
    }

    handlePeerError(err: any) {
        this.logSvc.info('UserRoomServerObject - handlePeerError' + JSON.stringify(err));
        this.fire(UserRoomServerEvent.PeerError, err);
    }

    async handlePeerOpen(id) {
        console.log('UserRoomServerObject handlePeerOpen');
        console.log('My id ' + this.peerId + ' other id ' + id);
       return;
    }

    async handleConnectionData(data: any) {
        this.logSvc.info('UserRoomServerObject - handleConnectionData');
        try {
            
            const {type, uid, rid} = data;
            const msgData = data.data;
            const rosterRecord = this.room.roster$.value.find( (rosterRecord) => rosterRecord.uid == uid);
            

            switch(type) {
                case UserObjectDataEventTypes.audioLevel:
                if(rosterRecord) {
                    await rosterRecord.userObject.remoteUpdateVolume(msgData*100);
                }
                break;
                case UserObjectDataEventTypes.Initiator:
                    const tmpCall = this.peer.call(data.peerId, this.dvcScv.mainStream);
                    this.calls.push(tmpCall);
                    tmpCall.on('stream', this.handleCallStream.bind(this));
                    tmpCall.on('close', this.handleCallStreamClose.bind(this));
                    tmpCall.on('error', this.handleCallStreamError.bind(this));
                    break;
                
            }
            return;
        } catch(e) {
            this.logSvc.error('UserRoomServer - handleConnectionData ' + e);
            return;
        }
        
    }

    async handleConnectionClose() {
        console.log('UserRoomServerObject handleClose');
    }

    handleCallStream(stream: MediaStream) {
        this.logSvc.info('UserRoomServerObject - handleCallStream ');
        // Other Users Initiate Stream to the Server - do nothing 
        return;
    }



    /**
     *
     *
     * @return {*} 
     * @memberof UserRoomServer
     */
    async reconnect(): Promise<null> {
        return new Promise( async (resolve, reject) => {
          try {
            await this.disconnect();
            await this.setState(UserRoomServerState.loading);
            resolve(null);
          } catch(e) {
    
          }
        })
    }
    /**
     *
     * handler for PeerJS connection errors;
     * @param {*} err
     * @return {*} 
     * @memberof UserRoomServer
     */
    handleConnectionError(err: any) {
        this.logSvc.info('UserRoomServer handleConnectionError - ' + JSON.stringify(err));
        // Unknown how to handle errors as of yet 
        return;
    }

    disconnect() {
        this.peer = null;
        this.connections = [];
        this.calls = [];
        this.mainStream = null;
        this.fire(UserRoomServerEvent.DisconnectionEvent, this);
        this.fire(UserRoomServerEvent.ScreensUpdated, this.mainStream );
        return;
    }

    async checkConnection() {
        // TODO for RoomUserServer Check 
    }

    async sendDataEvent(evt: UserObjectDataEvent) {
        this.connections.forEach( (conn) => {
            conn.send(evt);
        })
        return;
        
    }

    async sendEvent(evtType: UserObjectDataEventTypes, data: any, roomId?: string, msg?: string ) {
        const newEvent: UserObjectDataEvent = {
          type: evtType,
          uid: this.authSvc.localUser.uid,
          roomId: roomId,
          data: data,
          msg: msg
        };
        await this.sendDataEvent(newEvent);
        return;
      }

    muteMeRemoteToggle() {
        
        this.authSvc.localUser.muteToggle();
        this.sendEvent(UserObjectDataEventTypes.audioMuted, this.authSvc.localUser.muted);
    }
    
}