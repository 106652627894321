import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AgoraService } from '../agora-service/agora-service';
import { RoomService } from '../room.service/room.service';

@Injectable({
  providedIn: 'root'
})
export class VrService {
  override = false;
  overrideresult = true;
  vrEngaged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    public agoraSvc: AgoraService,
    public roomSvc: RoomService,
    public ngZone: NgZone
  ) {}

  supportsVR() {
    if(this.override) {
      return this.overrideresult
    }
    
    return ('getVRDisplays' in navigator);
  }

  supportsXR() {
    const supportsXR = 'xr' in window.navigator;
    return supportsXR;
  }

  toggleVREngaged() {
    
    const curernt = this.vrEngaged.value;
    this.override = !this.override;
    this.vrEngaged.next(!curernt);
    if(this.vrEngaged.value) {
      setTimeout(() => {
        // Find the acene component and subscribe to the vent exiting vr 
        this.activateVR();
        // @ts-ignore
        document.querySelector('a-scene').addEventListener('exit-vr', () => {
          // then on exit vr also set the vrEngaged to false
          this.vrEngaged.next(false);
        });
      }, 500)
    }
    
  }

  isMetaQuestVR() {
    const userAgent = window.navigator.userAgent;
    const metaQuestVRHeadsets = [
      "Meta Quest",
      "Meta Quest 2",
      "Meta Quest 3",
      "Meta Quest Pro"
    ];
    return metaQuestVRHeadsets.some(headset => userAgent.includes(headset));
  }

  async activateVR() {
    this.ngZone.runOutsideAngular( async() => {
      const supportsXR = 'xr' in window.navigator;
      console.log(supportsXR);
      if(supportsXR) {
        // @ts-ignore
        await document.querySelector('a-scene').enterVR(true);
        
      } else {
        // @ts-ignore
        await document.querySelector('a-scene').enterVR();
      }
    });
    
    
  }

  exitVR() {
    // @ts-ignore
    document.querySelector('a-scene').exitVR();
    this.vrEngaged.next(false);
  }
}
