import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputerObject } from '../../objects/computerObject';
import { AuthService } from '../../services/auth-service/auth.service';
import { ServerObject } from '../../services/computer-server-service/ServerObject';
import * as moment from 'moment';
import { ComputerObjectEvents } from '../../objects/computerEnum';

@Component({
  selector: 'server-header-bar',
  templateUrl: './server-header-bar.component.html',
  styleUrls: ['./server-header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ServerHeaderBarComponent implements OnInit {
  @Input()
  server: ServerObject;
  @Input()
  computer: ComputerObject;
  timeDiff: number;
  constructor(
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.timeDiff = moment.now() - this.computer.computer.lastOnline;
    this.computer.on(ComputerObjectEvents.computerProfileChanged, this.updateView.bind(this));
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
