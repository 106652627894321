import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdateServiceService } from 'src/app/services/update-service/update-service.service';

@Component({
  selector: 'app-app-download-modal',
  templateUrl: './app-download-modal.component.html',
  styleUrls: ['./app-download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDownloadModalComponent implements OnInit {
  electron = window.electron;
  constructor(
    public updateSvc: UpdateServiceService,
  ) { }

  ngOnInit(): void {
  }

  openWebBrowser(): void {
    const linkAdd = this.updateSvc.getMainSiteLink();
    window.electron.openWebBrowser(linkAdd);
  }

}
