// Build me a service to get the auth bear token from the auth service and then use it hit the firebase function apis I have built
// First setup the api-service in angular 
// Then build the api-service to get from the firebase functions

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { LogService } from "../log-service/log.service";
import axios from 'axios';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
        token: string = null;
        constructor(
            public afAuth: AngularFireAuth,
            public logSvc: LogService
            ) {
            // Setup subscribitions ot get the token whenever it updates 
            this.setupSubscriptions();
        }

        setupSubscriptions() {
            this.afAuth.idToken.subscribe( (token) => {
            
                this.token = token;
            })
        }

        async get(url: string) {
            // setup the headers with the bear token and make the auth call 
            // return the response
            try {
                let response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                })
                return response;
            } catch(e) {
                this.logSvc.error(e);
                throw e;
                
            }
        }

        async post(url: string, data: any) {
            // setup the headers with the bear token and make the auth call 
            // return the response
            try {
                let response = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                })
                return response;
            } catch(e) {
                this.logSvc.error(e);
                throw e;
            }
        }



    }
