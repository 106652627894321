
<mat-card class="hoverArea" (mouseenter)="hoverShow()" (mouseleave)="hoverHide()" (click)="wideScreenVideo()">
    <mat-card-header *ngIf="showTop" class="revealOnHover">
        <user-list-item [rosterItem]="roster"></user-list-item>
    </mat-card-header>
    <mat-card-content  style="position:relative;justify-content: center; display: flex;" *ngIf="user && user.profile">
        <div [id]="'user-video-' + user.uid + '-small'" class="video-area" [matTooltip]="user.profile.name">
            <div>
                <user-avatar *ngIf="!videoTrack" [user]="user" style=""></user-avatar>  
                <br>
                 <div *ngIf="!videoTrack" class="username-title">
                    {{user.profile.name}}
                 </div>  
            </div>
        </div>
        <mat-icon class="icon-location" [id]="'mute-usr-' + user.uid" [ngClass]="{'mat-warn' : user.muted || user.localMute}" [matTooltip]="user.agoraAutoTrack ? 'Microphone' : 'User has muted themselves'" [matTooltipHideDelay]="1000" (click)="clickMuteToggle()">{{user.muted ? 'mic_off' : 'mic'}}</mat-icon>
       
    </mat-card-content>
</mat-card>