

export enum DeviceEvents {
  newStream = 'newStream',
  newMediaDevice = 'newMediaDevice',
  muteAudio = 'muteAudio',
  muteVideo = 'muteVideo',
  screenShare = 'screenShare',
  videoTrackAdded = 'videoTrackAdded'

}
