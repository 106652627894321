import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuTypes } from '../../../models/Menu';
import { PublicProfile } from '../../../models/Users';
import { InviteService } from '../../../services/invite-service/invite.service';
import { LogService } from '../../../services/log-service/log.service';
import { MenuService } from '../../../services/menu-service/menu.service';
import { RoomService } from '../../../services/room.service/room.service';

@Component({
  selector: 'app-user-invite-many-rooms-dialog',
  templateUrl: './user-invite-many-rooms-dialog.component.html',
  styleUrls: ['./user-invite-many-rooms-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInviteManyRoomsDialogComponent implements OnInit {
  profile: PublicProfile;
  uid: string;
  spaceRoomTypes = MenuTypes;
  constructor(
    public logSvc: LogService,
    public roomSvc: RoomService,
    public menuSvc: MenuService,
    @Inject(MAT_DIALOG_DATA) public data: {uid: string, profile: PublicProfile},
    private inviteSvc: InviteService,
    public dialogRef: MatDialogRef<UserInviteManyRoomsDialogComponent>
  ) { 
    this.profile = this.data.profile;
    this.uid = this.data.uid;
  }

  ngOnInit(): void {
  }

  SendInvites() {

  }

}
