import {FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AvatarModule } from 'ngx-avatars';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';
import { CommonModule } from '@angular/common';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { UpdateServiceService } from 'src/app/services/update-service/update-service.service';


@Component({
  selector: 'get-started-modal',
  templateUrl: './get-started-modal.component.html',
  styleUrls: ['./get-started-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    AvatarModule,
    CommonModule,
  ],
})
export class GetStartedModalComponent implements OnInit {
  @ViewChild('displayname', { read: ElementRef }) displaynamefield: any;
  @ViewChild('hostname', { read: ElementRef }) hostname: any;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  displayName = 'Loading...';
  size = 100;
  registration: string;
  hostnamefield: string;
  constructor(private _formBuilder: FormBuilder,
    public dvcSvc: DeviceService,
    public dialog: MatDialog,
    public authSvc: AuthService,
    public eltronSvc: ElectronService,
    public dialogRef: MatDialogRef<GetStartedModalComponent>,
    public updateSvc: UpdateServiceService,
    public compterServerSvc: ComputerServerService,
    public logSvc: LogService,
    public cdr: ChangeDetectorRef) {
      dialogRef.disableClose = true; 
     }

  async ngOnInit(): Promise<void> {
    if(this.eltronSvc.isDesktop()) {
      this.registration = await window.electron.getRegistration();
      this.hostnamefield = await window.electron.getComputerName();
    }
  }

  async saveName() {
    if(this.displaynamefield.nativeElement.value) {
      await this.authSvc.localUser.updateUserProfileRecord({name: this.displaynamefield.nativeElement.value});
    }
    return;
  }

  async clickSave() {
    try {
      await this.compterServerSvc.registerServer(this.hostname.nativeElement.value);
      setTimeout( () => {
        this.dialogRef.close();
      }, 500)
    } catch(e) {
      this.logSvc.error('ComputerAddModalComponent - clickSave');
    }
    
  }

  async finalStep() {
    this.saveName();
    if(this.eltronSvc.isDesktop()) {
      await this.clickSave();
    }
    setTimeout( () => {
      this.authSvc.activateUser();
    }, 200)
    this.dialogRef.close();
  }

  async skip() {
    setTimeout( () => {
      this.authSvc.activateUser();
    }, 200)
    this.dialogRef.close();
  }

  

}
