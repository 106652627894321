import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogSignIn } from '../popups/login.modal/login.modal.component';
import { ComputerAddModalComponent } from '../popups/computer-add.modal/computer-add.modal.component';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input()
  status = 'Loading...';
  steps = ['Loading...', 'Login Required...', 'Loading Account...', 'Loading Spaces...', 'Loading Computers...', 'Loading Rosters...', 'Loading VR Environments...', 'Integrating Computers and VR Workspace...', 'Ready...'];
  step = 0;
  user$?: BehaviorSubject<any>;
  constructor(public authSvc: AuthService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.setupSubscriptions();
    
  }

  setupSubscriptions(): void {
    this.user$ = this.authSvc.user;
    console.log('user$', this.user$)
    this.user$.subscribe((user) => {
      console.log('user', user)
      if(!user) {
        this.status = this.steps[1];
        this.openDialog('200ms', '200ms');
      } else {
        this.startLoadingSequence();
      }
    });
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    console.log('openDialog')
    this.dialog.open(DialogSignIn, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  startLoadingSequence(): void {
    if(this.step > this.steps.length - 1) {
      return;
    }
    this.status = this.steps[this.step];
    this.step++;
    setTimeout(this.startLoadingSequence.bind(this), 500);
  }

}
