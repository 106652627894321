
export type ComputerShares = {
  cid: string, 
  roomId: string,
  ownerId: string,
  tmpImg?: string, 
  access: 'View' | 'Control',
  screens?: {name: string, img: string}
  created?: number, 
  updated?: number
}


export enum ComputerObjectState {
  init = 'Initializing',
  loading = 'Loading',
  connecting = 'Connecting',
  dataOnline = 'DataOnline',
  ready = 'Ready',
  disconnected = 'Disconnected',
  offline = 'Offline',
  reconnecting = 'Reconnecting'
};

export enum ComputerServerState {
  init = "Initializing",
  loading = 'Loading',
  registering = 'Registering',
  securing = 'Securing',
  ready = 'Ready',
  closing = "Closing",
  closed = "Closed",
  unregistered = 'Unregistered',
  error = 'Error',
}

export enum ComputerServerEvents {
  computerStateChanged = 'computerStateChanged',
  computerProfileChanged = 'computerProfileChanged',
  computerControlEvent = 'computerControlEvent',
  computerConnectionEvent = 'computerConnectionEvent',
  computerDisconnectionEvent = 'computerDisconnectionEvent',
  computerStreamSharedEvent = 'computerStreamSharedEvent',
  computerNewPhysicalScreen = 'computerNewPhysicalScreen',
  computerNewScreen = 'computerNewScreen',
  computerServerPeerError = 'computerServerPeerError',
  computerServerUserConnect = 'computerServerUserConnect',
  computerScreensUpdated = 'computerScreensUpdated',
  computerConfigUpdated = 'computerConfigUpdated',
  computerDisplayMetricChanged = 'computerDisplayMetricChanged'
}

export enum ComputerObjectEvents {
  computerStateChanged = 'computerStateChanged',
  computerProfileChanged = 'computerProfileChanged',
  computerControlEvent = 'computerControlEvent',
  computerConnectionEvent = 'computerConnectionEvent',
  computerDisconnectionEvent = 'computerDisconnectionEvent',
  computerStreamSharedEvent = 'computerStreamSharedEvent',
  computerNewScreen = 'computerNewScreen',
  computerScreensUpdated = 'computerScreensUpdated',
  computerMouseEvent = 'computerMouseEvent',
  computerMouseLinkSharedEvent = 'computerMouseLinkSharedEvent'
}

export enum ComputerClientState {
  connected = 'Connected',
  securing = 'Securing',
  ready = 'Ready',
  error = 'Error',
}

export enum ComputerCommands {
  compCmdValidateSecurity = 'CompCmdValidateSecurity',
  comCmdGetScreens = 'ComcmdGetScreens',
  cmpCmdSendKeys = 'CmpCmdSendKeys',
  cmpCmdSendMouse = 'CmpCmdSendMouse'
};
