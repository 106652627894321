export const environment = {
  production: true
};

export const APP_CONFIG = {
  production: true,
  environment: 'LOCAL',
  loadMock: true,
  url: 'https://dev.aro.work/',
  firebase: {
    apiKey: 'AIzaSyAkjMz3qCiDrZrx_a-cU6pcNirSDR_2aA0',
    authDomain: 'zero-f9dc1.firebaseapp.com',
    databaseURL: 'https://zero-f9dc1.firebaseio.com',
    projectId: 'zero-f9dc1',
    storageBucket: 'zero-f9dc1.appspot.com',
    messagingSenderId: '888189768177',
    appId: '1:888189768177:web:c7657f913ead850c103450',
    measurementId: 'G-6FC74KQQWX'
  }
};
