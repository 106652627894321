import { ConnectionDetails } from "../models/ConnectionDetails";
import * as sdpTransform from 'sdp-transform';

export function checkConnection(peerConnection: RTCPeerConnection, lastConnectionDetails?: ConnectionDetails): Promise<ConnectionDetails> {

        return new Promise(async (resolve, reject) => {
            let connectionDetails: ConnectionDetails = {
                bandwidthMbps: 0,
                jitter: 0,
                packetLoss: 0,
                latency: 0,
                numcalls: 0,
                quality: 'good',
            };
            let stats = await peerConnection.getStats();
            let numreportstransport = 0;
            let numreportsinboundrtp = 0;
            let packetLoss:number  = 0;
            let jitter = 0;
            let roundTripTime = 0;
            let numReports = 0;
            // get the keys of an object as an array 
            // @ts-ignore
            let statsArray = Array.from(stats);
            // don't do as a foreach do as a for loop 

            stats.forEach( (value, key, report: any) => {
                let sentbytes;
                let readbytes;
                let headerBytes;
                let packets;
                let state;
                // @ts-ignore
                if (value.type === 'transport') {
                // @ts-ignore
                if (value.isRemote) {
                    console.log('remote transport', value.bytesReceived)
                    return;
                }
                numreportstransport++;
                // @ts-ignore
                const now = value.timestamp;
                sentbytes = value.bytesSent;
                headerBytes = value.headerBytesSent;
                readbytes = value.bytesReceived;
                if(lastConnectionDetails && lastConnectionDetails.readBytes) {
                    const bytesdiff = readbytes - lastConnectionDetails.readBytes;
                    connectionDetails.bandwidthMbps = Number((((bytesdiff / 5)* 8)/1000000).toFixed(3));
                }
                connectionDetails.readBytes = readbytes.toFixed(2).valueOf();
                
                
                packets = value.packetsSent;
                state = value.iceState;
                // if(state == 'disconnected' || state == 'failing') {
                //     this.setComputerState(ComputerObjectState.disconnected);
                //     return;
                // }
                
                }

                if(value.type == 'inbound-rtp') {
                numreportsinboundrtp++;
                connectionDetails.jitter = value.jitter;
                packetLoss += value.packetsLost;
                jitter += value.jitter;
                
                }
                if (numReports > 0) {
                    connectionDetails.latency = roundTripTime / numReports;
                }
                if(numreportsinboundrtp) {
                    connectionDetails.jitter = jitter/numreportsinboundrtp;
                }
                connectionDetails.packetLoss = packetLoss;
                
                if (value.type == 'remote-inbound-rtp') {
                   
                    if (value.roundTripTime) {
                        roundTripTime += value.roundTripTime;
                        numReports++;
                    }
                    connectionDetails.packetLoss = value.packetsLost;
                    connectionDetails.latency = value.latency;
                    connectionDetails.roundTripTime = value.roundTripTime;
                    connectionDetails.timestamp = value.timestamp;
                }

                if(value.type == 'outbound-rtp' && value.mediaType === 'video') {
                   connectionDetails.codec = value.codecId;
                }

                if(value.type == 'codec') {
                    connectionDetails.codec = value.mimeType;
                }

                if(value.type == 'codec' && value.id === connectionDetails.codec) {
                    connectionDetails.codec = value;
                    
                }
                //console.log('codec', value);
            
            });
            
            //connectionDetails.numcalls = this.calls.length;
            if(connectionDetails.jitter > 0.02) {
                connectionDetails.quality = 'degraded';
            }
            //console.log(connectionDetails);
            resolve(connectionDetails);
        });

}

export function modifySdp(sdp: string): string {
    console.log('sdpTransform');
    const sdpTmp = sdpTransform.parse(sdp);
    console.log(sdpTmp.groups)
    console.log(sdpTmp.media)
    
    
    // Scroll through all media types in the sdp
    for(let x = 0; x < sdpTmp.media.length; x++) {
        // Get the AV1 Payload ID 
        let av1PayloadId = sdpTmp.media[x].rtp.find((rtp: any) => rtp.codec === 'AV1');
        // Get the v9 Payload ID
        let vp9PayloadId = sdpTmp.media[x].rtp.find((rtp: any) => rtp.codec === 'VP9');
        // Get the H264 Payload ID
        let h264PayloadId = sdpTmp.media[x].rtp.find((rtp: any) => rtp.codec === 'H264');
        // If the AV1 Payload ID is found
        if(av1PayloadId) {
            // find the fmtp line for the AV1 codec
            let av1Fmtp = sdpTmp.media[x].fmtp.find((fmtp: any) => fmtp.payload.toString() === av1PayloadId.payload.toString());
            //If the fmtp line is found, remove it
            if(av1Fmtp) {
                sdpTmp.media[x].fmtp = sdpTmp.media[x].fmtp.filter((fmtp: any) => fmtp.payload !== av1PayloadId.payload.toString());
            }
            // Add the AV1 fmtp line
            sdpTmp.media[x].fmtp.push({
                payload: av1PayloadId.payload,
                config: 'useadaptivelayering_v2=true; useadaptivelayering=true'
            });
            //level-idx=4.1;profile=0;tier=0
        };



        // Prioritize VP9 in the sdpTmp.media[x].payloads string
        if(vp9PayloadId) {
            sdpTmp.media[x].payloads = sdpTmp.media[x].payloads.split(' ').filter((payload: any) => payload !== vp9PayloadId.payload.toString()).join(' ');
            sdpTmp.media[x].payloads = vp9PayloadId.payload + ' ' + sdpTmp.media[x].payloads;
        }

        // Prioritize H264 in the sdpTmp.media[x].payloads string
        if(h264PayloadId) {
            sdpTmp.media[x].payloads = sdpTmp.media[x].payloads.split(' ').filter((payload: any) => payload !== h264PayloadId.payload.toString()).join(' ');
            sdpTmp.media[x].payloads = h264PayloadId.payload + ' ' + sdpTmp.media[x].payloads;
        }

        // Prioritize AV1 in the sdpTmp.media[x].payloads string
        if(av1PayloadId) {
            sdpTmp.media[x].payloads = sdpTmp.media[x].payloads.split(' ').filter((payload: any) => payload !== av1PayloadId.payload.toString()).join(' ');
            sdpTmp.media[x].payloads = av1PayloadId.payload + ' ' + sdpTmp.media[x].payloads;
        }


    }
    console.log(sdpTransform.write(sdpTmp));
    return sdpTransform.write(sdpTmp);
    
  }
