import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MenuItems } from '../../models/Menu';
import { MenuTypes } from '../../models/Menu';

@Component({
  selector: 'general-list-item',
  templateUrl: './general-list-item.component.html',
  styleUrls: ['./general-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralListItemComponent implements OnInit {

  @Input()
  menuItem: MenuItems;
  spaceRoomTypes = MenuTypes;
  constructor() { }

  ngOnInit(): void {
    
  }

}

