import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "../auth-service/auth.service";
import { LogService } from "../log-service/log.service";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../../models/Users";

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {
    user$?: BehaviorSubject<User>;
    constructor(
        public authSvc: AuthService,
        public logSvc: LogService,
        public router: Router
    ) {
        this.setupSubscriptions();
    }

    setupSubscriptions() {
        this.user$ = this.authSvc.user;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise( (resolve, reject) => {
            resolve(true);
            try {
                let checkAccess = false;
                if( this.user$ && this.user$.value ) {
                    checkAccess = true;
                }
                this.logSvc.info('AuthGuard canActivate');
                resolve(checkAccess);
            } catch(e) {
                this.logSvc.error('AuthGuard Error canActivate + ' + JSON.stringify(e))
                resolve(false);
            }
        })
        
    }
}