

export enum UserObjectState {
    init = 'Initializing',
    loading = 'Loading',
    connecting = 'Connecting',
    ready = 'Ready',
    disconnected = 'Disconnected',
    offline = 'Offline',
    reconnecting = 'Reconnecting'
}


export enum UserObjectEvent {
    userStateChanged = 'UserStateChanged',
    userProfileChanged = 'UserProfileChanged',
    ConnectionEvent = 'ConnectionEvent',
    DisconnectionEvent = 'DisconnectionEvent',
    StreamSharedEvent = 'StreamSharedEvent',
    NewScreen = 'NewScreen',
    ScreensUpdated = 'ScreensUpdated',
    audioLevelUpdate = 'AudioLevelUpdate',
    actionMuted = 'MuteToggle',
    actionVideoToggle = 'actionVideoToggle'
}

export type UserObjectDataEvent = {
    type: UserObjectDataEventTypes,
    uid: string,
    roomId: string, 
    data: any,
    msg?: any
}

export enum UserObjectDataEventTypes {
    audioLevel = 'audioLevel',
    emoji = 'emoji',
    audioMuted = 'audioMuted',
    Initiator = 'Initiator',
    screenShared = 'ScreenShare'
}