// Enum of all the audio cues
export enum AudioCue {
    // General
    ERROR = 'assets/audio/error.mp3',
    SUCCESS = 'assets/audio/success.mp3',
    // Room
    ROOM_JOIN = 'assets/audio/join.mp3',
    ROOM_LEAVE = 'assets/audio/leave.mp3',
    ROOM_CREATE = 'assets/audio/room_create.mp3',
    ROOM_DELETE = 'assets/audio/room_delete.mp3',
    ROOM_UPDATE = 'assets/audio/room_update.mp3',
    // Computer
    COMPUTER_ONLINE = 'assets/audio/computer_online.mp3',
    COMPUTER_LEAVE = 'assets/audio/computer_leave.mp3',
    COMPUTER_CREATE = 'assets/audio/computer_create.mp3',
    COMPUTER_DELETE = 'assets/audio/computer_delete.mp3',
    COMPUTER_UPDATE = 'assets/audio/computer_update.mp3',
    // Screen
    SCREEN_JOIN = 'assets/audio/screen_join.mp3',
    SCREEN_LEAVE = 'assets/audio/screen_leave.mp3',
    SCREEN_CREATE = 'assets/audio/screen_create.mp3',
    SCREEN_DELETE = 'assets/audio/screen_delete.mp3',
    SCREEN_UPDATE = 'assets/audio/screen_update.mp3',
    // Device
    DEVICE_MUTE = 'assets/audio/mute.mp3',
    DEVICE_UNMUTE = 'assets/audio/unmute.mp3',
    DEVICE_CREATE = 'assets/audio/device_create.mp3',
    DEVICE_DELETE = 'assets/audio/device_delete.mp3',
    DEVICE_UPDATE = 'assets/audio/device_update.mp3',
    // Device
    USER_JOIN = 'assets/audio/user_join.mp3',
    USER_LEAVE = 'assets/audio/user_leave.mp3',
    USER_CREATE = 'assets/audio/user_create.mp3',
    USER_DELETE = 'assets/audio/user_delete.mp3',
    USER_UPDATE = 'assets/audio/user_update.mp3',
    // Device
    USER_AUDIO_JOIN = 'assets/audio/user_audio_join.mp3',
    USER_AUDIO_LEAVE = 'assets/audio/user_audio_leave.mp3',
    USER_AUDIO_CREATE = 'assets/audio/user_audio_create.mp3',
    USER_AUDIO_DELETE = 'assets/audio/user_audio_delete.mp3',
    USER_AUDIO_UPDATE = 'assets/audio/user_audio_update.mp3',
    // Device
    USER_VIDEO_JOIN = 'assets/audio/user_video_join.mp3',
    USER_VIDEO_LEAVE = 'assets/audio/user_video_leave.mp3',
    USER_VIDEO_CREATE = 'assets/audio/user_video_create.mp3',
    USER_VIDEO_DELETE = 'assets/audio/user_video_delete.mp3',
    USER_VIDEO_UPDATE = 'assets/audio/user_video_update.mp3',
}