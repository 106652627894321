import { EventEmitter } from '@billjs/event-emitter';
import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { Computer, ComputerDataEvent, ComputerDataEventTypes } from '../models/Computers';
import { ComputerObjectEvents, ComputerObjectState } from './computerEnum';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LogService } from '../services/log-service/log.service';
import { AuthService } from '../services/auth-service/auth.service';
import { NgZone } from '@angular/core';
import { StunServiceService } from '../services/stun-service/stun-service.service';
import { BehaviorSubject } from 'rxjs';
import { ConnectionDetails } from '../models/ConnectionDetails';
import { checkConnection, modifySdp } from '../utils/webrtcutil';

export class ComputerObject extends EventEmitter {
  name = 'loading...';
  cid?: string;
  computer?: Computer;
  computerState?: ComputerObjectState;
  peer?: Peer;
  connection?: DataConnection;
  calls: MediaConnection[] = [];
  streams: MediaStream[] = [];
  extraStreams: MediaStream[] = [];
  screens: any;
  lastMouseEvent: number = 0;
  reconnections: number = 0;
  statsLastReadByes: number = 0;
  connectionDetails$ = new BehaviorSubject<ConnectionDetails | null>(null);
  connectDetails: ConnectionDetails = {
    bandwidthMbps: 0, //mbps 
    latency: 0, //ms
    jitter: 0, //ms
    packetLoss: 0, //%
    quality: 'unknown',
    numcalls: 0
  }
  streamRequested: boolean = false;
  connectionAttempts = 0;
  constructor(computer: Computer, 
    public afs: AngularFirestore,
    public logSvc: LogService,
    public authSvc: AuthService,
    public stunSvc: StunServiceService,
    private ngZone: NgZone, ) {
    super();
    this.computer = computer;
    this.cid = this.computer.cid;
    this.name = this.computer.name;
    
    this.fire(ComputerObjectEvents.computerProfileChanged, this.computer);
    this.initiate();
  }

  initiate() {
    this.logSvc.info('ComputerObject Init');
    this.setupSubscriptions();
    return;
  }

  async setupSubscriptions() {

    this.authSvc.user.subscribe( (user) => {
      if(user && !this.computerState) {
        this.setComputerState(ComputerObjectState.loading);
      }
    })

    this.afs.collection('computer').doc(this.cid).valueChanges().subscribe( async (change: Computer) => {
      try {
        console.log('computer data change')
        console.log(change)
        this.computer = change;
        // @ts-ignore
        if(change.peerId != this.computer.peerId) {
            // @ts-ignore
            if(change.peerId != false) { 

            if(!this.computer) {
              this.computer = change;
            } else {
              this.computer = change;
            await this.setComputerState(ComputerObjectState.reconnecting);
            }
            
          } else {
            delete this.computer.peerId;

            await this.setComputerState(ComputerObjectState.disconnected);
          }

        }

        if(this.computerState != ComputerObjectState.ready && this.computerState != ComputerObjectState.loading && this.computerState != ComputerObjectState.dataOnline && this.computerState != ComputerObjectState.init) {
          const lastOnlineSec = (moment.now() - this.computer.lastOnline);
          if(lastOnlineSec < 5000) {
            this.setComputerState(ComputerObjectState.init);
            // computer came online and we need to reconnect
            console.log('computer back online or came online')
          }
          
        }

      } catch(e) {
        throw e;
      }
      
    })

    // Setup Window events for if the window is closed, or goes offline 
    window.addEventListener('beforeunload', async (event) => {
      this.shutdownAll();
      await this.setComputerState(ComputerObjectState.disconnected);
    });
    // window offline event
    window.addEventListener('offline', async (event) => {
      await this.setComputerState(ComputerObjectState.disconnected);
    });
    // window online event
    window.addEventListener('online', async (event) => {
      await this.setComputerState(ComputerObjectState.loading);
    });

  }

  shutdownAll() {
    this.logSvc.info('ComputerObject - shutdownAll');

    // First Order Streams
    for(let x = 0; x < this.streams.length; x++) {
      for(let y = 0; y < this.streams[x].getTracks().length; y++) {
        this.streams[x].getTracks()[y].stop();
      }
    }
    // Second Order Streams
    for(let x = 0; x < this.extraStreams.length; x++) {
      for(let y = 0; y < this.extraStreams[x].getTracks().length; y++) {
        this.extraStreams[x].getTracks()[y].stop();
      }
    }

    // Calls 
    for(let x = 0; x < this.calls.length; x++) {
      this.calls[x].close();
    }
    this.connection?.close();
    this.peer?.destroy();
    this.disconnectComputer();
  }

  async setComputerState(state: ComputerObjectState) {
    return new Promise( async (resolve, reject) => {
      try {
        console.log('ComputerObject State Change - ' + state)
        this.logSvc.info('ComputerObject State Change - ' + state);
        if(this.computerState == state) {
          resolve(this);
        }
        this.computerState = state;
        
        switch(state) {
          case ComputerObjectState.init:
            if(!this.computer.peerId || ( this.computer?.lastOnline! - moment.now()) > 5000) {
              this.setComputerState(ComputerObjectState.offline);
              resolve(this);
              
            }
            this.setComputerState(ComputerObjectState.loading);
            resolve(this);
            break;
          case ComputerObjectState.loading:
            
            await this.getComputerProfile();
            this.startConnection();
            
            resolve(this);
            break;
          case ComputerObjectState.dataOnline:
            resolve(this);
            break;
          case ComputerObjectState.ready:

            resolve(this);
            break;
          case ComputerObjectState.disconnected:
            this.shutdownAll();
            await this.disconnectComputer();
            resolve(this);
            break;
          case ComputerObjectState.reconnecting:
            await this.reconnect();
            resolve(this);
            break;
          
            
        }
        this.fire(ComputerObjectEvents.computerStateChanged, state);
      } catch(e) {
        reject(e);
      }
    })
  }

  async getComputerProfile() {
    return new Promise( (resolve, reject) => {
      this.afs.collection('computer').doc(this.cid).get().subscribe( async (server) => {
        this.computer = server.data() as Computer;
        this.fire(ComputerObjectEvents.computerProfileChanged, this.computer);
        resolve(this.computer);
    })
    })

  }

  mapComputer(doc: any): Computer {
    return doc.data() as Computer;
  }

  sendDataEvent(evt: ComputerDataEvent) {
    try {
      if(this.connection) {
        this.connection.send(evt);
      } else {
        this.logSvc.error('ComputerObject - sendDataEvent - sending data with no connection');
      }
    } catch(e) {
      this.logSvc.error('ComputerObject - sendDataEvent ' + JSON.stringify(e));
    }
    
  }

  public async sendEvent(evtType: ComputerDataEventTypes, uid: string, data: any, roomId?: string, msg?: string ) {
    uid = this.authSvc.localUser.uid;
    const newEvent: ComputerDataEvent = {
      type: evtType,
      uid: uid,
      roomId: roomId,
      data: data,
      msg: msg
    };
    await this.sendDataEvent(newEvent);
    return;
  }

  async sendReqLiveConfig() {
    this.logSvc.info('ComputerObject - sendReqLiveConfig');
    await this.sendEvent(ComputerDataEventTypes.configRequest, this.authSvc.localUser.uid, {});
  }

  async sendUserViewStart(uid: string, screenId: string, roomId?: string) {
    this.logSvc.info('ComputerObject - sendUserViewStart');

    const viewData = {
      uid: uid,
      screenId: screenId,
      timestamp: moment.now()
    }
    await this.sendEvent(ComputerDataEventTypes.screenViewStart, uid, viewData, roomId );
  }

  async sendUserViewEnd(uid: string, screenId: string, roomId?: string) {
    const viewData = {
      uid: uid,
      screenId: screenId,
      timestamp: moment.now()
    }
    await this.sendEvent(ComputerDataEventTypes.screenViewEnd, uid, viewData, roomId );
  }

  async sendUserHover(uid: string, screenId: string, evtData: any, roomId?: string) {
    const mouseData = {
      uid: uid,
      screenId: screenId,
      timestamp: moment.now(),
      evtData: evtData
    }
    await this.sendEvent(ComputerDataEventTypes.screenHoverUpdate, uid, mouseData, roomId );
  }

  async sendMouseEvent(uid: string, screenId: string, evtData: any, roomId?: string) {
    
    if( (moment.now() - this.lastMouseEvent) > 50 ) {
      //this.lastMouseEvent = moment.now();
      const mouseData = {
        uid: uid,
        screenId: screenId,
        timestamp: moment.now(),
        evtData: evtData
      }
      await this.sendEvent(ComputerDataEventTypes.mouseEvent, uid, mouseData, roomId );

    } 
    
  }

  async sendKeyboardEvent(uid: string, evtData: any, roomId?: string) {
    const keyboardEvent = {
      uid: uid,
      timestamp: moment.now(),
      evtData: JSON.parse(JSON.stringify(evtData))
    }
    await this.sendEvent(ComputerDataEventTypes.keyboardEvent, uid, keyboardEvent, roomId);
  }

  async sendConfigUpdateEvent(config: Partial<Computer>) {
    if(this.authSvc.localUser.uid != this.computer.ownerUid) {
      throw 'Access Denied'
    }
    await this.sendEvent(ComputerDataEventTypes.configUpdate, this.authSvc.localUser.uid, config);
    return;
  }

  async reconnect() {
    return new Promise( async (resolve, reject) => {
      try {
        this.logSvc.info('computerObject - reconnect')
        this.reconnections++;
        await this.disconnectComputer();
        if(this.reconnections < 3) {
          setTimeout( async () => {
            await this.setComputerState(ComputerObjectState.loading);
          }, 1000);
          
        } else {
          
        }
        
        resolve(this);
      } catch(e) {
        this.logSvc.error('reconnect Error')
        reject(this);
      }
    })
  }

  async checkConnection() {
    try {
      if(!this.connection?.peerConnection || !this.calls.length || this.computerState == ComputerObjectState.disconnected) {
        return;
      }
      const checkRet = await checkConnection(this.calls[0].peerConnection, this.connectDetails);
      // this.connectDetails = checkRet;
      // this.connectionDetails$.next(checkRet);
      // setTimeout(this.checkConnection.bind(this), 5000);
      // return;
      // @ts-ignore
      let stats = await this.calls[0].peerConnection.getStats();
      let numreportstransport = 0;
      let numreportsinboundrtp = 0;
      let packetLoss:number  = 0;
      let jitter = 0;
      let roundTripTime = 0;
      let numReports = 0;
      stats.forEach( (value, key, report: any) => {
        let sentbytes;
        let readbytes;
        let headerBytes;
        let packets;
        let state;
        // @ts-ignore
        if (value.type === 'transport') {
           // @ts-ignore
          if (value.isRemote) {
            console.log('remote transport', value.bytesReceived)
            return;
          }
          numreportstransport++;
           // @ts-ignore
          const now = value.timestamp;
          sentbytes = value.bytesSent;
          headerBytes = value.headerBytesSent;
          readbytes = value.bytesReceived;
          const bytesdiff = readbytes - this.statsLastReadByes;
          this.statsLastReadByes = readbytes.toFixed(2).valueOf();
          this.connectDetails.bandwidthMbps = Number((((bytesdiff / 5)* 8)/1000000).toFixed(3));
          packets = value.packetsSent;
          state = value.iceState;
          if(state == 'disconnected' || state == 'failing') {
            this.setComputerState(ComputerObjectState.disconnected);
            return;
          }
          
        }

        if(value.type == 'inbound-rtp') {
          numreportsinboundrtp++;
          //console.log(value);
          this.connectDetails.jitter = value.jitter;
          packetLoss += value.packetsLost;
          jitter += value.jitter;
          
        }

        if (value.type == 'remote-inbound-rtp') {
          console.log('remote-inbound-rtp', value)
          if (value.roundTripTime) {
            roundTripTime += value.roundTripTime;
            numReports++;
          }
        }
       
      });
      if (numReports > 0) {
        this.connectDetails.latency = roundTripTime / numReports;
      }
      this.connectDetails.packetLoss = packetLoss;
      this.connectDetails.jitter = jitter/numreportsinboundrtp;
      this.connectDetails.numcalls = this.calls.length;
     
      this.connectionDetails$.next(this.connectDetails);
      setTimeout(this.checkConnection.bind(this), 5000);
    } catch(e) {
      this.logSvc.error('checkConnection Error' + e);
      console.error(e);
      setTimeout(this.checkConnection.bind(this), 5000);
    }
  }


  async disconnectComputer() {

      
   
      this.connection = null;
      this.calls = [];
      this.streams = [];
      this.extraStreams = [];
      this.connectionAttempts = 0;
      this.screens = null;
      if(this.computerState != ComputerObjectState.disconnected) {
        this.setComputerState(ComputerObjectState.disconnected);
      }
      this.fire(ComputerObjectEvents.computerDisconnectionEvent, this);
      this.fire(ComputerObjectEvents.computerScreensUpdated, this.streams);
  
    
    return;
  }

  


  async startConnection() {
    return new Promise( async (resolve, reject) => {
      try {
        console.log('start')
        this.connectionAttempts++;
        console.log(this.connectionAttempts)
        if(this.peer || this.connection || this.connectionAttempts >= 4) {
          resolve(null)
        }
        this.logSvc.info('ComputerObject - startConnection');
        const urls = await this.stunSvc.getURLS();
        
        this.peer = new Peer(null,{
          config: {
            'iceServers': [urls.v.iceServers],
            pingInterval: 500,
          
          },
          // @ts-ignore
          host: peerjsserver,
          secure: true,
        });

       
          this.ngZone.run( async() => {
            this.peer.on('open', this.handlePeerOpen.bind(this));
            this.peer.on('call', this.handlePeerCall.bind(this));
            this.peer.on('connection', this.handlePeerConnection.bind(this));
            this.peer.on('error', this.handlePeerError.bind(this));
            this.peer.on('disconnected', this.handlePeerDisconnected.bind(this));
            this.peer.on('close', this.handlePeerClose.bind(this));
          
            
            // update the data of the component
          });
          resolve(null);

      } catch(e) {
        console.log(e);
        this.logSvc.error(e);
        reject(e);
      }
    })
  }

  requestScreenStream(force?: boolean) {
    console.log('requestScreenStream')
    
    if(this.streamRequested ) {
      console.log('1')
      return;
    }
    if(window.electron) {
      console.log('2')
      return;
    }
    if(this.computerState == ComputerObjectState.ready && !force) {
      console.log('3')
      return;
    }
    if(this.calls.length && force) {
      console.log('4')
      this.setComputerState(ComputerObjectState.dataOnline);
      this.calls[0].close();
      this.calls = [];
    }
    console.log('5')
    this.streamRequested = true;
    console.log(this.calls);
    this.sendEvent(ComputerDataEventTypes.requestScreenStreams, this.authSvc.localUser.uid, {})
  }

  handleConnectionError(err: any) {
    this.logSvc.info('ComputerObject handleConnectionError');
    this.streams = [];
    this.connection = undefined;
    this.reconnect();
  }

  handleConnectionClose() {
    this.logSvc.info('ComputerObject handleConnectionClose');
    this.streams = [];
    this.connection = undefined;

    this.fire(ComputerObjectEvents.computerDisconnectionEvent);
    this.setComputerState(ComputerObjectState.disconnected);
  }

  async handleConnectionOpen(conn) {
    this.logSvc.info('ComputerObject handleConnectionOpen');
    this.connection.send('Hello!');
    //await this.sendReqLiveConfig();
    this.setComputerState(ComputerObjectState.dataOnline);
    setTimeout(this.checkConnection.bind(this), 5000);
  }

  async handlePeerCall(call: MediaConnection) {
    console.log('handlePeerCall')
    this.logSvc.info('ComputerObject handlePeerCall');
    if(this.calls.length && this.calls[0].open) {
      console.log('call already open')
      call.close();
      this.streamRequested = false;
      return;
    }
    
    console.log(call.metadata)
    console.log(JSON.parse(call.metadata.screens))
    if(call.metadata && call.metadata.screens) {
      this.screens = JSON.parse(call.metadata.screens);
      
    }

    call.answer(null,  {
      sdpTransform: modifySdp
    });
    
   
    this.calls.push(call);
    setTimeout(this.checkConnection.bind(this), 5000);
    

    call.on('stream', this.handleCallStream.bind(this));
    call.on('close', this.handleCallStreamClose.bind(this));
    call.on('error', this.handleCallStreamError.bind(this));
    this.streamRequested = false;
    
  }

  async handleRegotiation(rtc, evt) {
    this.logSvc.info('ComputerObject handleRegotiation');
    rtc.oldNegotiator(evt);
  }

  async handleNewTrack(rtc, evt) {
    this.logSvc.info('ComputerObject handleNewTrack');
    rtc.oldontrack(evt);
    //this.fire(ComputerObjectEvents.computerNewScreen, evt.streams[0]);
  }

  async handleCallStreamClose() {
    this.logSvc.info('ComputerObject handleCallStreamClose');
    this.streamRequested = false;
    // This could be a call closing because a newer call is coming in, maybe the monitor setup close. 
    //this.disconnectComputer()
  }

  async handleCallStreamError(err) {
    this.logSvc.info('ComputerObject handleCallStreamError' + JSON.stringify(err));
    this.disconnectComputer()
  }

  async handleCallStream(stream: MediaStream) {
    console.log('handleCallStream')
    this.logSvc.info('ComputerObject handleCallStream');
    console.log(stream);
    this.streams.push(stream);
    
    const allstreams = [];
    const videoTracks = stream.getVideoTracks();
    
   
    console.log(videoTracks)
    if( videoTracks.length >= 1 ) {
      let index = 0;
      for(let x = 0; x < videoTracks.length; x++) {
        let index = x;
        console.log(this.screens[index])
        console.log(videoTracks[index])
        //console.log(videoTracks[index].getCapabilities())
        const screen = videoTracks[index];
        const mediaStream1 = new MediaStream([screen]);
        // find the screen that matches the trackID and replace it with the new stream
        if(this.screens[index].trackId == screen.id) {
          this.screens[index].stream = mediaStream1;
        } else {
          // find the this.screens index with the right trackId
          index = this.screens.findIndex( (screen) => {
            return screen.trackId == videoTracks[index].id;
          })
          if(index == undefined || index == -1) {
            index = x;
          }
          console.log(index)
          console.log(this.screens);
          this.screens[index].stream = mediaStream1;
        }
        allstreams[index] = mediaStream1;
      }

      this.extraStreams = allstreams;
    }
    this.streamRequested = false;
    if(this.computerState != ComputerObjectState.ready) {
      this.setComputerState(ComputerObjectState.ready);
    }
    this.fire(ComputerObjectEvents.computerStreamSharedEvent, stream);
    this.fire(ComputerObjectEvents.computerScreensUpdated, stream);
  }

  handlePeerError(err: any) {
    this.logSvc.info('ComputerObject handlePeerError - ' + JSON.stringify(err));
    this.setComputerState(ComputerObjectState.disconnected);
  }

  handlePeerDisconnected() {
    this.logSvc.info('ComputerObject handlePeerDisconnected');
    this.fire(ComputerObjectEvents.computerDisconnectionEvent);
    this.setComputerState(ComputerObjectState.disconnected);
  }

  handlePeerClose() {
    this.logSvc.info('ComputerObject handlePeerClose');
    this.fire(ComputerObjectEvents.computerDisconnectionEvent);
    this.setComputerState(ComputerObjectState.disconnected);
  }

  async handleCommandData(data: any) {
    this.logSvc.info('ComputerObject handleCommandData');
    switch(data.type) {
      case ComputerDataEventTypes.screensUpdated:
        await this.reconnect();
        break;
      case ComputerDataEventTypes.mouseEvent:
        this.fire(ComputerObjectEvents.computerMouseEvent, data);
        break;
      case ComputerDataEventTypes.mouseLink:
        this.fire(ComputerObjectEvents.computerMouseEvent, data);
        break;
      case ComputerDataEventTypes.configShared:
        this.processConfigShare(data);
        break;
      case ComputerDataEventTypes.configUpdate:
        this.processConfigUpdated()
        break;

    }

  }

  processConfigUpdated() {
    if(this.computerState == ComputerObjectState.ready) {
      // Close 
      // Clean Up the current screens 
      this.requestScreenStream(true);
    }
    return;
  }

  processConfigShare(data) {
    console.log(this.computerState)
    const config = data.data;
    this.computer = data.data;
    this.fire(ComputerObjectEvents.computerProfileChanged, this.computer);
  }

  async handlePeerOpen(id: string) {
    
    this.logSvc.info('ComputerObject - handlePeerOpen' + id);
    this.connectUser();
    
  }

  async connectUser() {
    return new Promise( async (resolve, reject) => {
      try {
          this.logSvc.info('ComputerObject - connectUser');
        if(this.computer.peerId) {
          this.logSvc.info('ComputerObject - connectUser if peers' + this.computer.peerId);
          if(this.peer) {
            console.log(this.computer.peerId)
            this.connection = this.peer!.connect(this.computer.peerId);
            this.connection.on('open', this.handleConnectionOpen.bind(this));
            this.connection.on('data', this.handleConnectionData.bind(this));
            this.connection.on('error', this.handleConnectionError.bind(this));
            this.connection.on('close', this.handleConnectionClose.bind(this));
          } else {
            this.reconnect()
          }
          
          
          
          
        }
      } catch(e) {
        this.logSvc.error('connectUser Error' + e);
        reject(e);
      }
    })
    
  }

  async forceConnect() {
    this.logSvc.info('ComputerObject - connectUser if peers' + this.computer.peerId);
    this.connection = this.peer!.connect(this.computer.peerId);
    this.connection.on('open', this.handleConnectionOpen.bind(this));
    this.connection.on('data', this.handleConnectionData.bind(this));
    this.connection.on('error', this.handleConnectionError.bind(this));
    this.connection.on('close', this.handleConnectionClose.bind(this));
  }

  handlePeerConnection(conn: DataConnection) {
    this.logSvc.info('ComputerObject - handlePeerConnection');
    this.connection = conn;
    this.connection.on('data', this.handleCommandData.bind(this));
    this.setComputerState(ComputerObjectState.dataOnline);
    this.fire(ComputerObjectEvents.computerConnectionEvent);
    
  }

  handleConnectionData(data: any) {
    this.logSvc.info('ComputerObject - handleConnectionData');
    console.log(data);
    this.handleCommandData(data);
  }


  async getAllStreams(stream: MediaStream) {
    if(!stream) {
      if(this.streams[0]) {
        stream = this.streams[0];
      }
    }
    let extraStreams = [];
    if(stream) {
      for await (const screen of stream.getVideoTracks()) {
          
        const mediaStream1 = new MediaStream([screen]);
       
        extraStreams.push(mediaStream1);
      }
    }
    
    return extraStreams;
  }



};

