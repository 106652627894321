<div class="rightMenu" *ngIf="this.room.roomProfile.value"> 
    <div class="userRightMenu">
        <button type="button" mat-raised-button style="width: 100%;">
            <mat-icon class="mat-accent" style="margin-right: 5px;" (click)="shareRoom()">add_circle</mat-icon>
             Roster</button>
        <user-list-item *ngFor="let rosterItem of room.getSortedRoster(); let i = index; " [rosterItem]="rosterItem" [itemNum]="i"></user-list-item>
        <button type="button" mat-raised-button style="width: 100%;">Computers</button>
        <computer-list-item *ngFor="let roomcomputer of room.roomComputers$.value" [computer]="roomcomputer"></computer-list-item>
    </div>
    <div class="userRightMenu"> 
        <button class="mat-raised-button mat-accent" style="margin: 0px; width: 100%;" (click)="openAddComputerShareDialog()">
                 Add Computer
        </button>
    
    </div>
</div>


