import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Computer } from '../../../models/Computers';
import { ComputerAddModalComponent } from '../../popups/computer-add.modal/computer-add.modal.component';
import { AppDownloadModalComponent } from '../../popups/app-download-modal/app-download-modal/app-download-modal.component';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';

@Component({
  selector: 'computer-check',
  templateUrl: './computer-check.component.html',
  styleUrls: ['./computer-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComputerCheckComponent implements OnInit {
  computer$?: BehaviorSubject<any>;
  constructor(
    public logSvc: LogService,
    public authSvc: AuthService,
    public compServerSvc: ComputerServerService,
    public electronSvc: ElectronService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    console.log('computer-check')
    this.setupSubscriptions();
    this.compServerSvc.start();
  }

  openDownloadDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    if(!this.electronSvc.isDesktop()) {
      this.dialog.open(AppDownloadModalComponent, {
        width: '250px',
        enterAnimationDuration,
        exitAnimationDuration,
      });
    }
  }

  openComputerAddDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ComputerAddModalComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  setupSubscriptions() {
    this.computer$ = this.compServerSvc.server$;
    this.computer$.subscribe( (computer) => {
      if(!computer) {
        // If electron, and has registration? 
        // do nothing computer is registered
        if( this.authSvc.registering ) {
          this.openComputerAddDialog('200ms', '200ms');
          this.authSvc.registering = false;
        }
        
      } else {
        if( this.authSvc.registering) {
          this.openDownloadDialog('200ms', '200ms');
          this.authSvc.registering = false;
        }
        //if electron and no registration 
        // show registration dialog  
      }
    })

  }



}
