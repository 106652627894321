import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { RoomObject } from 'src/app/objects/roomObject';
import { AgoraService } from 'src/app/services/agora-service/agora-service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { UserAvatarItemComponent } from '../../user-avatar/user-avatar.component';

export type snackBarData = {
  room?: RoomObject,
  message: string, 
  userId?: string, 
  computerId?: string, 
  screenId?: string
}

@Component({
  selector: 'app-snackbar-notification',
  templateUrl: './snackbar-notification.component.html',
  styleUrls: ['./snackbar-notification.component.scss'],
  standalone: true,
  imports: [ MatButtonModule, MatSnackBarModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarNotificationComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef);
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: snackBarData,
    public authSvc: AuthService,
    public agoraSvc: AgoraService,

  ) { 
  }

  ngOnInit(): void {
  }

}
