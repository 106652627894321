import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Behavior } from 'aframe';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FriendItems } from '../../models/Friend';
import { AuthService } from '../auth-service/auth.service';
import { LogService } from '../log-service/log.service';
import * as moment from 'moment';
import { PublicProfile } from 'src/app/models/Users';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {
  friends: BehaviorSubject<FriendItems[]> = new BehaviorSubject([]);
  friendsCollection: AngularFirestoreCollection<FriendItems>;
  friendDoc: AngularFirestoreDocument<FriendItems>;
  newInvite$: Subject<FriendItems> = new Subject();
  liveInviteSub$: Observable<any>;
  constructor(private authSvc: AuthService, 
    private logSvc: LogService,
    private afs: AngularFirestore) {
    this.friendsCollection = this.afs.collection('friends');
    this.setupSubscriptions();
    this.initialFriends();
  }

  setupSubscriptions() {
    this.logSvc.info('FriendsService - setupSubscriptions');
    // this.liveInviteSub$ = this.afs.collection('friends', ref => ref.where('uid', '==', this.authSvc.user.value.uid)).stateChanges(['added']);

    // this.liveInviteSub$.subscribe((results) => {
    //   const newRecords: FriendItems[] = this.mapDocsToFriends([results]);
    //   newRecords.forEach((record) => {
    //     this.newInvite$.next(record);
    //   })
    // })
  }

  async initialFriends() {
    return new Promise( async (resolve, reject) => {

      try {
        this.authSvc.user.subscribe( (localuser) => {
          if(localuser) {
            let tmpRef = this.afs.collection('friends', ref => ref.where('uid', '==', this.authSvc.user.value.uid));
            tmpRef.get().subscribe((results) => {
              let tmpResults = this.mapDocsToFriends(results.docs);
              this.friends.next(tmpResults);
              resolve(tmpResults);
            })
          }
        })
        
      } catch(e) {
        this.logSvc.error('initialFriends - ' + e);
        reject(e);
      }
    })
  }

  getFriends(uid: string): Observable<FriendItems[]> {
    return this.afs.collection('friends', ref => ref.where('uid', '==', uid)).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as FriendItems;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  async addFriend(friend: FriendItems): Promise<void> {
    friend.creation = moment().unix();
    friend.lastContact = moment().unix();
    const checker = await this.afs.collection('friends', ref => ref.where('uid', '==', this.authSvc.user.value.uid).where('otherseruid', '==', friend.otherseruid));
    checker.get().subscribe( async (result) => {
      if(result.docs.length) {
        return;
      }
      const newFriend = await this.friendsCollection.add(friend);
      friend.fid = newFriend.id;
      await this.friendsCollection.doc(friend.fid).set(friend);
      const currentFriends = this.friends.value;
      currentFriends.push(friend);
      this.friends.next(currentFriends);
      // Now add the opposite Record 
      let otherFriendItem: FriendItems = {
        uid: friend.otherseruid,
        otherseruid: this.authSvc.localUser.uid,
        creation: moment().unix(),
        lastContact: moment().unix()
      }
      const otherFriendListItem = await this.friendsCollection.add(otherFriendItem);
      return 
    })
    
  }

  async deleteFriend(friend: FriendItems): Promise<void> {
    const friends = this.friends.value;
    const friendid = friends.findIndex( (cfriend) => {return cfriend.fid === friend.fid});
    friends.splice(friendid);
    this.friends.next(friends);
    await this.friendsCollection.doc(friend.fid).delete();
    return
  }

  findFriend(uid: string) {
    return this.friends.value.find( (friend) => { return friend.otherseruid == uid});
  }

  async getFriendProfile(uid: string): Promise<PublicProfile> {
    return new Promise( (resolve, reject) => {
      this.afs.doc(`publicProfiles/${uid}`).get().subscribe( (response) => {
        if(response.data) {
          let tmpProfile = this.mapProfile(response.data());
          resolve(tmpProfile);
        }
      })
    })
  }

  mapProfile(doc: any): PublicProfile {
    return doc as PublicProfile;
  }

  mapDocsToFriends(docs: Array<any>): FriendItems[] {
    const menuItems: FriendItems[] = [];
      docs.forEach(doc => {
        const rosterItem = doc.data() as FriendItems;
        rosterItem.fid = doc.id;
        menuItems.push(rosterItem);
      });
      return menuItems;
  }
}
