import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ServerPageComponent } from './pages/server.page/server.page.component';
import { SpacePageComponent } from './pages/space.page/space.page.component';
import { AuthGuard } from './services/auth-guard/auth.guard.service';


const routes: Routes = [
  {
    path: 'space/:spaceId',
    component: SpacePageComponent
  },
  {
    path: 'server/',
    component: ServerPageComponent
  },
  {
    path: 'computer/:computerId',
    component: ServerPageComponent, canActivate: [AuthGuard]
  },
  {
    path: 'main/',
    component: MainPageComponent
  },
  {
    path: '',
    component: MainPageComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
