import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RoomObject } from '../../../objects/roomObject';
import { UserObject } from '../../../objects/userObject';
import { UserObjectEvent } from '../../../objects/userObjectEnum';
import { defaultHUDConfig } from '../../../utils/defaultSpaceconfig';

@Component({
  selector: 'user-vector-space-handler',
  templateUrl: './user-vector-space-handler.component.html',
  styleUrls: ['./user-vector-space-handler.component.scss'],
  standalone: true,
  imports: [
		CommonModule,
	],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVectorSpaceHandlerComponent implements OnInit {
  @Input()
  user: UserObject;
  @Input()
  room: RoomObject;
  elements: HTMLElement[] =  [];
  spaceConfig:any = defaultHUDConfig;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.user.on(UserObjectEvent.userStateChanged, this.updateView.bind(this));
    // setTimeout( async () => {
    //   await this.drawVectorSpace();
    // }, 500);
  }

  async updateView() {
    if(this.user.streams.length) {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      
    }
  }


  async drawVectorSpace() {
    
    if(this.user.streams.length && this.spaceConfig.length) {
      let index = 0;
      // @ts-ignore
      this.spaceConfig = this.spaceConfig[0].screens[0];
      // @ts-ignore
      console.log(this.spaceConfig)
        const screenElem = document.createElement('a-curvedimage');
        screenElem.setAttribute('class', 'screensClass');
        screenElem.setAttribute('mixin', 'screensMixin');
        screenElem.setAttribute('position', this.spaceConfig.position);
        screenElem.setAttribute('rotation', this.spaceConfig.rotation);
        screenElem.setAttribute('width', this.spaceConfig.width);
        screenElem.setAttribute('height', this.spaceConfig.height);
        screenElem.setAttribute('muted', 'true');
        screenElem.setAttribute('margin', "0 0.25 0 0")
        screenElem.setAttribute('src', '#' + this.user.streams[0].id.toString());
        
        
        screenElem.setAttribute('theta-length', '90');
        screenElem.setAttribute('theta-start', '130');
        document.querySelector('#mainScene').appendChild(screenElem);
        this.elements.push(screenElem);
      
      

    }
    
  }

  

}
