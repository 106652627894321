import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { RoomObject } from '../../../objects/roomObject';
import { UserObject } from '../../../objects/userObject';
import { UserObjectEvent } from '../../..//objects/userObjectEnum';
import { AuthService } from '../../../services/auth-service/auth.service';
import { DeviceService } from '../../../services/device-service/device.service';
import { LogService } from '../../..//services/log-service/log.service';
import { StunServiceService } from '../../../services/stun-service/stun-service.service';

@Component({
  selector: 'profile.modal',
  templateUrl: './profile.modal.component.html',
  styleUrls: ['./profile.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileModalComponent implements OnInit {
  @Input()
  user: UserObject;
  @Input()
  room: RoomObject;
  @Input()
  cropping = false;
  @Input()
  choosePic = false;
  @Input() 
  nameEditors = false;
  noProfileName = '';
  cropperReadiness = false;
  @ViewChild('username') usernameInput: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {user: UserObject, room?: RoomObject},
    public dialogRef: MatDialogRef<ProfileModalComponent>,
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public afs: AngularFirestore,
    public logSvc: LogService,
    public devSvc: DeviceService,
    public stunSvc: StunServiceService,
    public zone: NgZone,
  ) { 
    
    dialogRef.disableClose = true;
    
    if(this.data && this.data.user) {
      this.user = this.data.user;
    }
    
    if(!this.user) {
      // If there is no real user, use yourself
      this.user = this.authSvc.localUser;
      this.user.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this));
    }
  }

  ngOnInit(): void {
   
  }

  editName() {
    this.nameEditors = !this.nameEditors;
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  clickChoosePic() {
    this.choosePic = !this.choosePic;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async imageToDataUri(img, width, height): Promise<string> {
    return new Promise( (resolve, reject) => {
      // We create an image to receive the Data URI
      var tmpimg = document.createElement('img')

      // When the event "onload" is triggered we can resize the image.
      tmpimg.onload = function()
      {        
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas');
          // @ts-ignore
          var ctx = canvas.getContext('2d');

          // We set the dimensions at the wanted size.
          canvas["width"] = width;
          canvas["height"] = height;

          // We resize the image with the canvas method drawImage();
          // @ts-ignore
          ctx.drawImage(this, 0, 0, width, height);
          // @ts-ignore
          var dataURI = canvas.toDataURL();

          /////////////////////////////////////////
          // Use and treat your Data URI here !! //
          /////////////////////////////////////////
          resolve(dataURI);
      };

      tmpimg["src"] = img;
    })

  }

  startCropping() {
    this.cropping = true;
  }

  imageChangedEvent: any = '';

    croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.cropping = true;
    this.choosePic = true;
    this.imageChangedEvent = event;
    this.updateView();
  }

    async imageCropped(event: ImageCroppedEvent) {
      try {

        if(!this.cropperReadiness) {
          return;
        }
        const newImage: string = await this.imageToDataUri(event.base64, 64, 64);
        this.croppedImage = newImage;
        await this.user.updateUserProfileRecord({avatar: newImage});
        this.imageChangedEvent = null;
        this.cropperReadiness = false;
        setTimeout(() => {
          this.updateView();
        }, 2000)
        //this.cropping = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      } catch(e) {
        const newImage = await this.imageToDataUri(event.base64, 64, 64);
     
        alert('Image is too large');
      }
        
    }
    imageLoaded(image: LoadedImage) {
      console.log('image loaded')
        // show cropper
      
    }
    cropperReady() {
      this.cropperReadiness = true;
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    closeMe() {
      this.dialogRef.close();
    }

    nameChange(evt) {
      console.log(evt);
    }

    async saveProfile() {
      if(this.usernameInput || this.user.profile.name) {
        let username = this.user.profile?.name || '';
        if(this.usernameInput) {
          username = this.usernameInput.nativeElement.value;
        }
            await this.user.updateUserProfileRecord({name: username, avatar:  this.croppedImage});
            await this.authSvc.reload();
            this.dialogRef.close()

       
      } else {
        console.log('saveProfile3')
        alert('Please add a username.');
      }
    }

 

  

}
