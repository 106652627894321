import { Injectable } from '@angular/core';
import { AudioCue } from './AudioCues';

@Injectable({
  providedIn: 'root',
})
export class AudioCueService {
  private audio: HTMLAudioElement;

  constructor() {
    this.audio = new Audio();
  }

  playAudio(src: AudioCue): void {
    this.audio.src = src;
    this.audio.play();
  }

  pauseAudio(): void {
    this.audio.pause();
  }

  stopAudio(): void {
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
