<div class="profile-header-row mat-border-container " *ngIf="userObject && userObject.profile">
    <div class="left-side-listing" >
        <user-avatar *ngIf="!(userObject && userObject.profile)" [name]="name" [user]="userObject"></user-avatar>
        <user-avatar *ngIf="userObject && userObject.profile" [name]="userObject.profile.name" [img]="userObject.profile.avatar" [user]="userObject"></user-avatar>
     </div>
     <div class="right-side-listing">
        <div class="right-side-ontop">
            
                <div class="right-side-full" >
                    {{ name}}
                </div>
            
            <div class="right-side-full">
                <div class="right-side-actions" *ngIf="rosterItem && rosterItem.userObject && rosterItem.userObject.state === 'Ready'">
                    <mat-icon [ngClass]="{'mat-accent' : rosterItem.userObject.state === 'Ready'}" >supervised_user_circle</mat-icon>
                </div>
                <div class="right-side-actions" *ngIf="rosterItem && rosterItem.userObject.state === 'Ready'">
                    <mat-icon [id]="'mute-usr-' + rosterItem.userObject.uid" [ngClass]="{'mat-warn' : rosterItem.userObject.muted || rosterItem.userObject.localMute}" [matTooltip]="rosterItem.userObject.agoraAutoTrack ? 'Microphone' : 'User has muted themselves'" [matTooltipHideDelay]="1000" (click)="clickMuteToggle()">{{rosterItem.userObject.muted ? 'mic_off' : 'mic'}}</mat-icon>
                </div>
                <!-- <div class="right-side-actions" *ngIf="rosterItem.userObject.state === 'Ready'">
                    <mat-icon [matTooltip]="'Video'" [matTooltipHideDelay]="1000" (click)="clickVideoToggle()">video_call</mat-icon>
                </div> -->
                <div class="right-side-actions" *ngIf="rosterItem && rosterItem.userObject.state === 'Ready'">
                    <mat-icon [id]="'screenshare-usr-' + rosterItem.userObject.uid" class="mat-accent"> screen_share</mat-icon>
                </div>
                <div class="right-side-actions" *ngIf="rosterItem && rosterItem.uid != this.authSvc.localUser.uid" (click)="addFriend(rosterItem.userObject)">
                    <mat-icon [id]="'add-usr-' + rosterItem.userObject.uid" class="mat-accent"> add_circle</mat-icon>
                </div>
            </div>
       </div>
        
     </div>
</div>
