import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ComputerObject } from '../../objects/computerObject';
import { RoomEvents } from '../../objects/roomEnums';
import { RoomObject } from '../../objects/roomObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';

@Component({
  selector: 'computer-flat-screen-small',
  templateUrl: './computer-flat-screen-small.component.html',
  styleUrls: ['./computer-flat-screen-small.component.scss'],
})
export class ComputerFlatScreenSmallComponent implements OnInit {
  @Input()
  videoStream: MediaStream;
  @Input()
  spaceConfig: any;
  @Input()
  room: RoomObject;
  @Input()
  computer: ComputerObject;
  @Input()
  index: number;
  element: HTMLElement;
  constructor(
    public authSvc: AuthService,
    public logSvc: LogService
  ) { }

  ngOnInit(): void {
    this.logSvc.info('ComputerFlatScreenSmallComponent - Init');
  }


  async drawVectorSpace() {
    if(this.spaceConfig) {
      const screenElem = document.createElement('a-curvedimage');
      screenElem.setAttribute('class', 'screensClass');
      screenElem.setAttribute('mixin', 'screensMixin');
      screenElem.setAttribute('position', this.spaceConfig[this.index].position);
      screenElem.setAttribute('rotation', this.spaceConfig[this.index].rotation);
      screenElem.setAttribute('width', this.spaceConfig[this.index].width);
      screenElem.setAttribute('height', this.spaceConfig[this.index].height);
      screenElem.setAttribute('muted', 'true');
      screenElem.setAttribute('margin', "0 0.25 0 0")
      screenElem.setAttribute('src', '#' + this.videoStream.id.toString());
      screenElem.setAttribute('theta-length', '90');
      screenElem.setAttribute('theta-start', '130');
      document.querySelector('#mainScene').appendChild(screenElem);
      this.element = screenElem;

    }
    
  }


  async clickScreen() {
    
    if(this.room) {
      this.room.fire(RoomEvents.screenFocused, {screenId: this.computer.screens[this.index].id, stream: this.videoStream, computer: this.computer});
      //await this.computer.sendUserViewStart(this.authSvc.user.value.uid, this.computer.screens[this.index].id, this.room.roomId);
    }
  }

  ngOnDestroy() {
    // if(this.element) {
    //   this.element.remove();
    //   delete this.element;
    // }
  }



}
