import { AngularFirestore, AngularFirestoreDocument, DocumentData } from "@angular/fire/compat/firestore";
import { EventEmitter } from "@billjs/event-emitter"
import Peer, { MediaConnection } from "peerjs";
import { BehaviorSubject, Observable } from "rxjs";
import { ScreenConfig, ScreenType } from "../models/Screens";
import { AuthService } from "../services/auth-service/auth.service";
import { Room, RoomRoster, RoomType } from '../models/Room';
import { HelpRoomConfig, RoomEvents, RoomState } from "./roomEnums";
import * as moment from 'moment';
import { ComputerObjectEvents, ComputerShares } from "./computerEnum";
import { ComputerService } from "../services/computer-service/computer.service";
import { ComputerObject } from "./computerObject";
import { UserRoomServer } from "./UserRoomServer";
import { LogService } from "../services/log-service/log.service";
import { UserObject } from "./userObject";
import { DeviceService } from "../services/device-service/device.service";
import { defaultHUDConfig, defaultSpaceConfig } from "../utils/defaultSpaceconfig";
import { NgZone } from "@angular/core";
import { StunServiceService } from "../services/stun-service/stun-service.service";
import { RoomService } from "../services/room.service/room.service";
import { UserObjectEvent, UserObjectState } from "./userObjectEnum";
import * as THREE from 'three';
import { AgoraService } from "../services/agora-service/agora-service";
import { NotificationService } from "../services/notification-service/notification-service.service";
import { AudioCue } from "../services/AudioCueService/AudioCues";
import { MenuService } from "../services/menu-service/menu.service";
import { MenuTypes } from "../models/Menu";

export class RoomObject extends EventEmitter {
    roomProfile?: BehaviorSubject<Room | null> = new BehaviorSubject(null);
    roomRef: AngularFirestoreDocument;
    roomRoster$: Observable<any>; // This is subscription to roster collection new additions 
    roomId: string;
    initializeRoom?: Room;
    roomState: RoomState = RoomState.init;
    roster$: BehaviorSubject<RoomRoster[]> = new BehaviorSubject([]);
    onlineRoster$: BehaviorSubject<RoomRoster[]> = new BehaviorSubject([]);
    computerShares$: BehaviorSubject<ComputerShares[]> = new BehaviorSubject([]); 
    peer: Peer;
    roomUserServer: UserRoomServer;
    calls: MediaConnection[] = []; // calls from other users
    streams: MediaStream[] = [];
    availableScreens: BehaviorSubject<ScreenConfig[]> = new BehaviorSubject([]);
    activeScreens: BehaviorSubject<ScreenConfig[]> = new BehaviorSubject([]);
    computers$: BehaviorSubject<ComputerObject[]>;
    roomComputers$: BehaviorSubject<ComputerObject[]> = new BehaviorSubject([]);
    spaceConfigs = defaultSpaceConfig;
    HUDScreens: BehaviorSubject<ScreenConfig[]> = new BehaviorSubject([]);
    focus$: BehaviorSubject<{screenId?: string, stream?: MediaStream, streamIndex?: number,video?: string, title?: string, computer?: ComputerObject, user?: UserObject, spaceConfig?: any}[]> = new BehaviorSubject([]);
    created: number;
    constructor(room: string | Room, 
        public afs: AngularFirestore,
        public authSvc: AuthService,
        public roomRoster: RoomService,
        public dvcSvc: DeviceService,
        public menuSvc: MenuService,
        public computerSvc: ComputerService, 
        public agoraSvc: AgoraService,
        public stunSvc: StunServiceService, 
        private ngZone: NgZone, 
        public notificationSvc: NotificationService,
        public logSvc: LogService ) {
        super();
        this.roomId = (typeof room === 'string') ? room : room.rid;
        if( typeof room === 'object') {
            this.initializeRoom = room;
        }
        this.initialize();
    }

    async initialize() {
        return new Promise( async ( resolve, reject ) => {
            try {
                this.roomRef = this.afs.doc(
                    `room/${this.roomId}`
                  );
                this.created = moment.now();
                await this.setState(RoomState.init);
               
                resolve(this);
            } catch(e) {
                reject(e);
            }
        })
    }

    async setState(state: RoomState) {
        return new Promise( async (resolve, reject) => {
            try {
                // Logger 
                this.fire(RoomEvents.roomStateChange, state);
                this.roomState = state;
                switch(state) {

                    case RoomState.init:
                        // @ts-ignore
                        spatialwindowservice.roomInitiate(this);
                        //await this.clearForInit();
                        await this.checkRoomAndJoin();
                        await this.setupRoomServer();
                        await this.getScreenShares();

                        
                        this.setState(RoomState.loading);
                        
                        break;
                    case RoomState.loading:
                        
                        await this.setupSubscriptions();
                        this.setState(RoomState.p2pSetup);
                        
                        break;
                    case RoomState.p2pSetup:
                        await this.setupComputers();
                        await this.setupAgora();
                        this.setState(RoomState.rosterLoad);
                        
                        break;
                    case RoomState.rosterLoad:
                        // @ts-ignore 
                        
                        this.setState(RoomState.ready);
                        
                        break;
                    case RoomState.ready:
                        
                        break;
                    
                }
                resolve(null);
            } catch(e) {
                reject(e);
            }
        })
    }

    async setupRoomServer() {
        return new Promise( async (resolve, reject) => {
            try {
                if(!this.roomUserServer) {
                    this.roomUserServer = new UserRoomServer(this.authSvc.user.value.uid, this.roomId, this, this.authSvc, this.logSvc, this.dvcSvc, this.stunSvc, this.afs);
                }
                resolve(this);
            } catch(e) {

            }
        })
    }

    async agoraEventHandler(evt) {
        
        if(evt.type == 'user-joined') {
            this.fire(RoomEvents.UserJoin, evt.user.uid);
        }

        if(evt.type == 'user-left') {
            this.fire(RoomEvents.UserStateChange, evt.user.uid);
        }

    }

    clearForInit() {
        this.roomProfile.next(null);

    }

    async setupSubscriptions() {
        return new Promise(async (resolve, reject) => {
            try {
                let that = this;
                this.agoraSvc.agoraEvents$.subscribe(this.agoraEventHandler.bind(this))
                // Subscribe to the room details change or the roster updates
                this.roomRoster$ = this.roomRef.collection('roster').stateChanges(['added']) //
                this.roomRoster$.subscribe( (newDocChange) => {
                    // Send all docs to the rosterJoin function
                    newDocChange.forEach( (doc) => {
                        const rosterupdate: RoomRoster = doc.payload.doc.data();
                        this.rosterJoin(rosterupdate);
                    })
                })

                this.computers$ = this.computerSvc.computers$;
                

                this.roomRef.collection('screens').stateChanges(['added']).subscribe( (share) => {
                    // New Computer Share Added 
                    if(share.length) {
                        let tmpShares = this.computerShares$.value;
                        let record = share[0].payload.doc.data() as ComputerShares;
                        let finder = this.computerShares$.value.find( (value) => value.cid === record.cid);
                        if(!finder) {
                            tmpShares.push(record);
                            this.computerShares$.next(tmpShares);
                        }
                    }
                })

                this.computerShares$.subscribe( async (shares) => {
                        let tmp: ComputerObject[] = this.roomComputers$.value;
                        for await (const share of shares) {
                            let tmpComputer = await this.computerSvc.getExternalComputer(share.cid, share.ownerId);
                            if(tmpComputer) {
                                tmp.push(tmpComputer);
                            }
                        }
                        this.roomComputers$.next(tmp);
                });
                resolve(null);
            } catch(e) {
                reject(e);
            }
        })
    }

    async addComputerShare(cid: string, owner: string): Promise<ComputerShares> {
        let tmpRecord: ComputerShares = {
            cid,
            roomId: this.roomId,
            ownerId: owner,
            access: 'View',
            created: moment.now()
        }
        await this.roomRef.collection('screens').doc(cid).set(tmpRecord);
        return tmpRecord;
    }

    async rosterJoin(rosterItem: RoomRoster) {
        let findRoster = this.roster$.value.find( (value) => value.uid === rosterItem.uid);
        if(!findRoster) {
            if(this.authSvc.localUser.uid == rosterItem.uid) {
                rosterItem.userObject = this.authSvc.localUser;
            } else {
                rosterItem.userObject = new UserObject(rosterItem.rid, rosterItem.uid, this.afs, this.logSvc,this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngZone);
                rosterItem.userObject.on(UserObjectEvent.userStateChanged, this.userStateChange.bind(this));
            }
            
            this.fire(RoomEvents.UserJoin, rosterItem);
            let tmpRoster = this.roster$.value;
            tmpRoster.push(rosterItem);
            this.roster$.next(tmpRoster);
            return 
        } else {
            if(!findRoster.userObject) {
                if(this.authSvc.localUser.uid == rosterItem.uid) {
                    rosterItem.userObject = this.authSvc.localUser;
                } else {
                    rosterItem.userObject = new UserObject(rosterItem.rid, rosterItem.uid, this.afs, this.logSvc,this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngZone);
                    rosterItem.userObject.on(UserObjectEvent.userStateChanged, this.userStateChange.bind(this));
                }
            } else {
                rosterItem.userObject = new UserObject(rosterItem.rid, rosterItem.uid, this.afs, this.logSvc,this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngZone);
                rosterItem.userObject.on(UserObjectEvent.userStateChanged, this.userStateChange.bind(this));
            }
            this.fire(RoomEvents.UserJoin, rosterItem);
        } 
    };

    async userStateChange(event) {
        // Setup to add or remove users from online users array 
        let tmpOnlineRoster = this.onlineRoster$.value;
        let finder = tmpOnlineRoster.find( (value) => value.uid === event.data.uid);
        const rosterFinder = this.roster$.value.find( (value) => value.uid === event.data.uid);
        if(event.data.state === UserObjectState.ready) {
            // Update the online roster
            if(!finder) {
                tmpOnlineRoster.push(rosterFinder);
                this.onlineRoster$.next(tmpOnlineRoster);
            }
        }
        // else if disconnected then ensure they are removed from the tmpOnlineRoster
        else if (event.data.state === UserObjectState.disconnected) {
            if(finder) {
                let index = tmpOnlineRoster.indexOf(finder);
                tmpOnlineRoster.splice(index, 1);
                this.onlineRoster$.next(tmpOnlineRoster);
            }
        }
        this.fire(RoomEvents.UserStateChange, event);
    }

    async checkRoomAndJoin(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                
                  this.roomRef.get().subscribe( async (docSnapshot) => {
                    const roomData = docSnapshot.data() as Room;
                    console.log(roomData);
                    if(roomData) {
                        await this.getRoster();
                        this.roomProfile.next(roomData);
                        this.fire(RoomEvents.roomProfileChanged, this.roomProfile.value);
                        await this.joinRoom();
                      } else {
                        this.roomProfile.next(roomData);
                        await this.createRoom();
                        await this.joinRoom();
                      }
                      resolve()
                  })
            } catch(e) {
                reject(e);
            }
        })
    }

    async createRoom(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const roomName = (this.initializeRoom) ? this.initializeRoom.name : this.roomId;
                const tmpRoom = {
                    rid: this.roomId,
                    name: roomName,
                    ownerId: this.authSvc.user.value.uid,
                    roomType: RoomType.temp,
                    created: moment().unix(),
                    lastUpdate: moment().unix(),
                  };
                  await this.roomRef.set(tmpRoom);
                  this.roomProfile.next(tmpRoom);
                  this.fire(RoomEvents.roomCreated, tmpRoom);
                  this.fire(RoomEvents.roomProfileChanged, tmpRoom);
                  resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    async joinRoom(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {

                await this.checkRoomMenuAdd();
                // Join Room if user is not already there 
                let tmpmyRoster = this.roster$.value.find( (value) => value.uid === this.authSvc.user.value.uid) 

                if(!tmpmyRoster) {
                    //NewRosterItem
                    const newRosterItem: RoomRoster = {
                        uid: this.authSvc.user.value.uid,
                        rid: this.roomId,
                        lastUpdate: moment().unix(),
                        created: moment().unix(),
                
                    };
                    const addRecord = await this.addRosterRecord(newRosterItem);
                    if(this.authSvc.localUser.uid == newRosterItem.uid) {
                        newRosterItem.userObject = this.authSvc.localUser;
                    } else {
                        newRosterItem.userObject = new UserObject(newRosterItem.rid, newRosterItem.uid, this.afs, this.logSvc,this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngZone);
                    }
                }
                await this.getScreenShares();
                //No need to do this as the subscriptions for the colleciton should bounce
                //const result = await this.addRoster(newRosterItem);
                this.notificationSvc.sendAudioNotification(AudioCue.ROOM_JOIN);
                this.fire(RoomEvents.roomJoined, this);
                resolve(null);
            } catch(e) {
                reject(e);
            }
      })
    }

    async checkRoomMenuAdd() {
        return new Promise( async (resolve, reject) => {
            try {
                const tmpMenu = await this.menuSvc.checkRoomMenuItem(this.roomId);
                if(!tmpMenu) {
                    this.menuSvc.addMenuItem({
                        mid: this.roomId,
                        uid: this.authSvc.user.value.uid,
                        title: this.roomProfile.value.name,
                        type: MenuTypes.room,
                    })
                    
                }
                resolve(tmpMenu);
            } catch(e) {
                reject(e);
            }
        })
    }

    async getScreenShares(): Promise<ComputerShares[]> {
        return new Promise( async (resolve, reject) => {
            try {
                this.roomRef.collection('screens').get().subscribe( async (querySnapshot) => {
                
                    const data = querySnapshot.docs;
                    const mapResult = this.mapComputerShares(data);
                    const finder = this.computerShares$.value.find( (value) => value.cid === mapResult[0].cid);
                    if(!finder) {
                        this.computerShares$.next(mapResult);
                        this.fire(RoomEvents.roomComputerShareUpdated, mapResult);
                    }
                    
                    resolve(mapResult);
                })
            } catch(e) {
                reject(e);
            }
        });
    }

    async setupComputers() {
        return new Promise( (resolve, reject) => {
            this.computerShares$.subscribe( async (shares) => {
                //if(this.roomState != RoomState.ready) {
                    let tmp: ComputerObject[] = this.roomComputers$.value;
                    for await (const share of shares) {
                        let tmpComputer = await this.computerSvc.getExternalComputer(share.cid, share.ownerId);
                        if(tmpComputer) {
                            const finder = tmp.find( (value) => value.cid === tmpComputer.cid);
                            if(!finder) {
                                tmp.push(tmpComputer);
                            }
                        }
                    }
                    this.roomComputers$.next(tmp);
                    resolve(this.roomComputers$.value);
                //}
                
            });
        })
    }

    async calcAvailableScreens(): Promise<ScreenConfig[]> {
        return new Promise(async (resolve, reject) => {
            try {
                setTimeout( async () => {
                    let tmpScreens: ScreenConfig[] = [];
                    tmpScreens.push(HelpRoomConfig);
                    await this.roomComputers$.value.forEach( async (computer) => {
                        computer.streams.forEach( (stream, index) => {
                            let newScreen: ScreenConfig = {
                                name: '[' + index + ']' + computer.name,
                                screenType: ScreenType.computer,
                                stream: stream
                            }
                            tmpScreens.push(newScreen);
                        })
                    })
                    this.availableScreens.next(tmpScreens);
                    this.fire(RoomEvents.roomUpdatedAvailableScreens, tmpScreens);
                    resolve(tmpScreens);
                }, 2000);
                
                
            } catch(e) {
                reject(e);
            }
        })
    }

    async getRoster(): Promise<RoomRoster[]> {
        return new Promise( async (resolve, reject) => {
            try {
                this.roomRef.collection('roster').get().subscribe( async (querySnapshot) => {
                    const data = querySnapshot.docs;
                    const mapResult = this.mapRoomRoster(data);
                    this.roster$.next(mapResult);
                    this.broadcastRosterUpdate(mapResult);
                    resolve(mapResult);
                })
            } catch(e) {
                reject(e);
            }
        });
    }

    broadcastRosterUpdate(roster: RoomRoster[]) {
        const sortedroster = this.sortRoomRosterByUserObjectState(roster);
        this.roster$.next(sortedroster);
        this.fire(RoomEvents.roomRosterChange, sortedroster);
    }

    sortRoomRosterByUserObjectState(roomRoster: RoomRoster[]) {
        return roomRoster.sort((a, b) => {
            const userObjectA = a.userObject;
            const userObjectB = b.userObject;
            if (!userObjectA || !userObjectB) {
                return 0;
            }
            if (userObjectA.state === UserObjectState.ready && userObjectB.state !== UserObjectState.ready) {
                return -1;
            }
            if (userObjectA.state !== UserObjectState.ready && userObjectB.state === UserObjectState.ready) {
                return 1;
            }
            return 0;
        });
    }

    getSortedRoster() {
        const roster = this.roster$.value;
        return roster.sort((a, b) => {
            const userObjectA = a.userObject;
            const userObjectB = b.userObject;
            if (!userObjectA || !userObjectB) {
                return 0;
            }
            if (userObjectA.state === UserObjectState.ready && userObjectB.state !== UserObjectState.ready) {
                return -1;
            }
            if (userObjectA.state !== UserObjectState.ready && userObjectB.state === UserObjectState.ready) {
                return 1;
            }
            return 0;
        });
    }

    mapRoomRoster(data: any): RoomRoster[] {
        let tmpArray = this.roster$.value;
        data.forEach((doc)=> {
            
            let tmpRosterItem: RoomRoster = doc.data();
            let findRoster = this.roster$.value.find( (value) => value.uid === tmpRosterItem.uid);
            
            if(!findRoster) {
                if(this.authSvc.localUser.uid == tmpRosterItem.uid) {
                    tmpRosterItem.userObject = this.authSvc.localUser;
                } else {
                    tmpRosterItem.userObject = new UserObject(tmpRosterItem.rid, tmpRosterItem.uid, this.afs, this.logSvc,this.dvcSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.ngZone);
                }
                
                tmpArray.push(tmpRosterItem)
            }
            
        })
        return tmpArray;
    }

    mapComputerShares(data: any): ComputerShares[] {
        let tmpArray = [];
        data.forEach((doc)=> {
            tmpArray.push(doc.data() as ComputerShares)
        })
        return tmpArray;
    }
    
      
      addRosterRecord(item: RoomRoster): Promise<RoomRoster> {
        return new Promise(async (resolve, reject) => {
            try {
                const tmpRoster = JSON.parse(JSON.stringify(item)) as RoomRoster;
                
                //copiedRoster.userObject = null;
                //Add to firestore under the room
                let result = await this.roomRef.collection('roster').doc(item.uid).set(tmpRoster);
                resolve(item);

            } catch(e) {
                reject(e);
            }
        })
      }
    
      addFocus(focusDefinition: {screenId?: string, stream?: MediaStream, streamIndex?: number, video?: string, title?: string, computer?: ComputerObject, user?: UserObject, spaceConfig?: any;}) {
        try {
            if(focusDefinition.computer) {
                const findFocus = this.focus$.value.find( (focusDef) => {return focusDef.stream?.id == focusDefinition.stream?.id || focusDefinition.screenId == focusDef.screenId});
                if(findFocus) {
                    return;
                }
            }
            if(!focusDefinition.spaceConfig) {
                if(focusDefinition.computer) {
                    focusDefinition.spaceConfig = focusDefinition.computer.screens[focusDefinition.streamIndex];
                }
            }
            focusDefinition.spaceConfig = this.getSpaceConfig(focusDefinition.spaceConfig);
            const tmpFocus = this.focus$.value;
            if(focusDefinition.computer) {
                this.fire(RoomEvents.screenFocused, {streamIndex: 0, stream: focusDefinition.computer.streams[0], computer: focusDefinition.computer});
            }
            if(focusDefinition.user) {
                this.fire(RoomEvents.screenFocused, {streamIndex: 0, stream: focusDefinition.user.streams[0], user: focusDefinition.user});
            }
            tmpFocus.push(focusDefinition);
            this.focus$.next(tmpFocus);
        } catch (error) {
            this.logSvc.error('roomObject - addFocus ' + error);
        }
    }
    

    getSpaceConfig(spaceConfig) {
        const cameraPos = new THREE.Vector3(...'0 1 0'.split(' ').map(Number));
        if (!spaceConfig) {
            
            const defaultPos = new THREE.Vector3(cameraPos.x, cameraPos.y, cameraPos.z - 1);
            spaceConfig = {
              position: `${defaultPos.x} ${defaultPos.y + .5} ${defaultPos.z}`,
              rotation: '0 0 0',
              width: '1',
              height: '2',
              loadingSrc: '#loadLogo',
              src: '#loadLogo'
            };
        }
      
        const focusDefinitions = this.focus$.value;
        const cameraPosition = cameraPos;
        const cameraPosVec3 = new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      
        for (let i = 0; i < focusDefinitions.length; i++) {
            const focusDef: any = focusDefinitions[i];
            const focusSpaceConfig = focusDef.spaceConfig;
      
            if (!focusSpaceConfig) {
                continue;
            }
      
            const focusPosStr = focusSpaceConfig.position;
            const focusPosVec3 = new THREE.Vector3(...focusPosStr.split(' ').map(Number));
            
            const distanceX = Math.abs(cameraPosVec3.x - focusPosVec3.x);
            const distanceY = Math.abs(cameraPosVec3.y - focusPosVec3.y);
            const overlappingX = distanceX < (parseFloat(spaceConfig.width) + parseFloat(focusSpaceConfig.width));
            const overlappingY = distanceY < (parseFloat(spaceConfig.height) + parseFloat(focusSpaceConfig.height));
              
            if (overlappingX && overlappingY) {
            // There is an overlap, calculate new position
            
            // Calculate new position to the right
            const rightPosVec3 = focusPosVec3.clone();
            rightPosVec3.x += + spaceConfig.width;
            
            // Calculate new position to the left
            const leftPosVec3 = focusPosVec3.clone();
            leftPosVec3.x -= + spaceConfig.width;
            
            // Check which new position is farther from the camera
            const distanceToRight = cameraPosVec3.distanceTo(rightPosVec3);
            const distanceToLeft = cameraPosVec3.distanceTo(leftPosVec3);
            const newFocusPosVec3 = distanceToRight > distanceToLeft ? rightPosVec3 : leftPosVec3;
        
            spaceConfig = {
              ...focusSpaceConfig,
              position: `${newFocusPosVec3.x} ${newFocusPosVec3.y + 0.4} ${newFocusPosVec3.z}`
            };
        
          }
        }
        
        // No overlap found, return original space config
        return spaceConfig;
      }
      

    removeFocus(index: number) {
        let tmpFocus = this.focus$.value;
        if(index === 0) {
            if(tmpFocus.length === 1) {
                tmpFocus.splice(index, 1);
            } else {
                tmpFocus.splice(index, 1);
            }
        } else {
            tmpFocus.splice(index, 1);
        }

        this.focus$.next(tmpFocus);
        this.fire(RoomEvents.removeFocused, index);
        
        return;
    }
    
    registerScreen(mediastream: MediaStream, config?: any) {
        const totScreens = this.activeScreens.value.length;
        const tmpScreens = this.activeScreens.value;
        const tmpConfig = {
            ...config,
            src: '#' + mediastream.id,
            vectorProps: this.spaceConfigs[totScreens].screens[totScreens]

        } as ScreenConfig;
        tmpScreens.push(tmpConfig);
        this.activeScreens.next(tmpScreens);
        return tmpConfig;
    }

    registerHUDScreen(mediastream, config?: any) {
        const totScreens = this.HUDScreens.value.length;
        const tmpScreens = this.HUDScreens.value;
        const spacing = defaultHUDConfig[0].screens[0];
        let xspacing = -1.5 + (totScreens * 2.1);
        spacing.position = xspacing.toString() + " 1.1 -2";
        const tmpConfig = {
            ...config,
            src: '#' + mediastream.id,
            vectorProps: spacing

        } as ScreenConfig;
        tmpScreens.push(tmpConfig);
        this.HUDScreens.next(tmpScreens);
        return tmpConfig;
    }

    async setupAgora() {
        return new Promise( async (resolve, reject) => {
            try {
                await this.agoraSvc.joinChannel(this.roomId);
                resolve(null);
            } catch(e) {
                reject(e);
            }
        })
    }

}