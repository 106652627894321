import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})

export class ForgotPasswordDialogComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private fb: FormBuilder,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  sendPasswordResetEmail() {
    const email = this.forgotPasswordForm.value.email;
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      alert('Password reset email should arrive shortly.')
      this.dialogRef.close();
    }).catch((error) => {
      console.error(error);
    });
  }
}
