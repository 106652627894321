import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { LogService } from 'src/app/services/log-service/log.service';

@Component({
  selector: 'audio-input-modal',
  templateUrl: './audio-input.component.html',
  styleUrls: ['./audio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioInputComponent implements OnInit {
  selected: any = 'default';
  constructor(
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public logSvc: LogService,
    public deviceSvc: DeviceService,
    public dialogRef: MatDialogRef<AudioInputComponent>) {}

  async ngOnInit(): Promise<void> {
    this.deviceSvc.availableDevices$.subscribe(this.updateView.bind(this));
    await this.deviceSvc.getAvailableMediaDevices();
    return;
  }

  updateView() {
    
    // if(this.deviceSvc.availableDevices$.value.length) {
    //   this.deviceSvc.availableDevices$.value.forEach( (device) => {
    //     if()
    //   })
    // }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  updateDevice() {

  }

  dismiss() {
    this.dialogRef.close();
  }

}
