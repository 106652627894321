import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { APP_CONFIG } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DialogSignIn } from './components/popups/login.modal/login.modal.component';
import { MatDialog, MatDialogRef, MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle, } from '@angular/material/dialog';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import {MatDividerModule} from '@angular/material/divider';
import { ComputerAddModalComponent } from './components/popups/computer-add.modal/computer-add.modal.component';
import { ComputerCheckComponent } from './components/computer-check/computer-check/computer-check.component';
import { AppDownloadModalComponent } from './components/popups/app-download-modal/app-download-modal/app-download-modal.component';
import { LeftMenuComponent } from './components/left-menu/left-menu/left-menu.component';
import { AddRoomDialogComponent } from './components/popups/add-room/add-room/add-room.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { GeneralListItemComponent } from './components/general-list-item/general-list-item.component';
import { SpaceListItemComponent } from './components/space-list-item/space-list-item.component';
import { UserListItemComponent } from './components/user-list-item/user-list-item.component';
import { ComputerListItemComponent } from './components/computer-list-item/computer-list-item.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserAvatarItemComponent } from './components/user-avatar/user-avatar.component';
import { AvatarModule } from 'ngx-avatars';
import { HttpClientModule } from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import { ShareRoomModalComponent } from './components/popups/share.room.modal/share.room.modal.component';
import { SpacePageComponent } from './pages/space.page/space.page.component';
import { RoomHeaderComponent } from './components/room-header/room-header.component';
import { RoomRightMenuComponent } from './components/room-right-menu/room-right-menu.component';
import { RoomContentAreaComponent } from './components/room-content-area/room-content-area/room-content-area.component';
import { PanelToggleComponent } from './components/panel-toggle/panel-toggle/panel-toggle.component';
import { ScreenCfgSmallComponent } from './components/screen-cfg-small/screen-cfg-small.component';
import { RoomAddComputerDialogComponent } from './components/popups/room-add-computer-dialog/room-add-computer-dialog.component';
import { ComputerContentComponent } from './components/computer-content/computer-content.component';
import { ScreenMediastreamComponent } from './components/screen-mediastream/screen-mediastream.component';
import { ScreenViewerSmallComponent } from './components/screen-viewer-small/screen-viewer-small.component';
import { VideoScreenViewerSmallComponent } from './components/video-screen-viewer-small/video-screen-viewer-small.component';
import { MatSelectModule } from '@angular/material/select';
import { AudioInputComponent } from './components/popups/audio-input/audio-input.component';
import { UserContentComponent } from './components/user-content/user-content.component';
import { UserVideoTrackComponent } from './components/user-video-track/user-video-track.component';
import { VectorRoomListComponent } from './components/vector-room-list/vector-user-list.component';
import { VectorUserListComponent } from './components/vector-user-list/vector-user-list.component';
import { WebviewDirective } from './directive/webview-directive';
import { BrowserComponent } from './components/browser/browser.component';
import { ComputersScreensHorizontalScrollComponent } from './components/computers-screens-horizontal-scroll/computers-screens-horizontal-scroll.component';
import { ComputerScreenListHorizontalComponent } from './components/computer-screen-list-horizontal/computer-screen-list-horizontal.component';
import { ComputerFlatScreenSmallComponent } from './components/computer-flat-screen-small/computer-flat-screen-small.component';
import { ComputerCardComponent } from './components/computer-card/computer-card.component';
import { ComputerScreenLargeInputComponent } from './components/computer-screen-large-input/computer-screen-large-input.component';
import { UserProfileHeaderComponent } from './components/user-profile-header/user-profile-header.component';
import { ProfileModalComponent } from './components/popups/profile.modal/profile.modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OverlayUserHeadComponent } from './components/overlay-user-head/overlay-user-head.component';
import { SharedCardComponent } from './components/shared-card/shared-card.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { RoomSideNavComponent } from './components/room-side-nav/room-side-nav.component';
import { ServerInfoComponent } from './components/server-info/server-info.component';
import { RoomMenuDialogAddComponent } from './components/popups/room-menu-add/room-menu-add.component';
import { ServerPageComponent } from './pages/server.page/server.page.component';
import { RoomMediaListComponent } from './components/room-media-list/room-media-list.component';
import { RoomVectorSpaceComponent } from './components/vector-space/room-vector-space/room-vector-space.component';
import { UserVectorSpaceHandlerComponent } from './components/vector-space/user-vector-space-handler/user-vector-space-handler.component';
import { ComputerVectorSpaceHandlerComponent } from './components/vector-space/computer-vector-space-handler/computer-vector-space-handler.component';
import { BaseOverlayComponent } from './components/base-overlay/base-overlay.component';
import { LinkyModule } from 'ngx-linky';
import { FlatCockpitControllerComponent } from './components/flat-cockpit-controller/flat-cockpit-controller.component';
import { ForgotPasswordDialogComponent } from './components/popups/forgot-password-dialog/forgot-password-dialog.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { SafePipe } from './pipes/safe/safe.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { UserScreenshareLargeComponent } from './components/user-screenshare-large/user-screenshare-large.component';
import { ServerManagerComponent } from './components/server-manager/server-manager.component';
import { UserScreenLargeInputComponent } from './components/user-screen-large-input/user-screen-large-input.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { AvailableScreenListItemComponent } from './components/available-screen-list-item/available-screen-list-item.component';
import { SettingListItemComponent } from './components/setting-list-item/setting-list-item.component';
import { ServerHeaderBarComponent } from './components/server-header-bar/server-header-bar.component';
import { TimeSincePipe } from './pipes/timesincepipe/timesincepipe.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { UserAudioComponent } from './components/user-audio/user-audio.component';
import { TimePastPipe } from 'ng-time-past-pipe';
import { TimeDiffPipe } from './pipes/timediff/timediffpipe.component';
import { AppsModalComponent } from './components/popups/apps.modal/apps.modal.component';
import { AppsScreenComponent } from './components/apps-screen/apps-screen.component';
import { AppCardsComponent } from './components/app-cards/app-cards.component';
import { VideoScreenComponent } from './components/video-screen/video-screen.component';

import { AddFriendModalComponent } from './components/popups/add-friend-modal/add-friend.component';
import { FriendListItemComponent } from './components/friend-list-item/friend-list-item.component';
import { UserInviteManyRoomsDialogComponent } from './components/popups/user-invite-many-rooms-dialog/user-invite-many-rooms-dialog.component';
import { VrCockpitv2Component } from './components/vr-cockpitv2/vr-cockpitv2.component';
import { UserOnlineHeadComponent } from './components/user-online-head/user-online-head.component';
import { RoomMainViewIncallComponent } from './components/room-main-view-incall/room-main-view-incall.component';
import { RoomControlBarComponent } from './components/room-control-bar/room-control-bar.component';
import { UserVideoCardComponent } from './components/user-video-card/user-video-card.component';
import { ComputerListBarComponent } from './components/computer-list-bar/computer-list-bar.component';
import { UserListBarComponent } from './components/user-list-bar/user-list-bar.component';
import { SnackbarNotificationComponent } from './components/popups/snackbar-notification/snackbar-notification.component';
import { PresentModalComponent } from './components/popups/present-modal/present-modal.component';
import { RoomIncallUsersComponent } from './components/room-incall-users/room-incall-users.component';
import { InviteResizeableCardComponent } from './components/invite-resizeable-card/invite-resizeable-card.component';
import { UserVideoResizableCardComponent } from './components/user-video-resizable-card/user-video-resizable-card.component';
import { ComputerHeaderCardComponent } from './components/computer-header-card/computer-header-card.component';
import { LargeStreamModal } from './components/popups/large-stream-modal/large-stream.modal.component';
import { AllowScreenRecordModalComponent } from './components/popups/allow-screen-record-modal/allow-screen-record-modal.component';
import { GetStartedModalComponent } from './components/popups/get-started-modal/get-started-modal.component';
import { AudioSrcMenu } from './components/menu/audio-src-menu/audio-src-menu.component';
import { VideoSrcMenu } from './components/menu/video-src-menu/video-src-menu.component';
import { ComputerListAvailableComponent } from './components/computers-list-available/computer-list-available.component';
import { BasicServerScreenComponent } from './components/basic-server-screen/basic-server-screen.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DialogSignIn,
    ComputerAddModalComponent,
    ComputerCheckComponent,
    AppDownloadModalComponent,
    LeftMenuComponent,
    AddRoomDialogComponent,
    AddFriendModalComponent,
    GeneralListItemComponent,
    SpaceListItemComponent,
    UserListItemComponent,
    ComputerListItemComponent,
    UserMenuComponent,
    UserAvatarItemComponent,
    ShareRoomModalComponent,
    SpacePageComponent,
    RoomHeaderComponent,
    RoomRightMenuComponent,
    RoomContentAreaComponent,
    PanelToggleComponent,
    ScreenCfgSmallComponent,
    RoomAddComputerDialogComponent,
    ComputerContentComponent,
    ScreenMediastreamComponent,
    ScreenViewerSmallComponent,
    VideoScreenViewerSmallComponent,
    AudioInputComponent,
    UserContentComponent,
    UserVideoTrackComponent,
    BrowserComponent,
    ComputersScreensHorizontalScrollComponent,
    ComputerScreenListHorizontalComponent,
    ComputerFlatScreenSmallComponent,
    ComputerCardComponent,
    ComputerScreenLargeInputComponent,
    UserProfileHeaderComponent,
    ProfileModalComponent,
    OverlayUserHeadComponent,
    SharedCardComponent,
    RoomSideNavComponent,
    ServerInfoComponent,
    RoomMenuDialogAddComponent,
    ServerPageComponent,
    RoomMediaListComponent,
    BaseOverlayComponent,
    FlatCockpitControllerComponent,
    ForgotPasswordDialogComponent,
    GetStartedComponent,
    SafePipe,
    UserScreenshareLargeComponent,
    ServerManagerComponent,
    UserScreenLargeInputComponent,
    MainPageComponent,
    AvailableScreenListItemComponent,
    SettingListItemComponent,
    ServerHeaderBarComponent,
    TimeSincePipe,
    UserAudioComponent,
    TimeDiffPipe,
    AppsModalComponent,
    AppsScreenComponent,
    AppCardsComponent,
    VideoScreenComponent,
    FriendListItemComponent,
    UserInviteManyRoomsDialogComponent,
    UserOnlineHeadComponent,
    RoomMainViewIncallComponent,
    RoomControlBarComponent,
    UserVideoCardComponent,
    ComputerListBarComponent,
    UserListBarComponent,
    PresentModalComponent,
    RoomIncallUsersComponent,
    InviteResizeableCardComponent,
    UserVideoResizableCardComponent,
    ComputerHeaderCardComponent,
    LargeStreamModal,
    ComputerListAvailableComponent,
    BasicServerScreenComponent
    
   
    
  ],
  imports: [
    BrowserModule,
    MatTooltipModule,
    AngularFireModule.initializeApp(APP_CONFIG.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    MatProgressBarModule,
    MatDividerModule,
    MatDialogModule,
    AppRoutingModule,
    HttpClientModule,
    MatSidenavModule,
    MatSelectModule,
    MatExpansionModule,
    AvatarModule,
    MatIconModule,
    VrCockpitv2Component,
    VectorRoomListComponent,
    VectorUserListComponent,
    WebviewDirective,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    RoomVectorSpaceComponent,
    UserVectorSpaceHandlerComponent,
    ComputerVectorSpaceHandlerComponent,
    LinkyModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    TimePastPipe,
    SnackbarNotificationComponent,
    AllowScreenRecordModalComponent,
    GetStartedModalComponent,
    AudioSrcMenu,
    VideoSrcMenu,
    NoopAnimationsModule // Disable zone.js for entire application
    
  
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [MatDialog, MatBottomSheet],
  bootstrap: [AppComponent],
  entryComponents: [MatDialogModule],
  exports: [
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatAutocompleteModule,
    LoaderComponent,
    MatDividerModule,
    MatDialogModule,
    DialogSignIn,
    ComputerAddModalComponent,
    ComputerCheckComponent,
    AppDownloadModalComponent,
    LeftMenuComponent,
    AddRoomDialogComponent,
    AddFriendModalComponent,
    MatSidenavModule,
    GeneralListItemComponent,
    SpaceListItemComponent,
    UserListItemComponent,
    ComputerListItemComponent,
    UserMenuComponent,
    ShareRoomModalComponent,
    RoomHeaderComponent,
    RoomRightMenuComponent,
    SpacePageComponent,
    RoomContentAreaComponent,
    PanelToggleComponent,
    ScreenCfgSmallComponent,
    RoomAddComputerDialogComponent,
    ComputerContentComponent,
    ScreenMediastreamComponent,
    ScreenViewerSmallComponent,
    VideoScreenViewerSmallComponent,
    AudioInputComponent,
    UserContentComponent,
    UserVideoTrackComponent,
    VectorRoomListComponent,
    VectorUserListComponent,
    WebviewDirective,
    BrowserComponent,
    ComputersScreensHorizontalScrollComponent,
    ComputerScreenListHorizontalComponent,
    ComputerFlatScreenSmallComponent,
    ComputerCardComponent,
    ComputerScreenLargeInputComponent,
    UserProfileHeaderComponent,
    OverlayUserHeadComponent,
    SharedCardComponent,
    RoomSideNavComponent,
    ServerInfoComponent,
    RoomMenuDialogAddComponent,
    RoomMediaListComponent,
    RoomVectorSpaceComponent,
    UserVectorSpaceHandlerComponent,
    ComputerVectorSpaceHandlerComponent,
    BaseOverlayComponent,
    FlatCockpitControllerComponent,
    ForgotPasswordDialogComponent,
    GetStartedComponent,
    UserScreenshareLargeComponent,
    ServerManagerComponent,
    UserScreenLargeInputComponent,
    MainPageComponent,
    AvailableScreenListItemComponent,
    SettingListItemComponent,
    ServerHeaderBarComponent,
    TimeSincePipe,
    UserAudioComponent,
    FriendListItemComponent,
    UserInviteManyRoomsDialogComponent,
  ]
})
export class AppModule { }
