export enum UserRoomServerState {
    init = 'Initializing',
    loading = 'Loading',
    ready = 'Ready',
    offline = 'Offline',
}

export enum UserRoomServerEvent {
    userServerStateChanged = 'UserServerStateChanged',
    ConnectionEvent = 'ConnectionEvent',
    DisconnectionEvent = 'DisconnectionEvent',
    StreamSharedEvent = 'StreamSharedEvent',
    NewScreen = 'NewScreen',
    ScreensUpdated = 'ScreensUpdated',
    PeerError = 'PeerError'
}