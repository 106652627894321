import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaldbService {
  private storageKeyPrefix = 'aro-settings-';
  constructor() { }

  /**
   * Get spatial settings for a specific room, computer, and user.
   * @param userId (Optional) The ID of the user.
   * @param settingLabel The label of the setting to retrieve.
   * @returns The setting value, or null if not found.
   */
  getSetting(userId: string | null, settingLabel: string): any | null {
    const storageKey = this.getStorageKey(userId, settingLabel);
    return JSON.parse(localStorage.getItem(storageKey));
  }

  /**
   * Save a spatial setting for a specific room, computer, and user
   * @param userId (Optional) The ID of the user.
   * @param settingLabel The label of the setting to save.
   * @param settingValue The value to save.
   */
  saveSetting(userId: string | null, settingLabel: string, settingValue: string): void {
    const storageKey = this.getStorageKey(userId, settingLabel);
    localStorage.setItem(storageKey, JSON.stringify(settingValue));
  }

  /**
   * Update a spatial setting for a specific room, computer, and user.
   * @param userId (Optional) The ID of the user.
   * @param settingLabel The label of the setting to update.
   * @param settingValue The new value to set.
   */
  updateSetting(userId: string | null, settingLabel: string, settingValue: string): void {
    this.saveSetting(userId, settingLabel, settingValue);
  }

  /**
   * Delete a spatial setting for a specific room, computer, and user.
   * @param roomId The ID of the room.
   * @param computerId (Optional) The ID of the computer.
   * @param userId (Optional) The ID of the user.
   * @param settingLabel The label of the setting to delete.
   */
  deleteSetting(userId: string | null, settingLabel: string): void {
    const storageKey = this.getStorageKey(userId, settingLabel);
    localStorage.removeItem(storageKey);
  }

  private getStorageKey(userId: string | null, settingLabel: string): string {
    const ids = [userId].filter(Boolean).join('-');
    return `${this.storageKeyPrefix}${ids}-${settingLabel}`;
  }

}
