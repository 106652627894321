import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MenuItems, MenuTypes } from '../../models/Menu';
import { AuthService } from '../../services/auth-service/auth.service';
import { MenuService } from '../../services/menu-service/menu.service';
import { ComputerObject } from '../../objects/computerObject';
import { RoomObject } from '../../objects/roomObject';

@Component({
  selector: 'room-side-nav',
  templateUrl: './room-side-nav.component.html',
  styleUrls: ['./room-side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomSideNavComponent implements OnInit {
  @Input()
  room: RoomObject;
  @Input()
  computers: ComputerObject[];
  inMenu = false;
  constructor(
    public menuSvc: MenuService,
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    
    this.room.roomComputers$.subscribe((computers) => {
      this.computers = computers;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    })
  }

  async checkifinMenu() {

    const menuItems = await this.menuSvc.getMenuItems();
    const findme = menuItems.find( (item) => { return item.mid === this.room.roomId})
    if(!findme) {
      this.inMenu = true;
      this.cdr.markForCheck();
      this.cdr.detectChanges();

    }
  }

  async addToMenu() {
    const menuItem: MenuItems = {
      uid: this.authSvc.localUser.uid,
      mid: this.room.roomId,
      title: this.room.roomProfile.value.name,
      type: MenuTypes.room
    }
    await this.menuSvc.addMenuItem(menuItem);
  }

}
