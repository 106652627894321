<div style="display: flex; justify-content: center;"><h1 mat-dialog-title>Allow ARO.Work Screensharing</h1></div>
<mat-stepper headerPosition="bottom" #stepper >
    <mat-step [stepControl]="firstFormGroup" style="margin: auto; width:100%;">
        <ng-template matStepLabel>Open Settings</ng-template>
        <img src="assets/img/help-imgs/mac-allow-screenrecording-1.png" width="572px" alt="allow-screen-record" class="allow-screen-record">
        <div style="display: flex; justify-content: center;">
          <button mat-button matStepperNext>Next</button>
        </div>
     
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" style="margin: auto;">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Privacy and Security</ng-template>
       <img src="assets/img/help-imgs/mac-allow-screenrecording-2.png" width="572px" alt="allow-screen-record" class="allow-screen-record">
        <div style="display: flex; justify-content: center;">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Allow ARO.Work</ng-template>
      <img src="assets/img/help-imgs/mac-allow-screenrecording-3.png" width="572px" alt="allow-screen-record" class="allow-screen-record">
        <div style="display: flex; justify-content: center;">
            Done! You should of had to restart the ARO.Work app.
        </div>
      <div style="display: flex; justify-content: center;">
        <button mat-button (click)="testAccess()" #testBtn>{{ finalTestBtnText }}</button>
      </div>
      <div style="display: flex; justify-content: center;">
        <br>
          <button mat-button (click)="finalStep()" #testBtn>Skip</button>
        </div>
    </mat-step>
  </mat-stepper>
