import { Injectable } from '@angular/core';
import { LogService } from '../log-service/log.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  isElectron = false;
  constructor(
    private logSvc: LogService
  ) { 
    this.isElectron = (!!window.electron);
    this.logSvc.info('ElectronService Initiate isElectron:' + JSON.stringify(this.isElectron));
  }

  browsers: object = {};

  isDesktop(): boolean {
    return window.electron;
  };

  // async addBrowser(config: AROBrowserConfig): Promise<AROBrowsers | undefined> {
  //   try {
  //     console.log('Eelectron ' + window.electron);
  //     if(window.electron) {
  //       const result = await window.electron.addBrowser(config);
  //       this.browsers[result.id] = result;
  //       return result;
  //     }
     
  //     return undefined;
  //   } catch(e) {
  //     console.log(e);
  //     throw e
  //   }
    
  // }

  async removeBrowser(id: any): Promise<void> {
    
    if(window.electron) {
      const result = await window.electron.removeBrowser(id);
      return result;
    }

    return undefined;
  }


}
