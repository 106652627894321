import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, take } from 'rxjs';
import { Room, RoomType } from '../../models/Room';
import { RoomObject } from '../../objects/roomObject';
import { AuthService } from '../auth-service/auth.service';
import { ComputerService } from '../computer-service/computer.service';
import { DeviceService } from '../device-service/device.service';
import { LogService } from '../log-service/log.service';
import { StunServiceService } from '../stun-service/stun-service.service';
import { AgoraService } from '../agora-service/agora-service';
import { AudioCueService } from '../AudioCueService/audio-cue.service';
import { NotificationService } from '../notification-service/notification-service.service';
import { MenuService } from '../menu-service/menu.service';
import * as moment from 'moment';
import { MenuTypes } from 'src/app/models/Menu';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  rooms$: BehaviorSubject<RoomObject[]> = new BehaviorSubject([]);
  currentRoom$: BehaviorSubject<RoomObject | null> = new BehaviorSubject(null);
  constructor(
    public afs: AngularFirestore,
    private authService: AuthService,
    public computerSvc: ComputerService, 
    public agoraSvc: AgoraService,
    private ngZone: NgZone, 
    public menuSvc: MenuService,
    public dvcSvc: DeviceService,
    public notificationSvc: NotificationService,
    public logSvc: LogService,
    public stunSvc: StunServiceService, 
  ) { }

  getRoom(room: string | Room): RoomObject {
    if(!room) {
      throw 'No Room Specified';
    }
    if(typeof room === 'string') {
      let rid = room;
      let tmproom = this.rooms$.value.find(r => r.roomId === rid);
      if(!tmproom) {
        tmproom = new RoomObject(rid, this.afs, this.authService, this, this.dvcSvc, this.menuSvc, this.computerSvc, this.agoraSvc, this.stunSvc, this.ngZone,this.notificationSvc, this.logSvc);
        let tmpRooms = this.rooms$.value;
        tmpRooms.push(tmproom);
        this.rooms$.next(tmpRooms);
        
      }
      this.currentRoom$.next(tmproom);
      return tmproom;
    }
    else {
      let tmproom = this.rooms$.value.find(r => r.roomId === room.rid);
      if(!tmproom) {
        tmproom = new RoomObject(room, this.afs, this.authService, this, this.dvcSvc, this.menuSvc, this.computerSvc, this.agoraSvc, this.stunSvc, this.ngZone, this.notificationSvc, this.logSvc,);
        let tmpRooms = this.rooms$.value;
        tmpRooms.push(tmproom);
        this.rooms$.next(tmpRooms);
        
      } 
      return tmproom;
    }
  }

  removeRoom(roomId: string) {
    try {
      const room = this.getRoom(roomId);
      // get the room profile once 
      room.roomProfile.pipe(take(1)).subscribe((roomProfile) => {
        // check if it's your room, if so, delete it, otherwise just delete roster record 
        if(room.roomProfile.value.ownerId === this.authService.user.value.uid) {
          // What to do when user owns the room?
          
        } else {
          // When the user doesn't own the room
        }
        this.afs.collection('room').doc(roomId).collection('roster').doc(this.authService.user.value.uid).delete();
        let tmpRooms = this.rooms$.value;
        tmpRooms = tmpRooms.filter(r => r.roomId !== room.roomId);
        this.rooms$.next(tmpRooms);

      });
    } catch(e) {


    }
    
  }

  getRooms(): BehaviorSubject<RoomObject[]> {
    return this.rooms$;
  }

  createRoom(roomname: string, ): Promise<RoomObject> {
    return new Promise( async (resolve, reject) => {
      console.log(roomname);
      try {
        //Firestore create room 
        this.afs.collection('room').add({
          ownerId: this.authService.user.value.uid,
          ownerName: this.authService.user.value.profile.name,
          name: roomname,
          roomType: RoomType.permanent,
          roomStatus: 'active',
          created:  moment.now(),
          lastUpdated:  moment.now(),
        }).then( (result) => {
          // create roster 
          let roomRef = this.afs.doc(
            `room/${result.id}`
          );
          roomRef.collection('roster').doc(this.authService.user.value.uid).set({
            rid: result.id,
            uid: this.authService.user.value.uid,
            role: 'owner',
            status: 'active',
            created:  moment.now(),
            lastUpdated:  moment.now(),
          }).then( (result2) => {
            // create room object 
            const room = this.getRoom(result.id);
            resolve(room);
          }).catch( (e) => {
            reject(e);
          })
        }).catch( (e) => {
          reject(e);
        })
      } catch(e) {
        reject(e);
      }
    })
  }

  getMyDefaultRoomId(): Promise<string> {
    return new Promise( async (resolve, reject) => {
      try {
        // get the rooms from the menu service
        let rooms = this.menuSvc.menuItems$.value.filter( (items) => {
          return items.type === MenuTypes.room;
        });

        if(rooms.length > 0) {
          resolve(rooms[0].mid);
        }

      } catch(e) {
        reject(e);
      }
    });

  }


}
