import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComputerObjectEvents, ComputerObjectState, ComputerServerEvents } from '../../objects/computerEnum';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { ServerObject } from 'src/app/services/computer-server-service/ServerObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ComputerObject } from '../../objects/computerObject';
import { UserObject } from '../../objects/userObject';
import { RoomObject } from '../../objects/roomObject';
import { RoomService } from 'src/app/services/room.service/room.service';
import { DeviceService } from 'src/app/services/device-service/device.service';
import { Computer } from 'src/app/models/Computers';
import { FormControl } from '@angular/forms';
import { LogService } from 'src/app/services/log-service/log.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';
import * as moment from 'moment';

@Component({
  selector: 'server-info',
  templateUrl: './server-info.component.html',
  styleUrls: ['./server-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerInfoComponent implements OnInit {
  
  @Input()
  server: ServerObject
  @Input()
  computer: ComputerObject;
  @Input()
  config: Computer;
  JSON = JSON;
  availableScreens: any[];
  owner: UserObject;
  rooms: RoomObject[];
  settingConfig: any;
  @Input()
  panelOpenState = false;
  @Input()
  focused: {screenId: string, type?: 'camera' | 'screen', stream: MediaStream, computer?: ComputerObject, user?: UserObject}[] = [];
  timeDiff: number;
  @Input()
  extraStreams: MediaStream[] =[];
  constructor(
    public logSvc: LogService,
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public roomSvc: RoomService,
    public serverSvc: ComputerServerService,
    public devSvc: DeviceService,
    public computerSvc: ComputerService,
    public electronSvc: ElectronService,
    public route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.timeDiff = moment.now() - this.computer.computer.lastOnline;
    this.serverSvc.server$.subscribe(this.updateServer.bind(this));
    
    if(this.computer) {
      this.availableScreens = this.computer.computer.availableScreens;
      this.config = this.computer.computer;
    }

    this.roomSvc.getRooms().subscribe( (rooms) => {
      if(rooms.length) {
        this.rooms = rooms;
      }
    })

    

    this.computer.on(ComputerObjectEvents.computerStateChanged, this.updateScreens.bind(this));
    this.computer.on(ComputerObjectEvents.computerProfileChanged, this.updateProfile.bind(this));
    if(this.electronSvc.isElectron) {
      this.server.on(ComputerServerEvents.computerConfigUpdated, this.updateProfile.bind(this));
    }
    
    this.updateScreens();
  }

  handleUpdateScreens() {
    this.focused = [];
    this.updateProfile();
  }

  updateProfile() {
    this.availableScreens = this.computer.computer.availableScreens;
    this.config = this.computer.computer;
    if(this.server) {
      this.config = this.server.server$.value;
      this.availableScreens = this.config.availableScreens;
    }
    this.updateView();
  }

  async updateScreens() {
    if(this.computer.computerState != 'Ready') {
      this.focused = [];
      return;
    }
    if(this.focused.length ) {
      this.focused = [];
    }
    const videoTracks = this.computer.streams[0].getVideoTracks();
    for (let i = 0; i < videoTracks.length; i++) {
      const screen = videoTracks[i];
      const mediaStream1 = new MediaStream([screen]);
     
      this.extraStreams.push(mediaStream1);
      
      this.focused.push({screenId: this.computer.computer.availableScreens[i].id, stream: mediaStream1, computer: this.computer})
    }
    this.updateView();
  }

  updateServer() {
    if(this.serverSvc.server$.value && !this.server) {
      this.server = this.serverSvc.server$.value;
    }
    if(this.server) {
      this.server.server$.subscribe(this.updateConfig.bind(this));
    }
  }

  computerConfigUpdated(config: any) {
    this.logSvc.info('ServerInfoComponent - computerConfigUpdated');
    this.computer.sendConfigUpdateEvent(this.config);
  }

  screenRemoveEvent(screenId: string) {
    console.log(screenId);
    let item = this.availableScreens.find(screendef => screendef.id == screenId);
    let itemIndex = this.focused.findIndex(screendef => screendef.screenId == screenId);
    if (itemIndex > -1) {
      let item = this.focused.splice(itemIndex, 1);
    }
    console.log(item);
    item.included = false;
    this.computer.sendConfigUpdateEvent(this.config);    

  }

  async updateConfig(serverConfig) {
    if(serverConfig) {
      this.config = await this.server.getConfig();
      this.settingConfig = this.config.baseSettings;
      this.updateView();
    }

  }

  setupSubscriptions() {
    this.server.on(ComputerServerEvents.computerServerUserConnect, this.updateView.bind(this));
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

}
