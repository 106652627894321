import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RoomRoster } from 'src/app/models/Room';
import { RoomEvents } from 'src/app/objects/roomEnums';
import { RoomObject } from 'src/app/objects/roomObject';
import { UserObjectEvent } from 'src/app/objects/userObjectEnum';

@Component({
  selector: 'user-list-bar',
  templateUrl: './user-list-bar.component.html',
  styleUrls: ['./user-list-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListBarComponent implements OnInit {
  @Input()
  roster: RoomRoster[]
  @Input()
  room: RoomObject;
  @Input()
  showUserList: boolean = false;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if(this.room) {
      this.room.onlineRoster$.subscribe(this.updateView.bind(this))
    }
  }


  async updateStatus(item) {
   
    this.updateView();
  }

  updateView() {
    setTimeout(() => {
     
      this.roster = this.room.roster$.value.filter((item) => {return item.userObject.state === 'Ready'});
      
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }, 500)
    
  }

}
