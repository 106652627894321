import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoomObject } from '../../objects/roomObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';
import { AddRoomDialogComponent } from '../popups/add-room/add-room/add-room.component';
import { RoomAddComputerDialogComponent } from '../popups/room-add-computer-dialog/room-add-computer-dialog.component';
import { ShareRoomModalComponent } from '../popups/share.room.modal/share.room.modal.component';
import { VrService } from 'src/app/services/vr-service/vr.service';
import { UpdateServiceService } from 'src/app/services/update-service/update-service.service';
import { Computer } from 'src/app/models/Computers';
import { ComputerObject } from 'src/app/objects/computerObject';
import { ComputerObjectEvents, ComputerObjectState } from 'src/app/objects/computerEnum';

@Component({
  selector: 'get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  steps = [
    { label: 'Setup your space', icon: 'room', content: 'Content for step 1 goes here' },
    { label: 'Add your computer', icon: 'laptop', content: 'Content for step 2 goes here' },
    { label: 'Unlimited VR screens', icon: 'tv', content: 'Content for step 3 goes here' },
    { label: 'Faster computing socially', icon: 'people', content: 'Content for step 4 goes here' }
  ];
  @Input()
  room: RoomObject;
  onlineComputers: ComputerObject[] = [];
  constructor(
    public dialogSvc: MatDialog,
    public computerSvc: ComputerService,
    public electronSvc: ElectronService,
    public updateSvc: UpdateServiceService,
    public cdr: ChangeDetectorRef,
    public vrSvc: VrService
  ) { }

  ngOnInit() {
    this.setupSubscriptions();
  }

  setupSubscriptions() {
    this.computerSvc.computers$.subscribe((computers) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
    this.computerSvc.computers$.subscribe( (computers) => {
      this.onlineComputers = computers.filter( (computer) => {
          computer.on(ComputerObjectEvents.computerStateChanged, this.checkComputers.bind(this));
          return computer.computerState === ComputerObjectState.dataOnline || computer.computerState === ComputerObjectState.ready;
      });
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
    this.checkComputers();
  }

  checkComputers(): void {
    this.computerSvc.computers$.subscribe( (computers) => {
        this.onlineComputers = computers.filter( (computer) => {
            return computer.computerState === ComputerObjectState.dataOnline || computer.computerState === ComputerObjectState.ready;
        });
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    });

}

  addUser() {
    this.openShareRoomDialog('250ms', '250ms');
  }

  openShareRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialogSvc.open(ShareRoomModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  addRoom() {
    this.openAddRoomDialog('250ms', '250ms');
  }

  openAddRoomDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialogSvc.open(AddRoomDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  addComputer() {
    this.openAddComputerDialog('250ms', '250ms');
  }

  openAddComputerDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialogSvc.open(RoomAddComputerDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        room: this.room
      }
    });
  }

  jumpInVR() {
    if(!this.vrSvc.vrEngaged.value) {
      this.vrSvc.toggleVREngaged();
    }
    
    setTimeout( () => {
      this.vrSvc.activateVR()
      
    }, 1000);
    
  }

  clickDownloadClient() {

  }

  clickExploreSpaces() {
    
  }
}
