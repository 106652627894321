<h1 mat-dialog-title>Forgot Password</h1>
<div mat-dialog-content>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendPasswordResetEmail()">
    <p>Enter the email address associated with your account, and we will send you a password reset link.</p>
    <mat-form-field>
      <input matInput type="email" formControlName="email" required>
      <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-button type="submit" [disabled]="forgotPasswordForm.invalid">Send Password Reset Email</button>
    </div>
  </form>
</div>

