import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'vr-cockpitv2',
  templateUrl: './vr-cockpitv2.component.html',
  styleUrls: ['./vr-cockpitv2.component.scss'],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VrCockpitv2Component implements OnInit {
[x: string]: any;
  passthough:boolean = false;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (navigator.xr) {
      navigator.xr.isSessionSupported("immersive-vr").then((isSupported) => {
        if (isSupported) {
          this.passthough = true;
          this.cdr.markForCheck();
          this.cdr.detectChanges();
          // Remove the environment if the pass through is enabled 
          
          // @ts-ignore
          document.getElementById('environmentBackground').removeAttribute('environment');
        }
      })
    }
    console.log('VR Cockpit v2 loaded')
  }

}
