import {Component, Input} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { DeviceService } from '../../../services/device-service/device.service';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
/**
 * @title Basic menu
 */
@Component({
  selector: 'audio-src-menu',
  templateUrl: 'audio-src-menu.component.html',
  styleUrls: [`./audio-src-menu.component.scss`],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, MatIconModule],
})
export class AudioSrcMenu {
  @Input()
  audioSources: MediaDeviceInfo[];
    constructor(
        public devSvc: DeviceService,
    ) {}
  ngOnInit() {
    this.devSvc.getAudioSources().then((sources) => {
      this.audioSources = sources;
    })
  }

  async changeSource(source) {
    await this.devSvc.changeAudioSource(source);
  }
}