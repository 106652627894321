<h1 mat-dialog-title> Invite To Spaces </h1>
<mat-dialog-content>
    <div class="authBlock">
        <div class="formGroup">
            <friend-list-item [uid]="uid" [profile]="profile"></friend-list-item>
        </div>
        <div>
            <div>
                <div class="username" style="width:267px; justify-content: center;">
                    <input style="border: 2px solid; width:237px;" #search id="searchRoom" type="text" placeholder="Spaces Search..." >
                </div>
            </div>
            <div class="multiselect">
                <div *ngFor="let menuItem of  menuSvc.menuItems$.value ">
                    <div class="profile-header-row mat-border-container hoverArea" style="position:relative;" *ngIf="menuItem.type === spaceRoomTypes.room">
                        <div class="left-side-listing" >
                            <user-avatar [name]="'test'" [img]=""></user-avatar>
                        </div>
                        <div class="right-side-listing">
                            <div class="right-side-ontop">
                                <div class="right-side-full" >
                                    {{menuItem.title}}
                                </div>
                            </div>
                        </div>
                        <div class="right-side-full" style="justify-content: right; margin-right: 10px">
                            <mat-icon [matTooltip]="'Add'" >check_circle</mat-icon>
                        </div>
                    </div>
                </div>
                
                    
                </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="formGroup">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Send Invites" (click)="SendInvites()">
        <input type="button" class="mat-raised-button mat-warn" mat-button value="Cancel" (click)="dialogRef.close()">
      </div>
</mat-dialog-actions>
