import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RoomEvents, RoomState } from '../../../objects/roomEnums';
import { RoomObject } from '../../../objects/roomObject';
import { ComputerVectorSpaceHandlerComponent } from '../computer-vector-space-handler/computer-vector-space-handler.component';
import { UserVectorSpaceHandlerComponent } from '../user-vector-space-handler/user-vector-space-handler.component';

@Component({
  selector: 'room-vector-space',
  templateUrl: './room-vector-space.component.html',
  styleUrls: ['./room-vector-space.component.scss'],
  standalone: true,
  imports: [
		CommonModule,
    RoomVectorSpaceComponent,
    UserVectorSpaceHandlerComponent,
    ComputerVectorSpaceHandlerComponent
	],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomVectorSpaceComponent implements OnInit {
  @Input()
  room: RoomObject;
  constructor(
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.room.roomComputers$.subscribe(this.updateView.bind(this));
    this.room.on(RoomEvents.roomStateChange, this.handleStateChange.bind(this));
    

  }
s
  handleStateChange(evt) {
    
    if(evt.data === RoomState.ready) {
      this.updateView();
    }
  }

  updateView() {
    setTimeout( () => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }, 500);
  }

}
