import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { UserObject } from '../../objects/userObject';

@Component({
  selector: 'vector-user-list',
  templateUrl: './vector-user-list.component.html',
  styleUrls: ['./vector-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  standalone: true,
})
export class VectorUserListComponent implements OnInit {
  element: HTMLElement;
  @Input()
  user: UserObject;
  constructor() { }

  ngOnInit(): void {
    
    // setTimeout( () => {
    //   this.element = document.createElement('a-entity');
    //   this.element.setAttribute('class', 'screensClass');
    //   this.element.setAttribute('overlay', 'true');
    //   this.element.setAttribute('mixin', 'screensMixin');
    //   this.element.setAttribute('position', '0.25 -0.75 0.1');
    //   this.element.setAttribute('width', '40');
    //   this.element.setAttribute('height', '30');
    //   this.element.setAttribute('scale', '1 1 1');
    //   this.element.setAttribute('text', "font: mozillavr; value: Test");
    //   this.element.setAttribute('color', '#2F0E06');
    //   document.querySelector('#hud').appendChild(this.element);
    // }, 5000)
  }

}
