import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { LogService } from '../log-service/log.service';
import { AngularFirestore, AngularFirestoreDocument, QuerySnapshot } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InviteItems, InviteType } from '../../models/Invites';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddRoomDialogComponent } from 'src/app/components/popups/add-room/add-room/add-room.component';
import * as moment from 'moment';
import { FriendsService } from '../FriendsService/friends.service';
import { FriendItems } from 'src/app/models/Friend';
import { MenuItem } from 'electron';
import { MenuItems, MenuTypes } from 'src/app/models/Menu';
import { MenuService } from '../menu-service/menu.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  inviteItems$: BehaviorSubject<InviteItems[]> = new BehaviorSubject([]);
  newInvite$: Subject<InviteItems> = new Subject();
  liveInviteSub$: Observable<any>;
  constructor(private authSvc: AuthService,
    private logSvc: LogService,
    private friendSvc: FriendsService,
    private menuSvc: MenuService,
    public dialog: MatDialog,
    public afs: AngularFirestore) { 
      this.logSvc.info('InviteService Init');
      this.setupSubscriptions();
      this.getInviteItems();
    }

    setupSubscriptions() {
      this.logSvc.info('InviteService - setupSubscriptions');
      // this.liveInviteSub$ = this.afs.collection('invites', ref => ref.where('uid', '==', this.authSvc.user.value.uid)).stateChanges(['added']);

      // this.liveInviteSub$.subscribe((results) => {
      //   const newRecords: InviteItems[] = this.mapDocsToInviteItems([results]);
      //   newRecords.forEach((record) => {
      //     this.newInvite$.next(record);
      //   })
      // })
    }

    async getInviteItems():Promise<InviteItems[]> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('InviteService - getInviteItems');
          
          if(this.authSvc.user.value) {
           
            let tmpRef = this.afs.collection('invites', ref => ref.where('uid', '==', this.authSvc.user.value.uid));
            tmpRef.get().subscribe((results) => {
              let tmpResults = this.mapDocsToInviteItems(results.docs);
              this.inviteItems$.next(tmpResults);
              
              resolve(tmpResults);
            })
          } else {
            reject('No User');
          }
        } catch(e) {
          reject(e);
        }
      });
    }

   
    async addInviteItem(item: InviteItems):Promise<any> {
      return new Promise( async (resolve, reject) => {
        try {
          this.logSvc.info('InviteService - addInviteItem');
          item.creation = moment.now()
          let tmpResult = await this.afs.collection('invites').add(item);
          if(tmpResult.id) {
            item.iid = tmpResult.id;
            await this.afs.collection('invites').doc(tmpResult.id).set(item);
            await alert('Invite Sent')
            resolve(item);
          }
        } catch(e) {
          reject(e);
        }
      
      });
    }

    mapDocsToInviteItems(docs: Array<any>): InviteItems[] {
      const menuItems: InviteItems[] = [];
        docs.forEach(doc => {
          const rosterItem = doc.data() as InviteItems;
          rosterItem.iid = doc.id;
          menuItems.push(rosterItem);
        });
        return menuItems;
    }

    async acceptInvite(invite: InviteItems) {
      // Function to accept an invite
      // Get the invite information 
      // if user invite, create the room and update the invite, then add to friends list of current user, and desired user
      // if room invite, add to menu list 
      // remove invite 
      if(invite.type == InviteType.friend) {
        if(!this.friendSvc.findFriend(invite.otherseruid)) {
          let friend: FriendItems = {
            uid: this.authSvc.localUser.uid,
            otherseruid: invite.otherseruid,
  
          }
          await this.friendSvc.addFriend(friend)
        }
      
      }
      if(invite.type == InviteType.room) {
        let menuItem: MenuItems = {
          uid: this.authSvc.localUser.uid,
          title: invite.title,
          type: MenuTypes.room
        }
        await this.menuSvc.addMenuItem(menuItem)
      }

      const inviteIndex = this.inviteItems$.value.findIndex( (cinvite) => {return cinvite.iid === invite.iid});
      const allInvites = this.inviteItems$.value;
      await this.afs.collection('invites').doc(invite.iid).delete();
      allInvites.splice(inviteIndex);
      this.inviteItems$.next(allInvites);
      return;
    }

    async rejectInvite(invite: InviteItems) {
      console.log('reject invite')
      // just remove the invite record and update the local
      const inviteIndex = this.inviteItems$.value.findIndex( (cinvite) => {return cinvite.iid === invite.iid});
      const allInvites = this.inviteItems$.value;
      await this.afs.collection('invites').doc(invite.iid).delete();
      allInvites.splice(inviteIndex);
      this.inviteItems$.next(allInvites);
      return;
    }



}
