import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PublicProfile } from '../../models/Users';
import { LogService } from 'src/app/services/log-service/log.service';

@Component({
  selector: 'overlay-user-head',
  templateUrl: './overlay-user-head.component.html',
  styleUrls: ['./overlay-user-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayUserHeadComponent implements OnInit {
  @Input()
  uid: string;
  @Input()
  profile: PublicProfile;
  @Input()
  message: string;
  @Input()
  isHiddenName = false;
  window = window;
  constructor(
    public logSvc: LogService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(
  
  ): void {
    this.logSvc.info('overlay-user-head onmessage ');
    // This component should be used in child windows 
    if(window.child) {
      window.onmessage = async (e) => {
        const msg = e.data;
        this.logSvc.info('overlay-user-head onmessage ' + msg["type"]);
       
        if(msg["type"] == 'linkshared') {
          this.message = msg["msg"];
          this.updateView();
          setTimeout(this.fadeAwayHelper.bind(this), 8000);
        }

        if(msg["type"] == 'profileupdate') {
          this.profile = msg["profile"];
          this.updateView();
        }
        
      }
      this.profile = window.profile;
    }

    setTimeout(this.updateView.bind(this), 1000);
  }

  prevent(evt) {
    evt.preventDefault();
  }

  clickClose(evt) {
    evt.preventDefault();
    window.close();
  }

  fadeAwayHelper() {
    this.message = '';
    this.updateView();
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  fakeMessage() {
    this.message = "testing";
    setTimeout(this.fadeAwayHelper.bind(this), 8000);
    this.updateView();
  }

  reset() {
    this.message = null;
    
  }

}
