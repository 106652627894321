import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComputerObject } from '../../objects/computerObject';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'server-page',
  templateUrl: './server.page.component.html',
  styleUrls: ['./server.page.component.scss']
})
export class ServerPageComponent implements OnInit {
  @Input()
  computer: ComputerObject;
  constructor(
    public route: ActivatedRoute,
    public computerSvc: ComputerService,
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(async (params) => {
      // @ts-ignore
      const cid = params.computerId
      setTimeout( async () => {
        const tmpcomputer = await this.computerSvc.getComputerObject(cid);
        this.computer = tmpcomputer;
  
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }, 500); 
     
    });
  }

}
