import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AROWindowDetails } from 'electron/AROWindowService';
import * as moment from 'moment';
import { PublicProfile } from '../../models/Users';
import { UserObject } from '../../objects/userObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import getUrls from 'get-urls';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { config } from 'process';

@Component({
  selector: 'base-overlay',
  templateUrl: './base-overlay.component.html',
  styleUrls: ['./base-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
    // animation triggers go here
    state('open', style({
      height: '55px',
      opacity: 1,
    })),
    state('closed', style({
      height: '0px',
      opacity: 0.8,
    })),
    transition('close => open', [
      animate('10s')
    ]),
  ])
  ]
})
export class BaseOverlayComponent implements OnInit {
  @Input()
  overlayData: AROWindowDetails
  @Input()
  user: UserObject;
  @Input()
  uid: string;
  @Input()
  profile: PublicProfile;
  @Input()
  expiry: number;
  @Input()
  sharedMessage: string;
  @Input()
  msgSummaries: {
    type: 'Web' | 'Youtube' | 'User' | 'Room'
    avatar: string,
    header: string,
    subHeader: string,
    link: string
  }[];
  @Input()
  show = false;
  creationTime = moment.now();
  @Input() 
  progress = 100;
  @Input() expanded: boolean;
  @Input() pinned = false;
  @Input() copied = false;
  @ViewChild('overlayContainer', { static: true }) overlayContainer: ElementRef;
  constructor(
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.overlayData = {
      id: '1',
      type: 'static-overlay',
      uid: window.uid,
      profile: window.profile,
      created: moment.now(),
      lastEvent: moment.now(),
      expiry: window.expiry | 10000,
      sharedMessage: window.sharedMessage,
    }
    this.processText(this.overlayData.sharedMessage);
    
    // Setup the progress counter items 
    this.creationTime = moment.now();
    this.expiry = window.expiry | 10000;

    if(this.overlayData.expiry) {
      setTimeout( this.closeOutTimer.bind(this), this.overlayData.expiry);
    }

    setTimeout( () => {
      this.show = true;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      this.expanded = true;
    }, 500);
    
    this.updateExpiryProgress();
    
  }

  pinHUD() {
    this.pinned = !this.pinned;
    if(!this.pinned) {
      this.creationTime = moment.now();
      this.updateExpiryProgress();
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  closeOutTimer() {
    if(!this.pinned) {
      this.closeOut();
    }
  }

  clickCopy() {
    navigator.clipboard.writeText(this.overlayData.sharedMessage);
    this.copied = true;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  updateExpiryProgress() {
    if(this.progress > 100 || this.pinned) {
      return;
    }
    const timeDiff = moment.now() - this.creationTime;
    const progress = 100 - Math.round((timeDiff / this.expiry) * 100);
    this.progress = progress;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    setTimeout( this.updateExpiryProgress.bind(this), 200);
  }

  closeOut() {
    if(window.child) {
      window.close();
    } else {
      this.show = true;

    }
  }

  



  async processText(sharedMessage: string): Promise<{type: 'Web' | 'Youtube' | 'User' | 'Room'
    avatar: string,
    header: string,
    subHeader: string,
    link: string,
  }[]> {
    let summaryResponses:{type: 'Web' | 'Youtube' | 'User' | 'Room'
    avatar: string,
    header: string,
    subHeader: string,
    link: string,
  }[] = [];
    let urls = getUrls(this.overlayData.sharedMessage);
    for (const url of urls) {
      const websum = await window.electron.getSiteSummary(url);
      let newtype = 'Web';
      let newurl = url;
      if(this.getId(url)) {
        newtype = 'Youtube';
        newurl = 'https://www.youtube.com/embed/' + this.getId(url);
      }
      const tmpSum: {type: 'Web' | 'Youtube' | 'User' | 'Room',
      avatar: string,
      header: string,
      subHeader: string,
      link: string,
    } = {
      // @ts-ignore
        type: newtype,
        avatar: websum.banner,
        header: websum.title,
        subHeader: websum.description,
        link: newurl
      };
    
      summaryResponses.push(tmpSum);
    }
    this.msgSummaries = summaryResponses;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
    this.setWindowSize();
    return summaryResponses;

  }

  setWindowSize() {
    setTimeout( () => {
      const width = this.overlayContainer.nativeElement.offsetWidth;
      const height = this.overlayContainer.nativeElement.offsetHeight;
      window.resizeTo(width, height);
    }, 500)
    
  }

  getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return null;
    }
}

}
