import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ComputerObjectEvents, ComputerServerEvents } from '../../objects/computerEnum';
import { RoomObject } from '../../objects/roomObject';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { AuthService } from '../../services/auth-service/auth.service';
import { DeviceService } from '../../services/device-service/device.service';
import { DeviceEvents } from '../../services/device-service/LocalDeviceEnum';
import { LogService } from '../../services/log-service/log.service';
import { RoomService } from '../../services/room.service/room.service';
import { StunServiceService } from '../../services/stun-service/stun-service.service';
import { UserObject } from '../../objects/userObject';
import { AgoraService } from '../../services/agora-service/agora-service';

@Component({
  selector: 'user-content',
  templateUrl: './user-content.component.html',
  styleUrls: ['./user-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserContentComponent implements OnInit {
  @Input()
  user: UserObject;
  @Input()
  room: RoomObject;
  @Input()
  size = 40;
  @Input()
  showNameAlways = false;
  @Input()
  src: string;
  @Input()
  linkSender = false;
  audioLevel: number = 0;
  videoTracks: MediaStreamTrack[] = [];
  element: HTMLElement;
  @Input()
  isHidden = false;
  @Input()
  editor = false;
  @Input()
  editorCallback: any;
  opacity = 1;
  fading = false;
  @Input()
  message = null;
  muted = false; 
  @Input()
  disableVideo = false;
  @ViewChild('userCont') usercont: ElementRef;
  @ViewChild('linktosend') linksendInput: ElementRef;
  @ViewChild('sendhelp') sendhelp: ElementRef;
  constructor(public authSvc: AuthService, 
    public afs: AngularFirestore,
    public stunSvc: StunServiceService,
    public devSvc: DeviceService,
    public agoraSvc: AgoraService,
    public roomSvc: RoomService,
    public logSvc: LogService,
    public zoneSvc: NgZone,
    public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    // Really just for testing -
    
    if(!this.user && window.uid && !window.user) {
      this.user = new UserObject(window.room.rid, window.uid, this.afs, this.logSvc, this.devSvc, this.agoraSvc, this.authSvc, this.stunSvc, this.zoneSvc);
    }
    if(window.user) {
      this.user = window.user;
    }
    if(this.user.streams.length) {
      
      this.videoTracks = this.user.streams[0].getVideoTracks()
    }
    window.onmessage = async (e) => {
      const msg = e.data;
      if(msg["type"] == 'linkshared') {
        this.message = JSON.stringify(msg["msg"]);
        this.updateView();
        setTimeout(this.fadeAwayHelper.bind(this), 8000);
      }
      
    }
    this.user.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this));
    this.user.on(UserObjectEvent.audioLevelUpdate, this.updateView.bind(this));
    this.devSvc.on(DeviceEvents.screenShare, this.updateView.bind(this));
    this.user.on(UserObjectEvent.userStateChanged, this.updateView.bind(this));
    this.user.on(UserObjectEvent.actionMuted, this.muteToggle.bind(this));
    this.devSvc.on(DeviceEvents.videoTrackAdded, this.videoTrackUpdated.bind(this));
  }

  muteToggle() {
    this.muted = this.user.muted;
    this.updateView();
  }

  copyClipboard() {
    this.message = null;
    this.updateView();
  }

  fadeAwayHelper() {
    this.message = null;
    this.updateView();
  }

  videoTrackUpdated(track) {
    if(this.user.uid != this.authSvc.localUser.uid) {
      return;
    }
    if(this.videoTracks && track.data.kind == 'video') {
      if(track.id != this.videoTracks[0].id) {
        this.videoTracks = [];
        this.updateView();
        setTimeout( () => {
          this.videoTracks = [track.data];
          this.updateView();
        },200);
      }
    } else {
      this.videoTracks = this.user.streams[0].getVideoTracks();
      this.updateView();
    }
    
  }

  updateView() {
    this.audioLevel = Math.round(this.user.previousVolume);
    if(this.opacity < 0.9 && this.audioLevel > 20) {
      this.opacity = 1;
    }

    if(!this.videoTracks && this.user.streams.length) {
      console.log(this.user.streams[0].getVideoTracks());
      this.videoTracks = this.user.streams[0].getVideoTracks();
      
    }
    if(!this.room) {
      this.room = window.room;
    }
    // Check for any updates:
    if(this.room && this.room.roomComputers$.value.length) {
      if (this.room.roomComputers$.value.length) {
        this.room.roomComputers$.value[0].on(ComputerObjectEvents.computerMouseEvent, this.mouseEvent.bind(this));
      }
      
    }
   
    if(window.computer) {
      window.computer.on(ComputerObjectEvents.computerMouseEvent, this.mouseEvent.bind(this));
      window.computer.on(ComputerObjectEvents.computerMouseEvent, this.mouseLinkSharedEvent.bind(this));
      window.computer.on(ComputerObjectEvents.computerMouseLinkSharedEvent, this.mouseLinkShared.bind(this));
      window.computer.on(ComputerObjectEvents.computerMouseLinkSharedEvent, this.mouseEvent.bind(this));
    }
    
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

async mouseLinkSharedEvent(e) {
  if(e.data.type === 'linkshared') {
    alert('got here');
  }
}

 async mouseLinkShared(e) {
  const { data, uid, roomId, type} = e.data;
  if(this.user.uid == uid) {
    this.message = data.msg;
   
    this.updateView();
  }
 }

  async mouseEvent(e) {
    
    return new Promise( (resolve, reject) => {
      if(this.user.uid === e.data.uid) {
       
        if(this.user.uid === this.authSvc.user.value.uid && e.data.data.type === 'click') {
   
          this.linkSender = !this.linkSender;
          this.linksendInput.nativeElement.setAttribute('style', 'position:absolute;top:'+ e.data.data.evtData.y + 'px;left:'+ e.data.data.evtData.x + 'px;');
          this.updateView();
        }
        if(window.child) {
          // If this is a child window of user, then the parent serverobject will do the movement. 
          //this.setPosition(e.data.data.evtData.x,e.data.data.evtData.y)
          this.usercont.nativeElement.setAttribute('style', 'position:absolute;top:'+ e.data.data.evtData.y + 'px;left:'+ e.data.data.evtData.x + 'px;');
            this.resetFadeAway();
        } else {
          
            //this.usercont.nativeElement.setAttribute('style', 'position:absolute;top:'+ e.data.data.evtData.y + 'px;left:'+ e.data.data.evtData.x + 'px;');
            this.resetFadeAway();
          }
        }
    })
  }

  resetFadeAway() {
    if(this.fading) {
      return;
    }
    this.opacity = 1;
    this.fader();
  }

  fader() {
    if(window.child) {
      return;
    }
    if(this.opacity < 0.2) {
      this.fading = false;
      return;
    }
    setTimeout(this.fader.bind(this), 50);
    this.opacity = this.opacity - 0.015;
    this.usercont.nativeElement.style.opacity = this.opacity;
    this.fading = true;
  }

  drawUsersHead() {
    
    const screenElem = document.createElement('a-entity');
      screenElem.setAttribute('class', 'screensClass');
      screenElem.setAttribute('overlay', 'true');
      screenElem.setAttribute('promoClick', '');
      //screenElem.setAttribute('position', '-60 -84 -80');
      screenElem.setAttribute('position', '1.5 0.1 -0.1');
      //screenElem.setAttribute('rotation', this.spaceConfig.vectorProps.rotation);
      screenElem.setAttribute('width', '40');
      screenElem.setAttribute('height', '30');
      screenElem.setAttribute('scale', '3 3 3');
      screenElem.setAttribute('text', "font: mozillavr; value: " + this.user.profile.name);
      screenElem.setAttribute('color', '#2F0E06');
      
      
      
      
      //screenElem.setAttribute('look-at', '#camera');
      
      document.querySelector('#hud').appendChild(screenElem);
      this.element = screenElem;

  }

  setPosition(x: number, y: number) {
    this.usercont.nativeElement.setAttribute('style', 'position:absolute;top:'+ y + 'px;left:'+ x + 'px;');
  }

  receiveLink(e) {
    // Link has been shared by the user, and will be shown 
    this.message = e.data.msg;
  }

  sendLink() {
    console.log(this.linksendInput.nativeElement.value);
  }

}
