import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle, } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

@Component({
  templateUrl: 'dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSignIn implements OnInit {
  @ViewChild('userName') userName: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('userPassword') userPassword: ElementRef<HTMLInputElement> | undefined;
  register = false;
  title = 'Login';
  user$?: BehaviorSubject<any>;
  authWorking$?: BehaviorSubject<boolean>;
  loading: boolean = false; 
  constructor(
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public logSvc: LogService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogSignIn>) {
      dialogRef.disableClose = true;  
      
    }

    ngOnInit() {
      this.setupSubscriptions();
    }

    setupSubscriptions() {
      this.user$ = this.authSvc.user;
      this.user$.subscribe( (user) => {
        if(user) {
          this.dialogRef.close();
        }
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      })

      this.authWorking$ = this.authSvc.working;
      this.authWorking$.subscribe( (working) => {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      })

    }

  clickRegisterToggle() {
    this.register = !this.register;
    if(this.register) {
      this.title = 'Register';
    } else {
      this.title = 'Login';
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  clickDismiss() {
    this.logSvc.info('LoginModal - dismiss');
    if(this.authSvc.user.value) {
      this.dialogRef.close();
    } else {
      alert('Currently no user logged in.')
    }
  }

  async signIn() {
    return new Promise( async (resolve, reject) => {
      try {
        this.logSvc.info('Login Modal - Signin: ' + this.userName!.nativeElement.value);
        const loginResult = await this.authSvc.signIn(this.userName!.nativeElement.value, this.userPassword!.nativeElement.value);
        this.clickDismiss();
        resolve(true);
      } catch(e) {
        this.logSvc.error('Signin: ' + this.userName!.nativeElement.value);
        let errorMsg;
        if (e.code === 'auth/wrong-password') {
          errorMsg = 'Wrong password. Try again?';
        } else if (e.code === 'auth/user-not-found') {
          errorMsg = 'User not found. Please make sure your username is correct.';
        } else {
          errorMsg = 'An unknown error occured. Please try again later.';
        }
        alert(errorMsg);
        reject(e);
      }
      
    })
  }

  handle(evt: any) {
    if(evt.keyCode === 13){
      evt.preventDefault(); // Ensure it is only this code that runs

      this.signIn();
  }
  }

  openForgotPasswordDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '320px'
    });
  }

  clickStartRegister(email, password) {
    if(!email || !password) {
      alert('Please enter a valid email and password');
      return;
    }
    if(password.length < 6) {
      alert('Password must be at least 6 characters');
    }
    this.authSvc.signUp(email, password);
    
    this.dialogRef.close();
  }
}
