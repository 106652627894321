<div class='top-menu'>
    <mat-icon (click)="closeMe()">close</mat-icon>
</div>
<mat-dialog-content *ngIf="user">
    <user-profile-header [editor]="true" [user]="user" [room]="room" ></user-profile-header>
    <div class="username-edit" *ngIf="!user.profile || !user.profile.name || cropping" (click)="clickChoosePic()">
        <user-avatar *ngIf="!cropping" (click)="clickChoosePic()" [size]="100" [audioLevel]="99" [name]="'Upload Image'" ></user-avatar>
          <image-cropper *ngIf="cropping && choosePic"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    [roundCropper]="true"
    [cropper-outline-color]="'rgba(255,255,255,0.9)'"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>
          
    </div>
    <div class="icons">
        <mat-icon (click)="clickChoosePic()"> insert_photo</mat-icon>
    </div>
    <input *ngIf="!user.profile || !user.profile.avatar" type="file" (change)="fileChangeEvent($event)" />
          <input *ngIf="choosePic && user.profile" type="file" (change)="fileChangeEvent($event)" />
    <div class="username" *ngIf="user.profile">
        <h1 >{{user.profile.name || 'Enter Username'}}</h1><mat-icon class="icon-display" (click)="editName()">edit</mat-icon>
    </div>
    <div class="username" *ngIf="nameEditors || !user.profile">
        <input style="border: 2px solid red;" #username id="username" type="text" placeholder="Enter Display Name..." (value)="user.profile.name" >
    </div>
    
</mat-dialog-content>
<mat-dialog-actions>
    <div class="formGroup">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Save" (click)="saveProfile()">
    </div>
</mat-dialog-actions>
