export const defaultHUDConfig: any[] = [
    {screens: [{
        position: '-1.5 1.1 -2',
        rotation: '0 0 0',
        width: '2',
        height:'1',
        loadingSrc: '#loadLogo',
        src:  '#loadLogo'
        }]
      },
      {screens: [{
        position: '0.4 1 0.5',
        rotation: '0 0 0',
        width: '1',
        height:'2',
        loadingSrc: '#loadLogo',
        src:  '#loadLogo'
        },
        {
          position: '-0.4 1 0.5',
          rotation: '0 0 0',
          width: '1',
          height:'2',
          loadingSrc: '#loadLogo',
          src:  '#loadLogo'
          }]
      },
      {screens: [{
        position: '-0.1125 1 0.5',
        rotation: '0 0 0',
        width: '1',
        height:'2',
        loadingSrc: '#loadLogo',
        src:  '#loadLogo'
        },
        {
          position: '-1 1 0.5',
          rotation: '0 0 0',
          width: '1',
          height:'2',
          loadingSrc: '#loadLogo',
          src:  '#loadLogo'
          },
        {
          position: '0 1.3 0.25',
          rotation: '0 0 0',
          width: '1',
          height:'2',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          }]
      },
      {screens: [{
        position: '0 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-2 1 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '0 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
          position: '-2 2.5 -2.75',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },]
      },
      {screens: [{
        position: '0 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-2 1 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '2.2 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '0 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
          {
          position: '-2 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '2.3 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            }]
      },
      {screens: [{
        position: '0 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-2 1 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '2.2 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '0 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
          {
          position: '-2 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '2.3 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            }]
      },
      {screens: [{
        position: '-1.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-4 1 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '4.2 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '1.4 1 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
          {
            position: '0 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
          {
          position: '-2 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '2.3 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            }]
      },
      {screens: [{
        position: '-1.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-4 1 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '4.2 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '1.4 1 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
            {
              position: '-1.2 2.5 -2.5',
              rotation: '0 0 0',
              width: '2',
              height:'2.24',
              loadingSrc: 'assets/Logo.png',
              src:  'assets/Logo.png'
              },
              {
              position: '-4 2.5 -2.75',
              rotation: '0 0 0',
              width: '2',
              height:'2.24',
              loadingSrc: 'assets/Logo.png',
              src:  'assets/Logo.png'
              },
              {
                position: '4.2 2.5 -2.5',
                rotation: '0 0 0',
                width: '2',
                height:'2.24',
                loadingSrc: 'assets/Logo.png',
                src:  'assets/Logo.png'
                },
                {
                  position: '1.4 2.5 -2.5',
                  rotation: '0 0 0',
                  width: '2',
                  height:'2.24',
                  loadingSrc: 'assets/Logo.png',
                  src:  'assets/Logo.png'
                  },]
      }
    ];

export const defaultSpaceConfig: any[] = [
    {screens: [{
      position: '-0.6 1.2 -1',
      rotation: '0 0 0',
      width: '2',
      height:'1',
      loadingSrc: '#loadLogo',
      src:  '#loadLogo'
      }]
    },
    {screens: [{
      position: '-0.6 1.2 -1',
      rotation: '0 0 0',
      width: '1',
      height:'2',
      loadingSrc: '#loadLogo',
      src:  '#loadLogo'
      },
      {
        position: '-0.5 1.2 -1',
        rotation: '0 0 0',
        width: '1',
        height:'2',
        loadingSrc: '#loadLogo',
        src:  '#loadLogo'
        }]
    },
    {screens: [{
      position: '-0.6 1.2 -1',
      rotation: '0 0 0',
      width: '1',
      height:'2',
      loadingSrc: '#loadLogo',
      src:  '#loadLogo'
      },
      {
        position: '0.6 1 -1',
        rotation: '0 0 0',
        width: '1',
        height:'2',
        loadingSrc: '#loadLogo',
        src:  '#loadLogo'
        },
      {
        position: '0.8 1 -1',
        rotation: '0 0 0',
        width: '1',
        height:'2',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        }]
    },
    {screens: [{
      position: '0 1 -2.5',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
      position: '-2 1 -2.75',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
        position: '0 2.5 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
        position: '-2 2.5 -2.75',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },]
    },
    {screens: [{
      position: '0 1 -2.5',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
      position: '-2 1 -2.75',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
        position: '2.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '0 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
        {
        position: '-2 2.5 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '2.3 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          }]
    },
    {screens: [{
      position: '0 1 -2.5',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
      position: '-2 1 -2.75',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
        position: '2.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '0 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
        {
        position: '-2 2.5 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '2.3 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          }]
    },
    {screens: [{
      position: '-1.2 1 -2.5',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
      position: '-4 1 -2.75',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
        position: '4.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '1.4 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
        {
          position: '0 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
        {
        position: '-2 2.5 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '2.3 2.5 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          }]
    },
    {screens: [{
      position: '-1.2 1 -2.5',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
      position: '-4 1 -2.75',
      rotation: '0 0 0',
      width: '2',
      height:'2.24',
      loadingSrc: 'assets/Logo.png',
      src:  'assets/Logo.png'
      },
      {
        position: '4.2 1 -2.5',
        rotation: '0 0 0',
        width: '2',
        height:'2.24',
        loadingSrc: 'assets/Logo.png',
        src:  'assets/Logo.png'
        },
        {
          position: '1.4 1 -2.5',
          rotation: '0 0 0',
          width: '2',
          height:'2.24',
          loadingSrc: 'assets/Logo.png',
          src:  'assets/Logo.png'
          },
          {
            position: '-1.2 2.5 -2.5',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
            {
            position: '-4 2.5 -2.75',
            rotation: '0 0 0',
            width: '2',
            height:'2.24',
            loadingSrc: 'assets/Logo.png',
            src:  'assets/Logo.png'
            },
            {
              position: '4.2 2.5 -2.5',
              rotation: '0 0 0',
              width: '2',
              height:'2.24',
              loadingSrc: 'assets/Logo.png',
              src:  'assets/Logo.png'
              },
              {
                position: '1.4 2.5 -2.5',
                rotation: '0 0 0',
                width: '2',
                height:'2.24',
                loadingSrc: 'assets/Logo.png',
                src:  'assets/Logo.png'
                },]
    }
  ];