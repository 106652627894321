<div class="scrolling-container border">
 
    <div *ngIf="!focusComputer && open" class="content">
     
      <div *ngFor="let computer of myComputers; let i = index;" style="justify-content: center; margin-right:20px">
        <div *ngIf="i == 0" style="margin-bottom: 10px; position:absolute">My Computers</div>
        <computer-card  [computer]="computer" [hideBottom]="true" [hideTop]="false" [addToolTip]=""></computer-card>
      </div>
      <div *ngFor="let computer of computers; let i = index;" style="justify-content: center; margin-right:20px">
        <div *ngIf="i == 0" style="margin-bottom: 10px; position:absolute">Shared Computers</div>
        <computer-card  [computer]="computer" [hideBottom]="true" [hideTop]="false"></computer-card>
      </div>
      
    </div>
    <div *ngIf="focusComputer && open" class="content">
      <computer-header-card [computer]="focusComputer" [screenId]="focusScreenId"></computer-header-card>
    </div>
    <div>
      <div class="bottom">
        
        <mat-icon (click)="addComputer()" class="mat-accent" style="margin-right: 5px;">add_circle</mat-icon>
        <mat-icon *ngIf="focusComputer" (click)="removeFocus()">{{focusComputer ? 'keyboard_arrow_left' : ''}}</mat-icon>
        <div style="margin-right: 5px;">{{ focusComputer ? 'Computer: ' + focusComputer.name : 'Computers'}}</div>
        <mat-icon (click)="collapse()">{{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        <mat-slide-toggle [checked]="tracking"></mat-slide-toggle>
      </div>
      
    </div>
  </div>
