<div class='top-menu'>
    <mat-icon (click)="close()">close</mat-icon>
    <div style="display: flex; width: 100%; justify-content: center;">
       <h1> {{title}} </h1>
    </div>
    
</div>

<mat-dialog-content style="height: 100vh;">
    <div [id]="uuid" class="video-area">
        <video *ngIf="stream" #lgviewer [id]="'2d-stream-modal-' + stream.id.toString()" autoplay [srcObject]="stream" ></video>
        
    </div>