import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'available-screen-list-item',
  templateUrl: './available-screen-list-item.component.html',
  styleUrls: ['./available-screen-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableScreenListItemComponent implements OnInit {
  included = new FormControl(false);
  @Input()
  screenDef: {name: string, type: string, thumbnail: string, included?: boolean}
  @Output() screenConfigUpdate: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    if(!this.screenDef.included) {
      this.screenDef.included = this.included.value;
    }
    if(this.screenDef.included) {
      this.included.setValue(this.screenDef.included);
    }

    this.included.valueChanges.subscribe( (change) => {
      this.screenDef.included = change;
      this.screenConfigUpdate.emit(this.screenDef);
    })

  }
}
