<div>
  <div>
    <div>
      <h1>
        Getting Started
      </h1>
    </div>
    <div style="display:flex; justify-content: center; margin-bottom: 10px;">
      <iframe width="380" height="207" src="https://www.youtube.com/embed/X1YMeiPYaJU?si=46gqSFZNJyqt-AF9&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
    </div>
    <h2 *ngIf="onlineComputers.length"> Online Computers</h2>
    <div *ngIf="onlineComputers.length" class="computerSuggestions" style="display: flex; justify-content: center;">
      
      <computer-list-available>
  
      </computer-list-available>
    </div>
  </div>
  
  <div style="display: flex; justify-content: center;">
    <br>
    <br>
    <div *ngIf="!onlineComputers.length" class="platformSuggestAction">
      <button (click)="addRoom()" class="anyaction-btn"><img style="opacity: 0.8; width: 140px;" src="./assets/img/room-multiuser.svg"></button>
      <h3 style="position: absolute; left: 60px; bottom: 10px">Create Spaces</h3>
    </div>
    <div class="platformSuggestAction" *ngIf="room && computerSvc.computers$.value.length && !electronSvc.isElectron">
      <button (click)="addComputer()" class="anyaction-btn"><img style="opacity: 0.8; width: 210px;" src="./assets/img/computingunlimited.svg"></button>
      <h3 style="position: absolute; left: 70px; bottom: 10px">Share Computer</h3>
    </div>
    <div class="platformSuggestAction" *ngIf="!computerSvc.computers$.value.length && !electronSvc.isElectron">
      <a [href]="updateSvc.getUpdateLink()" target="_blank">
      <button (click)="clickDownloadClient()" class="anyaction-btn"><img style="opacity: 0.8; width: 140px;" src="./assets/img/aicomputer.svg"></button>
      <h3 style="position: absolute; left: 60px; bottom: 10px">Download Server</h3>
    </a>
    </div>
    <!-- <div class="platformSuggestAction" *ngIf="!room">
      <button (click)="clickExploreSpaces()" class="anyaction-btn"><img style="opacity: 0.8; width: 210px;" src="./assets/img/spaceship.svg"></button>
      <h1 style="position: absolute; left: 70px; bottom: 10px">Explore Spaces</h1>
    </div> -->
    <div class="platformSuggestAction" *ngIf="room">
      <button (click)="addUser()" class="anyaction-btn"><img style="opacity: 0.8; width: 210px;" src="./assets/img/networking.svg"></button>
      <h1 style="position: absolute; left: 80px; bottom: 10px">Invite Friends</h1>
    </div>
    <div class="platformSuggestAction" *ngIf="room">
      <button (click)="jumpInVR()" class="anyaction-btn"><img style="opacity: 0.8; width: 210px;" src="./assets/img/vr2.svg"></button>
      <h1 style="position: absolute; left: 100px; bottom: 10px">Jump in</h1>
    </div>
  </div>

</div>
  