


export type ScreenConfig = {

    focused?: boolean,
    HUD?: boolean, 
    keepBetweenSpaces?: boolean,
    screenType: ScreenType,
    name: string,
    src?: string,
    videoStatus?: 'Playing' | 'Paused',
    mute?: boolean,
    stream?: MediaStream
    streamVidIndex?: number
    vectorProps?: {
        position: string;
        rotation: string;
        width: string;
        height: string;
        loadingSrc?: string;
        src?: string;
        draw?: boolean;
        parentElement?: string;
        elementType?: string;
    }


}

export interface ScreenObjectInf {
    initialize: (screencfg: ScreenConfig) => Promise<Screen>
    setFocused: (beFocused: boolean) => Promise<Screen>
    setKeepBetweenSpaces: (keep: boolean) => Promise<Screen>
    play: () => Promise<Screen>
    pause: () => Promise<Screen>
    mute: () => Promise<Screen>

}

export enum ScreenType {
    video = 'Video',
    user = 'User',
    computer = 'screenshare',
}