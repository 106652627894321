<div class="mat-border-container fullrow">
    <div style="width:100%;height: 100%; display:flex; justify-content: center; align-items: center;">
        <div class="left-side">
            <input [id]="name" type="checkbox" [formControl]="settingFC"> 
        </div>
        <div class="right-side">
            {{setting.readableLabel}}
        </div>
    </div>
   
</div>
