import {Component, Input} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { DeviceService } from '../../../services/device-service/device.service';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
/**
 * @title Basic menu
 */
@Component({
  selector: 'video-src-menu',
  templateUrl: 'video-src-menu.component.html',
  styleUrls: [`./video-src-menu.component.scss`],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, MatIconModule],
})
export class VideoSrcMenu {
    @Input()
    videoSources: MediaDeviceInfo[];
    constructor(
        public devSvc: DeviceService,
    ) {}

    ngOnInit() {
        this.devSvc.getVideoSources().then((sources) => {
          this.videoSources = sources;
        })
      }
    
      async changeSource(source) {
        await this.devSvc.changeVideoSource(source);
      }
}