import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UpdateServiceService {

  constructor() { 
    //this.setupSubscriptions();
  }

  // setupSubscriptions() {
  //   autoUpdater.on('checking-for-update', () => {
  //     console.log('checking for update');
  //   })
  //   autoUpdater.on('update-available', (info) => {
  //     console.log('update available');
  //   })
  //   autoUpdater.on('update-not-available', (info) => {
  //     console.log('update not available');
  //   })
  //   autoUpdater.on('error', (err) => {
  //     console.log('error in auto-updater');
  //   })
  //   autoUpdater.on('download-progress', (progressObj) => {
  //     console.log('download progress');
  //   })
  //   autoUpdater.on('update-downloaded', (info) => {
  //     console.log('update downloaded');
  //   })
  // }

  // checkforUpdates() {
  //   autoUpdater.checkForUpdatesAndNotify();
  // }

  getUpdateLink() {
    return 'https://update.aro.work/';
  }

  getMainSiteLink() {
    return 'https://dev.aro.work/';
  }
}
