<div class="resizeable-card">
    <div>
        <div style="    display: flex;
        justify-content: center;">
            <button (click)="clickShare()"><mat-icon>supervised_user_circle</mat-icon>Share Space</button>
        </div>
        
        <br/>
        <div>You're the only one here. Invite others to collaborate.</div>
        <br/>
        <div style="    display: flex;
        justify-content: center;">
            <button (click)="clickAddComputer()"><mat-icon>supervised_user_circle</mat-icon>Add Computer</button>
        </div>
    </div>
     
</div>
