import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComputerObject } from 'src/app/objects/computerObject';
import { RoomObject } from 'src/app/objects/roomObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'large-stream-modal',
  templateUrl: './large-stream.modal.component.html',
  styleUrls: ['./large-stream.modal.component.scss']
})

export class LargeStreamModal implements OnInit, AfterViewInit {
    uuid: string;
    stream: MediaStream;
    screenId: string;
    computer: ComputerObject;
    room: RoomObject;
    videoTrack: any;
    title: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {videoTrack: any, stream: MediaStream, screenId: string, computer: ComputerObject, room: RoomObject, title?: string},
        public dialogRef: MatDialogRef<LargeStreamModal>,
        public cdr: ChangeDetectorRef,
        public authSvc: AuthService,
        public logSvc: LogService,
    ) { 
        this.title = this.data.title ? this.data.title : '';
    }

    ngOnInit() {
        this.uuid = uuidv4();
        this.stream = this.data.stream;
        this.screenId = this.data.screenId;
        this.computer = this.data.computer;
        this.room = this.data.room;
        this.videoTrack = this.data.videoTrack;
       
    }

    ngAfterViewInit() {
        // Code to execute after the view is initialized
        // You can interact with the DOM, manipulate elements, or perform other actions here
        if(this.videoTrack) {
            setTimeout(() => {
                this.videoTrack.play( this.uuid);
            }, 200);
           
        }
      }

    close() {
        this.dialogRef.close();
    }
}
