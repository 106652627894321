import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { LogService } from 'src/app/services/log-service/log.service';
import { MatDialog } from '@angular/material/dialog';
import { ComputerAddModalComponent } from '../popups/computer-add.modal/computer-add.modal.component';
import { AddRoomDialogComponent } from '../popups/add-room/add-room/add-room.component';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { ElectronService } from 'src/app/services/electron-service/electron.service';
import { ComputerServerService } from 'src/app/services/computer-server-service/computer.server.service';
import { ComputerServerEvents, ComputerServerState } from '../../objects/computerEnum';
import { RoomObject } from '../../objects/roomObject';
import { RoomService } from 'src/app/services/room.service/room.service';
import { AudioInputComponent } from '../popups/audio-input/audio-input.component';
import { ProfileModalComponent } from '../popups/profile.modal/profile.modal.component';
import { UserObjectEvent } from '../../objects/userObjectEnum';
import { UserObject } from '../../objects/userObject';
import { ComputerObject } from '../../objects/computerObject';
import { BehaviorSubject } from 'rxjs';
import { InviteService } from 'src/app/services/invite-service/invite.service';
import { InviteItems } from 'src/app/models/Invites';
import { FriendsService } from 'src/app/services/FriendsService/friends.service';
import { UpdateServiceService } from 'src/app/services/update-service/update-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
  user$: any;
  user: UserObject;
  computers$: BehaviorSubject<ComputerObject[]>;
  room: RoomObject;
  invitesItems: BehaviorSubject<InviteItems[]> | undefined;
  constructor(
    public authSvc: AuthService,
    public logSvc: LogService,
    public dialog: MatDialog,
    public electronSvc: ElectronService,
    public computerSvc: ComputerService,
    public friendSvc: FriendsService,
    private inviteSvc: InviteService,
    public computerServerSvc: ComputerServerService,
    public router: Router,
    public roomSvc: RoomService,
    public updateSvc: UpdateServiceService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.user$ = this.authSvc.user;
    this.user$.subscribe( this.setupSubscriptions.bind(this));
    this.friendSvc.friends.subscribe(this.updateView.bind(this));
    this.user = this.authSvc.localUser;
    this.invitesItems = this.inviteSvc.inviteItems$;
        this.invitesItems.subscribe( (invites) => {
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        })
    setTimeout( () => {
      this.authSvc.user.subscribe( () => {
        this.user = this.authSvc.localUser;
        
      })
      this.setupSubscriptions();
    }, 2000)
    
  }

  setupSubscriptions() {
    this.computers$ = this.computerSvc.computers$;
    this.computers$.subscribe( this.updateView.bind(this));
    this.roomSvc.currentRoom$.subscribe(this.updateView.bind(this));
    this.computerServerSvc.server$.subscribe(this.updateView.bind(this));
    //this.user.on(UserObjectEvent.userProfileChanged, this.updateView.bind(this));
    
  }

  updateView() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  openComputerAddDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(AddRoomDialogComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openComputerRegister(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ComputerAddModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openSettings(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(AudioInputComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openProfile(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ProfileModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  openRegisterComputer() {
    this.openComputerRegister('200ms', '200ms');

  }

  showRegisterComputer() {
    return (window.electron && this.computerServerSvc.server$.value.state === ComputerServerState.unregistered);
  }

  clickAdd() {
    this.openComputerAddDialog('200ms', '200ms');
  }

  home() {
    this.router.navigate(['/']);
  }

  clickSettings() {
    this.openSettings('200ms', '200ms');
  }

  clickProfile() {
    this.openProfile('200ms', '200ms');
  }

  async acceptFriendInvite(invite: InviteItems) {
    this.inviteSvc.acceptInvite(invite)
    // accept the invite 
  }

  rejectFriendInvite(invite: InviteItems) {
    this.inviteSvc.rejectInvite(invite);
    // reject the invite 
  }

}
