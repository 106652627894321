<div style="display: flex; justify-content: center;"><h1 mat-dialog-title>Getting started with ARO.Work</h1></div>
<mat-stepper headerPosition="bottom" #stepper >
    <mat-step [stepControl]="firstFormGroup" style="margin: auto; width:100%;">
        <ng-template matStepLabel>Name</ng-template>
        <div style="display: flex; justify-content: center;">
            <input style="border: 2px solid red;" #displayname id="displayname" type="text" placeholder="Enter Display Name..." >
        </div>
        <div style="display: flex; justify-content: center;">
            
          <button mat-button matStepperNext>Next</button>
        </div>
     
    </mat-step>
    <!-- <mat-step [stepControl]="secondFormGroup" style="margin: auto;">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Add Photo</ng-template>
        <div style="display: flex; justify-content: center;" [style.width]="(size+4)+'px'" >
            <ngx-avatars class="avatarvolume"  [name]="displayname || 'loading...'" [size]="100" [round]="true" >

            </ngx-avatars>
        </div>
        <div style="display: flex; justify-content: center;">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step> -->
    <mat-step >
      <ng-template matStepLabel>Computer</ng-template>
      <div style="display: flex; justify-content: center;" *ngIf="eltronSvc.isDesktop()">
        <div class="formControl container">

            <div class="formGroup">
                <input
                  type="text"
                  class=""
                  placeholder="Registration ID"
                  [value]="registration"
                  #computerId
                  readonly
                  required
                />
              </div>
              <div class="formGroup">
                <input
                  type="name"
                  class=""
                  placeholder="Computer Name:"
                  [value]="hostnamefield"
                  #hostname
                  required
                />
              </div>
        </div>
      </div>
        <div *ngIf="!eltronSvc.isDesktop()" style="display: flex; justify-content: center; height: 50px;">
          <a [href]="updateSvc.getUpdateLink()" target="_blank">
            <button class="mat-raised-button mat-accent" style="margin: 0px">Download Desktop App</button>
            </a>
           <br>
        </div>
      <div style="display: flex; justify-content: center;">
      <br>
        <!-- <button *ngIf="!eltronSvc.isDesktop()" mat-button (click)="skip()" #testBtn>Skip</button> -->
        <button mat-button (click)="finalStep()" #testBtn>Save</button>
      </div>
    </mat-step>
  </mat-stepper>