import { BehaviorSubject } from "rxjs";
import { UserObject } from "../objects/userObject";
import { PublicProfile, User } from "./Users";

export type Room = {
    rid: string;
    created?: number;
    lastUpdate?: number;
    serverid?: string; //Server it is possibly owned by
    ownerId?: string; //Possible OwnerID of the Room Associated
    name: string;
    description?: string;
    rosterSubject?: BehaviorSubject<RoomRoster[]>;
    screenShares?: BehaviorSubject<ScreenShareCfg[]>

    roomType: RoomType;
};

export type ScreenShareCfg = {

}

export enum RoomType {
    temp = 'TEMP',
    permanent = 'Perminent'
}

export enum RoomRosterAction {
    add = 'ADD',
    remove = 'REMOVE',
    update = 'UPDATE'
}

export type RoomRoster = {
    uid: string;
    rid: string;
    lastUpdate?: number;
    created?: number;
    positioning?: number;
    thumbnail?: string;
    peer?: string;
    role?: string;
    peerData?: [string];  //Peer Data for signalling;
    userProfile?: BehaviorSubject<PublicProfile>;
    userObject?: UserObject;
};

