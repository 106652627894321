<h1 mat-dialog-title><a *ngIf="register" (click)="clickRegisterToggle()"><</a> {{title}}</h1>
<mat-dialog-content *ngIf="authWorking$ && !authWorking$.value">
<div class="modal-body">
  <div class="two-column">
    <div class="displayTable" *ngIf="!register">
      <div class="displayTableCell">
        <div class="authBlock">
          <div class="formGroup">
            <input type="text" class="fullWidth" placeholder="email" #userName required>
          </div>
          <div class="formGroup">
            <div style="width:100%;">
              <input type="password" style="width: 92%;" class="fullWidth" placeholder="Password" #userPassword required (keypress)="handle($event)">
              <span style="font-size:8px;" (click)="openForgotPasswordDialog()">Forgot Password...</span>
            </div>
            
          </div>
          <!-- Calling SignIn Api from AuthService -->
          <div >
            <div class="formGroup">
              <input type="button" class="mat-raised-button mat-accent" mat-button value="Login " (click)="signIn()">
              <input type="button" class="mat-raised-button mat-warn" mat-button value="Register" (click)="clickRegisterToggle()">
            </div>
          </div>
          
        </div>
      </div>
    </div>
        <div class="displayTable" *ngIf="register">
          <div class="displayTableCell">
            <div class="authBlock">
              <div class="formGroup">
                <input
                  type="email"
                  class="fullWidth"
                  placeholder="Email Address"
                  #userEmail
                  required
                />
              </div>
              <div class="formGroup">
                <input
                  type="password"
                  class="fullWidth"
                  placeholder="Password"
                  #userPwd
                  required
                />
              </div>
              <div class="formGroup">
                <input
                  type="password"
                  class="fullWidth"
                  placeholder="Password"
                  #userPwd
                  required
                />
              </div>
              <div class="formGroup container">
                <input
                  type="button"
                  class="mat-raised-button mat-accent"
                  value="Start Registration"
                  (click)="clickStartRegister(userEmail['value'], userPwd['value'])"
                />
              </div>
            </div>
          </div>
        </div>
  </div>
</div></mat-dialog-content>
<mat-dialog-content *ngIf="authWorking$?.value">
  <!-- <div class="container"><img src="assets/img/logo-spinner.png" alt="logo" class="loader-logo"></div> -->
</mat-dialog-content>
<!-- <mat-dialog-actions class="container" *ngIf="!authWorking$?.value">
  <mat-divider></mat-divider>
  <button class="mat-raised-button mat-accent" mat-button (click)="clickRegisterToggle()">{{ register ? 'Login' : 'Register'}}</button>
  The mat-dialog-close directive optionally accepts a value as a result for the dialog. 
</mat-dialog-actions> -->