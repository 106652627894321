import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'setting-list-item',
  templateUrl: './setting-list-item.component.html',
  styleUrls: ['./setting-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingListItemComponent implements OnInit {
  @Input()
  setting: {
    readableLabel: string, 
    type: string,
    default: boolean,
    setTo: boolean
  }
  settingFC = new FormControl(true)
  @Output() configUpdate: EventEmitter<any> = new EventEmitter();
  @Input()
  name: string;
  constructor() { 
    
    

  }

  ngOnInit(): void {
    this.settingFC.setValue(this.setting.setTo);
    this.settingFC.valueChanges.subscribe( (change) => {
      this.setting.setTo = change;
      this.configUpdate.emit(change);
    })
  }

}
