import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import { AuthService } from '../auth-service/auth.service';
import { SnackbarNotificationComponent } from 'src/app/components/popups/snackbar-notification/snackbar-notification.component';
import { RoomObject } from 'src/app/objects/roomObject';
import { AudioCue } from '../AudioCueService/AudioCues';
import { AudioCueService } from '../AudioCueService/audio-cue.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationStayDelay: number = 5; // delay in seconds
  constructor(
    public authSvc: AuthService,
    public audioSvc: AudioCueService,
    private _snackBar: MatSnackBar
  ) { }

  sendLocal(message: string, room?: RoomObject, userId?: string, computerId?: string, screenId?) {
    this._snackBar.openFromComponent(SnackbarNotificationComponent, {
      duration: this.notificationStayDelay * 1000,
      panelClass: 'custom-snackbar',
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        message,
        room,
        userId,
        computerId,


      }
    });

  }

  sendAudioNotification(src: AudioCue) {
    this.audioSvc.playAudio(src);
  }
}
