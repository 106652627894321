<h1 mat-dialog-title> New Space </h1>
<mat-dialog-content>
    <div class="authBlock">
        <div class="formGroup">
          <input type="text" #roomName class="fullWidth" placeholder="Name your space" required>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="formGroup">
        <input type="button" class="mat-raised-button mat-accent" mat-button value="Add" (click)="createRoom()">
        <input type="button" class="mat-raised-button mat-warn" mat-button value="Cancel" (click)="dialogRef.close()">
      </div>
</mat-dialog-actions>
