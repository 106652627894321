import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import { ComputerDataEventTypes } from '../../models/Computers';
import { RoomRoster } from '../../models/Room';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserComponent implements OnInit {
  @Input()
  url: string;
  webview: HTMLElement;
  myControl = new FormControl('');
  options: string[] = [];
  window = window;
  @Input()
  roster: RoomRoster[];
  constructor(
    public authSvc: AuthService,
    public cdr: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.webview = document.createElement('webview');
    this.webview.setAttribute('src', this.url);
    this.webview.setAttribute('style', 'display:inline-flex; width:100%; height:100%;')
    document.querySelector('#browserArea').appendChild(this.webview);
    this.setupSubscriptions();
    this.roster = await window.room.getRoster();
    this.cdr.markForCheck();
    this.cdr.detectChanges();

    return;
  }

  async setupSubscriptions() {
    this.webview.addEventListener('dom-ready', this.handleStartNav.bind(this));
  }

  async handleStartNav(evt) {
    // @ts-ignore
    this.options.push(this.webview.src);
  }

  handleWebview(args?: any) {
    // @ts-ignore
    this.webview.loadURL(this.url);
  }

  goBack() {
    // @ts-ignore
    this.webview.goBack();
  }

  goReload() {
    // @ts-ignore
    this.webview.reload();
    
  }

}
