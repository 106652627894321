import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComputerObject } from '../../objects/computerObject';
import { AuthService } from '../auth-service/auth.service';
import { AngularFirestore, AngularFirestoreDocument, QuerySnapshot } from '@angular/fire/compat/firestore';
import { LogService } from '../log-service/log.service';
import { Computer } from '../../models/Computers';
import { StunServiceService } from '../stun-service/stun-service.service';
import { ComputerServerService } from '../computer-server-service/computer.server.service';
import { NotificationService } from '../notification-service/notification-service.service';
import { ComputerObjectEvents, ComputerObjectState } from 'src/app/objects/computerEnum';
import { MatDialog } from '@angular/material/dialog';
import { ComputerAddModalComponent } from 'src/app/components/popups/computer-add.modal/computer-add.modal.component';

@Injectable({
  providedIn: 'root'
})
export class ComputerService {
  computers$: BehaviorSubject<ComputerObject[]> = new BehaviorSubject([]);
  liveUpdatecomputer$: Observable<any>
  focusComputer: BehaviorSubject<{computer: ComputerObject, screenId: string} | null> = new BehaviorSubject(null);
  constructor( private authSvc: AuthService,
    private logSvc: LogService,
    public ngZone: NgZone,
    public dialog: MatDialog,
    public stunSvc: StunServiceService,
    public notifySvc: NotificationService,
    public afs: AngularFirestore, // Inject Firestore service
    ) {
      this.initialize();
      
      }

  initialize() {
    return new Promise( async (resolve, reject) => {
      try {
        this.logSvc.info('ComputerService - initialize');
        this.authSvc.user.subscribe(async (user) => {
          if(user) {
            const tmpcomps = await this.firstLoadCheck();
            resolve(null);
          }
        });
      } catch(e) {
        reject(e);
      }
    })
  }

  async setupComputerRecordSubscription(): Promise<void> {
    
    return new Promise( async (resolve, reject) => {
      try {
        const querySnap = this.afs.collection('computer', ref => ref.where('ownerUid', '==', this.authSvc.user.value.uid)).stateChanges(['added']);
        this.liveUpdatecomputer$ = querySnap;
        let computers = this.computers$.value;
        this.liveUpdatecomputer$.subscribe((changes) => {
          const newRecords: ComputerObject[] = this.mapComputersAdded(changes);
          computers = computers.concat(newRecords);
          if(computers.length) {
            this.computers$.next(computers);
          }
        });
        resolve();
      } catch(e) {
        reject(e);
      }
    })
  }

  async getRegisteredComputers(force: boolean = false): Promise<ComputerObject[]> {
    
    return new Promise( async (resolve, reject) => {
      try {
        const tmpu = this.authSvc.user.value.uid;
        const querySnap = this.afs.collection('computer', ref => ref.where('ownerUid', '==', tmpu)).get();
        querySnap.subscribe(snap => {
          if(!this.computers$.value.length || force) {
            const tmpComputers: ComputerObject[] = this.mapComputers(snap.docs);
            this.computers$.next(tmpComputers);
          }
          
          resolve(this.computers$.value);
        });
      }
      catch(e) {
        reject(e);
      }
    })

  }

  async firstLoadCheck(): Promise<boolean | ComputerObject[]> {
    return new Promise( async (resolve, reject) => {
      try {
        const computers = await this.getRegisteredComputers();
        
        // Check if it's electron and this computer is registered with the server
        // If not, show the register computer dialog
        if(window.electron == undefined) {
          resolve(computers);
          return;
        }
        const cid = await window.electron.getRegistration();
        const thisComputer = computers.find( (record) => record.cid === cid);

        if(computers.length && thisComputer) {
          resolve(true);
        } else {
          // Show the register computer dialog
          //this.openComputerRegister('200ms', '200ms');
          resolve(false);
        }
      } catch(e) {
        reject(e);
      }
    });
  }

  openComputerRegister(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(ComputerAddModalComponent, {
      width: '320px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  async delete(cid: string): Promise<boolean> {
    return new Promise( async (resolve, reject) => {
      try {
        const querySnap = await this.afs.collection('computer').doc(cid).delete();
        // Remove from local array and update observable
        const tmpComputers = this.computers$.value;
        const tmpIndex = tmpComputers.findIndex( (record) => record.cid === cid);
        tmpComputers.splice(tmpIndex, 1);
        this.computers$.next(tmpComputers); 
        this.notifySvc.sendLocal('Computer Removed');
        resolve(true)
      } catch(e) {
        reject(e);
      }
      
    })
  }

  async getExternalComputer(cid: string, uid: string): Promise<ComputerObject> {
    return new Promise( (resolve, reject) => {
      try {
        const finder = this.computers$.value.find( (entry) =>  entry.cid === cid);
        if(!finder) {
          //const querySnap = this.afs.collection('computer', ref => ref.where('ownerUid', '==', uid).where('cid', '==', cid)).get();
          const querySnap = this.afs.collection('computer', ref => ref.where('cid', '==', cid)).get();
          querySnap.subscribe( (result) => {
            
            let tmpResult = this.mapComputers(result.docs)[0];
            resolve(tmpResult);
          })
        }
          else {
            resolve(finder);
          }
       
      } catch(e) {
        reject(e);
      }
    })
  }

  ngOnDestroy(): void {
    this.computers$.unsubscribe();
    
  }

  mapComputers(querySnapDocs) {
    console.log('mapComputers')
    if(!querySnapDocs) {
  
      return [];
    }
  
    const records = [];
    for (const querySnap of querySnapDocs) {
      const record: Computer = querySnap.data();
      //const tmpcomp = new ComputerObject(record, this.afs, this.logSvc, this.authSvc, this.computerServerSvc.server$.value, this.stunSvc, this.ngZone);
      if(this.computers$ && this.computers$.value) {
        let tmpRecord = this.computers$.value.find( (record1: ComputerObject) => record1.cid === record.cid);
        if(!tmpRecord) {
          const tmpcomp = new ComputerObject(record, this.afs, this.logSvc, this.authSvc, this.stunSvc, this.ngZone);
          records.push(tmpcomp);
        }
      }
     
    };
    return records;
  }


  mapComputersAdded(changes) {
    console.log('mapComputersAdded')
    if(!changes || !changes.length) {
      return [];
    }
    const records = [];
    for (const querySnap of changes) {
      const record: Computer = querySnap.payload.doc.data();
      //const tmpcomp = new ComputerObject(record, this.afs, this.logSvc, this.authSvc, this.computerServerSvc.server$.value, this.stunSvc, this.ngZone);
      if(this.computers$ && this.computers$.value) {
        let tmpRecord = this.computers$.value.find( (record1: ComputerObject) => record1.cid === record.cid);
        if(!tmpRecord) {
          const tmpcomp = new ComputerObject(record, this.afs, this.logSvc, this.authSvc, this.stunSvc, this.ngZone);
          records.push(tmpcomp);
        }
      }
     
    };
    return records;
  }

  async getComputerObject(cid: string): Promise<ComputerObject | null> {
    return new Promise( (resolve, reject) => {
      let tmpRecord = this.computers$.value.find( (record1: ComputerObject) => record1.cid === cid);
      if(!tmpRecord) {
        const querySnap = this.afs.collection('computer').doc(cid).get();
        querySnap.subscribe( (result) => {
          
          let tmpResult: any = result.data();
          if(tmpResult) {
            const tmpcomp = new ComputerObject(tmpResult, this.afs, this.logSvc, this.authSvc, this.stunSvc, this.ngZone);
            const tmpComputers = this.computers$.value;
            tmpComputers.push(tmpcomp)
            this.computers$.next(tmpComputers);
            resolve(tmpcomp);
          }
          resolve(null);
        })
        
      } else {
        resolve(tmpRecord)
      }
    })
    
    
  }

  setFocusComputer(computer: ComputerObject, screenId: string) {
    this.focusComputer.next({computer: computer, screenId: screenId});
    computer.on(ComputerObjectEvents.computerStateChanged, () => {
      if(computer.computerState != ComputerObjectState.ready) {
        this.unFocusComputer();
      }
    })
  }

  unFocusComputer() {
    this.focusComputer.next(null);
  }

}
