
<div class="profile-header-row mat-border-container hoverArea " style="position:relative;">
    
    <div class="left-side-listing" >
        <user-avatar *ngIf="computer && computer.computer && computer.computer.thumbnail" [name]="computer.name" [round]="false" [src]="computer.computer.thumbnail"></user-avatar>
     </div>
     <div class="right-side-listing">
        <div class="right-side-ontop">
            
                <div class="right-side-full" (click)="clickComputer()">
                    {{ computer.name}}
                </div>
            <div class="right-side-full ">
                <div class="right-side-actions">
                    <mat-icon [ngClass]="{'mat-accent' : computer.computerState === 'DataOnline' || computer.computerState === 'Ready' }">desktop_mac</mat-icon>
                </div>
                <!-- <div class="right-side-actions">
                    <mat-icon>settings</mat-icon>
                </div> -->
                <div class="right-side-actions">
                    <mat-icon [ngClass]="{'mat-accent' : computer.computerState === 'Ready'}"> screen_share</mat-icon>
                </div>
                <div class="right-side-actions top-right revealOnHover" style=" transform: scale(0.8);">
                    <mat-icon [id]="'remove-computer-' + computer.cid" class="mat-warn" [matTooltip]="'Remove'" (click)="remove()"> remove_circle</mat-icon>
                </div>
                
            </div>
            <div class="right-side-actions connection-info" *ngIf="computer && computer.connectionDetails$.value" [matTooltip]="computer.connectionDetails$.value.bandwidthMbps + 'Mbps ' + 'Packetloss: ' + computer.connectionDetails$.value.packetLoss + ' Jitter: ' + computer.connectionDetails$.value.jitter">
                {{ computer.connectionDetails$.value.bandwidthMbps }} Mbps
            </div>
       </div>

</div>
 
