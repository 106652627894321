import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItems } from '../../models/Menu';
import { Room, RoomType } from '../../models/Room';
import { ComputerObject } from '../../objects/computerObject';
import { RoomObject } from '../../objects/roomObject';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ComputerService } from 'src/app/services/computer-service/computer.service';
import { MenuService } from 'src/app/services/menu-service/menu.service';
import { RoomService } from 'src/app/services/room.service/room.service';

@Component({
  selector: 'space-page',
  templateUrl: './space.page.component.html',
  styleUrls: ['./space.page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpacePageComponent implements OnInit, OnDestroy {
  @Input()
  spaceId?: string;
  spaceName?: string;
  space?: RoomObject;
  update$?: Observable<any>
  computers?: BehaviorSubject<ComputerObject[]>;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    public actRoute: ActivatedRoute,
    private roomSvc: RoomService,
    private computerSvc: ComputerService,
    public menuSvc: MenuService,
    public authSvc: AuthService,
    private ngZone: NgZone
  ) { 
    
  }

  ngOnInit(): void {
    this.setupSubscriptions();
  }

  async setupSubscriptions() {
    this.update$ = this.actRoute.paramMap;
    this.update$.subscribe(
      async (params: ParamMap) => {
        this.ngZone.run( async() => {
          this.spaceId = params.get('spaceId');
          await this.updateRoomCheck();
          // update the data of the component
        });
        
    
        // call your api etc to get the record against new id
      }
    );
    this.actRoute.queryParams.subscribe( async (params)=> {
      const tmpName = params['name'];
      if(tmpName && tmpName != this.spaceName) {
        this.spaceName = tmpName;
        await this.updateRoomCheck();
      }
      return;
    })

    this.computers = this.computerSvc.computers$;
    this.computers.subscribe( (computers) => {
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
    
  }

  async updateRoomCheck() {
    return new Promise( async (resolve, reject) => {
      try {
        if(this.space && this.space.roomId != this.spaceId) {
          // Old space is different 
          this.space = await this.getRoom();

          this.cdr.detectChanges();
          this.cdr.markForCheck();
        } else {
          if(!this.space) {
            this.space = await this.getRoom();
            this.cdr.detectChanges();
            this.cdr.markForCheck();
          }
        } 
      } catch(e) {
        reject(e);
      }
    })
  }

  async getRoom(): Promise<RoomObject> {
    return new Promise( async (resolve, reject) => {
      try {
        if(this.spaceId && this.spaceName) {
          let tmpRoom: Room = {
            rid: this.spaceId,
            name: this.spaceName,
            roomType: RoomType.permanent
          }
          this.space = await this.roomSvc.getRoom(tmpRoom);
           resolve(this.space);
        } else {
          this.space = await this.roomSvc.getRoom(this.spaceId);
          resolve(this.space);
        }
           
        } 
       catch(e) {
        reject(e);
      }
    })
  }

  routerCanReuse() {
    return false;
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    console.log('Items destroyed');
  }
}
